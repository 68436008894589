<template>
  <v-dialog persistent v-model="showSelf" max-width="700px">
    <v-card>
      <v-card-title class="modalHeader">
        <span class="headline">Square連携設定</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-container grid-list-md>
            <v-flex xs12>
              <div class="inputFieldTitle">使用Squareアカウント</div>
              <v-text-field slot="activator"
                            v-model="squareAccountEmail"
                            @click="openSquareAccountSelectDialog"
                            readonly
                            :rules="squareAccountIdRules"
              ></v-text-field>
              <select_square_account_dialog ref="selectSquareAccountDialog" @select="onSelectSquareAccount"></select_square_account_dialog>
            </v-flex>

            <v-flex xs12>
              <v-text-field
                v-model="settings.companyName"
                label="主催者情報（例：企業名など）"
                :rules="companyNameRules"
                required>
              </v-text-field>
            </v-flex>

            <v-flex xs12>
              <v-text-field
                v-model="settings.email"
                label="返金窓口のメールアドレス"
                :rules="emailRules"
                required>
              </v-text-field>
            </v-flex>

            <v-flex xs12>
              <v-text-field
                v-model="settings.telNumber"
                label="返金窓口の電話番号"
                :rules="telNumberRules"
                required>
              </v-text-field>
            </v-flex>

            <v-flex xs12>
              <v-text-field
                v-model="settings.zipCode"
                label="領収書に表示する郵便番号"
                :rules="zipCodeRules"
                required>
              </v-text-field>
            </v-flex>

            <v-flex xs12>
              <v-text-field
                v-model="settings.address"
                label="領収書に表示する住所"
                :rules="addressRules"
                required>
              </v-text-field>
            </v-flex>

            <v-flex xs12>
              <v-select
                v-model="settings.squareLocationId"
                item-text="name"
                item-value="id"
                :items="listOfSquareLocations"
                label="オンライン店舗情報を設定"
                :rules="squareLocationIdRules"
                required/>
            </v-flex>

            <div class="mt-3">
              <v-btn color="error" @click="onSave" :disabled="!valid">保存</v-btn>
              <v-btn @click.native="close">キャンセル</v-btn>
            </div>

          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import selectSquareAccountDialog from '@/components/square/AssignSquareAccountSelectDialog.vue'
  import {
    getListOfSqLocationsBySquareAccount,
  } from '../../utils/APIUtils'
  export default {
    name: "AssignSquareAccountSelectDialog",
    components: {
      'select_square_account_dialog': selectSquareAccountDialog
    },
    data() {
      return {
        showSelf: false,
        valid: false,

        settings: {
          squareAccountId: 0,
          squareLocationId: '',
          email: null,
          companyName:null,
          address: null,
          zipCode:null,
          telNumber:null,
        },

        squareAccountEmail: '',

        listOfSquareLocations: [],

        squareAccountIdRules: [
          v => !!v || 'Squareアカウントの選択は必須です'
        ],
        squareLocationIdRules: [
          v => !!v || 'オンライン店舗情報の選択は必須です'
        ],
        emailRules: [
          v => !!v || 'メールアドレスは必須です',
          v => /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'メールアドレスの形式で入力を行ってください。'
        ],
        companyNameRules: [
          v => !!v || "主催者名は必須です",
          v => (v && v.length <= 128) || '128文字以下で入力してください'
        ],
        addressRules: [
          v => !!v || "主催者名住所は必須です",
        ],
        zipCodeRules: [
          v => !!v || "郵便番号は必須です",
          v => /^[0-9]{3}-[0-9]{4}$/.test(v) || '郵便番号の形式で入力を行ってください。'
        ],
        telNumberRules: [
          v => !!v || "電話番号は必須です",
          v => /^0\d{1,4}-\d{1,4}-\d{3,4}$/.test(v) || '電話番号の形式で入力を行ってください。'
        ],

      }
    },
    methods: {
      openSquareAccountSelectDialog() {
        this.$refs.selectSquareAccountDialog.openForSelect()
      },
      onSelectSquareAccount(squareAccount) {
        this.settings.squareAccountId = squareAccount.id
        this.squareAccountEmail = squareAccount.email

        this.doGetListOfSqLocations()
      },
      open(settings, squareAccountEmail) {
        this.settings = settings
        this.settings.squareAccountId = settings.squareAccount.id
        this.squareAccountEmail = squareAccountEmail

        this.doGetListOfSqLocations()
        this.showSelf = true
      },
      close() {
        this.showSelf = false
      },
      doGetListOfSqLocations(){
        if(!this.settings.squareAccountId) return

        getListOfSqLocationsBySquareAccount({
          squareAccountId: this.settings.squareAccountId
        }).then(res => {
          this.listOfSquareLocations = res.data
        }).catch(err => {
          console.log("err",err)
        })
      },
      onSave() {
        if(!this.$refs.form.validate()) return

        this.$emit('save', this.settings)
        this.close()
      }
    }
  }
</script>

<style scoped>
  .inputFieldTitle{
    text-align:left;
    font-weight:bold;
  }
</style>
