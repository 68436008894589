<script>
import {
  attendVisitor,
  cancelVisitor,
  deleteVisitor,
  exportCsvFile,
  exportCsvTemplateFile,
  getEventVisitor,
  importCsvFile
} from '../../utils/APIUtils'

export default {
  data () {
    return {
      visitors: [],
      displayEmailDestinationsSelectDialog: false,
      emailDestinationsSelect: 'selected',
      displayEmailForm:false,
      importErrDialog: false,
      importErrMsgs: []
    }
  },
  computed: {
    csvUrl () {
      return exportCsvFile({eventId: this.eventId})
    },
    csvTemplateUrl () {
      return exportCsvTemplateFile({eventId: this.eventId})
    }
  },
  methods: {
    loadEventVisitor () {
      this.selected = []
      getEventVisitor({eventId: this.eventId})
        .then(res => {
          this.visitors = res.data
        }).catch(err => {
        console.error(err)
        alert('データの取得に失敗しました。')
      })
    },
    checkCanImportCsv () {
      document.getElementById('csvFile').click()
    },

    openEditVisitor () {
      this.clearVisitorForm()

      this.newVisitorFlg = false
      var checkBoxSelected = false
      this.visitorIds = ''
      if (this.selected.length === 1) {
        this.inputFormTitle = '来場者情報編集'

        //Set visitor info
        this.visitorForm.id = this.selected[0].id
        this.visitorForm.username = null
        this.visitorForm.password = null
        this.visitorForm.firstName = this.selected[0].firstName
        this.visitorForm.lastName = this.selected[0].lastName
        this.visitorForm.companyName = this.selected[0].companyName
        this.visitorForm.department = this.selected[0].department
        this.visitorForm.position = this.selected[0].position
        this.visitorForm.address = this.selected[0].address
        this.visitorForm.phone = this.selected[0].phone
        this.visitorForm.opt = this.selected[0].opt
        this.visitorForm.eventId = this.eventId
        this.visitorForm.notifications = this.selected[0].notifications

        this.visitorForm.freeItems = []
        this.freeItems.forEach(o => {
          this.visitorForm.freeItems.push({
            visitorOption: o.visitorOption,
            value: this.selected[0].freeItemValuesMap.get(o.visitorOption)
          })
        })

        this.showVisitorDialog = true
      } else {
        alert('編集対象のみ選択してください。')
      }
    },
    checkCancel () {
      var checkBoxSelected = false
      let hasPaidTicket = false
      this.visitorIds = ''
      for (var i = 0; i < this.selected.length; ++i) {
        if (this.selected[i]) {
          this.visitorIds += this.selected[i].id + ','
          checkBoxSelected = true
          if (this.selected[i].ticketPrice > 0) {
            hasPaidTicket = true
          }
        }
      }
      if (hasPaidTicket) {
        if (confirm('参加料徴収を行っている登録者が含まれます。キャンセルしてもよろしいですか？　※別途Square上で決済キャンセル処理が必要です。')) {
          this.cancel(checkBoxSelected)
        }
      } else {
        if (confirm('登録者の申し込みをキャンセルしてもよろしいですか？')) {
          this.cancel(checkBoxSelected)
        }
      }
    },
    cancel (checkBoxSelected) {
      if (checkBoxSelected) {
        cancelVisitor({data: this.visitorIds.split(',').filter(v => v !== ''), eventId: this.eventId})
          .then(res => {
            this.loadEventVisitor()
            alert('申し込みのキャンセルが完了しました。')
          }).catch(err => {
          console.log('err', err)
          alert('申し込みのキャンセルに失敗しました。')
        })
      } else {
        alert('申し込みのキャンセル対象を１つ以上選択してください。')
      }
    },
    deleteEditor () {
      var checkBoxSelected = false
      let hasPaidTicket = false
      this.visitorIds = ''
      for (var i = 0; i < this.selected.length; ++i) {
        if (this.selected[i]) {
          this.visitorIds += this.selected[i].id + ','
          checkBoxSelected = true
          if (this.selected[i].ticketPrice > 0) {
            hasPaidTicket = true
          }
        }
      }
      if (hasPaidTicket) {
        alert('参加料徴収を行っている登録者が含まれるため、削除できません。')
      } else {
        if (confirm('登録者情報の削除をしてもよろしいですか？該当の登録者に関するすべてのデータが削除され、履歴の確認ができなくなります。')) {
          this.executeDelete(checkBoxSelected)
        }
      }
    },
    executeDelete (checkBoxSelected) {
      if (checkBoxSelected) {
        deleteVisitor({data: this.visitorIds.split(',').filter(v => v !== ''), eventId: this.eventId})
          .then(res => {
            this.loadEventVisitor()
            if (this.eventData.tickets.length) {
              this.setTicketStatus()
            }
            alert('削除処理が完了しました。')
          }).catch(err => {
          console.log('err', err)
          alert('削除処理に失敗しました。')
        })
      } else {
        alert('削除対象を１つ以上選択してください。')
      }
    },
    open() {
      if(this.emailDestinationsSelect == "selected") {
        var checkBoxSelected = false
        this.mailTo = ''
        this.visitorIds = ''
        for (var i = 0; i < this.selected.length; ++i) {
          if (this.selected[i]) {
            this.mailTo += this.selected[i].username + ";"
            this.visitorIds += this.selected[i].id + ","
            checkBoxSelected = true
          }
        }
        if (!checkBoxSelected) {
          alert("メール配信対象を１つ以上選択してください。")
          return
        }
      }

      this.displayEmailForm = true
      this.displayEmailDestinationsSelectDialog = false
    },

    importCsv () {
      let input = document.getElementById('csvFile')
      let file = input.files[0]

      if (file === null || file === undefined) {
        return
      }
      if (!confirm('データの取り込みを開始してもよろしいでしょうか？')) {
        this.clearInputFile()
        return
      }
      importCsvFile({file: file, eventId: this.eventId}).then(res => {
        if (res.data.errList && res.data.errList.length > 0) {
          this.importErrMsgs = res.data.errList
          this.importErrDialog = true
        }
        alert(res.data.savedCount + '件のデータの取り込みが完了しました。')
        this.clearInputFile()
        // this.loadEventVisitor()
      }).catch(err => {
        console.error(err)
        this.clearInputFile()
        alert('データの取り込みに失敗しました。')
      })
    },
    clearInputFile() {
      document.getElementById("csvFile").value = ''
      this.ticketIdForImport = null
      this.ignoreStock = false
    },

    checkInVisitor() {
      if (confirm('選択した登録者の来場登録を行ってもよろしいですか？')) {
        var checkBoxSelected = false;
        this.visitorIds = ''
        for (var i = 0; i < this.selected.length; ++i) {
          if (this.selected[i]) {
            this.visitorIds += this.selected[i].id + ","
            checkBoxSelected = true
          }
        }
        if (checkBoxSelected) {
          let data = {
            visitorIds: this.visitorIds.split(",").filter(v => v !== ""),
            subjectName: this.subjectName,
            attendanceType: this.attendanceType,
          }
          attendVisitor({
            data,
            eventId: this.eventId,
          })
            .then(res => {
              alert('受付処理が完了しました。')
              this.displayCheckinDialog = false
            }).catch(err => {
            console.log("err", err)
            alert('受付処理に失敗しました。')
          })
        } else {
          alert("来場受付者を１つ以上選択してください。")
        }
      }
    },
  }
}
</script>
