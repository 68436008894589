<template>
  <v-dialog persistent v-model="showSelf" max-width="700px">
  <v-card>
    <v-card-title class="modalHeader">
      表示順設定
    </v-card-title>
    <v-card-text>
      <div class="shared-file-list" v-if="sharedFilesList.length > 0">
        <div v-on:mouseover="onMouseoverDraggableArea" v-on:mouseleave="onMouseleaveDraggableArea" :class="classDraggableArea">
          <draggable v-model="sharedFilesList" @end="onEndDraggable" draggable=".draggable-item" ghost-class="ghost">
            <div v-for="(item, index) in sharedFilesList" class="draggable-item">
              <v-container fluid style="margin: 0;padding: 0;">
                <v-layout row align-center style="margin: 0;padding: 0;">
                  <v-flex shrink v-if="mouseoverDraggableArea">
                    <v-icon small color="grey lighten-1">fas fa-arrows-alt-v</v-icon>
                  </v-flex>
                  <v-flex grow style="margin: 0;padding: 0;">
                    <div class="shared-file-list-tile active">
                      <div class="shared-file-list-tile-content">
                        <div class="content-title">
                          {{item.fileName}}
                        </div>
                      </div>
                    </div>
                  </v-flex>
                </v-layout>
              </v-container>
            </div>
          </draggable>
        </div>
      </div>
      <div class="shared-file-list" v-else>
        公開ファイルなし
      </div>
    </v-card-text>
    <v-card-actions>
      <v-container>
        <v-layout>
          <v-flex class="text-xs-center">
            <v-btn class="edit-b" @click.native="closeSelf">キャンセル</v-btn>
            <v-btn color="error" @click="onSave">保存</v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-actions>
  </v-card>
  </v-dialog>
</template>

<script>
  import draggable from 'vuedraggable'
  import {setShare} from '../utils/APIUtils'
  export default {
    name: "FileShareSortDialog",
    components: {
      draggable
    },
    data() {
      return {
        showSelf: false,
        eventId: 0,
        sharedFilesList: [],
        confirmMessage: '',

        classDraggableArea: [
          'draggable-area'
        ],
        mouseoverDraggableArea: false
      }
    },
    methods: {
      open(eventId, sharedFilesList, confirmMessage) {
        this.eventId = eventId
        this.sharedFilesList = sharedFilesList
        this.confirmMessage = confirmMessage

        this.sortSharedFilesList()
        this.showSelf = true
      },
      closeSelf() {
        this.showSelf = false
      },
      onSave() {
        if(confirm(this.confirmMessage)){
          this.save()
        }
      },
      save() {
        let sendSharedFiles = []
        for(let i=0; i<this.sharedFilesList.length; i++) {
          let file = this.sharedFilesList[i]
          sendSharedFiles.push({
            id: file.id,
            fileName: file.fileName,
            sortIndex: i + 1
          })
        }
        setShare({
          eventId: this.eventId,
          data: {
            sharedFiles: sendSharedFiles
          }
        }).then(res => {
          this.$emit('succeed');
        }).catch(err => {
          console.log(err)
          this.$emit('failed');
        })

        this.showSelf = false
      },
      onMouseoverDraggableArea() {
        this.mouseoverDraggableArea = true
        this.classDraggableArea = [
          'draggable-area',
          'mouseover'
        ]
      },
      onMouseleaveDraggableArea() {
        this.mouseoverDraggableArea = false
        this.classDraggableArea = [
          'draggable-area'
        ]
      },
      onEndDraggable() {
        let orderedEventIds = this.sharedFilesList.map(e => e.id)
        this.$emit('changeOrder', orderedEventIds)
      },
      sortSharedFilesList() {
        if (this.sharedFilesList.length <= 0) return

        this.sharedFilesList.sort((a, b) => {
          let aSortIndex = (a.sortIndex) ? a.sortIndex : null
          let bSortIndex = (b.sortIndex) ? b.sortIndex : null
          if (aSortIndex != bSortIndex) {
            if (aSortIndex && !bSortIndex) return -1
            if (!aSortIndex && bSortIndex) return 1
            return (aSortIndex < bSortIndex) ? -1 : 1
          }

          let aFileName = (a.fileName) ? a.fileName : ''
          let bFileName = (b.fileName) ? b.fileName : ''
          if (aFileName != bFileName) {
            return (aFileName < bFileName) ? -1 : 1
          }

          return (a.id - b.id)
        })
      }
    }
  }
</script>

<style scoped>
  .shared-file-list {
    border-width: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .shared-file-list-tile {
    margin: 5px;
    border-radius: 10px;
    padding: 10px;

    transition: all 0.3s ease 0s;
  }
  .shared-file-list-tile.active:hover {
    cursor: pointer;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.25);
    transform: translateY(-0.1875em);
  }
  .shared-file-list-tile-content {
    text-align: left;
    width: 100%;
  }
  .shared-file-list-tile-content .content-title {
    width: 100%;
  }

  .draggable-area .draggable-item {
  }
  .draggable-area.mouseover .draggable-item {
    border-radius: 5px;
  }
  .draggable-item.ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }
</style>
