const COOKIE_NAME_EVENT_SITE_INFLOW_SOURCE = 'EIS'
const COOKIE_NAME_BUNDLE_SITE_INFLOW_SOURCE = 'BIS'

const DELIMITER_KEY_VALUE_PAIRS = '/'
const DELIMITER_KEY_VALUE = ':'


export const getCookiesMap = () => {
  let map = new Map()
  let values = document.cookie
  values.split(/;[ ]*/).forEach(keyValue => {
    let elems = keyValue.split('=')
    map.set(elems[0], elems[1])
  })
  return map
}

export const writeInflowSource = (name, publishId, inflowSource) => {
  if(!inflowSource) return
  if(!/^[0-9a-zA-Z_\-\.]*$/.test(inflowSource)) return

  let value = (inflowSource.length <= 30) ? inflowSource : inflowSource.substr(0, 30)

  let cookiesMap = getCookiesMap()
  let nowValues = cookiesMap.has(name) ? cookiesMap.get(name).split(DELIMITER_KEY_VALUE_PAIRS) : []
  let newValues = []
  newValues.push(publishId + DELIMITER_KEY_VALUE + value)
  nowValues.forEach(v => {
    if(newValues.length < 10) {
      let elems = v.split(DELIMITER_KEY_VALUE)
      if(elems[0] == publishId) return
      newValues.push(v)
    }
  })

  document.cookie = name + '=' + newValues.join(DELIMITER_KEY_VALUE_PAIRS)
}

export const readInflowSource = (name, publishId) => {
  let cookiesMap = getCookiesMap()
  if(!cookiesMap.has(name)) return ''

  let nowValues = cookiesMap.get(name).split(DELIMITER_KEY_VALUE_PAIRS)
  for(let i=0; i<nowValues.length; i++) {
    let elems = nowValues[i].split(DELIMITER_KEY_VALUE)
    if(elems[0] == publishId) return elems[1]
  }

  return ''
}

export const writeEventSiteInflowSource = (publishId, inflowSource) => {
  return writeInflowSource(COOKIE_NAME_EVENT_SITE_INFLOW_SOURCE, publishId, inflowSource)
}

export const readEventSiteInflowSource = (publishId) => {
  return readInflowSource(COOKIE_NAME_EVENT_SITE_INFLOW_SOURCE, publishId)
}

export const writeBundleSiteInflowSource = (publishId, inflowSource) => {
  return writeInflowSource(COOKIE_NAME_BUNDLE_SITE_INFLOW_SOURCE, publishId, inflowSource)
}

export const readBundleSiteInflowSource = (publishId) => {
  return readInflowSource(COOKIE_NAME_BUNDLE_SITE_INFLOW_SOURCE, publishId)
}

