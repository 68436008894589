<template>
        <div>
            <v-form ref="eventInformationForm" v-model="valid">
            <div style="margin-top:20px;">
                <v-layout row wrap>
                    <v-flex lg2 xs12>
                        <div style="margin-top:15px;" class="inputFieldTitle">グループ名<span class="essential">必須</span></div>
                    </v-flex>
                    <v-flex lg10 xs12 style="padding-bottom:-10px;">
                        <v-select
                        v-model="event_groupId"
                        :items="event_belongingGroups"
                        :rules="event_groupRules"
                        item-text="groupName"
                        item-value="id"
                        :disabled="eventData.id > 0 && !isCopy"
                        label=""
                        ></v-select>
                    </v-flex>
                    <v-flex lg12 xs12>
                        <div class="inputFieldTitle">イベント名<span class="essential">必須</span></div>
                        <v-text-field
                        v-model="event_name"
                        :rules="event_nameRules"
                        label=""
                        required
                        ></v-text-field>
                    </v-flex>
                    <v-flex lg2 xs12>
                        <div class="inputFieldTitle" style="margin-top:20px;">イベント開始日<span class="essential">必須</span></div>
                    </v-flex>
                    <v-flex lg2 xs12>
                        <v-menu
                            ref="menu1"
                            lazy
                            v-model="event_startdate"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            full-width
                            :nudge-right="40"
                            min-width="290px"
                            :return-value.sync="event_date"
                        >
                          <template v-slot:activator="{on}">
                            <v-text-field
                              v-on="on"
                              v-model="event_date"
                              :rules="event_dateRules"
                              readonly
                            ></v-text-field>

                          </template>
                            <v-date-picker v-model="event_date" no-title scrollable @input="$refs.menu1.save(event_date)">
                            </v-date-picker>
                        </v-menu>
                    </v-flex>
                    <v-flex lg2 xs12>
                        <div class="inputFieldTitle" style="margin-top:20px;">イベント開始時間<span class="essential">必須</span></div>
                    </v-flex>
                    <v-flex lg1 xs6>
                        <v-select
                        :items="hourData"
                        :rules="event_start_hourRules"
                        v-model="event_start_hour"
                        auto
                        label="時"
                        hide-details
                        single-line
                        @change="setEventStartTime"
                        ></v-select>
                    </v-flex>
                    <v-flex lg1 xs6>
                        <v-select
                        :items="minData"
                        :rules="event_start_minRules"
                        v-model="event_start_min"
                        label="分"
                        hide-details
                        single-line
                        @change="setEventStartTime"
                        ></v-select>
                    </v-flex>
                    <v-flex lg2 xs12>
                        <div class="inputFieldTitle" style="margin-top:20px;">イベント終了時間<span class="essential">必須</span></div>
                    </v-flex>
                    <v-flex lg1 xs6>
                        <v-select
                        :items="hourData"
                        :rules="event_end_hourRules"
                        v-model="event_end_hour"
                        auto
                        label="時"

                        single-line
                        @change="setEventEndTime"
                        ></v-select>
                    </v-flex>
                    <v-flex lg1 xs3 xs6>
                        <v-select
                        :items="minData"
                        :rules="event_end_minRules"
                        v-model="event_end_min"
                        label="分"

                        single-line
                        @change="setEventEndTime"
                        ></v-select>
                    </v-flex>
                    <v-flex lg2 xs12>
                        <div class="inputFieldTitle" style="margin-top:20px;">イベント終了日</div>
                    </v-flex>
                    <v-flex lg2 xs12>
                        <v-menu
                            ref="menu_eventEndDate"
                            lazy
                            v-model="event_endDateMenu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            full-width
                            :nudge-right="40"
                            min-width="290px"
                            :return-value.sync="event_endDate"
                        >
                          <template v-slot:activator="{on}">
                            <v-text-field
                              v-on="on"
                              v-model="event_endDate"
                              :rules="event_endDateRules"
                              readonly
                              clearable
                            ></v-text-field>
                          </template>
                            <v-date-picker v-model="event_endDate" no-title scrollable @input="$refs.menu_eventEndDate.save(event_endDate)">
                            </v-date-picker>
                        </v-menu>
                    </v-flex>
                    <v-flex lg2 xs12>
                        <div class="inputFieldTitle" style="margin-top:20px;">掲載期限日</div>
                    </v-flex>
                    <v-flex lg2 xs12>
                        <v-text-field
                        slot="activator"
                        v-model="event_pubPeriodDateTime"
                        @click="dialogPubPeriod = true"
                        readonly
                        ></v-text-field>
                        <v-dialog v-model="dialogPubPeriod" persistent max-width="600px">
                            <v-form ref="eventPubPeriodDateForm" v-model="validPubPeriod">
                                <v-card>
                                    <v-card-title class="modalHeader">
                                        <span class="headline">掲載期限日</span>
                                    </v-card-title>
                                <v-card-text>
                                    <v-container grid-list-md>
                                        <v-layout wrap>
                                            <v-flex xs12 sm6>
                                                <v-menu
                                                ref="menu2"
                                                lazy
                                                v-model="event_pubPeriodDateMenu"
                                                :close-on-content-click="false"
                                                transition="scale-transition"
                                                offset-y
                                                full-width
                                                :nudge-right="40"
                                                min-width="290px"
                                                :return-value.sync="event_pubPeriodDate"
                                                >
                                                <v-text-field
                                                slot="activator"
                                                v-model="event_pubPeriodDate"
                                                :rules="event_pubPeriodDateRules"
                                                readonly
                                                clearable
                                                label="期限日"
                                                ></v-text-field>
                                                <v-date-picker v-model="event_pubPeriodDate" no-title scrollable @input="$refs.menu2.save(event_pubPeriodDate)">
                                                </v-date-picker>
                                                </v-menu>
                                            </v-flex>
                                            <v-flex xs3>
                                                <v-select
                                                :items="hourData"
                                                :rules="event_pubPeriodHourRules"
                                                v-model="event_pubPeriodHour"
                                                auto
                                                label="時"
                                                @change="setEventPubPeriodTime"
                                                ></v-select>
                                            </v-flex>
                                            <v-flex xs3>
                                                <v-select
                                                :items="minData"
                                                :rules="event_pubPeriodMinRules"
                                                v-model="event_pubPeriodMin"
                                                label="分"
                                                @change="setEventPubPeriodTime"
                                                ></v-select>
                                            </v-flex>
                                        </v-layout>
                                    </v-container>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn  @click="dialogPubPeriod = false">キャンセル</v-btn>
                                    <v-btn color="error" @click="setPubPeriodDateTime">保存</v-btn>
                                </v-card-actions>
                                </v-card>
                            </v-form>
                        </v-dialog>
                    </v-flex>

                    <v-flex lg2 xs12>
                        <div class="inputFieldTitle" style="margin-top:20px;">申し込み期限日<span class="essential">必須</span></div>
                    </v-flex>
                    <v-flex lg2 xs12>
                        <v-text-field
                        slot="activator"
                        v-model="event_deadlineDateTime"
                        :rules="event_deadlineDateTimeRules"
                        @click="dialogDeadline = true"
                        readonly
                        ></v-text-field>
                        <v-dialog v-model="dialogDeadline" persistent max-width="600px">
                        <v-form ref="eventDeadlineDateForm" v-model="validDeadline">
                            <v-card>
                                <v-card-title class="modalHeader">
                                    <span class="headline">申し込み期限日</span>
                                </v-card-title>
                                <v-card-text>
                                    <v-container grid-list-md>
                                        <v-layout wrap>
                                            <v-flex xs12 sm6>
                                                <v-menu
                                                ref="menu3"
                                                lazy
                                                v-model="event_deadlineDateMenu"
                                                :close-on-content-click="false"
                                                transition="scale-transition"
                                                offset-y
                                                full-width
                                                :nudge-right="40"
                                                min-width="290px"
                                                :return-value.sync="event_deadlineDate"
                                                >
                                                <v-text-field
                                                slot="activator"
                                                v-model="event_deadlineDate"
                                                :rules="event_deadlineDateRules"
                                                readonly
                                                clearable
                                                label="期限日"
                                                ></v-text-field>
                                                <v-date-picker v-model="event_deadlineDate" no-title scrollable @input="$refs.menu3.save(event_deadlineDate)">
                                                </v-date-picker>
                                                </v-menu>
                                            </v-flex>
                                            <v-flex xs3>
                                                <v-select
                                                :items="hourData"
                                                :rules="event_deadlineHourRules"
                                                v-model="event_deadlineHour"
                                                auto
                                                label="時"
                                                @change="setDeadlineTime"
                                                ></v-select>
                                                </v-flex>
                                                <v-flex xs3>
                                                <v-select
                                                :items="minData"
                                                :rules="event_deadlineMinRules"
                                                v-model="event_deadlineMin"
                                                label="分"
                                                @change="setDeadlineTime"
                                                ></v-select>
                                            </v-flex>
                                        </v-layout>
                                    </v-container>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn  @click="dialogDeadline = false">キャンセル</v-btn>
                                    <v-btn color="error" @click="setDeadlineDateTime">保存</v-btn>
                                </v-card-actions>
                                </v-card>
                            </v-form>
                        </v-dialog>
                    </v-flex>

                    <!-- 空行 -->
                    <v-flex lg4 offset-lg8 xs12>
                    </v-flex>

                    <v-spacer></v-spacer>
                    <v-flex lg6 style="margin-top:15px;">
                        <v-flex lg10>
                          <div class="inputFieldTitle">参加者への来場案内<span class="essential">必須</span></div>
                          <v-radio-group v-model="useFacility" row style="margin-top:5px;">
                            <v-radio label="来場あり" value="true"></v-radio>
                            <v-radio label="来場なし（配信のみ）" value="false"></v-radio>
                          </v-radio-group>
                        </v-flex>
                        <template v-if="useFacility=='true'">
                          <v-flex lg10>
                            <div class="inputFieldTitle">場所<span class="essential">必須</span></div>
                            <v-text-field
                              v-model="event_facility"
                              :rules="event_facilityRules"
                              @keyup="onKeyUpEventFacility"
                            ></v-text-field>
                            <v-list v-show="showFacilitiesList" id="potential_facilities">
                              <v-list-tile
                                v-for="item in event_potential_facilities"
                                :key="item.id"
                                @click="pickFacility"
                                class="tile"
                              >
                                <v-list-tile-content v-html="item.facilityName"></v-list-tile-content>
                              </v-list-tile>
                            </v-list>
                          </v-flex>

                          <v-flex lg10 v-show="!showFacilitiesList">
                            <div class="inputFieldTitle">住所</div>
                            <v-text-field
                              v-model='facility_address'
                              readonly
                            ></v-text-field>
                          </v-flex>
                          <v-flex lg10 v-if="event_rooms.length > 0" v-show="!showFacilitiesList">
                            <div class="inputFieldTitle">会議室<span class="essential">必須</span></div>
                            <v-select
                              :items="event_rooms"
                              :rules="event_venueRules"
                              v-model="selectedEventRoom"
                              auto
                            ></v-select>
                          </v-flex>
                          <v-flex lg10 v-else v-show="!showFacilitiesList">
                            <div class="inputFieldTitle">会議室<span class="essential">必須</span></div>
                            <v-text-field
                              v-model='roomDescription'
                              :rules="event_venueRules"
                            ></v-text-field>
                          </v-flex>
                        </template>
                        <template v-else>
                          <template v-if="onlineEventPlatformsList.length > 1">
                            <v-flex lg10>
                              <div class="inputFieldTitle">プラットフォーム<span class="essential">必須</span></div>
                              <v-radio-group
                                v-model="onlineEventPlatform"
                                row
                                style="margin-top:5px;"
                              >
                                <template v-for="platform in onlineEventPlatformsList">
                                  <v-radio :label="platform.name" :value="platform.value"></v-radio>
                                </template>
                              </v-radio-group>
                            </v-flex>
                            <v-flex lg10 v-if='onlineEventPlatform == "VCUBE"'>
                              <div class="inputFieldTitle">ランディングURL<span class="essential">必須</span></div>
                              <v-text-field
                                v-model='vcubeEvent.landingUrl'
                                :rules="vcubeEventRules.landingUrl"
                              >
                              </v-text-field>
                            </v-flex>
                            <v-flex lg10 v-if='onlineEventPlatform == "ZOOM" && this.orgOnlineEventPlatform != "ZOOM" '>
                              <div class="note pb-3">※イベント登録完了後にZOOM連携設定を行ってください。</div>
                            </v-flex>
                          </template>
                        </template>
                    </v-flex>
                    <v-flex lg6 align-content-center="false" align-center="false" v-if="useFacility=='true'" v-show="!showFacilitiesList">
                        <v-flex lg12 align-content-center="false" align-center="false"  v-if="googleMapUrl!=''">
                            <p class="text-md-left">Google Map</p>
                            <p class="text-md-left" v-html="googleMapUrl">
                            </p>
                        </v-flex>
                        <v-flex lg12 v-if="mapUrlValid && mapUrl != null">
                            <p class="text-md-left">オリジナルマップ</p>
                            <p class="text-md-left"><img :src=mapUrl width="520" @error="imgError()"></p>
                        </v-flex>
                    </v-flex>
                </v-layout>
            </div>
            <v-btn class="edit-b" v-if="eventData.id !== undefined" :to="{path: '/RegistrationStatus', query: {id: eventData.id}}">
                戻る
            </v-btn>
            <v-btn v-else class="edit-b" href="./#/EventSchedule">
                戻る
            </v-btn>
            <v-btn color="error"  @click.native="moveNextStep()">次へ</v-btn>
            </v-form>
        </div>
</template>

<script>
import moment from "moment";
import {getOnlineEventPlatformsList} from '../../utils/APIUtils'
export default {
    props: ['facilities', 'eventData', 'groups', 'optionVcube'],
    watch: {

        groups(data) {
            this.event_belongingGroups = data
        },
        eventData(data){
            this.eventData = data
            //Edit
            if(this.eventData.id !== undefined){
                //Set Values
                this.event_groupId = this.eventData.eventGroup.id
                this.event_name = this.eventData.eventName
                if(this.$route.query.cp){
                  this.isCopy = true
                  if(!this.event_name.endsWith('（コピー）')){
                    this.event_name+="（コピー）"
                  }
                }
                this.event_date = this.eventData.eventDate
                let startTime = this.eventData.startTime.split(":")
                this.event_start_hour = startTime[0]
                this.event_start_min = startTime[1]
                let endTime = this.eventData.endTime.split(":")
                this.event_end_hour = endTime[0]
                this.event_end_min = endTime[1]
                this.event_endDate = this.eventData.eventEndDate

                let pubDateTime
                if(this.eventData.eventPubDateTime === null){
                    this.event_pubPeriodDate = null
                    this.event_pubPeriodHour = ''
                    this.event_pubPeriodMin = ''
                }
                else{
                    pubDateTime = moment(this.eventData.eventPubDateTime).toDate()
                    this.event_pubPeriodDate = pubDateTime.getFullYear() + "-" + ("0"+(pubDateTime.getMonth()+1)).slice(-2) + "-" + ("0"+pubDateTime.getDate()).slice(-2)
                    this.event_pubPeriodHour = ("0"+pubDateTime.getHours()).slice(-2)
                    this.event_pubPeriodMin = ("0"+pubDateTime.getMinutes()).slice(-2)
                    this.event_pubPeriodDateTime = this.event_pubPeriodDate + ' ' + this.event_pubPeriodHour + ':' + this.event_pubPeriodMin
                }

                let deadlineDateTime
                if(this.eventData.deadline === null){
                    this.event_deadlineDate = null
                    this.event_deadlineHour = ''
                    this.event_deadlineMin = ''
                }
                else{
                    deadlineDateTime = moment(this.eventData.deadline).toDate()
                    this.event_deadlineDate = deadlineDateTime.getFullYear() + "-" + ("0"+(deadlineDateTime.getMonth()+1)).slice(-2) + "-" + ("0"+deadlineDateTime.getDate()).slice(-2)
                    this.event_deadlineHour = ("0"+deadlineDateTime.getHours()).slice(-2)
                    this.event_deadlineMin = ("0"+deadlineDateTime.getMinutes()).slice(-2)
                    this.event_deadlineDateTime = this.event_deadlineDate + ' ' + this.event_deadlineHour + ':' + this.event_deadlineMin
                }

                if(! this.event_org_facilityId) {
                    this.event_org_facilityId = this.eventData.facility.id
                }
                if(! this.event_org_roomDescription) {
                    this.event_org_roomDescription = this.eventData.roomDescription
                }

                this.showFacilitiesList = false

                if(this.eventData.facility.id > 0) {
                  this.useFacility = 'true'

                  if(this.orgOnlineEventPlatform == 'NO_SET_YET') {
                    this.orgOnlineEventPlatform = null
                  }

                  this.event_facility_obj = this.eventData.facility
                  this.event_facility = this.selectedFacility = this.eventData.facility.facilityName
                  this.facility_address = this.eventData.facility.address
                  this.googleMapUrl = this.eventData.facility.googleMapUrl
                  this.mapUrl = this.eventData.facility.mapUrl

                  this.setEventRoomsItems()

                  if(this.event_rooms.length > 0) {
                    this.selectedEventRoom = this.eventData.roomDescription
                  } else {
                    this.roomDescription = this.eventData.roomDescription
                  }
                } else {
                  this.useFacility = 'false'

                  if(this.orgOnlineEventPlatform == 'NO_SET_YET') {
                    this.orgOnlineEventPlatform = this.eventData.onlineEventPlatform
                  }

                  this.onlineEventPlatform = this.eventData.onlineEventPlatform
                  if(this.onlineEventPlatform == 'VCUBE') {
                    this.vcubeEvent.landingUrl = this.eventData.vcubeEvent.landingUrl
                  }
                }

            }

            this.setOnlineEventPlatformsList()
        }
    },
    data() {
        return {
            isCopy: false,

            valid:false,
            validPubPeriod:false,
            validDeadline:false,

            dialogPubPeriod:false,
            dialogDeadline:false,
            //イベント基本情報
            readonly: true,
            event_name: '',
            event_nameRules: [
                v => !!v || 'イベント名は必須です',
                v => (v && v.length <= 200)|| '200文字以下で入力してください'
            ],
            event_date: null,
            event_dateRules: [
                v => !!v || 'イベント開始日は必須です',
                v => this.eventDateCheck(v) || '終了日より前の日を入力してください'
            ],
            event_start:'09:00',
            event_start_hour:'09',
            event_start_hourRules: [
                v => !!v || 'イベント開始時間の時間は必須です',
            ],
            event_start_min:'00',
            event_start_minRules: [
                v => !!v || 'イベント開始時間の分は必須です',
            ],
            event_end:'10:00' ,
            event_end_hour:'10',
            event_end_hourRules: [
                v => !!v || 'イベント終了時間の時間は必須です',
                v => parseInt(v,10) >= parseInt(this.event_start_hour,10) || '開始時間より後の時間を入力してください。'
            ],
            event_end_min:'00',
            event_end_minRules: [
                v => !!v || 'イベント終了時間の分は必須です',
                v => this.timeCheck(v) || '開始時間より後の時間を入力してください'
            ],
            event_endDate:null,
            event_endDateRules: [
                v => this.eventEndDateCheck(v) || '開始日より後の日を入力してください'
            ],

            event_startdate: false,
            event_time1: false,
            event_time2: false,
            event_endDateMenu:false,

            event_pubPeriodDateTime:'',
            event_pubPeriodDate:null,
            event_pubPeriodDateRules: [v => this.isPubPeriodDateInput() || '日付が入力されていません'],
            event_pubPeriodDateMenu:false,
            event_pubPeriodHour:'',
            event_pubPeriodHourRules: [v => this.isPubPeriodHourInput() || '時間が入力されていません'],
            event_pubPeriodMin:'',
            event_pubPeriodMinRules: [v => this.isPubPeriodMinInput() || '分が入力されていません'],
            event_pubPeriodTime:'',

            event_deadlineDateTime:'',
            event_deadlineDateTimeRules: [v => !!v || '申し込み期限日は必須です'],
            event_deadlineDate:null,
            event_deadlineDateRules: [
              v => this.isDeadlineDateInput() || '日付が入力されていません',
              v => this.isDeadlineInput() || '申し込み期限日は必須です'
            ],
            event_deadlineDateMenu:false,
            event_deadlineHour:'',
            event_deadlineHourRules: [v => this.isDeadlineHourInput() || '時間が入力されていません'],
            event_deadlineMin:'',
            event_deadlineMinRules: [v => this.isDeadlineMinInput() || '分が入力されていません'],
            event_deadlineTime:'',

            hourData:['','00','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23'],
            minData:['','00','05','10','15','20','25','30','35','40','45','50','55'],

            useFacility: 'true',
            event_facility: '',
            event_facility_obj: {},
            selectedFacility:'',
            event_facilityRules: [
                v => !!v || '場所は必須です',
                v => this.event_facility == this.selectedFacility || '場所を選択してください'
            ],
            selectedEventRoom:'',
            roomDescription: '',
            event_venueRules: [
                v => !!v || '会議室は必須です',
            ],

            event_potential_facilities: [],
            event_rooms: [],
            event_org_facilityId: '',
            event_org_roomDescription: '',

            showFacilitiesList: true,

            facility_address:'',
            googleMapUrl:null,

            mapUrl:null,
            mapUrlValid:true,

            onlineEventPlatformsList: [],
            onlineEventPlatform: "others",
            orgOnlineEventPlatform: 'NO_SET_YET',
            vcubeEvent: {
              landingUrl: "",
            },
            vcubeEventRules: {
              landingUrl: [
                v => !!v || 'ランディングURLは必須です',
                v => (v && v.length <= 255)|| '255文字以下で入力してください',
                v => /https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]\.[^\s]{2,}/.test(v) || 'URLの形式で入力してください',
              ]
            },

            event_belongingGroups:[],
            event_groupId:'',
            event_groupRules: [
                v => !!v || 'グループは必須です',
            ],
        }
    },

    methods: {
        onKeyUpEventFacility() {
            this.event_rooms = []
            this.selectedEventRoom = ''

            this.event_potential_facilities = this.facilities
                .filter(item => item.facilityName.toLowerCase().includes(this.event_facility.toLowerCase())
                                && this.event_facility !== "")
            this.showFacilitiesList = true
        },
        moveNextStep() {
            let eventPubDateTime
            if (this.event_pubPeriodDate !== null){eventPubDateTime = this.event_pubPeriodDate + " " + this.event_pubPeriodTime + ":00"}
            else {eventPubDateTime = null}
            let eventDeadLineDateTime
            if (this.event_deadlineDate !== null) {eventDeadLineDateTime = this.event_deadlineDate + " " + this.event_deadlineTime + ":00"}
            else { eventDeadLineDateTime = null}
            if (this.$refs.eventInformationForm.validate()) {
              　if(this.useFacility == 'false') {
                  this.event_facility_obj = {id: 0}
                  this.selectedEventRoom = ""
                  this.roomDescription = ""
                  this.facility_address = ""
                  this.googleMapUrl = ""
                  this.mapUrl = ""
                  this.mapUrlValid = true
                }

                this.$emit("forward", {
                    eventName: this.event_name,
                    eventDate: this.event_date,
                    startTime: this.event_start,
                    endTime: this.event_end,
                    eventEndDate:this.event_endDate,
                    eventPubDateTime:eventPubDateTime,
                    deadline:eventDeadLineDateTime,
                    facilityId: this.event_facility_obj.id,
                    facility: this.event_facility_obj,
                    roomDescription : (this.event_rooms.length > 0) ? this.selectedEventRoom : this.roomDescription,
                    googleMapUrl:this.googleMapUrl,
                    mapUrl:this.mapUrl,
                    groupId:this.event_groupId,
                    mapUrlValid:this.mapUrlValid,
                    onlineEventPlatform:this.onlineEventPlatform,
                    landingUrl:this.landingUrl,
                    vcubeEvent:(this.onlineEventPlatform == "VCUBE") ? this.vcubeEvent : {},
                })
            }
        },
        pickFacility(e) {
            this.showFacilitiesList = false

            this.selectedEventRoom = ""
            this.roomDescription = ""

            this.event_facility = this.selectedFacility = e.target.innerText
            this.event_facility_obj = this.event_potential_facilities.filter(v => v.facilityName === this.event_facility)[0]
            this.facility_address = this.event_facility_obj.address
            this.googleMapUrl = this.event_facility_obj.googleMapUrl
            this.mapUrl = this.event_facility_obj.mapUrl
            this.mapUrlValid = true

            this.setEventRoomsItems()
        },
        setEventEndTime(){
            this.event_end = this.event_end_hour + ":" +  this.event_end_min
        },
        setEventStartTime(){
            this.event_start = this.event_start_hour + ":" +  this.event_start_min
        },
        setEventPubPeriodTime(){
            if(this.event_pubPeriodHour !== '' || this.event_pubPeriodMin !== ''){
                this.event_pubPeriodTime = this.event_pubPeriodHour + ":" +  this.event_pubPeriodMin
            }
        },
        setDeadlineTime(){
            if(this.event_deadlineHour !== '' || this.event_deadlineMin !== ''){
                this.event_deadlineTime = this.event_deadlineHour + ":" +  this.event_deadlineMin
            }
        },
        setPubPeriodDateTime(){
            if(this.$refs.eventPubPeriodDateForm.validate()){
                this.event_pubPeriodDateTime = ''
                if(this.event_pubPeriodDate != null){
                    this.event_pubPeriodDateTime = this.event_pubPeriodDate + ' ' + this.event_pubPeriodHour + ':' + this.event_pubPeriodMin
                }
                this.dialogPubPeriod = false
            }
        },
        setDeadlineDateTime(){
            if(this.$refs.eventDeadlineDateForm.validate()){
                this.event_deadlineDateTime = ''
                if(this.event_deadlineDate != null){
                    this.event_deadlineDateTime = this.event_deadlineDate + ' ' + this.event_deadlineHour + ':' + this.event_deadlineMin
                }
                this.dialogDeadline = false
            }
        },
        setEventRoomsItems() {
            this.event_rooms = []
            this.selectedEventRoom = ""
            this.roomDescription = ""

            let sameFacility = (this.event_facility_obj.id == this.event_org_facilityId)

            let venues = this.event_facility_obj.venues
            if(venues.length <= 0) {
                return
            }

            venues.sort((a, b) => {
                return a.viewOrderRank - b.viewOrderRank
            })

            venues.forEach(a => {
                this.event_rooms.push(a.venueName)
            }, this)

            if(sameFacility && (! this.event_rooms.includes(this.event_org_roomDescription))) {
                this.event_rooms.splice(0, 0, this.event_org_roomDescription)
            }
        },
        eventDateCheck(eventStartDate){
            if(this.event_endDate !== null && eventStartDate > this.event_endDate){return false}
            else{return true}
        },
        eventEndDateCheck(eventEndDate){
            if(eventEndDate !== null && eventEndDate < this.event_date){return false}
            else{return true}
        },
        timeCheck(event_end_min){
            if((parseInt(event_end_min,10) > parseInt(this.event_start_min,10)) && (parseInt(this.event_start_hour,10) === parseInt(this.event_end_hour,10))){
                return true
            }
            else if(parseInt(this.event_start_hour,10) !== parseInt(this.event_end_hour,10)){
                return true
            }
            else{
                return false
            }
        },
        isPubPeriodDateInput(){
            if ((this.event_pubPeriodMin !== '' || this.event_pubPeriodHour !== '') && this.event_pubPeriodDate === null) {
                return false
            }
            return true
        },
        isPubPeriodHourInput(){
            if ((this.event_pubPeriodDate !== null || this.event_pubPeriodMin !== '') && this.event_pubPeriodHour === '') {
                return false
            }
            return true
        },
        isPubPeriodMinInput(){
            if ((this.event_pubPeriodDate !== null || this.event_pubPeriodHour !== '') && this.event_pubPeriodMin === '') {
                return false
            }
            return true
        },
        isDeadlineDateInput(){
            if ((this.event_deadlineMin !== '' || this.event_deadlineHour !== '') && this.event_deadlineDate === null) {
                return false
            }
            return true
        },
        isDeadlineInput(){
            if(this.event_deadlineMin === '' && this.event_deadlineHour === '' && this.event_deadlineDate === null) {
                return false
            }
            return true
        },
        isDeadlineHourInput(){
            if ((this.event_deadlineDate !== null || this.event_deadlineMin !== '') && this.event_deadlineHour === '') {
                return false
            }
            return true
        },
        isDeadlineMinInput(){
            if ((this.event_deadlineDate !== null || this.event_deadlineHour !== '') && this.event_deadlineMin === '') {
                return false
            }
            return true
        },
        imgError(){
            this.mapUrlValid = false
        },
        setOnlineEventPlatformsList() {
            if(!this.eventData) return []

            let listTemplate = [
                {name: 'V-CUBE', value: 'VCUBE'},
                {name: 'ZOOM', value: 'ZOOM'},
            ]

            this.onlineEventPlatformsList = []
            getOnlineEventPlatformsList({
                eventId: (this.eventData.id !== undefined) ? this.eventData.id : 0
            }).then(res => {
                listTemplate.forEach(itemTemplate => {
                    res.data.allowVideoPlatforms.forEach(platform => {
                        if (platform == itemTemplate.value) this.onlineEventPlatformsList.push(itemTemplate)
                    })
                })

                this.onlineEventPlatformsList.push({name:"その他", value:"OTHERS"})
            }).catch(err => {
                console.log(err)
                alert('編集画面の準備に失敗しました')
            })
        },
    }
}
</script>

<style scoped>
.none{
    box-shadow: none;
}
.title{
     text-align: left;
     margin-left: 30px;
     margin-top: 20px;
     font-size: 23px;
 }
 .note{
     text-align: left;
     font-size:12px;
     width:60%;
     color:#666666;
 }

 .fee {
  margin: 30px;
}
.free {
    margin: 30px;
}

.tile:hover {
    background-color: #dadee5;
    transition: 0.5s;
}

.tile {
    transition: 0.5s
}

#potential_facilities {
    max-height: 300px;
    overflow-y: auto;
}
.inputFieldTitle{
        text-align:left;
        font-weight:bold;
}
.essential{
    background-color: #f44336;
    color: white;
    font-size: 10px;
    font-weight: bold;
    padding: 4px 0.5em;
    vertical-align: top;
    margin-left: 1.1em;
    border-radius: 10px;        /* CSS3草案 */
    -webkit-border-radius: 10px;    /* Safari,Google Chrome用 */
    -moz-border-radius: 10px;   /* Firefox用 */
}
</style>
