<template>
  <v-card text>
    <div class="tool">
      <div class="toolTitle">管理メニュー</div>
      <div class="iconmenu">
        <v-tooltip bottom>
          <v-btn :to="{path: '/AccountsEventScheduleList'}" icon dark slot="activator" v-bind:class='{iconColor:accountsEventScheduleList}'>
            <v-icon>fas fa-microphone-alt</v-icon>
          </v-btn>
          <span>イベント一覧</span>
        </v-tooltip>
        <v-tooltip bottom>
          <v-btn :to="{path: '/VenueList'}" icon dark slot="activator" v-bind:class='{iconColor:venueList}'>
            <v-icon>location_city</v-icon>
          </v-btn>
          <span>会場一覧</span>
        </v-tooltip>
        <v-tooltip bottom>
          <v-btn :to="{path: '/AccountList'}" icon dark slot="activator" v-bind:class='{iconColor:accountList}'>
            <v-icon>supervisor_account</v-icon>
          </v-btn>
          <span>アカウント一覧</span>
        </v-tooltip>
        <v-tooltip bottom>
          <v-btn :to="{path: '/PlanList'}" icon dark slot="activator" v-bind:class='{iconColor:planList}'>
            <v-icon>description</v-icon>
          </v-btn>
          <span>プラン一覧</span>
        </v-tooltip>
        <v-tooltip bottom>
          <v-btn :to="{path: '/SquareAccounts'}" icon dark slot="activator" v-bind:class='{iconColor:squareAccounts}'>
            <v-icon>store_mall_directory</v-icon>
          </v-btn>
          <span>共通Squareアカウント一覧</span>
        </v-tooltip>
        <v-tooltip bottom>
          <v-btn :to="{path: '/AnnouncementList'}" icon dark slot="activator" v-bind:class='{iconColor:announcementList}'>
            <v-icon>comment</v-icon>
          </v-btn>
          <span>お知らせ一覧</span>
        </v-tooltip>
      </div>
    </div>
  </v-card>
</template>

<script>
  export default {
    name: "AdminHeader",
    props: [
      'accountsEventScheduleList',
      'venueList',
      'accountList',
      'planList',
      'squareAccounts',
      'announcementList'
    ]
  }
</script>

<style scoped>
  .tool {
    background-color: #263238;
    color: white;
    height: 70px;
  }
  .toolTitle {
    text-align: left;
    float: left;
    font-size: 24px;
    margin-left: 50px;
    padding: 15px 0px;
  }
  .iconmenu {
    text-align: right;
    padding: 7px 0px;
    margin-right: 25px;
  }
  .iconColor{
    border-width:1px;
    border-style:solid;
    border-color:white;
  }
</style>
