<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-app id="inspire">
    <template v-if="!displayEmailForm">
      <middle_header :eventData=this.eventData mailHistory="true"></middle_header>

      <v-container fluid>
        <v-layout column>
          <v-flex>
            <p class="title">
              <v-icon large>mail_outline</v-icon>
              メール詳細
            </p>

            <p v-if="this.mail.status > 300" class="errorMessage">メール配信に失敗しています。詳細は管理者にお問い合わせください。</p>

            <v-container grid-list-md v-if="this.displayEditMenu" style="padding:4px;margin-left:0px;margin-right:0px;max-width:100%;">
              <v-layout align-end justify-end row fill-height>
                <v-tooltip bottom v-if="role!=='Viewer'">
                  <v-btn slot="activator" @click.stop="openEmailForm" icon>
                    <v-icon class="iconStyle" lot="activator" large>edit</v-icon>
                  </v-btn>
                  <span>メール編集</span>
                </v-tooltip>
                <v-tooltip bottom v-if="role!=='Viewer'">
                  <v-btn slot="activator" @click="deleteMail" icon>
                    <v-icon class="iconStyle" lot="activator" large>delete</v-icon>
                  </v-btn>
                  <span>メール削除</span>
                </v-tooltip>
              </v-layout>
            </v-container>

            <v-container grid-list-md style="margin-left:0px;margin-right:0px;max-width:100%;">
              <v-layout wrap>
                <v-flex md6 xs12>
                  <div class="inputFieldTitle">配信日時</div>
                  <div class="text">{{formatDateTime(mail.sendDate)}}</div>
                </v-flex>
                <v-flex md6 xs12>
                  <div class="inputFieldTitle">予約日時</div>
                  <div class="text">{{formatDateTime(mail.schedule)}}</div>
                </v-flex>
                <v-flex xs12 v-if="mail.toAddr != null">
                  <div class="inputFieldTitle">配信先</div>
                  <div class="text">
                    <template v-if="mail.toAll">
                      送信時の登録者全員
                    </template>
                    <template v-else>
                      <span v-for="addr in mail.toAddr">{{addr}}, </span>
                    </template>
                  </div>
                </v-flex>
                <v-flex md6 xs12>
                  <div class="inputFieldTitle">配信者名</div>
                  <div class="text">{{mail.senderName}}</div>
                </v-flex>
                <v-flex md6 xs12>
                  <div class="inputFieldTitle">配信者メールアドレス（From）</div>
                  <div class="text">{{mail.fromAddr}}</div>
                </v-flex>
                <v-flex xs12>
                  <div class="inputFieldTitle">返信先メールアドレス（Reply to）</div>
                  <div class="text">{{formatReplyTo(mail.replyToAddr)}}</div>
                </v-flex>

                <v-flex xs12 v-if="mail.toAddr != null">
                  <div class="inputFieldTitle">件名</div>
                  <div class="text">{{mail.title}}</div>
                </v-flex>

                <v-flex md6 xs12 v-if="mail.toAddr == null">
                  <div class="inputFieldTitle">件名</div>
                  <div class="text">{{mail.title}}</div>
                </v-flex>
                <v-flex md6 xs12 v-if="mail.toAddr == null">
                  <div class="inputFieldTitle">テンプレート</div>
                  <div class="text">{{formatReplyTo(mail.templateName)}}</div>
                </v-flex>

                <v-flex xs12>
                  <div class="inputFieldTitle" style="margin-top:10px;">本文
                    <span class="attention">（差し込み値は配信時に変換されます）</span>
                  </div>
                  <div class="text-area" v-html="mail.body">
                  </div>
                </v-flex>
              </v-layout>
            </v-container>
          </v-flex>

          <v-flex v-if="hasMailStatus" class="destination-title">
            <p class="title">
              <v-icon large>drafts</v-icon>
              メール配信ステータス統計
            </p>
          </v-flex>

          <template v-if="hasMailStatus" style="padding-top: 20px">
            <table id="table1">
              <thead>
              <tr>
                <td class="statistics_td">
                  総配信数
                  <v-tooltip top>
                    <v-btn icon dark slot="activator" style="color:#999999;">
                      <v-icon lot="activator" small>info</v-icon>
                    </v-btn>
                    <span>配信されたメール総数</span>
                  </v-tooltip>
                </td>
                <td class="statistics_td">
                  開封済
                  <v-tooltip top>
                    <v-btn icon dark slot="activator" style="color:#999999;">
                      <v-icon lot="activator" small>info</v-icon>
                    </v-btn>
                    <span>開封済の件数</span>
                  </v-tooltip>
                </td>
                <td class="statistics_td">
                  未開封
                  <v-tooltip top>
                    <v-btn icon dark slot="activator" style="color:#999999;">
                      <v-icon lot="activator" small>info</v-icon>
                    </v-btn>
                    <span>未開封の件数</span>
                  </v-tooltip>
                </td>
                <td class="statistics_td">
                  受信待ち
                  <v-tooltip top>
                    <v-btn icon dark slot="activator" style="color:#999999;">
                      <v-icon lot="activator" small>info</v-icon>
                    </v-btn>
                    <span>受信者のメールサーバーへ配信待ちの件数</span>
                  </v-tooltip>
                </td>
                <td class="statistics_td">
                  スパム
                  <v-tooltip top>
                    <v-btn icon dark slot="activator" style="color:#999999;">
                      <v-icon lot="activator" small>info</v-icon>
                    </v-btn>
                    <span>迷惑メールとして判定された件数</span>
                  </v-tooltip>
                </td>
                <td class="statistics_td">
                  配信エラー
                  <v-tooltip top>
                    <v-btn icon dark slot="activator" style="color:#999999;">
                      <v-icon lot="activator" small>info</v-icon>
                    </v-btn>
                    <span>メールサーバーへ配信できなかった件数</span>
                  </v-tooltip>
                </td>
                <td class="statistics_td">
                  その他
                  <v-tooltip top>
                    <v-btn icon dark slot="activator" style="color:#999999;">
                      <v-icon lot="activator" small>info</v-icon>
                    </v-btn>
                    <span>情報取得中または削除済登録者などの件数</span>
                  </v-tooltip>
                </td>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td class="statistics_tr">{{ statistics.total }}</td>
                <td class="statistics_tr">{{ statistics.open }}</td>
                <td class="statistics_tr">{{ statistics.unopened }}</td>
                <td class="statistics_tr">{{ statistics.deferred }}</td>
                <td class="statistics_tr">{{ statistics.spam }}</td>
                <td class="statistics_tr">{{ statistics.error }}</td>
                <td class="statistics_tr">{{ statistics.noinfo }}</td>
              </tr>
              </tbody>
            </table>
          </template>

          <v-flex v-if="mail.toAddr == null" class="destination-title">
            <p class="title">
              <v-icon large>list_alt</v-icon>配信先
            </p>
          </v-flex>

          <template v-if="mail.toAddr == null">
            <v-container grid-list-md style="padding:4px;margin-left:0px;margin-right:0px;max-width:100%;">
              <v-layout align-end justify-end row fill-height>
            <div style="width: 500px">
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </div>
                <v-tooltip bottom>
                  <v-btn slot="activator" :href="csvUrl" icon>
                    <v-icon class="iconStyle" lot="activator" large>vertical_align_top</v-icon>
                  </v-btn>
                  <span>CSV<br>エクスポート</span>
                </v-tooltip>

              </v-layout>
            </v-container>

            <v-data-table
              :headers="headers"
              :items="reports"
              :search="search"
              :rows-per-page-items="rowsPerPageItems"
              :pagination.sync="pagination"
              :custom-filter="customFilter"
              :loading="reportLoading"
              :no-data-text="reportNoDataText"
              item-key="username"
              must-sort
            >
              <template slot="headerCell" slot-scope="props">
                {{ props.header.text }}
              </template>
              <template slot="items" slot-scope="props">
                <tr>
                  <td class="text-md-left" v-if="mail.sendDate === null">配信予約中</td>
                  <td class="text-md-left" v-if="mail.sendDate !== null">{{ props.item.sgEvent }}</td>
                  <td class="text-md-left">{{ props.item.lastName }}&nbsp;{{props.item.firstName }}</td>
                  <td class="text-md-left">{{ props.item.username }}</td>
                  <td v-if="visitorOptions.COMPANY" class="text-md-left">{{ props.item.companyName }}</td>
                  <td v-if="visitorOptions.DEPT" class="text-md-left">{{ props.item.department }}</td>
                  <td v-if="visitorOptions.TICKET" class="text-md-left">{{ props.item.ticketName }}</td>
                  <td v-if="visitorOptions.TICKET" class="text-md-left">{{ props.item.ticketPrice | toPriceLocaleString }}</td>
                </tr>
              </template>
            </v-data-table>
          </template>

          <v-flex md12 sm12 xs12 class="text-md-center" style="padding-bottom: 50px;">
            <v-btn @click="cancel">戻る</v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </template>

    <template v-if="displayEmailForm">
      <mailSendForm @close="closeMailSendForm" :mailTo="this.mailTo" :visitorIds="this.visitorIds"
                    :eventId="eventId" :mailId="mailId" :toAll="mail.toAll" :isEdit="true"></mailSendForm>
    </template>
  </v-app>
</template>

<script>
  import header from '@/components/EventHeader.vue'
  import mailSendForm from '@/components/CreateEmail.vue'
  import moment from "moment"
  import {
    getMe,
    getEvent,
    getEmail,
    getEmailReports,
    exportEmailReportsCsvFile,
    deleteEmail,
  } from '../utils/APIUtils'

  export default {
    name: 'MailDetail',
    components: {
      'middle_header': header,
      'mailSendForm': mailSendForm
    },
    data() {
      return {
        role: '',
        organizerId: null,
        eventId: null,
        eventData: {},
        eventName: '',
        eventDate: '',
        eventFacility: '',

        displayEmailForm : false,

        statistics: {},

        rowsPerPageItems: [
          { text: '100', value: 100 },
          { text: '1000', value: 1000 },
          { text: '5000', value: 5000 }
        ],
        pagination: {
          rowsPerPage: 1000,
          sortBy: 'username',
          descending: true
        },
        search: '',
        reports: [],
        headers: [],
        visitorOptions: {
          COMPANY: false,
          DEPT: false,
          TICKET: false
        },

        mailId: null,
        mail: {
          toAddr: null,
          sendDate: null
        },
        hasMailStatus: false,
        displayEditMenu: false,

        mailTo: '',
        visitorIds: '',

        reportLoading: false,
        reportNoDataText: "",
        csvUrl: '',
      }
    },
    watch: {
      mail(data){
        this.checkHasMailStatus()
        this.canEdit()
      },
      reports(data) {
        this.statisticalData(data)
      },
      reportLoading(data){
        let noDataText = (this.mail && this.mail.toAll && !(this.mail.sendDate)) ? "送信時の登録者全員" : "No data available"
        this.reportNoDataText = data ? "Loading..." : noDataText
      }
    },
    filters: {
      toPriceLocaleString: function (value) {
        if (!value) {
          return 0;
        }
        return value.toLocaleString('ja-JP', {"style": "currency", "currency": "JPY"});
      },
    },
    methods: {
      cancel() {
        this.$router.push({path: 'MailHistory', query: {id: this.eventId}});
      },
      formatReplyTo(replyTo) {
        return replyTo === null ? '　' : replyTo
      },
      formatDateTime(datetime) {
        if(datetime == null){
          return "-"
        }
        return datetime ? moment(datetime).format('YYYY/M/D HH:mm') : datetime
      },
      setHeaderData() {
        let options = this.eventData.visitorOptions
        let optionLabels = []
        options.forEach(o => {
          optionLabels.push(o.visitorOption)
        })

      this.headers.push({ text: 'ステータス', value: 'sgEvent' });
      this.headers.push({ text: '氏名', value: 'lastName' });
      this.headers.push({ text: 'メールアドレス', value: 'username' });
      if (optionLabels.indexOf('COMPANY') !== -1) {
        this.headers.push({ text: '会社名', value: 'companyName' });
        this.visitorOptions.COMPANY = true;
      }
      if (optionLabels.indexOf('DEPT') !== -1) {
        this.headers.push({ text: '部署名', value: 'department' });
        this.visitorOptions.DEPT = true;
      }
      if (this.eventData.tickets.length > 0) {
        this.headers.push({ text: '参加券名称', value: 'ticketName' });
        this.headers.push({ text: '購入価格', value: 'ticketPrice' });
        this.visitorOptions.TICKET = true;
      }
    },
    getMailDetail() {
      this.reportLoading = true;
      this.mail = {};
      this.reports = [];
      getEmail({ eventId: this.eventId, mailId: this.mailId })
        .then(res => {
          this.mail = res.data;
          getEmailReports({ eventId: this.eventId, mailId: this.mailId })
            .then(res => {
              this.reports = res.data;
              this.reportLoading = false;
            })
            .catch(err => {
              console.log('メール履歴詳細の取得に失敗しました。');
              this.reportLoading = false;
            });
        })
        .catch(err => {
          console.log('メール詳細の取得に失敗しました。');
          this.reportLoading = false;
        });
    },
    statisticalData(data) {
        this.$set(this.statistics, "total", Object.keys(this.reports).length);

        let open = data.filter(function (value) {
          return value.sgEvent === '開封済'
        })
        this.$set(this.statistics, "open", Object.keys(open).length);

        let deferred = data.filter(function (value) {
          return value.sgEvent === '受信待ち'
        })
        this.$set(this.statistics, "deferred", Object.keys(deferred).length);

        let spam = data.filter(function (value) {
          return value.sgEvent === 'スパム'
        })
        this.$set(this.statistics, "spam", Object.keys(spam).length);

        let error = data.filter(function (value) {
          return value.sgEvent === '配信エラー'
        })
        this.$set(this.statistics, "error", Object.keys(error).length);

      let noinfo = data.filter(function(value) {
        return value.sgEvent === 'その他'
      });
      this.$set(this.statistics, "noinfo", Object.keys(noinfo).length);

      let unopened =
        Object.keys(this.reports).length -
        Object.keys(open).length -
        Object.keys(error).length -
        Object.keys(deferred).length -
        Object.keys(spam).length -
        Object.keys(noinfo).length;
      this.$set(this.statistics, "unopened", unopened);
    },
    loadEvent() {
      return getEvent({ eventId: this.eventId })
        .then(res => {
          this.eventData = res.data;
          this.eventName = this.eventData.eventName;
          this.eventDate = this.eventData.eventDate;
          this.eventFacility = this.eventData.facility.facilityName;
          const user = res.data.eventGroup.members.find(member => {
            return member.organizer.id === this.organizerId;
          });
          this.role = user.role;
          this.setHeaderData();
        })
        .catch(err => {
          alert('イベントデータの取得に失敗しました。')
        });
    },
    customFilter(items, searchWord, filter, headers) {
      let word = searchWord.trim();
      if (word === "") return items

      let loweredWord = word.toLowerCase();
      return items.filter(function(item) {
        if (this.includesIgnoreCase(item.username, word, loweredWord)) return true

        if (this.includesIgnoreCase(item.sgEvent, word, loweredWord)) return true
        let fullName = item.lastName + " " + item.firstName;
        if (this.includesIgnoreCase(fullName, word, loweredWord)) return true
        if (this.includesIgnoreCase(item.ticketName, word, loweredWord)) return true
        if (this.includesIgnoreCase(item.ticketPrice, word, loweredWord)) return true
        if (this.includesIgnoreCase(item.companyName, word, loweredWord)) return true
        if (this.includesIgnoreCase(item.department, word, loweredWord)) return true

        return false
      }, this);
    },
    includesIgnoreCase(target, needle, loweredNeedle) {
      if (!target && target != 0) return false

      if (typeof target === 'string' && target.includes(needle)) return true
      if (typeof target === 'string' && target.toLowerCase().includes(loweredNeedle)) return true

      return false
    },
      checkHasMailStatus(){
        this.hasMailStatus = this.mail.toAddr === null && this.mail.sendDate !== null
      },
      canEdit() {
        this.displayEditMenu = this.scheduleCheck(this.mail.schedule) && this.mail.status < 300
      },
      openEmailForm() {
        this.mailTo = '';
        this.visitorIds = '';
        for (let i = 0; i < this.reports.length; ++i) {
          if (this.reports[i]) {
            this.mailTo += this.reports[i].username + ";";
            this.visitorIds += this.reports[i].visitorId + ",";
          }
        }
        if (this.scheduleCheck && this.mail.sendDate === null) {
          this.displayEmailForm = true
        } else {
          alert("予約された時間を過ぎています。メールを編集できません。")
        }
      },
      scheduleCheck(input) {
        let now = moment(new Date())
        let scheduled = moment(input)
        return scheduled.isAfter(now)
      },
      closeMailSendForm() {
        this.displayEmailForm = false
        this.getMailDetail()
      },
      deleteMail() {
        if (this.scheduleCheck) {
          if(confirm('このメールを削除してよろしいですか。')) {
            deleteEmail({eventId: this.eventId, mailId: this.mailId}).then(res => {
              alert('メールを削除しました。')
              this.$router.push({path: '/MailHistory', query:{id:this.eventId}})
            }).catch(err => {
              if (err.response.status === 423) {
                alert("他のユーザーが更新を行ったか、すでに配信済のメールです。メール詳細を確認してください。")
              } else {
                alert('メールの削除処理に失敗しました。')
              }
            })
          }
        } else {
          alert('予約された時間を過ぎています。メールを削除できません。')
        }
      }
  },
  mounted() {
    this.eventId = this.$route.query.id;
    this.mailId = this.$route.query.mailId;
    let headers = { accountType: "organizer" };
    getMe({ headers: headers, accountType: "organizer" })
      .then(res => {
        this.organizerId = res.data.id;
        this.loadEvent();
        this.csvUrl = exportEmailReportsCsvFile({
          eventId: this.eventId,
          mailId: this.mailId
        });
        this.getMailDetail();
      })
      .catch(err => {
        console.log("get me failed", err);
      });
  }
};
</script>
<style>
  #inspire {
    color: #666666;
    background-color: white;
  }

  .eventdate {
    text-align: left;
    margin: 10px;
  }

  .title {
    text-align: left;
    margin-left: 30px;
  }

  .iconStyle {
    color: rgba(0, 0, 0, 0.54) !important;
  }

  .inputFieldTitle {
    text-align: left;
    font-weight: bold;
    padding-top: 20px;
  }

  .text {
    background-color: #ffffff;
    padding-top: 20px;
    text-align: left;
    color: #666666;
    border-bottom: #999999 solid 1px;
  }

  .text-area {
    background-color: #ffffff;
    padding-top: 20px;
    padding-bottom: 50px;
    text-align: left;
    color: #666666;
    border-bottom: #999999 solid 1px;
  }

  .attention {
    font-weight: normal;
    margin-left: 5px;
    font-size: 90%;
  }

  .destination-title {
    padding-top: 50px;
    margin-bottom: 30px;
  }
  .errorMessage {
    color: #ff0000;
    text-align: left;
    margin-left: 30px;
  }

.statistics_td {
  height: 50px;
  width: 180px;
  background-color: #eeeeee;
  border: 1px solid #a9a2a9;
}

.statistics_tr {
  height: 50px;
  width: 180px;
  border: 1px solid #a9a2a9;
}

#table1 {
  margin: auto;
  border-collapse: collapse;
  border: 1px solid #a9a2a9;
}

#table1 thead tr {
  background-color: #cccccc;
}
</style>
