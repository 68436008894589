<template>
  <v-app>

    <v-app-bar dense clipped-left app>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>2021年度全国通訳案内士試験管理画面</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn text>{{name}}</v-btn>
        <v-btn v-if="loggedin" @click='logOut' text color="blue-grey">ログアウト</v-btn>
      </v-toolbar-items>
    </v-app-bar>


    <v-navigation-drawer
      v-model="drawer"
      clipped permanent app
    >
      <v-list
        dense
        nav
      >
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>



    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import {signout} from '@/utils/APIUtils.js'
export default {
  name: 'App',
  data() {
    return {
      drawer: false,
      items: [
        { title: '申込者管理', icon: 'mdi-view-dashboard' },
        { title: 'お知らせ管理', icon: 'mdi-view-dashboard' },
        { title: 'マスタ管理', icon: 'mdi-view-dashboard' },
        { title: '権限管理', icon: 'mdi-view-dashboard' },
      ],
      organization: '',
      name: '通訳太郎',
      organizerId: '',
      loggedin: false
    }
  },
  methods: {
    loadUser(data) {
      this.organization = data.affiliation
      this.name = data.lastName + " " + data.firstName
      this.userId = data.id
      this.loggedin = true;
    },
    clean() {
      this.organization = "",
        this.name = ""
    },
    toPreview(data) {
      this.eventData = data
    },
    logOut() {
      signout()
        .then(res => {
          this.loggedin = false
          this.$router.push({path: '/Signout'})
        })
        .catch(err => {
          console.log("signout catch ", err)
        })
    },
  },
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
v-main{
  text-align: center;
}

</style>
