
<template>
    <div>
        <v-flex ls12 xs12 md12 v-if="eventData.isPrivate==='true'">
            <v-alert
            :value="true"
            type="warning"
            style="font-size:16px;padding:5px;"
            icon="lock"
            >
            限定公開サイトの設定がされています。
            </v-alert>
        </v-flex>
        <div class="titleControl">
            <div class="inputFieldTitle" style="text-align: left;">トップカバー画像上のイベント名</div>
            <v-radio-group v-model="viewEventName" hide-details row style="margin-top:5px;">
                <v-radio label="表示あり" value="true"></v-radio>
                <v-radio label="表示なし" value="false"></v-radio>
            </v-radio-group>
            <div v-if="judgeViewEventName()" id="app" class="control">
                <v-menu bottom offset-y :close-on-content-click="false">
                  <template v-slot:activator="{on}">
                    <v-btn v-on="on" class="btn-title-font-color">タイトルカラー変更　
                      <v-icon>format_color_fill</v-icon>
                    </v-btn>
                  </template>
                    <chrome-picker class="eventTitleColorPicker" v-model="eventTitleColorContainer" style="margin-left:auto;margin-right:auto;display:block;" />
                </v-menu>

                <v-menu bottom offset-y>
                  <template v-slot:activator="{on}">
                    <v-btn v-on="on">タイトルフォント変更　
                        <v-icon>format_bold</v-icon>
                    </v-btn>
                  </template>
                    <v-list>
                        <v-list-tile
                          v-for="item in eventTitleFontList"
                          :key="item.value"
                          @click="pickEventTitleFont"
                        >
                        <v-list-tile-content class="event-title" v-html="item.name" v-bind:class="item.value"></v-list-tile-content>
                        </v-list-tile>
                    </v-list>
                </v-menu>

                <v-menu bottom offset-y>
                  <template v-slot:activator="{on}">
                    <v-btn v-on="on" class="btn-title-font-size">タイトルサイズ変更　
                        <v-icon>format_size</v-icon>
                    </v-btn>
                  </template>
                    <v-card>
                        <p style="padding-top: 10px;">{{this.eventTitleSize}} px</p>
                        <v-slider
                          v-model="eventTitleSize"
                          :min="30"
                          :max="80"
                          :value="eventTitleSize"
                          style="padding: 0px 10px 0px 10px;"
                        ></v-slider>
                    </v-card>
                </v-menu>
            </div>
        </div>

            <v-app id="inspire" style="background-color: white;min-width:320px;">
                <parts-event-title
                    ref="partsEventTitle"
                    v-bind:viewEventName="judgeViewEventName()"
                    v-bind:eventName="eventData.eventName"
                    v-bind:coverImageUrl="eventData.imageUrl"
                    v-bind:eventTitleColorHex8="eventTitleColorHex8"
                    v-bind:eventTitleFont="eventTitleFont"
                    v-bind:eventTitleSize="eventTitleSize"
                ></parts-event-title>

                <v-container grid-list-md text-md-center offset-xl2>
                    <v-layout row wrap aling-center>
                            <v-flex xl12 xs12>
                                <div class="textarea" style="text-align:left;margin-left:6px;" v-html="this.eventData.information"></div>
                            </v-flex>
                      <v-flex xl12 xs12>
                        <div class="subTitle subContentsTitle"><span>主催者情報</span></div>
                      </v-flex>
                      <v-flex xl12 xs12>
                        <div class="organizer" style="text-align:left;margin-left:6px;" v-html="this.eventData.organizerInfo"></div>
                      </v-flex>
                            <v-flex xl12 xs12>
                                <div class="subTitle subContentsTitle"><span>会期日</span></div>
                            </v-flex>
                            <v-flex xl12 xs12>
                                <p class="text-xs-left contents">
                                    {{formatDateWithDayOfWeek(this.eventData.eventDate)}}
                                    <template v-if="eventData.eventEndDate != null">
                                        〜 {{formatDateWithDayOfWeek(this.eventData.eventEndDate)}}
                                    </template>
                                </p>
                            </v-flex>
                            <v-flex xl12 xs12>
                                <div class="subTitle subContentsTitle"><span>会期時間</span></div>
                            </v-flex>
                            <v-flex xl12 xs12>
                                <p class="text-xs-left contents">{{this.eventData.startTime}}〜{{this.eventData.endTime}}</p>
                            </v-flex>

                            <template v-if="eventData.facilityId > 0">
                                <v-flex xl12 xs12>
                                    <div class="subTitle subContentsTitle"><span>会場アクセス</span></div>
                                </v-flex>
                                <v-flex xl12 xs12>
                                    <p class="text-xs-left contents">
                                        {{this.event_facility.name}}
                                    </p>
                                    <p class="text-xs-left contents" style="font-size:120%;font-weight:normal;">
                                        {{this.event_facility.address}}
                                    </p>
                                    <p class="text-xs-left contents" style="font-size:120%;font-weight:normal;">
                                        {{this.eventData.roomDescription}}
                                    </p>
                                </v-flex>

                                <!-- PC時地図 -->
                                <v-flex xl6 class="hidden-sm-and-down" v-if="eventData.mapUrlValid && eventData.mapUrl != null">
                                    <p class="text-md-left">オリジナルマップ</p>
                                    <p class="text-md-left"><img v-bind:src="eventData.mapUrl" width="560" height="315"></p>
                                </v-flex>
                                <v-flex xl6 class="hidden-sm-and-down" v-if="eventData.googleMapUrl !== null">
                                    <p class="text-md-left">Google Map</p>
                                    <div class="mapresize">
                                        <p class="text-md-left" v-html="eventData.googleMapUrl"></p>
                                    </div>
                                </v-flex>

                                <v-flex xs12 class="hidden-md-and-up">
                                    <div class="maparea text-md-center">
                                        <v-tabs
                                        centered
                                        color="grey"
                                        dark
                                        icons-and-text
                                        >
                                            <v-tabs-slider color="white"></v-tabs-slider>
                                            <template v-if="eventData.mapUrl != null">
                                                <v-tab href="#tab-1" v-if="eventData.mapUrlValid">
                                                    オリジナルマップ
                                                </v-tab>
                                            </template>
                                            <v-tab href="#tab-2" v-if="eventData.googleMapUrl !== null">
                                                Google Map
                                            </v-tab>
                                            <template v-if="eventData.mapUrl != null">
                                                <v-tab-item
                                                :id="'tab-1'"
                                                :key="1"
                                                v-if="eventData.mapUrlValid"
                                                >
                                                    <p class="text-md-center"><img v-bind:src="eventData.mapUrl" width="100%"></p>
                                                </v-tab-item>
                                            </template>
                                            <v-tab-item
                                            :id="'tab-2'"
                                            :key="2"
                                            v-if="eventData.googleMapUrl !== null"
                                            >
                                                <p class="text-md-center" v-html="this.eventData.googleMapUrl">
                                                </p>
                                            </v-tab-item>
                                        </v-tabs>
                                    </div>
                                </v-flex>
                            </template>
                            <v-flex xl12 xs12>
                                    <v-form>
                                        <div class="input-area">
                                          <p class="inputAreaTitle">お申込み情報入力</p>

                                          <v-menu bottom offset-y v-if="needControlDisplayPriceOfFreeTickets">
                                            <v-btn slot="activator" class="btn-title-font-size">無料表記の有無　
                                              <v-icon small>fas fa-yen-sign</v-icon>
                                            </v-btn>
                                            <v-list>
                                              <v-list-tile
                                                v-for="item in displayPriceOfFreeTicketsList"
                                                :key="item.value"
                                                @click="pickDisplayPriceOfFreeTickets"
                                              >
                                                <v-list-tile-content v-html="item.name"></v-list-tile-content>
                                              </v-list-tile>
                                            </v-list>
                                          </v-menu>

                                          <v-flex md10 offset-md1 xs10 offset-xs1 v-if="this.eventData.createTicket" v-on:mouseover="onMouseoverDraggableTicketsArea" v-on:mouseleave="onMouseleaveDraggableTicketsArea" :class="classDraggableTicketsArea" style="background-color: #cccccc; padding:20px 0;">
                                            <div class="inputFieldTitle" style="color: #666666">
                                              <template>　選択してください</template>
                                              <span class="essential">必須</span>
                                            </div>
                                            <draggable v-model="sortableTickets" draggable=".draggable-ticket" ghost-class="ghost">
                                              <template v-for="(ticket) in sortableTickets" style="margin:1em 1em auto 1em;">
                                                <template v-if="!ticket.noDisp">
                                                  <div style="margin:1em 1em auto 1em;" class="draggable-ticket">
                                                    <v-container fluid style="margin: 0;padding: 0;">
                                                      <v-layout row align-center style="margin: 0;padding: 0;">
                                                        <v-flex shrink v-if="mouseoverDraggableTicketsArea">
                                                          <v-icon small color="white">fas fa-arrows-alt-v</v-icon>
                                                        </v-flex>
                                                        <v-flex grow style="margin: 0;padding: 0;">
                                                          <v-list class="ticketList">
                                                            <v-list v-if="ticket.paidTicket">
                                                              {{ticket.name}}　参加費　{{ticket.price | toPriceLocaleString}}円(税込)　ここに販売状況が表示されます
                                                            </v-list>
                                                            <v-list v-else>
                                                              {{ticket.name}}<span v-if="displayPriceOfFreeTickets">　参加費　'無料'</span>　ここに販売状況が表示されます
                                                            </v-list>
                                                            <v-list v-if="ticket.description != undefined && ticket.description.length > 0" class="ticketDescription">{{ticket.description}}</v-list>
                                                          </v-list>
                                                        </v-flex>
                                                      </v-layout>
                                                    </v-container>
                                                  </div>
                                                </template>
                                              </template>
                                            </draggable>
                                          </v-flex>

                                          <v-spacer style="margin-top:40px;"></v-spacer>
                                            <v-flex md10 offset-md1 xs10 offset-xs1>
                                                <div class="inputFieldTitle"><template v-if="getLabelUnsortableVisitorOption('MAIL')==null">メールアドレス</template><template v-else>{{getLabelUnsortableVisitorOption('MAIL')}}</template><span class="essential">必須</span></div>
                                                <v-text-field
                                                    solo
                                                    text
                                                    class="inputField"
                                                >
                                                </v-text-field>
                                                <template v-if="isUserInputUnsortableVisitorOption('MAIL_CONFIRMATION')">
                                                  <div class="inputFieldTitle"><template v-if="getLabelUnsortableVisitorOption('MAIL_CONFIRMATION')==null">メールアドレス（確認）</template><template v-else>{{getLabelUnsortableVisitorOption('MAIL_CONFIRMATION')}}</template><span class="essential">必須</span></div>
                                                  <v-text-field
                                                    solo
                                                    text
                                                    class="inputField"
                                                  >
                                                  </v-text-field>
                                                </template>
                                                <div class="inputFieldTitle">Password<span class="essential">必須</span></div>
                                                <v-text-field
                                                    solo
                                                    text
                                                    :append-icon="showPassword ? 'visibility_off' : 'visibility'"
                                                    :type="showPassword ? 'text' : 'password'"
                                                    @click:append="showPassword = !showPassword"
                                                    class="inputField"
                                                >
                                                </v-text-field>
                                                <v-layout row wrap>
                                                    <v-flex xs6>
                                                        <div class="inputFieldTitle"><template v-if="getLabelUnsortableVisitorOption('LASTNAME')==null">姓</template><template v-else>{{getLabelUnsortableVisitorOption('LASTNAME')}}</template><span class="essential">必須</span></div>
                                                        <v-text-field
                                                            solo
                                                            text
                                                            class="inputField"
                                                        >
                                                        </v-text-field>
                                                    </v-flex>
                                                    <v-flex xs6>
                                                        <div class="inputFieldTitle"><template v-if="getLabelUnsortableVisitorOption('FIRSTNAME')==null">名</template><template v-else>{{getLabelUnsortableVisitorOption('FIRSTNAME')}}</template><span class="essential">必須</span></div>
                                                        <v-text-field
                                                            solo
                                                            text
                                                            class="inputField"
                                                        >
                                                        </v-text-field>
                                                    </v-flex>
                                                </v-layout>
                                                <div v-on:mouseover="onMouseoverDraggableArea" v-on:mouseleave="onMouseleaveDraggableArea" :class="classDraggableArea">
                                                  <draggable v-model="sortableVisitorOptions" draggable=".draggable-item" ghost-class="ghost">
                                                    <div v-for="item in sortableVisitorOptions" :key="item.visitorOption" class="draggable-item">
                                                      <v-container fluid style="margin: 0;padding: 0;">
                                                        <v-layout row align-center style="margin: 0;padding: 0;">
                                                          <v-flex shrink v-if="mouseoverDraggableArea">
                                                            <v-icon small color="grey lighten-1">fas fa-arrows-alt-v</v-icon>
                                                          </v-flex>
                                                          <v-flex grow style="margin: 0;padding: 0;">
                                                            <div class="inputFieldTitle">
                                                              {{item.labelPreview}}
                                                              <span class="essential" v-if="item.required">必須</span>
                                                              <span v-if="item.visitorOption == 'TEL'" class="attention">(ハイフンなしで入力してください)</span>
                                                            </div>
                                                            <v-text-field
                                                              solo
                                                              text
                                                              class="inputField"
                                                              hide-details
                                                            >
                                                            </v-text-field>
                                                          </v-flex>
                                                        </v-layout>
                                                      </v-container>
                                                    </div>
                                                  </draggable>
                                                </div>
                                                <v-layout row wrap>
                                                    <template v-if="isUserInputUnsortableVisitorOption('OPT')">
                                                    <v-flex md12 xs12>
                                                        今後、弊社よりお送りさせていただくメールマガジンなどのサービス情報を
                                                    </v-flex>
                                                    <v-flex md5 xs4>
                                                        &nbsp;
                                                    </v-flex>
                                                    <v-flex md4 xs6 justify-center>
                                                        <v-checkbox
                                                            label="希望する"
                                                            color="info"
                                                        ></v-checkbox>
                                                    </v-flex>
                                                    <v-flex md3 xs2>
                                                        &nbsp;
                                                    </v-flex>
                                                    </template>

                                                <v-flex md12 xs12>
                                                    <div class="subTitle subContentsTitle" style="text-align:center;"><span style="background-color:#F0F0F0;">個人情報の取り扱いについて</span></div>
                                                </v-flex>
                                                <v-spacer style="margin-top:40px;"></v-spacer>
                                                <v-flex md12 xs12>
                                                    <a :href="eventData.privacyPolicyUrl" class="linkDecoration" target="_blank">こちら</a>　をご確認いただき、同意される場合はチェックを入れてください。
                                                </v-flex>

                                                    <v-flex md5 xs4>
                                                        &nbsp;
                                                    </v-flex>
                                                    <v-flex md4 xs6 justify-center>
                                                        <v-checkbox
                                                            label="同意する"
                                                            color="info"
                                                        ></v-checkbox>
                                                    </v-flex>
                                                    <v-flex md3 xs2>
                                                        &nbsp;
                                                    </v-flex>

                                                    <v-flex xs12 class="text-xs-center">
                                                        <v-btn rounded block color="error">申し込む</v-btn>
                                                    </v-flex>
                                                </v-layout>
                                            </v-flex>
                                        </div>
                                    </v-form>
                                    <hr /><br />
                                    <v-btn class="edit-b" @click.native="goBack" id="editbtn">編集</v-btn>
                                    <v-btn color="error" @click.native="publish" :disabled="eventData.publishStatus">公開</v-btn>
                            </v-flex>
                    </v-layout>
                </v-container>

            </v-app>
    </div>
</template>

<script>
import {Chrome} from 'vue-color';
import PartsEventTitle from "../Publish/PartsEventTitle";
import moment from 'moment';
import draggable from 'vuedraggable'
export default {
  props: ['facilities','eventData'],
  components: {
     'chrome-picker': Chrome,
     'parts-event-title': PartsEventTitle,
     draggable
  },
  watch: {
        eventData(data){
            this.eventData = data
            //Edit
            if(this.eventData.id !== undefined){
                this.viewEventName = this.eventData.viewEventName ? 'true' : 'false'

                let titleFontInfo = this.eventData.titleFontInfo;
                if(!titleFontInfo) titleFontInfo = {}

                this.eventTitleColorHex8 = this.$refs.partsEventTitle.decideEventTitleColorHex8(titleFontInfo.color, data.imageUrl)
                this.eventTitleFont = this.$refs.partsEventTitle.decideEventTitleFont(titleFontInfo.font)
                this.eventTitleSize = this.$refs.partsEventTitle.decideEventTitleSize(titleFontInfo.size)
            } else {
                this.eventTitleColorHex8 = this.$refs.partsEventTitle.decideEventTitleColorHex8()
                this.eventTitleFont = this.$refs.partsEventTitle.decideEventTitleFont()
                this.eventTitleSize = this.$refs.partsEventTitle.decideEventTitleSize()
            }
            this.eventTitleColorContainer = this.getEventTitleColorContainer(this.eventTitleColorHex8)
            this.eventData.publishStatus = false

            this.hideOrDisplayAlphaValueAdjustmentUiEventTitleColorPicker(data.imageUrl)

            this.needControlDisplayPriceOfFreeTickets = this.isNeedControlDisplayPriceOfFreeTickets(this.eventData.tickets)
            this.displayPriceOfFreeTickets = (this.needControlDisplayPriceOfFreeTickets) ? this.eventData.displayPriceOfFreeTickets : true;

            this.setSortableTickets()
            this.setSortableVisitorOptions()
        },
        eventTitleColorContainer(data) {
            this.eventTitleColorHex8 = data.hex8
        }
    },
  data() {
      return {
          showPassword:false,
          event_facility:{name:'',address:''},
          event_data:{},
          eventTitleColorContainer:{},
          eventTitleColorHex8: "",
          dispFlgColorPicker:false,
          mapUrlValid:false,

          viewEventName: 'true',
          eventTitleFont: "",
          eventTitleSize: "",
          eventTitleFontList:[
            {'name':'Font1 サンセリフ', 'value':'font-style-1'},
            {'name':'Font2 セリフ', 'value':'font-style-2'},
            {'name':'Font3 サンセリフ 太字', 'value':'font-style-3'},
            {'name':'Font4 セリフ 太字', 'value':'font-style-4'}
          ],

          needControlDisplayPriceOfFreeTickets: false,
          displayPriceOfFreeTickets: true,
          displayPriceOfFreeTicketsList:[
            {'name':'あり', 'value':true},
            {'name':'なし', 'value':false}
          ],

          choosableVisitorOptionsMap: {
            'COMPANY': {
              label: '会社名',
              defaultOrderPriority: 1
            },
            'DEPT': {
              label: '部署',
              defaultOrderPriority: 2
            },
            'POSITION': {
              label: '役職',
              defaultOrderPriority: 3
            },
            'ADDRESS': {
              label: '住所',
              defaultOrderPriority: 4
            },
            'TEL': {
              label: 'TEL',
              defaultOrderPriority: 5
            }
          },

          sortableTickets: [],
          classDraggableTicketsArea: [
            'draggable-tickets-area'
          ],
          mouseoverDraggableTicketsArea: false,

          sortableVisitorOptions: [],
          classDraggableArea: [
            'draggable-area'
          ],
          mouseoverDraggableArea: false
      }
  },
  filters: {
    toPriceLocaleString : function (value){
      if(!value){
        return 0;
      }
      return value.toLocaleString('ja-JP', {"style": "currency", "currency": "JPY"});
    },
  },
  methods: {
    judgeViewEventName() {
      return (this.viewEventName === 'true')
    },
    isUserInputUnsortableVisitorOption(name) {
      let option = this.getUnsortableVisitorOption(name)
      if(option == null) return null
      return (option.userInput) ? option.userInput : false
    },
    getLabelUnsortableVisitorOption(name) {
      let option = this.getUnsortableVisitorOption(name)
      if(option == null) return null
      return (option.label) ? option.label: null
    },
    getUnsortableVisitorOption(name) {
      if(this.eventData.visitorOptionSchema == undefined) return null
      switch(name) {
        case 'LASTNAME': return this.eventData.visitorOptionSchema.lastname
        case 'FIRSTNAME': return this.eventData.visitorOptionSchema.firstname
        case 'MAIL': return this.eventData.visitorOptionSchema.mail
        case 'MAIL_CONFIRMATION': return this.eventData.visitorOptionSchema.mail_confirmation
        case 'OPT': return this.eventData.visitorOptionSchema.opt
      }
    },
    isNeedControlDisplayPriceOfFreeTickets(tickets) {
      if(tickets == null) return false;
      if(tickets.length == 0) return false;
      return (tickets.filter(ticket => (ticket.paidTicket && !ticket.delFlg)).length == 0)
    },
    formatDateWithDayOfWeek(date) {
      return moment(date).format('YYYY-M-D(ddd)')
    },
    onMouseoverDraggableTicketsArea() {
      this.mouseoverDraggableTicketsArea = true
      this.classDraggableTicketsArea = [
        'draggable-tickets-area',
        'mouseover'
      ]
    },
    onMouseleaveDraggableTicketsArea() {
      this.mouseoverDraggableTicketsArea = false
      this.classDraggableTicketsArea = [
        'draggable-tickets-area'
      ]
    },
    onMouseoverDraggableArea() {
      this.mouseoverDraggableArea = true
      this.classDraggableArea = [
        'draggable-area',
        'mouseover'
      ]
    },
    onMouseleaveDraggableArea() {
      this.mouseoverDraggableArea = false
      this.classDraggableArea = [
        'draggable-area'
      ]
    },
    goBack() {
       this.sortableTickets = []
       this.sortableVisitorOptions = []
       this.$emit("goBack")
    },
    publish() {
        let imageUrls = []
        let coverImageKeyUrl = ""
        this.eventData.publishStatus = true

        if (this.eventData.coverImageUrl !== "" && this.eventData.coverImageUrl !== null) {
            imageUrls = this.eventData.coverImageUrl.split('?')
            coverImageKeyUrl = imageUrls[0]
        } else {
            coverImageKeyUrl = ""
        }

        let ticketObj = {
          tickets: [],
          setTicket(t) {
            let entryIndex = this.tickets.length + 1
            this.tickets.push({
              ...t,
              entryIndex: entryIndex
            })
          },
          getAll() {
            return this.tickets
          }
        }
        this.sortableTickets.forEach(t => {
          ticketObj.setTicket(t)
        })
        this.eventData.tickets.forEach(t => {
          if(t.id > 0 && t.delFlg) {
            ticketObj.setTicket(t)
          }
        })

        let optionObj2 = {
            options: [],
            setUnsortableOption(visitorOption, o) {
                if(o.userInput) {
                    this.options.push({
                        visitorOption: visitorOption,
                        required: o.required,
                        label: o.label,
                        manageLabel: null,
                        active: true,
                        editIndex: 0,
                        entryIndex: 0
                    })
                }
            },
            setSortableItem(o, entryIndex) {
                this.options.push({
                    visitorOption: o.visitorOption,
                    required: o.required,
                    label: o.label,
                    manageLabel: o.manageLabel,
                    active: o.userinput,
                    editIndex: (o.editIndex) ? o.editIndex : 0,
                    entryIndex: entryIndex
                })
            },
            getAll() {
              return this.options
            }
        }
        optionObj2.setUnsortableOption('LASTNAME',  this.eventData.visitorOptionSchema.lastname)
        optionObj2.setUnsortableOption('FIRSTNAME', this.eventData.visitorOptionSchema.firstname)
        optionObj2.setUnsortableOption('MAIL',      this.eventData.visitorOptionSchema.mail)
        optionObj2.setUnsortableOption('MAIL_CONFIRMATION', this.eventData.visitorOptionSchema.mail_confirmation)
        optionObj2.setUnsortableOption('OPT',       this.eventData.visitorOptionSchema.opt)
        let entryIndex = 1
        this.sortableVisitorOptions.forEach(item => {
            optionObj2.setSortableItem(item, entryIndex)
            entryIndex++
        })

        this.$emit("publish", {
            tickets: ticketObj.getAll(),
            visitorOptions: optionObj2.getAll(),
            viewEventName: this.judgeViewEventName(),
            titleFontInfo: {
              color: this.eventTitleColorContainer.hex8,
              font: this.eventTitleFont,
              size: this.eventTitleSize
            },
            coverImageUrl:coverImageKeyUrl,
            displayPriceOfFreeTickets:this.displayPriceOfFreeTickets
        })
    },
    pickEventTitleFont(e) {
        let sel = this.eventTitleFontList.filter(function(elm) {return (elm.name == e.target.innerText)});
        this.eventTitleFont = sel[0].value;
    },
    getEventTitleColorContainer(hex8) {
        let rgba = this.getRgbaFromHex8(hex8)
        let colors = {
            hex8: hex8,
            hex: hex8.substring(0, 7),
            rgba: rgba,
            a: rgba.a,
            source: "rgba"
        }
        return colors
    },
    getRgbaFromHex8(hex8) {
        let elems = hex8.slice(1).match(/.{1,2}/g);
        let alpha = (elems.length == 4) ? parseFloat(parseInt((parseInt(elems[3], 16)/255)*100)/100) : 1
        return {
            r: parseInt(elems[0], 16),
            g: parseInt(elems[1], 16),
            b: parseInt(elems[2], 16),
            a: alpha
        }
    },
    hideOrDisplayAlphaValueAdjustmentUiEventTitleColorPicker(coverImageUrl) {
        let eventTitleColorPicker = document.getElementsByClassName("eventTitleColorPicker")
        if(eventTitleColorPicker.length != 1) return

      let alphaControlBar = eventTitleColorPicker[0].getElementsByClassName("vc-chrome-alpha-wrap")
      let styleDisplay = (coverImageUrl) ? "block" : "none"
      alphaControlBar[0].setAttribute("style", "display: " + styleDisplay + ";")
    },
    pickDisplayPriceOfFreeTickets(e) {
      let sel = this.displayPriceOfFreeTicketsList.filter(function(elm) {return (elm.name == e.target.innerText)});
      this.displayPriceOfFreeTickets = sel[0].value;
    },
    setSortableTickets() {
      let srcTickets = this.eventData.tickets
      if(!srcTickets || srcTickets.length == 0) return

      let destTickets = []
      srcTickets.forEach(e => {
        if(e.delFlg) return

        destTickets.push(e)
      })
      destTickets.sort((a,b) => {
        if(a.entryIndex != b.entryIndex) {
          if(!a.entryIndex) return 1
          if(!b.entryIndex) return -1

          return a.entryIndex - b.entryIndex
        } else {
          return a.orderPriority - b.orderPriority
        }
      })
      this.sortableTickets = destTickets
    },
    setSortableVisitorOptions() {
      if(this.sortableVisitorOptions.length > 0) return
      if(!this.eventData.visitorOptionSchema) return

      let srcOptions = this.eventData.visitorOptionSchema.sortableVisitorOptions
      if(!srcOptions || srcOptions.length == 0) return

      let destOptions = []
      srcOptions.forEach(e => {
        if(e.deleteFlg) return
        if(!e.userinput) return

        let definitions = this.choosableVisitorOptionsMap[e.visitorOption]
        let priority = (definitions) ? definitions.defaultOrderPriority : 0
        let labelPreview = (e.label) ? e.label : definitions.label
        destOptions.push({
          ...e,
          priority: priority,
          labelPreview: labelPreview
        })
      })
      destOptions.sort((a, b) => {
        if(a.entryIndex != b.entryIndex) {
          return a.entryIndex - b.entryIndex
        } else {
          return a.priority - b.priority
        }
      })
      this.sortableVisitorOptions = destOptions
    }
  },
  mounted() {
    if (this.eventData.tickets != undefined) {
      this.eventData.tickets = this.eventData.tickets.sort((a, b) => {
        return (a.orderPriority > b.orderPriority) ? 1 : (a.orderPriority < b.orderPriority) ? -1 : 0;
      })
    }

    this.hideOrDisplayAlphaValueAdjustmentUiEventTitleColorPicker(this.eventData.coverImageUrl)
  },
  beforeUpdate(){
        if(this.facilities.find( element => element.id === this.eventData.facilityId )!== undefined){
            this.event_facility = this.facilities.find( element => element.id === this.eventData.facilityId )
        }
  },
}
</script>

<style scoped>
.titleControl {
  background-color: #f5f5f5;
  border-style: solid;
  border-radius: 5px;
  border-width: 3px;
  border-color: #fcfcfc #e0e0e0 #e0e0e0 #fcfcfc;
  padding: 10px 20px;
}
#editbtn.edit-b{
    background: #969696;
    color:white;
}
.textarea{
    color:#969696;
    margin-top: 30px;
    margin-bottom: 30px;
    font-size:110%;
}
.organizer {
  color:#969696;
  margin-bottom: 30px;
  font-size:110%;
}
.subTitle{
    position: relative;
    text-align: left;
    font-size:130%;
    font-weight:bold;
    color:#959595;
}
.subTitle:before {
    border-top: 1px solid;
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
}
.subTitle span {
    background-color: white;
    display: inline-block;
    padding: 0 0.5em;
    position: relative;
}
.subContentsTitle{
    font-size:110%;
}
.contents{
    font-size:150%;
    color:#959595;
    font-weight:bold;
    text-indent:5px;
}
.maparea{
    margin-top: 10px;
    margin-bottom: 10px;
}

.input-area{
    background-color: #F0F0F0;
    padding-top:20px;
    padding-bottom: 50px;
    color:#959595;
}
.inputFieldTitle{
    text-align:left;
    font-weight:bold;
}
.inputAreaTitle{
    font-size:150%;
    font-weight:bold;
}
.linkDecoration{
    text-decoration:none;
    color:#5f9ddd;
    font-weight:bold;
}
.essential{
    background-color: #f44336;
    color: white;
    font-size: 13px;
    font-weight: bold;
    padding: 4px 0.5em;
    vertical-align: top;
    margin-left: 1.1em;
    border-radius: 10px;        /* CSS3草案 */
    -webkit-border-radius: 10px;    /* Safari,Google Chrome用 */
    -moz-border-radius: 10px;   /* Firefox用 */
}

.mapresize{
        width: 560px;
        height: 315px;
        display: block;
        overflow: hidden;
        position: relative;
    }
.attention {
    margin-left: 10px;
    font-weight:normal;
}

.ticketList {
  padding-left: 30px;
  text-align: left;
}
.ticketDescription {
  padding-left: 30px;
  white-space: pre-wrap;
  word-wrap: break-word;
}
.draggable-tickets-area .draggable-ticket {
  padding: 5px 0px 5px;
  margin-bottom: 25px;
}
.draggable-tickets-area.mouseover .draggable-ticket {
  border-radius: 5px;
  padding: 4px;
  border: solid 1px #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  transform: translateY(-0.1875em);
}
.draggable-item.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.draggable-area .draggable-item {
  padding: 5px 0px 5px;
  margin-bottom: 25px;
}
.draggable-area.mouseover .draggable-item {
  border-radius: 5px;
  padding: 4px;
  border: solid 1px #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  transform: translateY(-0.1875em);
}
.draggable-item.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>
