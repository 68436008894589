<template>
  <v-app id="inspire">
    <middle_header :eventData=this.eventData cancelInformation="true"></middle_header>

    <v-container fluid>
      <v-flex>
        <p class="title">
          <v-icon large>cancel</v-icon>
          <span class="titleName">キャンセル状況</span>
        </p>
      </v-flex>
      <v-data-table
        :headers="headers"
        :items="visitors"
        :rows-per-page-items="rowsPerPageItems"
        :pagination.sync="pagination"
        item-key="id"
        must-sort
      >
        <template slot="headerCell" slot-scope="props">
          {{ props.header.text }}
        </template>
        <template slot="items" slot-scope="props">
          <tr>
            <td v-if="visitorOptions.LASTNAME" class="text-md-left">&nbsp;{{ props.item.lastName }}&nbsp;{{ props.item.firstName }}</td>
            <td v-if="visitorOptions.MAIL" class="text-md-left">{{ props.item.username }}</td>
            <td class="text-md-left">{{ formatDate(props.item.createdAt) }}</td>
            <td class="text-md-left">{{ formatDate(props.item.cancelledAt) }}</td>
            <td v-if="props.item.cancelledBy" class="text-md-left">{{ props.item.cancelledBy }}</td>
            <td v-else="props.item.cancelledBy" class="text-md-left"></td>
            <td v-if="visitorOptions.TICKET" class="text-md-left">{{ props.item.ticketName }}</td>
            <td v-if="visitorOptions.TICKET" class="text-md-left">{{ props.item.ticketPrice | toPriceLocaleString }}</td>
            <td v-if="visitorOptions.COMPANY" class="text-md-left">{{ props.item.companyName }}</td>
            <td v-if="visitorOptions.DEPT" class="text-md-left">{{ props.item.department }}</td>
            <td v-if="visitorOptions.POSITION" class="text-md-left">{{ props.item.position }}</td>
            <td v-if="visitorOptions.ADDRESS" class="text-md-left">{{ props.item.address }}</td>
            <td v-if="visitorOptions.TEL" class="text-md-left">{{ props.item.phone }}</td>
          </tr>
        </template>
      </v-data-table>

      <v-flex lg3 offset-lg9 md3 offset-md9 xs10 offset-xs1 class="apparea">
        <p style="font-size:12px; margin-bottom:3px;">受付アプリはApp Storeでインストールしお使いください。<br>※受付アプリはiOS端末のみの対応となります。</p>
        <p style="margin-bottom:0px;"><a
          href="https://itunes.apple.com/jp/app/tkp-event-planner-%E5%8F%97%E4%BB%98%E3%82%A2%E3%83%97%E3%83%AA/id1438093541?mt=8"
          target="_blank"
          style="display:inline-block;overflow:hidden;background:url(https://linkmaker.itunes.apple.com/assets/shared/badges/ja-jp/appstore-lrg.svg) no-repeat;width:135px;height:40px;"></a>
        </p>
      </v-flex>
    </v-container>
  </v-app>
</template>

<script>
  import header from '@/components/EventHeader.vue'
  import {
    getMe,
    getEvent,
    getCancelledVisitor
  } from '../utils/APIUtils'

  export default {
    name: 'CancelInformation',
    components: {
      'middle_header': header
    },
    data() {
      return {
        role: '',
        organizerId: '',

        eventId: null,
        eventData: {},
        eventName: '',
        eventDate: '',
        eventFacility: '',

        headers: [],
        visitorOptions: {
          LASTNAME: false,
          DEPT: false,
          MAIL: false,
          COMPANY: false,
          POSITION: false,
          OPT: false,
          ADDRESS: false,
          TEL: false,
          TICKET: false
        },
        rowsPerPageItems: [
          {text: '10', value: 10},
          {text: '100', value: 100},
          {text: '1000', value: 1000},
          {text: '5000', value: 5000}
        ],
        pagination: {
          rowsPerPage: 1000,
          sortBy: 'cancelledAt',
          descending: true,
        },

        visitors: []
      }
    },
    watch: {},
    filters: {
      toPriceLocaleString: function(value) {
        if (!value) {
          return 0;
        }
        return value.toLocaleString("ja-JP", {
          style: "currency",
          currency: "JPY"
        });
      }
    },
    methods: {
      loadEvent() {
        getEvent({eventId: this.eventId}).then(res => {
          this.eventData = res.data
          this.eventName = this.eventData.eventName
          this.eventDate = this.eventData.eventDate
          this.eventFacility = this.eventData.facility.facilityName
          this.setHeaderData()
          this.loadCancelledVisitor()
          const user = res.data.eventGroup.members.find(member => {
            return member.organizer.id === this.organizerId
          })
          this.role = user.role
        }).catch(err => {
          console.log("err", err)
          alert('データの取得に失敗しました。')
        })
      },
      loadCancelledVisitor() {
        getCancelledVisitor({eventId: this.eventId}).then(res => {
          this.visitors = res.data
        }).catch(err => {
          console.log("err", err)
          alert('データの取得に失敗しました。')
        })
      },
      setHeaderData() {
        let options = this.eventData.visitorOptions
        let optionLabels = []
        options.forEach(o => {
          optionLabels.push(o.visitorOption)
        })

        if (optionLabels.indexOf("LASTNAME") !== -1) {
          this.headers.push({text: '氏名', value: 'lastName',})
          this.visitorOptions.LASTNAME = true
        }
        if (optionLabels.indexOf("MAIL") !== -1) {
          this.headers.push({text: 'メールアドレス', value: 'username'})
          this.visitorOptions.MAIL = true
        }
        this.headers.push({text: '登録日時', value: 'createdAt'})
        this.headers.push({text: 'キャンセル日時', value: 'cancelledAt'})
        this.headers.push({text: 'キャンセル処理者', value: 'cancelledBy'})
        if(this.eventData.tickets.length > 0) {
          this.headers.push({text: '参加券名称', value: 'ticketName'})
          this.headers.push({text: '購入価格', value: 'ticketPrice'})
          this.visitorOptions.TICKET = true
        }
        if (optionLabels.indexOf("COMPANY") !== -1) {
          this.headers.push({text: '会社名', value: 'companyName'})
          this.visitorOptions.COMPANY = true
        }
        if (optionLabels.indexOf("DEPT") !== -1) {
          this.headers.push({text: '部署名', value: 'department'})
          this.visitorOptions.DEPT = true
        }
        if (optionLabels.indexOf("POSITION") !== -1) {
          this.headers.push({text: '役職', value: 'position'})
          this.visitorOptions.POSITION = true
        }
        if (optionLabels.indexOf("ADDRESS") !== -1) {
          this.headers.push({text: '住所', value: 'address'})
          this.visitorOptions.ADDRESS = true
        }
        if (optionLabels.indexOf("TEL") !== -1) {
          this.headers.push({text: 'TEL', value: 'phone'})
          this.visitorOptions.TEL = true
        }
      },
      formatDate(date) {
        return date ? new Date(date).toLocaleString() : date;
      }
    },
    mounted() {
      this.eventId = this.$route.query.id
      let headers = {accountType: 'organizer'};
      getMe({headers: headers, accountType: 'organizer'})
        .then(res => {
          this.organizerId = res.data.id
          this.loadEvent();
        }).catch(err => {
        this.$router.push('/')
      })
    },
    computed:{}
  }

</script>
<style scoped>
  #inspire {
    color: #666666;
    background-color: white;
  }

  .title {
    text-align: left;
    margin-left: 17px;
  }

  .titleName {
    display: inline-block;
    vertical-align: top;
    padding-top: 9px;
    padding-left: 5px;
  }

  td {
    color: #666666;
    white-space: nowrap;
  }

  .apparea {
    background-color: #FAFAFA;
    padding: 0px;
    border: solid 1px #BDBDBD;
    margin-top: 10px;
  }


</style>
