<template>
  <v-container>
    <Loading></Loading>
    <h2>申込者情報の編集</h2>
    <EntryForm v-model="entry" @save="save"></EntryForm>
  </v-container>
</template>
<script>
import EntryForm from '../../event/components/EntryForm'
import {getVisitor} from '../../utils/APIUtils'
import axios from 'axios'
import Loading from '../../event/components/Loading'

export default {
  components: {EntryForm, Loading},
  data () {
    return {
      loading: false,
      eventId: this.$route.params.eventId,
      visitorId: this.$route.params.visitorId,
      entry: null
    }
  },
  async created () {
    await this.fetchVisitor(this.visitorId)
  },
  methods: {
    async fetchVisitor () {
      this.loading = true
      let {data} = await getVisitor({
        visitorId: this.visitorId
      })
      this.detail = data
      this.entry = data.entry
      this.subjectEntry = data.subjectEntry
      this.status = data.entryStatus
      this.entryChangelogs = data.entryChangelogs
      this.loading = false
    },
    async save (data) {
      this.loading = true
      // if (data) {
      //   console.log(data)
      //   return
      // }
      try {
        await axios.post(`/api/entry/save/${this.visitorId}`, data)
        this.$emit('toast', '保存しました')
      } catch (e) {
        console.error(e)
        this.$emit('error', 'エラーが発生しました。しばらくしてから再度お試しください')
      } finally {
        this.loading = false
      }
    },
  }
}
</script>
