<template>
    <div>
    <v-form v-model="valid">
    <v-container class="input-area">
        <v-layout row wrap>
            <v-flex xs12>
                <p class="label">
                  登録者が入力する項目をご選択ください。<br>
                  (＊変更したラベルは公開画面にのみ反映されます。)
                </p>

                <v-container fluid>
                    <v-layout row wrap>
                        <v-flex xs12>
                          <h3 class="subtitle">基本項目</h3>
                        </v-flex>

                        <v-flex md1 xs1>
                        </v-flex>
                        <v-flex md2 xs4>
                                <div class="titlePosition"></div>
                        </v-flex>
                        <v-flex md1 xs2>
                        </v-flex>
                        <v-flex md1 xs2>
                                <div class="titlePosition">表示</div>
                        </v-flex>
                        <v-flex md1 xs3>
                                <div class="titlePosition">必須</div>
                        </v-flex>

                        <v-flex md1 xs1 class="hidden-sm-and-down">
                        </v-flex>
                        <v-flex md2 xs4 class="hidden-sm-and-down">
                                <div class="titlePosition"></div>
                        </v-flex>
                        <v-flex md1 xs2 class="hidden-sm-and-down">
                        </v-flex>
                        <v-flex md1 xs2 class="hidden-sm-and-down">
                                <div class="titlePosition">表示</div>
                        </v-flex>
                        <v-flex md1 xs3 class="hidden-sm-and-down">
                                <div class="titlePosition">必須</div>
                        </v-flex>

                        <v-flex md1 xs1>
                        </v-flex>
                        <v-flex md2 xs4>
                                <div v-if="!this.editFlg_name" class="optionName" style="margin-top:20px;">名前</div>
                                <div v-else class="optionName" style="margin-top:20px;">
                                  <v-text-field
                                    v-model="customLabel_lastname"
                                    text
                                    clearable
                                    label="姓"
                                    type="text"
                                  ></v-text-field>
                                    <v-text-field
                                      v-model="customLabel_firstname"
                                      text
                                      clearable
                                      label="名"
                                      type="text"
                                    ></v-text-field>
                                </div>
                        </v-flex>
                        <v-flex md1 xs2>
                                <v-icon @click="onClickEditCustomLabelIcon('NAME')" class="iconPosition">
                                  create
                                </v-icon>
                        </v-flex>
                        <v-flex md1 xs2>
                                <v-checkbox
                                v-model="userinput_name"
                                color="primary"
                                disabled
                                ></v-checkbox>
                        </v-flex>
                        <v-flex md1 xs3>
                                <v-checkbox
                                v-model="userinput_name"
                                color="primary"
                                disabled
                                ></v-checkbox>
                        </v-flex>
                        <v-flex md1 xs1>
                        </v-flex>
                        <v-flex md2 xs4>
                                <div v-if="!this.editFlg_company" class="optionName" style="margin-top:20px;">会社名</div>
                                <div v-else class="optionName">
                                  <v-text-field
                                    v-model="customLabel_company"
                                    :rules="customLabel_rule"
                                    text
                                    label="会社名"
                                    type="text"
                                  ></v-text-field>
                                </div>
                        </v-flex>
                        <v-flex md1 xs2>
                                <v-icon @click="onClickEditCustomLabelIcon('COMPANY')" class="iconPosition">
                                  create
                                </v-icon>
                        </v-flex>
                        <v-flex md1 xs2>
                                <v-checkbox
                                v-model="userinput_company"
                                @change="onChangeUserInput('COMPANY')"
                                color="primary"
                                ></v-checkbox>
                        </v-flex>
                        <v-flex md1 xs3>
                                <v-checkbox
                                v-model="required_company"
                                @change="onChangeRequired('COMPANY')"
                                color="error"
                                ></v-checkbox>
                        </v-flex>

                        <v-flex md1 xs1>
                        </v-flex>
                        <v-flex md2 xs4>
                                <div v-if="!this.editFlg_mail" class="optionName" style="margin-top:20px;">メールアドレス</div>
                                <div v-else class="optionName" style="margin-top:15px;">
                                  <v-text-field
                                    v-model="customLabel_mail"
                                    :rules="customLabel_rule"
                                    text
                                    label="メールアドレス"
                                    type="text"
                                  ></v-text-field>
                                </div>
                        </v-flex>
                        <v-flex md1 xs2>
                                <v-icon @click="onClickEditCustomLabelIcon('MAIL')" class="iconPosition">
                                  create
                                </v-icon>
                        </v-flex>
                        <v-flex md1 xs2>
                                <v-checkbox
                                v-model="userinput_mail"
                                color="primary"
                                disabled
                                ></v-checkbox>
                        </v-flex>
                        <v-flex md1 xs3>
                                <v-checkbox
                                v-model="required_mail"
                                color="primary"
                                disabled
                                ></v-checkbox>
                        </v-flex>

                        <v-flex md1 xs1>
                        </v-flex>
                        <v-flex md2 xs4>
                                <div v-if="!this.editFlg_dept" class="optionName" style="margin-top:20px;">部署</div>
                                <div v-else class="optionName" style="margin-top:15px;">
                                  <v-text-field
                                    v-model="customLabel_dept"
                                    :rules="customLabel_rule"
                                    text
                                    label="部署"
                                    type="text"
                                  ></v-text-field>
                                </div>
                        </v-flex>
                        <v-flex md1 xs2>
                                <v-icon @click="onClickEditCustomLabelIcon('DEPT')" class="iconPosition">
                                  create
                                </v-icon>
                        </v-flex>
                        <v-flex md1 xs2>
                                <v-checkbox
                                v-model="userinput_dept"
                                @change="onChangeUserInput('DEPT')"
                                color="primary"
                                ></v-checkbox>
                        </v-flex>
                        <v-flex md1 xs3>
                                <v-checkbox
                                v-model="required_dept"
                                @change="onChangeRequired('DEPT')"
                                color="error"
                                ></v-checkbox>
                        </v-flex>

                        <v-flex md1 xs1>
                        </v-flex>
                        <v-flex md2 xs4>
                                <div v-if="!this.editFlg_mail_confirmation" class="optionName" style="margin-top:20px;">メールアドレス（確認）</div>
                                <div v-else class="optionName" style="margin-top:15px;">
                                  <v-text-field
                                    v-model="customLabel_mail_confirmation"
                                    :rules="customLabel_rule"
                                    text
                                    label="メールアドレス（確認）"
                                    type="text"
                                  ></v-text-field>
                                </div>
                        </v-flex>
                        <v-flex md1 xs2>
                                <v-icon @click="onClickEditCustomLabelIcon('MAIL_CONFIRMATION')" class="iconPosition">
                                  create
                                </v-icon>
                        </v-flex>
                        <v-flex md1 xs2>
                                <v-checkbox
                                v-model="userinput_mail_confirmation"
                                color="primary"
                                ></v-checkbox>
                        </v-flex>
                        <v-flex md1 xs3>
                                <v-checkbox
                                v-model="required_mail_confirmation"
                                color="primary"
                                disabled
                                ></v-checkbox>
                        </v-flex>

                        <v-flex md1 xs1>
                        </v-flex>
                        <v-flex md2 xs4>
                                <div v-if="!this.editFlg_pos" class="optionName" style="margin-top:20px;">役職</div>
                                <div v-else class="optionName" style="margin-top:15px;">
                                  <v-text-field
                                    v-model="customLabel_pos"
                                    :rules="customLabel_rule"
                                    text
                                    label="役職"
                                    type="text"
                                  ></v-text-field>
                                </div>
                        </v-flex>
                        <v-flex md1 xs2>
                                <v-icon @click="onClickEditCustomLabelIcon('POSITION')" class="iconPosition">
                                  create
                                </v-icon>
                        </v-flex>
                        <v-flex md1 xs2>
                                <v-checkbox
                                v-model="userinput_pos"
                                @change="onChangeUserInput('POSITION')"
                                color="primary"
                                ></v-checkbox>
                        </v-flex>
                        <v-flex md1 xs3>
                                <v-checkbox
                                v-model="required_pos"
                                @change="onChangeRequired('POSITION')"
                                color="error"
                                ></v-checkbox>
                        </v-flex>

                        <v-flex md1 xs1>
                        </v-flex>
                        <v-flex md2 xs4>
                                <div v-if="!this.editFlg_add" class="optionName" style="margin-top:20px;">住所</div>
                                <div v-else class="optionName" style="margin-top:15px;">
                                  <v-text-field
                                    v-model="customLabel_add"
                                    :rules="customLabel_rule"
                                    text
                                    label="住所"
                                    type="text"
                                  ></v-text-field>
                                </div>
                        </v-flex>
                        <v-flex md1 xs2>
                                <v-icon @click="onClickEditCustomLabelIcon('ADDRESS')" class="iconPosition">
                                  create
                                </v-icon>
                        </v-flex>
                        <v-flex md1 xs2>
                                <v-checkbox
                                v-model="userinput_add"
                                @change="onChangeUserInput('ADDRESS')"
                                color="primary"
                                ></v-checkbox>
                        </v-flex>
                        <v-flex md1 xs3>
                                <v-checkbox
                                v-model="required_add"
                                @change="onChangeRequired('ADDRESS')"
                                color="error"
                                ></v-checkbox>
                        </v-flex>

                        <v-flex md1 xs1>
                        </v-flex>
                        <v-flex md2 xs4>
                                <div v-if="!this.editFlg_tel" class="optionName" style="margin-top:20px;">TEL</div>
                                <div v-else class="optionName" style="margin-top:15px;">
                                  <v-text-field
                                    v-model="customLabel_tel"
                                    :rules="customLabel_rule"
                                    text
                                    label="TEL"
                                    type="text"
                                    style="color:black;"
                                  ></v-text-field>
                                </div>
                        </v-flex>
                        <v-flex md1 xs2>
                                <v-icon @click="onClickEditCustomLabelIcon('TEL')" class="iconPosition">
                                  create
                                </v-icon>
                        </v-flex>
                        <v-flex md1 xs2>
                                <v-checkbox
                                v-model="userinput_tel"
                                @change="onChangeUserInput('TEL')"
                                color="primary"
                                ></v-checkbox>
                        </v-flex>
                        <v-flex md1 xs3>
                                <v-checkbox
                                v-model="required_tel"
                                @change="onChangeRequired('TEL')"
                                color="error"
                                ></v-checkbox>
                        </v-flex>

                        <v-flex md1 xs1>
                        </v-flex>
                        <v-flex md2 xs4>
                                <div class="optionName" style="margin-top:20px;">オプトイン</div>
                        </v-flex>
                        <v-flex md1 xs2>
                        </v-flex>
                        <v-flex md1 xs2>
                                <v-checkbox
                                v-model="userinput_opt"
                                color="primary"
                                ></v-checkbox>
                        </v-flex>
                        <v-flex md7 xs3>
                        </v-flex>

                        <v-flex xs12>
                                <hr class="submenu">
                        </v-flex>
                        <v-flex xs12>
                                <h3 class="subtitle">カスタム項目</h3>
                        </v-flex>

                        <v-flex xs1>
                        </v-flex>
                        <v-flex xs2>
                                <div class="titlePosition">管理用名称</div>
                        </v-flex>
                        <v-flex xs5>
                                <div class="titlePosition">表示ラベル</div>
                        </v-flex>
                        <v-flex xs1>
                        </v-flex>
                        <v-flex xs1>
                                <div class="titlePosition">表示</div>
                        </v-flex>
                        <v-flex xs1>
                                <div class="titlePosition">必須</div>
                        </v-flex>
                        <v-flex xs1>
                          <div class="titlePosition">削除</div>
                        </v-flex>

                        <template v-for="(item, index) in freeItems">
                          <v-flex xs1>
                          </v-flex>
                          <v-flex xs2>
                                <div class="optionName" style="margin-top:15px;">
                                  <v-text-field
                                    v-model="item.manageLabel"
                                    :rules="customManageLabel_rule"
                                    text
                                    type="text"
                                  ></v-text-field>
                                </div>
                          </v-flex>
                          <v-flex xs5>
                                <div class="optionName" style="margin-top:15px;">
                                  <v-text-field
                                    v-model="item.label"
                                    :rules="customLabel_rule"
                                    text
                                    type="text"
                                  ></v-text-field>
                                </div>
                          </v-flex>
                          <v-flex xs1>
                          </v-flex>
                          <v-flex xs1>
                                <v-checkbox
                                  v-model="item.userinput"
                                  @change="onChangeUserInput('FREE_ITEMS', index)"
                                  color="primary"
                                ></v-checkbox>
                          </v-flex>
                          <v-flex xs1>
                                  <v-flex xs6>
                                    <v-checkbox
                                      v-model="item.required"
                                      @change="onChangeRequired('FREE_ITEMS', index)"
                                      color="error"
                                    ></v-checkbox>
                                  </v-flex>
                          </v-flex>
                          <v-flex xs1>
                                  <v-flex v-if="item.nowVisitorOption" xs6 style="text-align: left;">
                                    <v-checkbox
                                      v-model="item.deleteFlg"
                                      @change="onChangeFreeItemDeleteFlg('FREE_ITEMS', index)"
                                      color="black"
                                    ></v-checkbox>
                                  </v-flex>
                                  <v-layout v-else align-center fill-height>
                                      <v-flex xs6>
                                        <v-btn right @click.stop="onClickDeleteFreeItem(index)" icon class="btnDeleteFreeItem">
                                          <v-icon>close</v-icon>
                                        </v-btn>
                                      </v-flex>
                                  </v-layout>
                          </v-flex>
                        </template>
                        <v-flex xs12>
                          <div class="btn-area">
                            <v-btn large @click.prevent="onClickAddFreeItem">➕　項目追加</v-btn>
                          </div>
                        </v-flex>
                     </v-layout>
                 </v-container>
            </v-flex>
        </v-layout>
    </v-container>

    <v-btn class="edit-b" @click.native="goBack()">戻る</v-btn>
    <v-btn color="error" @click.native="moveNextStep()" :disabled="!valid">次へ</v-btn>
    </v-form>
    </div>
</template>

<script>
const uuidv4 = require('uuid/v4');
export default {
  props: ["eventData"],
  watch: {
    eventData(data) {
      this.eventData = data;
      //Edit
      if (this.eventData.id !== undefined) {
        //Set Values
        if(this.eventData.visitorOptionSchema === undefined){
            let tmpFreeItems = []
            let options = this.eventData.visitorOptions
            let maxEntryIndex = null
            options.forEach(o => {
                if(maxEntryIndex) {
                    maxEntryIndex = (maxEntryIndex > o.entryIndex) ? maxEntryIndex : o.entryIndex
                } else {
                    maxEntryIndex = o.entryIndex
                }

                switch (o.visitorOption) {
                    case "MAIL":
                        this.userinput_mail = true
                        this.required_mail = true
                        if(o.label != null){
                          this.customLabel_mail = o.label
                          this.editFlg_mail = true
                        }
                        break;
                    case "MAIL_CONFIRMATION":
                        this.userinput_mail_confirmation = true
                        this.required_mail_confirmation = true
                        if(o.label != null){
                          this.customLabel_mail_confirmation = o.label
                          this.editFlg_mail_confirmation = true
                        }
                        break;
                    case "LASTNAME":
                        this.userinput_name = true
                        this.required_name = true
                        if(o.label != null){
                          this.customLabel_lastname = o.label
                          this.editFlg_name = true
                        }
                        break;
                    case "FIRSTNAME":
                        if(o.label != null){
                          this.customLabel_firstname = o.label
                          this.editFlg_name = true
                        }
                        break;
                    case "COMPANY":
                        this.userinput_company = true
                        this.required_company = o.required
                        if(o.label != null){
                          this.customLabel_company = o.label
                          this.editFlg_company = true
                        }
                        this.entryIndex_company = o.entryIndex
                        break;
                    case "DEPT":
                        this.userinput_dept = true;
                        this.required_dept = o.required
                        if(o.label != null){
                          this.customLabel_dept = o.label
                          this.editFlg_dept = true
                        }
                        this.entryIndex_dept = o.entryIndex
                        break;
                    case "POSITION":
                        this.userinput_pos = true
                        this.required_pos = o.required
                        if(o.label != null){
                          this.customLabel_pos = o.label
                          this.editFlg_pos = true
                        }
                        this.entryIndex_pos = o.entryIndex
                        break;
                    case "ADDRESS":
                        this.userinput_add = true
                        this.required_add = o.required
                        if(o.label != null){
                          this.customLabel_add = o.label
                          this.editFlg_add = true
                        }
                        this.entryIndex_add = o.entryIndex
                        break;
                    case "TEL":
                        this.userinput_tel = true
                        this.required_tel = o.required
                        if(o.label != null){
                          this.customLabel_tel = o.label
                          this.editFlg_tel = true
                        }
                        this.entryIndex_tel = o.entryIndex
                        break;
                    case "OPT":
                        this.userinput_opt = true;
                        break;
                    default:
                        tmpFreeItems.push({
                          visitorOption: o.visitorOption,
                          userinput: o.active,
                          required: o.required,
                          label: o.label,
                          entryIndex: o.entryIndex,

                          manageLabel: o.manageLabel,
                          editIndex: o.editIndex,

                          deleteFlg: false,
                          nowVisitorOption: o.visitorOption
                        })
                        break;
                }
            });

            this.freeItems = tmpFreeItems
            this.maxEntryIndex = maxEntryIndex
        }
      } else {
        // default value of visitor signup option
        this.userinput_mail_confirmation = true
      }
    }
  },
  data() {
    return {
      userinput_name: true,
      required_name: true,
      customLabel_lastname:null,
      customLabel_firstname:null,
      editFlg_name:false,

      userinput_dept: false,
      required_dept: false,
      customLabel_dept:null,
      editFlg_dept:false,
      entryIndex_dept:null,

      userinput_mail: true,
      required_mail: true,
      customLabel_mail:null,
      editFlg_mail:false,

      userinput_mail_confirmation: false,
      required_mail_confirmation: true,
      customLabel_mail_confirmation:null,
      editFlg_mail_confirmation:false,

      userinput_company: false,
      required_company: false,
      customLabel_company:null,
      editFlg_company:false,
      entryIndex_company:null,

      userinput_pos: false,
      required_pos: false,
      customLabel_pos:null,
      editFlg_pos:false,
      entryIndex_pos:null,

      userinput_add: false,
      required_add: false,
      customLabel_add:null,
      editFlg_add:false,
      entryIndex_add:null,

      userinput_tel: false,
      required_tel: false,
      customLabel_tel:null,
      editFlg_tel:false,
      entryIndex_tel:null,

      userinput_opt: false,
      valid:false,

      freeItems: [],
      maxEntryIndex: null,
      isDisplayedAlertDeleteFreeItem: false,

      customLabel_rule: [v => (v && v.length <= 128)|| '128文字以下で入力してください'],
      customManageLabel_rule: [v => (v && v.length <= 12)|| '12文字以下で入力してください']
    };
  },
  methods: {
    onClickEditCustomLabelIcon(key){
      switch (key) {
        case 'NAME':
          this.editFlg_name = !this.editFlg_name
          if (!this.editFlg_name) {
            this.customLabel_lastname = null
            this.customLabel_firstname = null
          }
          break;
        case 'MAIL':
          this.editFlg_mail = !this.editFlg_mail
          if (!this.editFlg_mail) this.customLabel_mail = null
          break;
        case 'MAIL_CONFIRMATION':
          this.editFlg_mail_confirmation = !this.editFlg_mail_confirmation
          if (!this.editFlg_mail_confirmation) this.customLabel_mail_confirmation = null
          break;
        case 'COMPANY':
          this.editFlg_company = !this.editFlg_company
          if(!this.editFlg_company) this.customLabel_company = null
          break;
        case 'DEPT':
          this.editFlg_dept = !this.editFlg_dept
          if(!this.editFlg_dept) this.customLabel_dept = null
          break;
        case 'POSITION':
          this.editFlg_pos = !this.editFlg_pos
          if(!this.editFlg_pos) this.customLabel_pos = null
          break;
        case 'ADDRESS':
          this.editFlg_add = !this.editFlg_add
          if(!this.editFlg_add) this.customLabel_add = null
          break;
        case 'TEL':
          this.editFlg_tel = !this.editFlg_tel
          if(!this.editFlg_tel) this.customLabel_tel = null
          break;
        default:
          break;
      }
    },
    onChangeUserInput(key, index) {
      switch (key) {
        case 'COMPANY':
          this.required_company = false
          break;
        case 'DEPT':
          this.required_dept = false
          break;
        case 'POSITION':
          this.required_pos = false
          break;
        case 'ADDRESS':
          this.required_add = false
          break;
        case 'TEL':
          this.required_tel = false
          break;
        case 'FREE_ITEMS':
          this.freeItems[index].required = false
          break;
        default:
          break;
      }
    },
    onChangeRequired(key, index) {
      switch (key) {
        case 'COMPANY':
          if (this.required_company) {
            this.userinput_company = true
          }
          break;
        case 'DEPT':
          if (this.required_dept) {
            this.userinput_dept = true
          }
          break;
        case 'POSITION':
          if (this.required_pos) {
            this.userinput_pos = true
          }
          break;
        case 'ADDRESS':
          if (this.required_add) {
            this.userinput_add = true
          }
          break;
        case 'TEL':
          if (this.required_tel) {
            this.userinput_tel = true
          }
          break;
        case 'FREE_ITEMS':
          if(this.freeItems[index].required) {
            this.freeItems[index].userinput = true
          }
          break;
        default:
          break;
      }
    },
    onChangeFreeItemDeleteFlg(key, index) {
      if(this.freeItems[index].deleteFlg) {
        if(!confirm('設定済みの入力項目を削除すると、収集したデータは以後参照できなくなります。')) {
          this.$nextTick().then(() => {
            this.freeItems[index].deleteFlg = false
          })
        }
        this.isDisplayedAlertDeleteFreeItem = true
      }
    },
    onClickAddFreeItem() {
      this.addFreeItem()
    },
    onClickDeleteFreeItem(index) {
      this.freeItems.splice(index, 1)
    },
    moveNextStep() {
      if(!this.validateFreeItems()) return

      this.customLabel_lastname = this.getValueOrNullWhenEmpty(this.customLabel_lastname)
      this.customLabel_firstname = this.getValueOrNullWhenEmpty(this.customLabel_firstname)
      this.customLabel_mail = this.getValueOrNullWhenEmpty(this.customLabel_mail)
      this.customLabel_mail_confirmation = this.getValueOrNullWhenEmpty(this.customLabel_mail_confirmation)
      this.customLabel_dept = this.getValueOrNullWhenEmpty(this.customLabel_dept)
      this.customLabel_company = this.getValueOrNullWhenEmpty(this.customLabel_company)
      this.customLabel_pos = this.getValueOrNullWhenEmpty(this.customLabel_pos)
      this.customLabel_add = this.getValueOrNullWhenEmpty(this.customLabel_add)
      this.customLabel_tel = this.getValueOrNullWhenEmpty(this.customLabel_tel)

      this.completionFreeItems()

      let sortableVisitorOptions = [
        {
          visitorOption: "COMPANY",
          userinput: this.userinput_company,
          required: this.required_company,
          label: this.customLabel_company,
          entryIndex: (this.entryIndex_company) ? this.entryIndex_company : null,

        },
        {
          visitorOption: "DEPT",
          userinput: this.userinput_dept,
          required: this.required_dept,
          label: this.customLabel_dept,
          entryIndex: (this.entryIndex_company) ? this.entryIndex_company : null
        },
        {
          visitorOption: "POSITION",
          userinput: this.userinput_pos,
          required: this.required_pos,
          label: this.customLabel_pos,
          entryIndex: (this.entryIndex_pos) ? this.entryIndex_pos : null
        },
        {
          visitorOption: "ADDRESS",
          userinput: this.userinput_add,
          required: this.required_add,
          label: this.customLabel_add,
          entryIndex: (this.entryIndex_add) ? this.entryIndex_add : null
        },
        {
          visitorOption: "TEL",
          userinput: this.userinput_tel,
          required: this.required_tel,
          label: this.customLabel_tel,
          entryIndex: (this.entryIndex_tel) ? this.entryIndex_tel : null
        }
      ]

      this.freeItems.forEach(item => {
        sortableVisitorOptions.push(item)
      })

      this.$emit("forward", {
        visitorOptionSchema: {
          lastname: {
            userInput: this.userinput_name,
            required: this.required_name,
            label: this.customLabel_lastname
          },
          firstname: {
            userInput: this.userinput_name,
            required: this.required_name,
            label: this.customLabel_firstname
          },
          dept: {
            userInput: this.userinput_dept,
            required: this.required_dept,
            label: this.customLabel_dept
          },
          mail: {
            userInput: this.userinput_mail,
            required: this.required_mail,
            label: this.customLabel_mail
          },
          mail_confirmation: {
            userInput: this.userinput_mail_confirmation,
            required: this.required_mail_confirmation,
            label: this.customLabel_mail_confirmation
          },
          company: {
            userInput: this.userinput_company,
            required: this.required_company,
            label: this.customLabel_company
          },
          position: {
            userInput: this.userinput_pos,
            required: this.required_pos,
            label: this.customLabel_pos
          },
          opt: {
            userInput: this.userinput_opt,
            required: this.userinput_opt, //入力するならば常に必須
            label: null
          },
          address: {
            userInput: this.userinput_add,
            required: this.required_add,
            label: this.customLabel_add
          },
          tel: {
            userInput: this.userinput_tel,
            required: this.required_tel,
            label: this.customLabel_tel
          },
          freeItems: this.freeItems,
          sortableVisitorOptions: sortableVisitorOptions
        },
      });
    },
    goBack() {
      this.$emit("goBack");
    },
    getValueOrNullWhenEmpty(value) {
      return (value) ? value : null
    },
    addFreeItem() {
      this.maxEntryIndex++

      this.freeItems.push({
        visitorOption: uuidv4(),
        userinput: true,
        required: false,
        label: null,
        entryIndex: this.maxEntryIndex,

        manageLabel: null,
        editIndex: null,

        deleteFlg: false,
        nowVisitorOption: null,
      })
    },
    validateFreeItems() {
      if(this.freeItems.length == 0) return true

      let manageLabelMap = new Map()
      let labelMap = new Map()
      let activeCount = 0
      this.freeItems.forEach(item => {
        if(item.deleteFlg) return
        activeCount++
        manageLabelMap.set(item.manageLabel.trim(), 1)
        labelMap.set(item.label.trim(), 1)
      })

      if(manageLabelMap.size != activeCount) {
        alert("重複(visitorOption)")
        return false
      }
      if(labelMap.size != activeCount) {
        alert("重複(label)")
        return false
      }

      return true
    },
    completionFreeItems() {
      if(this.freeItems.length == 0) return

      let index = 1
      this.freeItems.forEach(item => {
        item.editIndex = index
        index++
      })
    }
  }
};
</script>

<style scoped>
.none {
  box-shadow: none;
}
.title {
  text-align: left;
  margin-left: 30px;
  margin-top: 20px;
  font-size: 23px;
}
.note {
  text-align: left;
  font-size: 12px;
  width: 60%;
  color: #666666;
}
.input-area {
  margin-bottom: 0px;
}
.fee {
  margin: 30px;
}
.free {
  margin: 30px;
}
.displayTrue {
  color: white;
  background-color: #2da9ef;
}
.displayFalse {
  color: black;
  background-color: #e0e0e0;
}
.requiredTrue {
  color: white;
  background-color: #f44336;
}

.optionName{
  font-size:110%;
  margin-top:16px;
  margin-right:4px;
  text-align:right;
}
.titlePosition{
  text-align:left;
}
.iconPosition{
  margin-top:20px;
  margin-right:15px;
  text-align:left;
}
.btnDeleteFreeItem {
  /*margin-left: 0px;
  text-align: left;
  align-content: start;*/
}

.subtitle{
  text-align: left;
  color: #666666;
}
.submenu{
  margin: 30px 0;
}

</style>

