<template>
  <AdminSidebar>
    <v-container fluid>
      <v-flex>
        <p class="title">
          <v-icon large>mdi-how-to-reg</v-icon>
          <span class="titleName">トップページ情報</span></p>

        <v-form ref="form" class="text-left">

          <template v-if="showHtmlEditor">
            <h3>冒頭</h3>
            <v-row>
              <v-col>
                <VueEditor v-model="form.introductionText" :editorToolbar="customToolbar" :editorOptions="editorOptions"></VueEditor>
              </v-col>
              <v-col>
                <p v-html="form.introductionText" class="info-text" />
              </v-col>
            </v-row>
            <h3>電子申請用メールアドレス登録</h3>
            <v-row>
              <v-col>
                <VueEditor v-model="form.emailRegistrationNotice" :editorToolbar="customToolbar" :editorOptions="editorOptions"></VueEditor>
              </v-col>
              <v-col>
                <p v-html="form.emailRegistrationNotice" class="info-text" />
              </v-col>
            </v-row>
            <h3>お知らせ</h3>
            <v-row>
              <v-col>
                <VueEditor v-model="form.notificationsText" :editorToolbar="customToolbar" :editorOptions="editorOptions"></VueEditor>
              </v-col>
              <v-col>
                <p v-html="form.notificationsText" class="info-text" />
              </v-col>
            </v-row>
            <h3>注意事項</h3>
            <v-row>
              <v-col>
                <VueEditor v-model="form.entryNotice" :editorToolbar="customToolbar" :editorOptions="editorOptions"></VueEditor>
              </v-col>
              <v-col>
                <p v-html="form.entryNotice" class="info-text" />
              </v-col>
            </v-row>
            <h3>マイページ注釈</h3>
            <v-row>
              <v-col>
                <VueEditor v-model="form.myPageNotice" :editorToolbar="customToolbar" :editorOptions="editorOptions"></VueEditor>
              </v-col>
              <v-col>
                <p v-html="form.myPageNotice" class="info-text" />
              </v-col>
            </v-row>
          </template>

          <template v-else>
            <v-textarea label="冒頭" v-model="form.introductionText">
            </v-textarea>
            <v-textarea label="電子申請用メールアドレス登録" v-model="form.emailRegistrationNotice">
            </v-textarea>
            <v-textarea label="お知らせ" v-model="form.notificationsText">
            </v-textarea>
            <v-textarea label="注意事項" v-model="form.entryNotice">
            </v-textarea>
            <v-textarea label="マイページ注釈" v-model="form.myPageNotice">
            </v-textarea>
          </template>

          <!--          お知らせ<v-btn @click="addNotification">addNotification</v-btn>-->
          <!--          <template v-for="notify in form.notifications">-->
          <!--            <v-row>-->
          <!--              <v-col>-->
          <!--                <v-text-field label="日時" v-model="notify.publishedAt"></v-text-field>-->
          <!--              </v-col>-->
          <!--              <v-col>-->
          <!--                <v-textarea label="内容" v-model="notify.text"></v-textarea>-->
          <!--              </v-col>-->
          <!--            </v-row>-->
          <!--          </template>-->

          <v-row>
            <v-col>
              <v-file-input label="よくある質問PDF" @change="uploadFile"></v-file-input>
            </v-col>
            <v-col>
              <v-btn v-if="form.faqDoc" :href="form.faqDoc.url">download</v-btn>
            </v-col>
          </v-row>


          <v-btn @click.stop="updateEventInfo">更新する</v-btn>

        </v-form>


      </v-flex>
    </v-container>
  </AdminSidebar>
</template>
<script>
import EventLayout from './EventLayout'
import AdminSidebar from './AdminSidebar'
import VisitorService from '../mixins/VisitorService'
import {mapGetters} from 'vuex'
import axios from 'axios'

import {Quill, VueEditor} from 'vue2-editor'

Quill.register(Quill.import('attributors/style/color'), true);
Quill.register(Quill.import('attributors/style/size'), true);
Quill.register(Quill.import('attributors/style/direction'), true);
Quill.register(Quill.import('attributors/style/align'), true);
Quill.register(Quill.import('attributors/style/background'), true);
Quill.register(Quill.import('attributors/style/font'), true);

function imageHandler() {
  var range = this.quill.getSelection();
  var value = prompt('What is the image URL');
  this.quill.insertEmbed(range.index, 'image', value, Quill.sources.USER);
}

const toolbar = [[{
    header: [false, 1, 2, 3, 4, 5, 6]
  }], ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    [{
      align: ''
    }, {
      align: 'center'
    }, {
      align: 'right'
    }, {
      align: 'justify'
    }], ['blockquote', 'code-block'], [{
      list: 'ordered'
    }, {
      list: 'bullet'
    }, {
      list: 'check'
    }], [{
      indent: '-1'
    }, {
      indent: '+1'
    }], // outdent/indent
    [{
      color: []
    }, {
      background: []
    }],
    ['link','image'], ['clean']
  ];

export default {
  components: {AdminSidebar, EventLayout, VueEditor},
  mixins: [VisitorService],
  props: {
    // showHtmlEditor: {
    //   type: Boolean,
    //   default: true
    // }
  },
  data () {
    return {
      form: {
        introductionText: '',
        emailRegistrationNotice: '',
        notifications: [],
        entryNotice: '',
        notificationsText: '',
        myPageNotice: '',
        faqDoc: null
      },
      showHtmlEditor : !this.$route.query.src,
      customToolbar: toolbar,
      editorOptions:{
        modules: {
          toolbar :{
            container: toolbar,
            handlers : {
              image: imageHandler
            }
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'eventId'
    ]),
  },
  async created () {
    this.$store.commit('setEventId', this.$route.params.eventId)
    await this.getEventInfo()
  },
  methods: {
    async getEventInfo () {
      let {data} = await axios.get('/api/eventinfo/' + this.eventId)
      Object.assign(this.form, data)
    },
    async updateEventInfo () {
      if (!this.$refs.form.validate()) {
        return
      }
      try {
        let {data} = await axios.post('/api/eventinfo/' + this.eventId, this.form)
        Object.assign(this.form, data)
        this.$emit('toast', '変更しました')
      } catch (e) {
        this.$emit('error', '更新に失敗しました')
      }
    },
    addNotification () {
      this.form.notifications.push({})
    },
    async uploadFile (file) {
      let params = new FormData()
      params.append('file', file)
      let {data} = await axios.post(`/api/eventinfo/upload/${this.eventId}`, params)
      this.form.faqDoc = {
        fileId: data.id,
        key: file.name
      }
    },
  }
}
</script>
