<template>
  <AdminSidebar>
    <v-container fluid>
      <v-flex xs12>
        <p class="title">
          <v-icon large class="icon_design">supervisor_account</v-icon>
          <span class="title_name">アカウント一覧</span></p>
        <v-spacer></v-spacer>
      </v-flex>
      <v-layout row wrap>
        <v-flex xs9>
        </v-flex>
        <v-flex xs3 style="text-align:right;">
          <v-btn
            slot="activator"
            color="error"
            dark
            @click="openNewAdminWindow"
            v-if="allowAddAdmin"
          >
            <v-icon dark small>supervisor_account</v-icon>
            Admin追加
          </v-btn>
          <v-btn
            slot="activator"
            color="primary"
            dark
            v-if="isAdmin"
            @click="openNewAccountWindow"
          >
            <v-icon dark small>add_circle</v-icon>
            アカウント追加
          </v-btn>
        </v-flex>
      </v-layout>

      <v-data-table
        :headers="headers"
        :items="clients"
        v-model="selected"
        item-key="id"
        sort-by="id"
      >
        <template v-slot:item.createdAt="{item}">
          <td>{{new Date(item.createdAt).toLocaleString()}}</td>
        </template>
        <template v-slot:item.action="props">
          <td class="justify-center layout px-0">
            <v-icon
              small
              class="mr-2"
              @click="editItem(props.item)"
            >
              edit
            </v-icon>
            <!--            <v-icon-->
            <!--              small-->
            <!--              class="mr-2"-->
            <!--              @click="openSendMailDialog(props.item)"-->
            <!--            >-->
            <!--              mail-->
            <!--            </v-icon>-->
            <v-icon
              small
              v-if="isAdmin"
              @click="deleteItem(props.item)"
            >
              delete
            </v-icon>
          </td>
        </template>
        <!--          <template slot="item" slot-scope="props">-->
        <!--            <tr>-->
        <!--              <td class="text-xs-left">{{ props.item.organizer.affiliation }}</td>-->
        <!--              <td class="text-xs-left">{{ props.item.organizer.lastName }} {{ props.item.organizer.firstName }}</td>-->
        <!--              <td class="text-xs-left">{{ props.item.organizer.username }}</td>-->
        <!--              <td class="text-xs-left">{{ props.item.customerId }}</td>-->
        <!--              <td class="text-xs-left">{{ props.item.clientPlan.plan.name}}</td>-->
        <!--              <td class="text-xs-left">{{ formatDate(props.item.createdAt) }}</td>-->
        <!--              <td class="text-xs-left">{{ formatDate(props.item.organizer.updatedAt) }}</td>-->
        <!--              <td class="text-xs-left">{{ formatDate(props.item.clientPlan.updatedAt) }}</td>-->
        <!--              <td class="justify-center layout px-0">-->
        <!--                <v-icon-->
        <!--                  small-->
        <!--                  class="mr-2"-->
        <!--                  @click="editItem(props.item)"-->
        <!--                >-->
        <!--                  edit-->
        <!--                </v-icon>-->
        <!--                <v-icon-->
        <!--                  small-->
        <!--                  class="mr-2"-->
        <!--                  @click="openSendMailDialog(props.item)"-->
        <!--                >-->
        <!--                  mail-->
        <!--                </v-icon>-->
        <!--                <v-icon-->
        <!--                  small-->
        <!--                  @click="deleteItem(props.item)"-->
        <!--                >-->
        <!--                  delete-->
        <!--                </v-icon>-->
        <!--              </td>-->
        <!--            </tr>-->
        <!--          </template>-->
      </v-data-table>
    </v-container>

    <v-dialog v-model="dialog" max-width="500px">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title class="modalHeader">
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  <v-text-field v-model="editedItem.affiliation" :rules="affiliationRules" label="会社名"
                                required></v-text-field>
                </v-flex>
                <v-flex xs6>
                  <v-text-field v-model="editedItem.lastName" :rules="lastNameRules" label="姓" required></v-text-field>
                </v-flex>
                <v-flex xs6>
                  <v-text-field v-model="editedItem.firstName" :rules="firstNameRules" label="名"
                                required></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-text-field v-model="editedItem.username" :rules="usernameRules" label="メールアドレス"
                                required></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-select v-model="editedItem.accountRoles" label="役割"
                            multiple
                            :items="roles">
                  </v-select>
                </v-flex>
                <v-flex xs6 v-if="isEditWindow">
                  <v-switch
                    :label="`パスワードを変更`"
                    v-model="toggle"
                  >
                  </v-switch>
                </v-flex>
                <v-flex xs12 v-if="isEditWindow&&toggle">
                  <v-text-field v-model="editedItem.password" :rules="passwordRules" label="パスワード" required
                                type="password"></v-text-field>
                </v-flex>
                <v-flex xs12 v-if="isEditWindow&&toggle">
                  <v-text-field v-model="editedItem.passwordConfirm" :rules="passwordConfirmRules" label="パスワード（確認）"
                                required type="password"></v-text-field>
                </v-flex>


                <v-flex xs12 class="text-xs-center">
                  <v-btn class="edit-b" @click.native="close">閉じる</v-btn>
                  <v-btn color="error" :disabled="!valid" @click="submit">保存</v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
        </v-card>
      </v-form>
    </v-dialog>


    <v-dialog v-model="dialogAdmin" max-width="500px">
      <v-form ref="adminForm" v-model="validAdmin" lazy-validation>
        <v-card>
          <v-card-title class="modalHeader">
            <span class="headline">Admin新規追加</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  <v-text-field v-model="adminData.affiliation" :rules="affiliationRules" label="会社名"
                                required></v-text-field>
                </v-flex>
                <v-flex xs6>
                  <v-text-field v-model="adminData.lastName" :rules="lastNameRules" label="姓" required></v-text-field>
                </v-flex>
                <v-flex xs6>
                  <v-text-field v-model="adminData.firstName" :rules="firstNameRules" label="名" required></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-text-field v-model="adminData.username" :rules="usernameRules" label="メールアドレス"
                                required></v-text-field>
                </v-flex>
                <v-flex xs6 v-if="isAdminEditWindow">
                  <v-switch
                    :label="`パスワードを変更`"
                    v-model="toggleAdmin"
                  >
                  </v-switch>
                </v-flex>
                <v-flex xs12 v-if="!isAdminEditWindow || isAdminEditWindow&&toggleAdmin">
                  <v-text-field v-model="adminData.password" :rules="passwordRules" label="パスワード" required
                                type="password"></v-text-field>
                </v-flex>
                <v-flex xs12 v-if="!isAdminEditWindow || isAdminEditWindow&&toggleAdmin">
                  <v-text-field v-model="adminData.passwordConfirm" :rules="passwordConfirmAdminRules" label="パスワード（確認）"
                                required type="password"></v-text-field>
                </v-flex>
                <v-spacer></v-spacer>
                <v-flex xs12 class="text-xs-center">
                  <v-btn class="edit-b" @click="closeAdminWindow">閉じる</v-btn>
                  <v-btn color="error" @click="createNewAdmin">保存</v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-model="dialogSendMail" max-width="500px">
      <v-card>
        <v-card-title class="modalHeader">
          <span class="headline">メール送信</span>
        </v-card-title>
        <v-card-text>
          <v-flex xs12>
            送信するメールを選択してください
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex xs12 class="text-xs-center">
            <v-radio-group v-model="typeOfSendMail" :mandatory="false">
              <v-radio label="招待メール" value="invitation"></v-radio>
              <v-radio label="パスワードリセット" value="password_reset"></v-radio>
            </v-radio-group>
          </v-flex>
          <v-btn color="error" :disabled="!typeOfSendMail" @click="sendMail">送信</v-btn>
          <v-btn class="edit-b" @click="closeSendMailDialog">閉じる</v-btn>

        </v-card-text>
      </v-card>
    </v-dialog>
  </AdminSidebar>
</template>

<script>
import header from '@/components/AdminHeader.vue'
import {
  createAdmin,
  createOrganizer,
  deleteOrganizer,
  getOrganizers,
  sendClientInvitation,
  sendClientPasswordReset,
  updateOrganizer
} from '../utils/APIUtils'
import AdminSidebar from '../admin/views/AdminSidebar'
import {mapGetters} from 'vuex'

export default {
  name: 'accountList',
  components: {
    'middle_header': header,
    AdminSidebar
  },
  data () {
    return {
      allowAddAdmin: false,
      search: '',
      selected: [],
      rowsPerPageItems: [10, 25, 50, 100],
      pagination: {
        rowsPerPage: 100,
      },
      toggle: false,
      dialog: false,
      valid: false,
      clients: [],
      isEditWindow: false,
      headers: [
        {
          text: 'id',
          value: 'id'
        },
        {
          text: '会社名',
          value: 'affiliation'
        },
        {
          text: '氏名',
          value: 'lastName'
        },
        {
          text: 'メールアドレス',
          value: 'username'
        },
        {
          text: '登録日時',
          value: 'createdAt',
          width: 160
        },
        {
          text: 'action',
          value: 'action',
          width: 30
        }
      ],
      roles: [
        {
          text: '管理者',
          value: 'ROLE_ORGANIZER_ADMIN'
        }, {
          text: '編集者',
          value: 'ROLE_ORGANIZER_EDITOR'
        }, {
          text: '閲覧者',
          value: 'ROLE_ORGANIZER_READONLY'
        }, {
          text: '当日受付',
          value: 'ROLE_ORGANIZER_APP'
        }
      ],
      plans: [],
      editedItem: {
        id: '',
        affiliation: '',
        firstName: '',
        lastName: '',
        username: '',
        password: '',
        passwordConfirm: '',
        accountRoles: []
      },
      squareAccountTypeList: [
        {name: '個別アカウント', value: 'PERSONAL'},
        {name: '共通アカウント', value: 'COMMON_USE'},
      ],
      affiliationRules: [
        v => !!v || '会社名は必須です',
        v => (v && v.length <= 128) || '128文字以下にしてください'
      ],
      customerIdRules: [
        v => !!v || '顧客IDは必須です',
        v => (v && v.length <= 128) || '128文字以下にしてください'
      ],
      firstNameRules: [
        v => !!v || '名は必須です',
        v => (v && v.length <= 128) || '128文字以下にしてください'
      ],
      lastNameRules: [
        v => !!v || '姓は必須です',
        v => (v && v.length <= 128) || '128文字以下にしてください'
      ],
      usernameRules: [
        v => !!v || 'メールアドレスは必須です',
        v => (v && v.length <= 128) || '128文字以下にしてください',
        v => /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'メールアドレスの形式で入力を行ってください。'
      ],
      passwordRules: [
        v => !!v || 'パスワードは必須です',
        v => (v && v.length <= 55 && v.length >= 8 && /^(?=.*?[a-z])(?=.*?\d)[a-z\d]{8,55}$/i.test(v)) || 'パスワードは半角英字・数字をどちらも使用した文字列で、8-55文字以内で設定してください'
      ],
      passwordConfirmRules: [
        v => !!v || 'パスワードは必須です',
        v => (v && v.length <= 55 && v.length >= 8 && /^(?=.*?[a-z])(?=.*?\d)[a-z\d]{8,55}$/i.test(v)) || 'パスワードは半角英字・数字をどちらも使用した文字列で、8-55文字以内で設定してください',
        v => v == this.editedItem.password || '入力したパスワードが一致しません。'
      ],
      passwordConfirmAdminRules: [
        v => !!v || 'パスワードは必須です',
        v => (v && v.length <= 55 && v.length >= 8 && /^(?=.*?[a-z])(?=.*?\d)[a-z\d]{8,55}$/i.test(v)) || 'パスワードは半角英字・数字をどちらも使用した文字列で、8-55文字以内で設定してください',
        v => v == this.adminData.password || '入力したパスワードが一致しません。'
      ],
      planRules: [
        v => !!v || 'プランは必須です'
      ],
      adminData: {
        username: '',
        password: '',
        passwordConfirm: '',
        firstName: '',
        lastName: '',
        affiliation: '',
        customerId: '',
        subscriptionId: ''
      },
      toggleAdmin: false,
      dialogAdmin: false,
      validAdmin: false,
      isAdminEditWindow: false,

      dialogSendMail: false,
      itemForSendMail: null,
      typeOfSendMail: '',
    }
  },
  computed: {
    ...mapGetters([
      'eventId', 'isAdmin'
    ]),
    formTitle () {
      return !(this.isEditWindow) ? 'アカウント追加' : 'アカウント編集'
    }
  },
  watch: {
    dialog (val) {
      val || this.close()
    }
  },
  methods: {
    clearEditedItem () {
      this.editedItem.id = null
      this.editedItem.affiliation = ''
      this.editedItem.lastName = ''
      this.editedItem.firstName = ''
      this.editedItem.username = ''
      this.editedItem.password = ''
      this.editedItem.passwordConfirm = ''

    },
    clearAdminData () {
      this.adminData.username = ''
      this.adminData.password = ''
      this.adminData.passwordConfirm = ''
      this.adminData.firstName = ''
      this.adminData.lastName = ''
      this.adminData.affiliation = ''
      this.adminData.customerId = ''
      this.adminData.subscriptionId = ''
    },
    editItem (item) {
      this.clearEditedItem()

      this.isEditWindow = true
      this.editedItem.id = item.id
      this.editedItem.affiliation = item.affiliation
      this.editedItem.firstName = item.firstName
      this.editedItem.lastName = item.lastName
      this.editedItem.username = item.username
      this.editedItem.customerId = item.customerId
      this.editedItem.accountRoles = item.accountRoles
      this.editedItem.password = ''
      this.editedItem.passwordConfirm = ''

      this.dialog = true
    },

    openSendMailDialog (item) {
      this.typeOfSendMail = null
      this.itemForSendMail = item
      this.dialogSendMail = true
    },
    closeSendMailDialog () {
      this.typeOfSendMail = null
      this.itemForSendMail = null
      this.dialogSendMail = false
    },
    sendMail () {
      switch (this.typeOfSendMail) {
        case 'invitation' :
          this.sendInvitation()
          break
        case 'password_reset' :
          this.sendPasswordReset()
          break
        default :
          console.log('invalid typeOfSendMail:' + this.typeOfSendMail)
      }
    },
    sendInvitation () {
      if (confirm('メールを送信してもよろしいですか？')) {
        sendClientInvitation({
          id: this.itemForSendMail.id
        }).then(r => {
          alert('送信しました。')
          this.dialogSendMail = false
        }).catch(err => {
          alert('送信処理に失敗しました。')
        }).finally(() => {
          this.closeSendMailDialog()
        })
      }
    },
    sendPasswordReset (item) {
      if (confirm('メールを送信してもよろしいですか？')) {
        sendClientPasswordReset({
          id: this.itemForSendMail.id
        }).then(r => {
          alert('送信しました。')
          this.dialogSendMail = false
        }).catch(err => {
          alert('送信処理に失敗しました。')
        }).finally(() => {
          this.closeSendMailDialog()
        })
      }
    },

    deleteItem (item) {
      const index = this.clients.indexOf(item)
      if (confirm('データを削除してもよろしいですか？')) {
        this.clients.splice(index, 1)
        deleteOrganizer({
          id: item.id
        }).catch(err => {
          alert('削除に失敗しました。')
        })
      }
    },

    close () {
      this.dialog = false
      this.googleMapCode = ''
      this.displayMapFlg = false
      this.toggle = false
    },

    load () {
      getOrganizers()
        .then(res => {
          this.clients = res.data
        }).catch(err => {
        alert('データの取得に失敗しました。')
      })
    },
    submit () {
      if (!this.$refs.form.validate()) return

      if (confirm('データを登録してもよろしいですか？')) {
        if (this.editedItem.id === null) {
          //Clientの新規登録
          createOrganizer({
            data: this.editedItem
          }).then(res => {
            this.load()
            alert('アカウントを登録しました。')
            this.dialog = false
          }).catch(err => {
            if (err.response.status === 409 && err.response.data === 'client already exists') {
              alert('入力された顧客IDは既に登録されています。')
            } else if (err.response.status === 409 && err.response.data === 'user already exists') {
              alert('入力されたメールアドレスは既に登録されています。(Groupに属している場合は所属しているGroupすべてから脱退してから登録を再度行ってください。)')
            } else {
              alert('アカウントの登録に失敗しました。')
            }
          })
        } else {
          //Clientの更新
          if (!this.toggle) this.editedItem.password = null
          updateOrganizer({
            data: this.editedItem
          }).then(res => {
            this.load()
            alert('アカウント情報を更新しました。')
            this.dialog = false
          }).catch(err => {
            if (err.response.status === 409 && err.response.data === 'client already exists') {
              alert('入力された顧客IDは既に登録されています。')
            } else if (err.response.status === 409 && err.response.data === 'user already exists') {
              alert('入力されたメールアドレスは既に登録されています。(Groupに属している場合は所属しているGroupすべてから脱退してから登録を再度行ってください。)')
            } else if (err.response.status === 404) {
              alert('選択されたアカウントは存在しません。ページを更新して再度編集を行ってください。')
            } else {
              alert('アカウント情報の更新に失敗しました。')
            }
          })
        }
      }
    },
    openNewAccountWindow () {
      this.isEditWindow = false
      this.clearEditedItem()
      this.dialog = true
    },
    openNewAdminWindow () {
      this.isAdminEditWindow = false
      this.clearAdminData()
      this.dialogAdmin = true
    },
    closeAdminWindow () {
      this.dialogAdmin = false
      this.toggleAdmin = false
    },
    createNewAdmin () {
      if (this.$refs.adminForm.validate()) {
        //Adminの新規登録
        createAdmin({
          data: this.adminData
        }).then(res => {
          alert('アカウントを登録しました。')
          this.closeAdminWindow()
        }).catch(err => {
          if (err.response.status === 409) {
            alert('入力されたメールアドレスは既に登録されています。')
          } else {
            alert('アカウントの登録に失敗しました。')
          }
        })
      }
    },
    formatDate (updateAtDate) {
      return updateAtDate ? new Date(updateAtDate).toLocaleString() : updateAtDate
    },
  },
  mounted () {
    if (this.$route.query.add_admin == 'allow') {
      this.allowAddAdmin = true
    }

    // let headers = {accountType: 'admin'};
    // getMe({headers: headers, accountType:'admin'})
    // .then(res => {
    //   this.load()
    // }).catch(err => {
    //   this.$router.push('/')
    // })
    this.load()
  }
}
</script>
<style scoped>
#inspire {
  color: #666666;
  background-color: white;
}

.icon_design {
  vertical-align: middle;
  line-height: 1;
}

.title {
  text-align: left;
  margin-left: 30px;
}

.title_name {
  margin-left: 25px;
  font-size: 120%;
}

td {
  color: #666666;
}

.modalHeader {
  background-color: #263238;
  color: white;
  height: 70px;
  font-size: 160%;
  font-weight: bold;
  filter: alpha(opacity=100);
  -moz-opacity: 1;
  opacity: 1;
}

.inputFieldTitle {
  text-align: left;
  margin-bottom: -10px;
  margin-top: 5px;
}

div.alert-message {
  text-align: left;
  padding-top: 0;
}

div.alert-message span {
  color: #f8504a;
  font-size: 100%;
}
</style>
