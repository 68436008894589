<template>
    <v-app id="inspire" style="background-color: white">
        <v-container fluid>
            <v-flex  xl4 md4 offset-md4 xs12>
                <div style="font-size:140%;">
                    登録者情報を入力してください。
                </div>
                <v-form v-model="valid">
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex md12>
                                <v-text-field v-model="affiliation" :rules="affiliationRules" label="会社名" required></v-text-field>
                            </v-flex>
                            <v-flex md6>
                                <v-text-field v-model="lastName" :rules="lastNameRules" label="姓" required></v-text-field>
                            </v-flex>
                            <v-flex md6>
                                <v-text-field v-model="firstName" :rules="firstNameRules" label="名" required></v-text-field>
                            </v-flex>
                            <v-flex md12>
                                <v-text-field v-model="password" :rules="passwordRules" label="パスワード" required type="password"></v-text-field>
                            </v-flex>
                            <v-flex md12>
                                <v-text-field v-model="passwordConfirm" :rules="passwordConfirmRules" label="パスワード（確認）" required type="password"></v-text-field>
                            </v-flex>
                            <v-flex md12>
                                <v-btn depressed large color="primary" :disabled="!valid" @click="submit">参加</v-btn>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-form>
            </v-flex>
        </v-container>
    </v-app>
</template>

<script>
import { isValidInvitationToken, addGroupMember } from '../utils/APIUtils.js'
export default {
  name: 'Signin',
    data () {
        return {
            valid:false,
            token: '',

            affiliation:null,
            lastName:null,
            firstName:null,
            password: '',
            affiliationRules: [
                v => !!v || '会社名は必須です',
                v => (v && v.length <= 128) || '128文字以下にしてください'
            ],
            firstNameRules: [
                v => !!v || '名は必須です',
                v => (v && v.length <= 128)|| '128文字以下にしてください'
            ],
            lastNameRules: [
                v => !!v || '姓は必須です',
                v => (v && v.length <= 128)|| '128文字以下にしてください'
            ],
            passwordRules: [
                v => !!v || 'パスワードは必須です',
                v => (v && v.length <= 55 && v.length >= 8 && /^(?=.*?[a-z])(?=.*?\d)[a-z\d]{8,55}$/i.test(v))|| 'パスワードは半角英字・数字をどちらも使用した文字列で、8-55文字以内で設定してください'
            ],
            passwordConfirm: '',
            passwordConfirmRules: [
                v => !!v || 'パスワードは必須です',
                v => (v && v.length <= 55 && v.length >= 8 && /^(?=.*?[a-z])(?=.*?\d)[a-z\d]{8,55}$/i.test(v))|| 'パスワードは半角英字・数字をどちらも使用した文字列で、8-55文字以内で設定してください',
                v => v == this.password || '入力したパスワードが一致しません。'
            ]
        }
    },
    methods:{
        submit () {
            if(confirm("グループ参加を行ってもよろしいですか？")){
                let data = {token: this.token, password: this.password, firstName: this.firstName, lastName: this.lastName,affiliation:this.affiliation}
                addGroupMember({data: data}).then(res => {
                    alert("グループへの参加処理が完了しました。")
                    this.$router.push('./')
                }).catch(err => {
                    if (err.response.status === 400 && err.response.data == 'exceeded plan limit') {
                        alert("プランのメンバー数上限に達したため招待に失敗しました。グループのオーナーに連絡してください。")
                    } else {
                        alert("グループへの参加に失敗しました。")
                    }
                })
            }
        }
    },
    mounted() {
        this.token = this.$route.query.token
        isValidInvitationToken({data: this.token})
        .then(res =>{
            let data = {token: this.token}
            if(res.data){
                addGroupMember({data: data}).then(res => {
                    alert('グループへの参加処理が完了しました。')
                    this.$router.push('./')
                }).catch(err => {
                    console.log("err.response.status",err.response.status)
                    if (err.response.status === 409) {
                        alert("すでにグループへ参加しています。")
                        this.$router.push('./')
                    }
                    else if(err.response.status === 400) {
                        alert("別の契約者のグループに属しています。一度グループから脱退をして再度参加を行ってください。")
                        this.$router.push('./')
                    }
                    else {
                        alert("グループへの参加に失敗しました。")
                        this.$router.push('./')
                    }
                })
            }
        }).catch(err => {
            alert("参加手続きの期限が過ぎています。再度招待依頼を行ってください。")
            this.$router.push('./')
        })
    }
}
</script>

<style>
    .logo-area{
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .logo-area img{
        width: 350px;
    }
</style>
