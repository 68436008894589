<template>
    <v-app id="inspire" style="background-color: white">
        <v-container fluid>
                <v-flex xl2 offset-xl5 md4 offset-md4 xs12>
                    <div class="signin-area">
                        <v-form v-model="valid" @submit.prevent="onLogin">
                            <v-text-field
                            v-model="mailaddress"
                            label="メールアドレス"
                            required
                            ></v-text-field>
                            <v-text-field
                            v-model="password"
                            label="パスワード"
                            required
                            :append-icon="showPassword ? 'visibility_off' : 'visibility'"
                            :type="showPassword ? 'text' : 'password'"
                            @click:append="showPassword = !showPassword"
                            ></v-text-field>
                            <v-checkbox
                                :label="`ログイン状態を保持`"
                                v-model="remember"
                                style="padding:0px;margin:0px;"
                            ></v-checkbox>
                            <v-btn type="submit" depressed large :color="isAdministrator ? 'success' : 'primary'">ログイン</v-btn>
                        </v-form>
                    </div>
                </v-flex>
                <v-flex xl2 offset-xl5 md4 offset-md4 xs12>
                    <v-alert
                        :value="loginFail"
                        type="error"
                        transition="scale-transition"
                    >
                        メールアドレスまたはパスワードが間違っています。
                    </v-alert>
                </v-flex>

                <v-icon style="position: relative; top:5px;">help</v-icon>
                <span><router-link :to="{path : '/PasswordReset'}">パスワードを忘れた方はこちら</router-link></span>

        </v-container>
    </v-app>
</template>

<script scoped>
import { getToken,getMe } from '../utils/APIUtils.js'
export default {
  name: 'Signin',
  metaInfo: {
    meta: [
        {
            'property' : 'description',
            'content':'「TKP イベントプランナー」は会議室や宴会場で展開される「情報」を集約できる企業の運営事務局のためのオペレーションツールです。会議を中心としたハード部分の提案・提供だけではなく、イベント運営に関わる負担軽減・業務効率化をシステム（ソフト面で）より支援をしてまいります。',
        },
        {
            'property' : 'og:title',
            'content':'TKP Event Planner',
        },
        {
            'property' : 'og:type',
            'content':'website',
        },
        {
            'property' : 'og:description',
            'content':'「TKP イベントプランナー」は会議室や宴会場で展開される「情報」を集約できる企業の運営事務局のためのオペレーションツールです。会議を中心としたハード部分の提案・提供だけではなく、イベント運営に関わる負担軽減・業務効率化をシステム（ソフト面で）より支援をしてまいります。',
        },
        {
            'property' : 'og:site_name',
            'content':'TKP Event Planner',
        },
        {
            'property' : 'twitter:card',
            'content':'Sumarry',
        },
        //クローラーのインデックス、巡回の拒否
        {
            'property' : 'robots',
            'content':'noindex,nofollow',
        },

    ]
  },
  data () {
        return {
            remember: false,
            accountTypeStatus:false,
            accountType:'',
            password: '',
            valid: true,
            mailaddress: '',
            showPassword: false,
            loginFail:false,
            isAdministrator:false,
        }
    },

    methods: {
        onLogin() {
            this.loginFail = false;
            getToken({
                'username': this.mailaddress,
                'password': this.password,
                'accountType': this.accountType,
                'remember':this.remember
                })
            .then(res => {
                let account = JSON.parse(atob(res.data.split('.')[1]));
                if (account.account_type === 'organizer')
                    this.$router.push({path: '/EventList'})
                if (account.account_type === 'admin')
                    this.$router.push({path: '/VenueList'})
              this.$store.dispatch("getMe")
            })
            .catch(err => {
                console.log("getToken catch ", err)
                this.loginFail = true
            })
        },
        checkLoginType(){
            let adminCheckStatus = false
            let routerInfo = JSON.stringify(this._routerRoot.$options.components)
            if(routerInfo.indexOf("AdminApp") !== -1){
                adminCheckStatus=true
            }
            return adminCheckStatus
        },
    },

    mounted() {
        if(this.checkLoginType()){
            this.isAdministrator = true
        }
        this.accountType = this.isAdministrator ? 'admin' : 'organizer' ;
        let headers = {accountType: this.accountType};
        getMe({headers: headers, accountType: this.accountType})
        .then(res => {
            if (this.isAdministrator)
                this.$router.push({path: '/VenueList'})
            else
                this.$router.push({path: '/EventList'})
        })
        .catch(err => {
        })
        this.$emit('login')
    }
}
</script>

<style scoped>
    .logo-area{
        margin-top: 50px;
        margin-bottom:20px;
    }
    .logo-area img{
        width: 350px;
    }
    .signin-area{
        margin-top: 30px;
        margin-bottom: 30px;
    }


</style>
