<template>
  <v-dialog persistent v-model="showSelf" max-width="700px">
    <v-card>
      <v-card-title class="modalHeader">
        <span class="headline">使用Squareアカウント選択</span>
      </v-card-title>
      <v-card-text>
        <v-container grid-list-md>
          <v-data-table
            :headers="headers"
            :items="squareAccounts"
            :rows-per-page-items="rowsPerPageItems"
            :pagination.sync="pagination"
            must-sort
            no-data-text="割り当て可能なSquareアカウントがありません。"
          >
            <template slot="items" slot-scope="props">
              <tr>
                <td class="text-md-left">
                  {{ props.item.email }}
                </td>
                <td class="right justify-center layout px-0">
                  <v-tooltip bottom>
                    <v-btn slot="activator"  @click="onClickSelect(props.item)" icon>
                      <v-icon color="grey darken-1" lot="activator">insert_link</v-icon>
                    </v-btn>
                    <span>選択</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </v-data-table>

          <div class="mt-3">
            <v-btn @click.native="close">キャンセル</v-btn>
          </div>

        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import {getAllowAssignSquareAccounts} from '../../utils/APIUtils'
  export default {
    name: "AssignSquareAccountSelectDialog",
    data() {
      return {
        showSelf: false,

        headers: [
          {
            text: 'メールアドレス',
            value: 'email'
          },
          {
            text: '',
            value: 'select',
            width: 30
          }
        ],
        rowsPerPageItems: [10, 25, 50,100],
        pagination:{
          rowsPerPage: 100,
          sortBy:'email',
          descending: true,
        },

        squareAccounts: [],
      }
    },
    methods: {
      openForSelect() {
        this.loadSquareAccounts()
        this.showSelf = true
      },
      close() {
        this.showSelf = false
      },
      loadSquareAccounts() {
        this.zoomAccounts = []

        getAllowAssignSquareAccounts().then(res => {
          this.squareAccounts = res.data
        }).catch(err => {
          if(err.response.status == 404) {
            this.squareAccounts = []
          } else {
            console.log(err)
            alert('Squareアカウントの取得に失敗しました')
          }
        })
      },
      onClickSelect(squareAccount) {
        this.$emit('select', squareAccount)
        this.close()
      }
    }
  }
</script>

<style scoped>

</style>
