<template>
    <v-app id="inspire" style="background-color: white">
        <v-container fluid>
                <v-flex md2 offset-md5>
                    <div class="signout-area">
                        <div class="signoutCharacter">ログアウトしました。</div>
                        <v-btn depressed large color="primary" @click="moveToSigninPage">ログイン画面を表示</v-btn>
                    </div>
                </v-flex>
        </v-container>
    </v-app>
</template>

<script scoped>
import { getToken } from '../utils/APIUtils.js'
export default {
  name: 'Signout',
    data () {
        return {

        }
    },
    methods: {
        moveToSigninPage() {
            this.$router.push({path: '/'})
        }
    },
    mounted() {
        this.$emit('login')
    }
}
</script>

<style scoped>
    .logo-area{
        margin-top: 30px;
    }
    .logo-area img{
        width: 350px;
    }
    .signout-area{
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .signoutCharacter{
        font-size:120%;
        margin-bottom:30px;
    }
</style>
