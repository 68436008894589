<template>
  <div>
    <template v-if="coverImageUrl">
      <template v-if="viewEventName">
        <template v-if="ie">
          <v-flex xs12 class="img-area-ie">
            <img :src="coverImageUrl" alt="">

            <p class="inner-frame event-title" v-bind:style="{color:fontColor,opacity:opacity,'font-size':eventTitleSize + 'px'}" v-bind:class="eventTitleFont">
              {{eventName}}
            </p>
          </v-flex>
        </template>
        <template v-else>
          <v-flex xs12 class="img-area-not-ie">
            <img :src="coverImageUrl" alt="">

            <!--max-widthでトップカバー画像の幅を指定-->
            <p class="inner-frame event-title" v-bind:style="{color:fontColor,opacity:opacity,'max-width':imageMaxWidth,'font-size':eventTitleSize + 'px'}" v-bind:class="eventTitleFont">
              {{eventName}}
            </p>
            <p class="outer-frame event-title" v-bind:class="this.eventTitleFont">
              {{eventName}}
            </p>
          </v-flex>
        </template>
      </template>
      <template v-else>
        <template v-if="ie">
          <v-flex xs12 class="img-area-ie">
            <img :src="coverImageUrl" alt="">
          </v-flex>
        </template>
        <template v-else>
          <v-flex xs12 class="img-area-not-ie">
            <img :src="coverImageUrl" alt="">
          </v-flex>
        </template>
      </template>
    </template>
    <template v-else>
      <template v-if="viewEventName">
        <v-flex ls12 xs12 class="img-area-text-only">
          <div class="event-title" v-bind:style="{color:fontColor,'font-size':eventTitleSize + 'px'}" v-bind:class="this.eventTitleFont">
            {{eventName}}
          </div>
        </v-flex>
      </template>
    </template>
  </div>
</template>

<script>
  export default {
    props: ['viewEventName', 'eventName', 'coverImageUrl', 'eventTitleColorHex8', 'eventTitleFont', 'eventTitleSize'],
    watch: {
      eventTitleColorHex8(data) {
        let hex8 = this.decideEventTitleColorHex8(data, this.coverImageUrl)
        this.fontColor = hex8.substring(0, 7)
        this.opacity = this.getRgbaFromHex8(hex8).a
      },
      /* テキストの最大幅を設定するために、画像URLの変更を監視 */
      coverImageUrl(data) {
        this.setImageMaxWidth(data);
      }
    },
    data() {
      return {
        ie:false,
        opacity:1,
        opacity16:"",
        fontColor:'#FFFFFF',
        imageMaxWidth: "",
      }
    },
    methods: {
      onloadImageForSetMaxWidth(that, image) {
        return function () {
          that.imageMaxWidth = Math.floor( (that.getImageAreaMaxHeight() / image.height) * image.width ) + "px";
          console.log("width:" + that.imageMaxWidth)
        }
      },
      /* 画像URLが変わった際に、そのサイズを取得してタイトル文字の最大表示幅を指定する */
      setImageMaxWidth(file) {
        if(file == undefined || file.length <= 0) {
        } else {
          let image = new Image();
          image.onload = this.onloadImageForSetMaxWidth(this, image);
          image.src = file;
        }
      },
      decideEventTitleColorHex8(hex8, coverImageUrl) {
        let defaultHex8 = (coverImageUrl) ? "#FFFFFFFF" : "#959595FF"

        if(!hex8) return defaultHex8
        if(hex8.length == 9) return hex8
        if(hex8.length == 7) return hex8 + "FF"

        return defaultHex8
      },
      decideEventTitleFont(font) {
        if(!font) return 'font-style-1'
        return font;
      },
      decideEventTitleSize(size) {
        if(!size) return 60;
        return size;
      },
      getImageAreaMaxHeight() {
        return 545
      },
      getRgbaFromHex8(hex8) {
        let elems = hex8.slice(1).match(/.{1,2}/g);
        let alpha = (elems.length == 4) ? parseFloat(parseInt((parseInt(elems[3], 16)/255)*100)/100) : 1
        return {
          r: parseInt(elems[0], 16),
          g: parseInt(elems[1], 16),
          b: parseInt(elems[2], 16),
          a: alpha
        }
      },
    },
    mounted() {
      let userAgent = window.navigator.userAgent.toLowerCase();
      if(userAgent.indexOf('trident') != -1 || userAgent.indexOf('msie') != -1) {
        this.ie=true
      }
    }
  }
</script>

<style>
  /* フォント選択時の凡例で使用できるようにスコープ外にしておく */
  .event-title.font-style-1 {
    font-weight: normal;
    font-family: "游ゴシック Medium", YuGothic, YuGothicM, "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, sans-serif;
  }
  .event-title.font-style-2 {
    font-weight: normal;
    font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  }
  .event-title.font-style-3 {
    font-weight: bold;
    font-family: "游ゴシック Medium", YuGothic, YuGothicM, "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, sans-serif;
  }
  .event-title.font-style-4 {
    font-weight: bold;
    font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  }
</style>

<style scoped>

  .img-area-ie {
    position: relative;
    margin-top: 20px;
    width: 100%;
    height: 500px;
    display: inline-block;
    overflow: hidden;
  }
  .img-area-not-ie{
    position: relative;
    margin-top: 20px;
    padding-top: 0;
    max-height: 545px;
    background-position: center !important;
    background-color: #ffffff;
  }

  .img-area-ie img {
    position: absolute;
    width: 100%;
    height: auto;
    min-height: 100%;
    min-width: 100%;
  }
  .img-area-not-ie img {
    position: relative;
    max-height: 545px;
    background-position: center;
    width: 100%;
    object-fit: contain;
    background-color: #ffffff;
  }

  .img-area-ie p.inner-frame {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    margin: 0;
    padding: 0;
    word-wrap: break-word;
  }

  @media screen and (min-width: 601px) {
    .img-area-not-ie p.inner-frame {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      -ms-transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      margin: 0;
      padding: 0;
      word-wrap: break-word;
    }
    .img-area-not-ie p.outer-frame {
      display: none;
    }
  }

  @media screen and (max-width: 600px) {
    .img-area-not-ie p.inner-frame {
      display: none;
    }
    .img-area-not-ie p.outer-frame {
      position: relative;
      display: block;
      margin: 0;
      padding-top: 1em;
      padding-bottom: 1em;
      background-color: #ffffff;
      color: #959595;
      text-align: center;
      font-size: 180%;
      font-weight: bold;
      word-wrap: break-word;
    }
  }

  @media print {
    .img-area-not-ie p.inner-frame {
      visibility: hidden;
    }
    .img-area-not-ie p.outer-frame {
      visibility: hidden;
    }
  }

  .img-area-text-only div {
    display: block;
    padding: 10px;
    text-align: center;
    word-wrap: break-word;
  }
</style>
