<template>
  <v-app id="inspire">
    <middle_header :eventData="this.eventData" ticketSales="true"></middle_header>

    <v-container fluid>
      <v-flex>
        <p class="title">
          <v-icon large>local_atm</v-icon>
          <span class="titleName">参加料徴収状況</span>
        </p>

        <v-layout row wrap>
          <v-flex md4 class="borderLine">
            <v-layout row wrap>
              <v-flex md2>
                <p class="graphAttrTitle">総販売数</p>
              </v-flex>
              <v-flex md4>
                <v-flex md12 style="height:10%;"></v-flex>
                <v-flex md12 style="height:90%;">
                  <Chart :data="ChartDataSalesNum" :options="options" style="width:150px;"></Chart>
                </v-flex>
              </v-flex>
              <v-flex md6 xs6>
                <v-flex md12 style="height:50%;"></v-flex>
                <v-flex md12 style="height:50%;">
                  <span class="salesNum">{{salesList.length}}</span>
                  <span class="salesNumSpacer">／</span>
                  <span class="salesNumMax">{{ticketTotalAmount}}</span>
                </v-flex>
              </v-flex>
            </v-layout>
          </v-flex>

          <v-flex md5 class="borderLine">
            <v-layout row wrap>
              <v-flex md2>
                <p class="graphAttrTitle">販売割合</p>
              </v-flex>
              <v-flex md7>
                <v-flex md12 style="height:20px;"></v-flex>
                <span v-for="ticket in sortTicketList" :key="ticket.id">
                  <v-flex md12 style="height:60px;">
                    <v-layout row wrap>
                      <v-flex md8 class="text-md-right">{{ticket.name}}</v-flex>
                      <v-flex md4>
                        <span class="salesNum">{{ticket.sold}}</span>
                        <span class="salesNumSpacer">／</span>
                        <span class="salesNumMax">{{ticket.amount}}</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </span>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-spacer width="5%"></v-spacer>
          <v-flex md3 style="text-align:left;">
            <v-layout row wrap>
              <v-flex md4 class="text-md-center">
                <p class="graphAttrTitle">合計販売金額</p>
              </v-flex>
              <v-flex md8>
                <v-flex md12 style="height:80%;"></v-flex>
                <v-flex md12 style="height:20%;">
                  <p>
                    <span class="purchasedPrice">{{ticketTotalSales | toPriceLocaleString}}</span>
                  </p>
                </v-flex>
              </v-flex>
              <v-flex md4></v-flex>
            </v-layout>
          </v-flex>
          <v-spacer></v-spacer>
        </v-layout>
        <v-spacer></v-spacer>
        <div style="padding-top: 30px;"></div>
        <v-data-table
          :headers="headers"
          :items="salesList"
          :rows-per-page-items="rowsPerPageItems"
          :pagination.sync="pagination"
          item-key="createdAt"
          must-sort
        >
          <template slot="items" slot-scope="props">
            <tr>
              <td class="text-md-left">{{ props.item.name }}</td>
              <td class="text-md-left">{{ props.item.email }}</td>
              <td class="text-md-left">{{ props.item.ticketName }}</td>
              <td class="text-md-left">{{ props.item.ticketPrice | toPriceLocaleString}}</td>
              <td class="text-md-left">{{ formatDate(props.item.createdAt) }}</td>
              <td class="text-md-left">{{ props.item.visitorId }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
      <v-flex lg3 offset-lg9 md3 offset-md9 xs10 offset-xs1 class="apparea">
        <p style="font-size:12px; margin-bottom:3px;">
          受付アプリはApp Storeでインストールしお使いください。
          <br />※受付アプリはiOS端末のみの対応となります。
        </p>
        <p style="margin-bottom:0px;">
          <a
            href="https://itunes.apple.com/jp/app/tkp-event-planner-%E5%8F%97%E4%BB%98%E3%82%A2%E3%83%97%E3%83%AA/id1438093541?mt=8"
            target="_blank"
            style="display:inline-block;overflow:hidden;background:url(https://linkmaker.itunes.apple.com/assets/shared/badges/ja-jp/appstore-lrg.svg) no-repeat;width:135px;height:40px;"
          ></a>
        </p>
      </v-flex>
    </v-container>
  </v-app>
</template>

<script>
import { getMe, getEvent, getSalesStatus } from "../utils/APIUtils";
import Chart from "@/components/Chart.vue";
import header from "@/components/EventHeader.vue";
export default {
  name: "TicketSales",
  components: {
    Chart,
    middle_header: header
  },
  data() {
    return {
      role: '',
      organizerId: null,
      ticketList: [],
      salesList: [],
      ticketTotalAmount: 0,
      ticketTotalSales: 0,
      eventId: null,
      eventData: {},
      eventName: "",
      eventDate: "",
      eventFacility: "",
      role: "",
      rowsPerPageItems: [10, 100, 1000, 5000],
      pagination: {
        rowsPerPage: 1000,
        sortBy: 'createdAt',
        descending: true,
      },
      headers: [],
      // グラフ購入者数用データ
      ChartDataSalesNum: {
        // ラベル
        labels: ["販売数", "在庫数"],
        // データ詳細
        datasets: [
          {
            data: [],
            backgroundColor: ["rgba(51, 168, 236, 1)", "rgba(210, 210, 210, 1)"]
          }
        ]
      },
      // グラフオプション
      options: {
        cutoutPercentage: 75,
        legend: {
          display: false
        }
      }
    };
  },
  watch: {
    eventData() {
      this.getTicketTotalAmount();
    },
    salesList() {
      this.loadSoldUnitTicket();
      this.loadTicketTotalSales();
      this.setChartData();
    },
  },
  filters: {
    localString: function(value) {
      if (!value) {
        return 0;
      }
      return value.toLocaleString();
    },
    toPriceLocaleString: function(value) {
      if (!value) {
        return 0;
      }
      return value.toLocaleString("ja-JP", {
        style: "currency",
        currency: "JPY"
      });
    }
  },
  mounted() {
    this.eventId = this.$route.query.id;
    let headers = { accountType: "organizer" };
    getMe({ headers: headers, accountType: "organizer" })
      .then(res => {
        this.organizerId = res.data.id
        this.$emit("loggedin", res.data);
        this.loadEvent();
      })
      .catch(err => {
        console.log("err", err)
        alert("権限がないためアクセスできません。");
        this.$router.push('/')
      });
  },
  computed: {
    sortTicketList() {
      return this.ticketList.sort((a, b) => {
        return  (a.orderPriority > b.orderPriority) ? 1 : (a.orderPriority < b.orderPriority) ? -1 : 0;
      });
    }
  },
  methods: {
    setHeaderData() {
      this.headers.push({ text: "氏名", value: "name", width: "200px" });
      this.headers.push({ text: "メールアドレス", value: "email" });
      this.headers.push({ text: "参加券名称", value: "ticketName" });
      this.headers.push({ text: "金額(税込)", value: "ticketPrice" });
      this.headers.push({ text: "購入日時", value: "createdAt" });
      this.headers.push({ text: "識別ID", value: "visitorId" });
    },
    formatDate(date) {
      return date ? new Date(date).toLocaleString() : date;
    },
    loadEvent() {
      getEvent({ eventId: this.eventId })
        .then(res => {
          this.eventData = res.data;
          this.eventName = this.eventData.eventName;
          this.eventDate = this.eventData.eventDate;
          this.eventFacility = this.eventData.facility.facilityName;
          this.loadSalesStatus();
          this.setHeaderData();
          const user = res.data.eventGroup.members.find(member => {
            return member.organizer.id === this.organizerId
          })
          this.role = user.role
        })
        .catch(err => {
          console.log("err", err);
          alert("イベント情報の取得に失敗しました。");
          this.$router.push("/");
        });
    },
    loadSalesStatus() {
      getSalesStatus({ eventId: this.eventId })
        .then(res => {
          const list = [];
          res.data.forEach(order => {
            list.push({
              name: order.lastName + " " + order.firstName,
              email: order.email,
              ticketId: order.ticketId,
              ticketName: order.ticketName,
              ticketPrice: order.price,
              createdAt: order.createdAt,
              visitorId: order.visitorId
            });
          });
          this.salesList = list;
        })
        .catch(err => {
          console.log("err", err)
          alert("参加料徴収データの取得に失敗しました。");
          this.$router.push("/");
        });
    },
    loadSoldUnitTicket() {
      let self = this;
      this.eventData.tickets.forEach(function(ticket) {
        let ticketSales = 0;
        if (self.salesList.length > 0) {
          self.salesList.forEach(function(sales) {
            if (sales.ticketId === ticket.id) {
              ticketSales++;
            }
          });
        }
        self.ticketList.push({
          id: ticket.id,
          name: ticket.name,
          amount: ticket.amount,
          sold: ticketSales,
          orderPriority: ticket.orderPriority
        });
      });
    },
    getTicketTotalAmount() {
      let totalAmount = 0;
      this.eventData.tickets.forEach(function(ticket) {
        totalAmount = totalAmount + ticket.amount;
      });
      this.ticketTotalAmount = totalAmount;
    },
    loadTicketTotalSales() {
      let totalSales = 0;
      this.salesList.forEach(function(sales) {
        totalSales = totalSales + sales.ticketPrice;
      });
      this.ticketTotalSales = totalSales;
    },
    setChartData() {
      this.ChartDataSalesNum.datasets[0].data[0] = this.salesList.length;
      this.ChartDataSalesNum.datasets[0].data[1] =
        this.ticketTotalAmount - this.salesList.length;
      this.ChartDataSalesNum = { ...this.ChartDataSalesNum };
    }
  }
};
</script>
<style>
#inspire {
  color: #666666;
  background-color: white;
}
.tool {
  background-color: #f88366;
  color: white;
  height: 70px;
}
.eventname {
  text-align: left;
  float: left;
  font-size: 24px;
  margin-left: 50px;
  padding: 15px 0px;
}
.iconmenu {
  text-align: right;
  padding: 7px 0px;
  margin-right: 25px;
}
.title {
  text-align: left;
  margin-left: 17px;
}
.titleName {
  display: inline-block;
  vertical-align: top;
  padding-top: 9px;
  padding-left: 5px;
}
td {
  color: #666666;
}
.borderLine {
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: #bdbdbd;
}
.graphAttrTitle {
  font-size: 105%;
}
.salesNum {
  color: #33a8ec;
  font-size: 200%;
  font-weight: 300;
}
.salesNumSpacer {
  color: #cccccc;
  font-size: 200%;
  font-weight: 50;
}
.salesNumMax {
  color: grey;
  font-size: 100%;
  font-weight: 50;
  margin-left: -10px;
}
.purchasedPrice {
  font-size: 200%;
}

.apparea {
  background-color: #fafafa;
  padding: 0px;
  border: solid 1px #bdbdbd;
  margin-top: 10px;
}
</style>
