<template>
  <v-app id="inspire">
    <v-container fluid>
      <v-flex md12>
        <p class="templateTitle"><v-icon large class="icon_design">email</v-icon><span class="templateTitle_name">カスタムメールテンプレート一覧</span></p>
        <v-spacer></v-spacer>
      </v-flex>
      <v-layout row wrap>
        <v-flex md9>
        </v-flex>
        <v-flex md3 style="text-align:right;">
          <v-btn
            slot="activator"
            color="primary"
            dark
            @click="openCreateMailTemplateDialog"
          >
            <v-icon dark small >add_circle</v-icon>
            新規登録
          </v-btn>
        </v-flex>
      </v-layout>
      <v-flex md12>
        <v-data-table
          :headers="headers"
          :items="mailTemplates"
          :rows-per-page-items="rowsPerPageItems"
          item-key="id"
          must-sort
        >
          <template slot="items" slot-scope="props">
            <tr :active="props.selected" @click="props.selected = !props.selected">
              <td class="text-xs-left">{{ props.item.templateTitle }}</td>
              <td class="text-xs-left">{{ props.item.lastModifiedOrganizer.name}}</td>
              <td class="text-xs-left">{{ formatDateTimeWithSec(props.item.updatedAt) }}</td>
              <td class="justify-center layout px-0">
                <v-tooltip bottom>
                  <v-btn slot="activator"  @click="openPreviewDialog(props.item)" icon>
                    <v-icon color="grey darken-1" lot="activator" small>drafts</v-icon>
                  </v-btn>
                  <span>プレビュー</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <v-btn slot="activator"  @click="openEditMailTemplateDialog(props.item)" icon>
                    <v-icon color="grey darken-1" lot="activator" small>edit</v-icon>
                  </v-btn>
                  <span>編集</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <v-btn slot="activator"  @click="deleteMailTemplate(props.item)" icon>
                    <v-icon color="grey darken-1" lot="activator" small>delete</v-icon>
                  </v-btn>
                  <span>削除</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-container>

    <preview-dialog ref="previewDialog"></preview-dialog>
    <edit_dialog ref="editDialog" @completeEdit="onCompleteEdit"></edit_dialog>
  </v-app>
</template>

<script>
  import moment from 'moment'
  import previewDialog from '@/components/custommailtemplate/CustomMailTemplatePreviewDialog.vue'
  import editDialog from '@/components/custommailtemplate/CustomMailTemplateEditDialog.vue'
  import {
    getAllCustomMailTemplates,
    deleteCustomMailTemplates
  } from '../../utils/APIUtils'
  export default {
    name: 'mailTemplatesList',
    components : {
      'preview-dialog' : previewDialog,
      'edit_dialog' : editDialog
    },
    data() {
      return {
        rowsPerPageItems: [10, 25, 50, 100],
        headers: [
          {
            text: '名称',
            value: 'templateTitle'
          },
          {
            text: '最終更新者',
            value: 'lastModifiedOrganizer.name'
          },
          {
            text: '最終更新日',
            value: 'updatedAt'
          },
          {
            text: '',
            value: 'CRUD'
          }
        ],
        mailTemplates: []
      }
    },
    methods: {
      load() {
        getAllCustomMailTemplates().then(res => {
          this.mailTemplates = res.data
        })
      },
      formatDateTimeWithSec(datetime) {
        return datetime ? moment(datetime).format('YYYY/MM/DD HH:mm:ss') : datetime;
      },
      openPreviewDialog(item) {
        this.$refs.previewDialog.openPreview(item.content)
      },
      openCreateMailTemplateDialog() {
        this.$refs.editDialog.openForCreate()
      },
      openEditMailTemplateDialog(item) {
        this.$refs.editDialog.openForEdit(item)
      },
      onCompleteEdit() {
        this.load()
      },
      deleteMailTemplate(item) {
        if (!confirm("選択したテンプレートを削除してもよろしいでしょうか？")) return

        deleteCustomMailTemplates({mailTemplateId: item.id}).then(() => {
          alert("テンプレートを削除しました")
          this.load()
        }).catch(err => {
          alert("テンプレートの削除に失敗しました")
        })
      }
    },
    mounted() {
      this.load()
    }
  }
</script>

<style scoped>
  #inspire{
    color:#666666;
    background-color: white;
  }
  .templateTitle {
    text-align: left;
    margin-left: 30px;
  }
  .templateTitle_name{
    margin-left:25px;
    font-size:120%;
  }
</style>
