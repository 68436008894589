<template>
    <v-app id="inspire">
    <v-container fluid>
        <v-flex >
            <v-form ref="form" v-model="valid">
                <p class="title"><v-icon large>email</v-icon><span class="titleName">メール配信</span> <span class="titleName" v-if="this.isEdit">編集</span></p>
                <v-container grid-list-md style="margin-left:0px;margin-right:0px;max-width:100%;">
                    <v-layout wrap>
                      <v-flex xs12 v-if="this.isEdit">
                        <p style="text-align: left">配信先、テンプレートの編集はできません。これらの項目を変更する場合はメールを削除し、再度作成してください。</p>
                      </v-flex>
                      <v-flex xs12>
                        <v-radio-group v-model="type" :rules="typeSelectRules" row>
                          <v-radio label="即時配信" value="instantly" v-if="!this.isEdit"></v-radio>
<!--                          <v-radio label="予約配信" value="scheduled"></v-radio>-->
                        </v-radio-group>
                        <v-text-field label="配信予約日時"
                                      slot="activator"
                                      v-model="schedule"
                                      :rules="scheduleRule"
                                      @click="editOpenSchedule"
                                      readonly
                                      v-if="this.type === 'scheduled'"
                        ></v-text-field>
                        <date_time_dialog ref="editOpenScheduleDialog"
                                           @save="onSaveSchedule"
                                          variableName='配信予約日時'
                                          okButtonTitle='保存'
                        ></date_time_dialog>
                      </v-flex>
                        <v-flex xs12>
                            <div class="inputFieldTitle">配信先</div>
                            <template v-if="toAll">
                                <v-text-field value="送信時の登録者全員" readonly  class="dialogTextArea"></v-text-field>
                            </template>
                            <template v-else>
                                <v-text-field v-model="mailTo" readonly  class="dialogTextArea"></v-text-field>
                            </template>
                        </v-flex>
                        <v-flex md6 xs12>
                            <div class="inputFieldTitle">配信者名<span class="essential">必須</span></div>
                            <v-text-field v-model="mailSenderName" :rules="mailSenderNameRule" label="" required  class="dialogTextArea"></v-text-field>
                        </v-flex>
                        <v-flex md6 xs12>
                            <div class="inputFieldTitle">配信者メールアドレス（From）<span class="essential">必須</span></div>
                            <v-text-field v-model="mailFrom" :rules="mailFromRule" class="dialogTextArea"></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                            <div class="inputFieldTitle">返信先メールアドレス（Reply to）<span class="attention">（受信者のメールソフトによっては適用されないことがあります）</span></div>
                            <v-text-field v-model="mailReplyTo" :rules="mailReplyToRule" class="dialogTextArea"></v-text-field>
                        </v-flex>

                        <v-flex md6 xs12>
                            <div class="inputFieldTitle">件名<span class="essential">必須</span></div>
                            <v-text-field v-model="mailTitle" :rules="mailTitleRule" required ></v-text-field>
                        </v-flex>
                        <v-flex md6 xs12>
                            <div class="inputFieldTitle">メールテンプレート</div>
                            <v-select
                                v-model="selectedTemplate"
                                :items="mailTemplates"
                                item-text="template_title"
                                item-value="template_title"
                                return-object
                                @input="setMailTemplate"
                                :disabled="this.isEdit"
                            ></v-select>
                        </v-flex>
                        <v-flex xs12>
                            <div class="inputFieldTitle" style="margin-top:10px;">本文<span class="essential">必須</span><span class="attention">（入力エリアにて右クリックをすると差し込み値を挿入することができます）</span></div>
                            <v-textarea
                                v-model="mailContents"
                                rows="8"
                                required
                                :rules="mailContentsRule"
                                @contextmenu="show"
                            ></v-textarea>
                            <v-menu
                                v-model="showMenu"
                                :position-x="x"
                                :position-y="y"
                                absolute
                                offset-y
                                >
                                <v-list>
                                    <v-list-item
                                    v-for="(item, index) in rightClickMenuItems"
                                    :key="index"
                                    @click="insertCode(item.value)"
                                    >
                                    <v-list-item-title>{{item.title}}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-flex>
                        <v-dialog v-model="previewDialog" max-width="624">
                            <v-card>
                                <div style="margin:0 50px 50px 50px; padding-top:50px">
                                    <p class="previewContent" v-html="previewMailContents"></p>
                                </div>
<!--                                <img src="https://cdn.event-planner.net/haikei_white.png" width="100%">-->
                                <v-btn
                                    @click="previewDialog = false"
                                >
                                    閉じる
                                </v-btn>
                            </v-card>
                        </v-dialog>
                        <v-flex xs12 class="text-md-center">
                            <v-btn @click="preview">プレビュー</v-btn>
                            <v-btn class="edit-b" @click="cancel">戻る</v-btn>
                            <v-btn color="error"  @click="submit" v-if="this.type === 'scheduled'">予約</v-btn>
                            <v-btn color="error"  @click="submit" v-if="this.type === 'instantly'">送信</v-btn>
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-form>
        </v-flex>
    </v-container>
</v-app>
</template>

<script>
import header from '@/components/EventHeader.vue'
import dateTimeDialog from '@/components/common/DateTimeDialog.vue'
import moment from "moment"
import {getAllCustomMailTemplatesList, getEmail, getEvent, getMe, sendEmail, updateEmail} from '../utils/APIUtils'

export default {
        name: 'CreateEmail',
        props: ['mailTo', 'visitorIds', 'eventId', 'mailId', 'isEdit', 'toAll'],
        components: {
            'middle_header': header,
            'date_time_dialog': dateTimeDialog
        },
        watch: {
            mailTo(data) {
                this.mailTo = data
            },
            visitorIds(data) {
                this.visitorIds = data
            },
            eventId(data) {
                this.eventId = data
            },
            type(data){
              if(data === 'instantly'){
                this.schedule = ''
              }
            },
        },
        data() {
            return {
                valid:false,
                showMenu: false,
                x: 0,
                y: 0,
                visitorDatas:{},
                visitorIdArray:[],
                eventData:{},
                eventName:'',
                eventDate:'',
                eventEndDate:'',
                eventTerm:'',
                startTime:'',
                endTime:'',
                eventFacility:'',

                rightClickMenuItems:[],

                type: 'instantly',
                typeSelectRules: [
                  v => !!v || '選択してください。',
                ],
                schedule: '',
                scheduleRule: [
                  v => !!v  || '配信予約日時は必須です',
                  v => (v && this.scheduleCheck(v)) || '現在日時より後の日時を入力してください'
                ],

                mailData:{},
                mailSenderName:'',
                mailSenderNameRule:[
                    v => !!v || 'メールの配信者名は必須です。',
                    v => (v && v.length <= 128) || '128文字以下にしてください'
                ],
                mailFrom:'',
                mailFromRule:[
                    v => !!v || '配信者メールアドレスは必須です。',
                    v => (v && v.length <= 128) || '128文字以下にしてください',
                    v => /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'メールアドレスの形式で入力を行ってください。'
                ],
                mailReplyTo:'',
                mailReplyToRule:[
                    v => !v || v.length <= 128 || '128文字以下にしてください',
                    v => !v || /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'メールアドレスの形式で入力を行ってください。'
                ],
                mailTitle:'',
                mailTitleRule:[
                    v => !!v || 'メールの件名は必須です。',
                    v => (v && v.length <= 128) || '128文字以下にしてください'
                ],
                mailContents:'',
                mailContentsRule:[
                    v => !!v || 'メールの本文は必須です。'
                ],
                mailContentsConverted:'',
                previewDialog: false,
                previewMailContents: '',

                selectedTemplate: {},
                mailTemplates:[],
            }
        },
        methods: {
            setMailTemplate() {
                if(!confirm("本文の内容が、テンプレートの内容で上書きされ消去されますがよろしいですか？")) {
                    this.selectedTemplate = null
                    return
                }
                this.mailContents = this.selectedTemplate.contents
            },
            // getTextAreaPosition(){
            //     var textarea = document.querySelector('textarea')
            //     var index = textarea.selectionStart
            //     this.textAreaPosition = index
            // },
            // getTextAreaPositionKeyDown(){
            //     var textarea = document.querySelector('textarea')
            //     var index = textarea.selectionStart
            //     if(event.key === 'Enter') {
            //         index++
            //     }
            //     this.textAreaPosition = index
            // },
            // clearSelection() {
            //     if(document.selection && document.selection.empty) {
            //         document.selection.empty();
            //     } else if(window.getSelection) {
            //         var sel = window.getSelection();
            //         sel.removeAllRanges();
            //     }
            // },
            preview() {
                this.previewMailContents = this.mailContents
                this.previewMailContents = this.replaceAll(this.previewMailContents, "{{lastName}}", "(姓)")
                this.previewMailContents = this.replaceAll(this.previewMailContents, "{{firstName}}", "(名)")
                this.previewMailContents = this.replaceAll(this.previewMailContents, "{{organizationFullName}}", "(会社名)")
                this.previewMailContents = this.replaceAll(this.previewMailContents, "{{departmentName}}", "(部署名)")
                this.previewMailContents = this.replaceAll(this.previewMailContents, "{{jobTitle}}", "(役職名)")
                this.previewMailContents = this.replaceAll(this.previewMailContents, "{{eventTitle}}", this.eventName)
                this.previewMailContents = this.replaceAll(this.previewMailContents, "{{eventDate}}", moment(this.eventDate).format('YYYY-MM-DD'))
                this.previewMailContents = this.replaceAll(this.previewMailContents, "{{eventEndDate}}", (this.eventEndDate) ? moment(this.eventEndDate).format('YYYY-MM-DD') : '')
                this.previewMailContents = this.replaceAll(this.previewMailContents, "{{startTime}}", moment(this.eventDate + ' ' + this.startTime).format('HH:mm'))
                this.previewMailContents = this.replaceAll(this.previewMailContents, "{{endTime}}", moment(this.eventDate + ' ' + this.endTime).format('HH:mm'))
                this.previewMailContents = this.replaceAll(this.previewMailContents, "{{eventFacility}}", (this.eventFacility) ? this.eventFacility : '')
                this.previewMailContents = this.replaceAll(this.previewMailContents, "{{eventMyPageUrl}}", "<a href='" + this.eventData.eventSiteUrl+"' target='_blank'>" + this.eventData.eventSiteUrl + "</a>")
                this.previewMailContents = this.replaceAll(this.previewMailContents, "{{myQrcodeUrl}}", '<img src="https://s3-ap-northeast-1.amazonaws.com/mixlite-dev-mock-map/qrcode.jpg">')
                this.previewMailContents = this.replaceAll(this.previewMailContents, "{{invitationPageUrl}}", '(招待ページリンク)')

                this.previewDialog = true
            },
            replaceAll(content, target, value) {
                let reg = new RegExp(target, "g");
                return content.replace(reg, value);
            },
            submit () {
                if (this.$refs.form.validate()) {
                  // this.mailContentsConverted = this.convertToRichText(this.mailContents)
                  this.mailContentsConverted = this.mailContents
                  if (this.isEdit) {
                    this.update()
                  } else {
                    this.setMailData()
                    var send_confirm = false
                    if (this.type === 'scheduled') {
                      if (this.scheduleCheck(this.schedule)) {
                        send_confirm = confirm('メール配信予約をしてもよろしいですか？')
                      }
                    } else if (this.type === 'instantly') {
                      send_confirm = confirm('メールを送信してもよろしいですか？')
                    }
                    if (send_confirm) {

                      sendEmail({data: this.mailData}).then(res => {
                        if (this.type === 'instantly') {
                          alert('メールを送信しました。')
                        } else if (this.type === 'scheduled') {
                          alert('メールを予約しました。設定内容はメール配信状況画面をご確認ください。予約日時までに削除もしくはキャンセルされた配信先へは、メール配信が行われません。')
                        }
                        this.$emit("close", {})
                      }).catch(err => {
                        if (err.response.status === 400 && err.response.data === 'exceeded plan limit') {
                          alert('プランのメール送信数上限に達したためメールの送信に失敗しました。')
                        } else{
                          alert("メールの送信に失敗しました。管理者にお問い合わせください。")
                          console.log(err)
                        }
                      })
                    }
                  }
                }
            },
            show (e) {
                    e.preventDefault()
                    this.showMenu = false
                    this.x = e.clientX
                    this.y = e.clientY
                    this.showMenu = true
            },
            insertCode(value){
                var textarea = document.querySelector('textarea')
                var sentence = textarea.value
                var len      = sentence.length
                var pos      = textarea.selectionStart
                var before   = sentence.substr(0, pos)
                var after    = sentence.substr(pos, len)
                this.mailContents = before + value + after
            },
            convertToRichText(textareaData){
                if(!textareaData) return ''

                //ダブルクォートをシングルクォートに置換
                textareaData = textareaData.replace(/"/g, '\'')
                //改行コードをタグに置換
                textareaData = textareaData.replace(/\r?\n/g, '<br/>')
                return textareaData
            },
            convertToSimpleText(textareaData) {
              if(!textareaData) return ''

              // シングルクォートをダブルクォートに置換
              textareaData = textareaData.replace('\'', /"/g)
              // タグを改行コードへ置換
              textareaData = textareaData.replace(/<br\/>/g,  "\r\n")
              return textareaData
            },
            setMailData(){
                this.mailData["emailId"] = this.mailId
                this.mailData["body"] = this.mailContentsConverted
                this.mailData["toAll"] = this.toAll
                this.mailData["to"] = (this.toAll) ? this.mailData["to"] = [] : this.mailData["to"] = this.mailTo.split(';').filter(i => i !== "")
                this.mailData["type"] = "ToVisitor"
                this.mailData["eventId"] = this.eventId
                this.mailData["from"] = this.mailFrom
                this.mailData["title"] = this.mailTitle
                this.mailData["replyTo"] = this.mailReplyTo
                this.mailData["senderName"] = this.mailSenderName
                if(!this.isEdit) {
                  this.mailData["templateName"] = this.selectedTemplate.template_title
                }
                this.mailData["commit"] = false
                if(this.type === 'scheduled') {
                  this.mailData["schedule"] = this.formatDateTimeForSend(this.schedule)
                }
            },
            loadEvent() {
                return getEvent({eventId:this.eventId})
                .then(res => {
                    this.eventData = res.data
                    this.eventName = this.eventData.eventName
                    this.eventDate = this.eventData.eventDate
                    this.eventEndDate = this.eventData.eventEndDate || ''
                    this.startTime = this.eventData.startTime
                    this.endTime = this.eventData.endTime
                    this.eventFacility = this.eventData.facility.facilityName || ''
                    this.setRightClickMenu(this.eventData.visitorOptions)
                }).catch(err => {
                    alert('データの取得に失敗しました。')
                })
            },
            cancel(){
                this.$emit("close", {})
            },
            setRightClickMenu(visitorOptions) {
                this.rightClickMenuItems.push({ title: '姓' ,value:'{{lastName}}'})
                this.rightClickMenuItems.push({ title: '名' ,value:'{{firstName}}'})
                this.rightClickMenuItems.push({ title: 'QRコード' ,value:'{{myQrcodeUrl}}'})
            },
            editOpenSchedule() {
                this.$refs.editOpenScheduleDialog.open(this.schedule)
            },
            onSaveSchedule(data) {
                this.schedule = data;
            },
            prepareEditAnnouncementData() {
                this.schedule = this.$refs.editScheduleDialog.extractDateTime(this.schedule).dateTime;
            },
            scheduleCheck(input) {
              let now = moment(new Date(), "YYYY-MM-DD HH:mm")
              let scheduled = moment(input, "YYYY-MM-DD HH:mm")
              return (scheduled - now) > 0
            },
          loadEmail() {
            getEmail({eventId: this.eventId, mailId: this.mailId}).then(res => {
              this.mailFrom = res.data.fromAddr
              this.mailTitle = res.data.title
              this.mailReplyTo = res.data.replyToAddr
              this.mailSenderName = res.data.senderName
              if (res.data.templateName === null) {
                this.mailContents = this.convertToSimpleText(res.data.body)
              } else {　
                this.selectedTemplate = res.data.templateName
                this.mailContents = this.convertToSimpleText(res.data.body)
              }
              this.type = 'scheduled'
              // this.schedule = res.data.schedule
              this.schedule = this.formatDateTime(res.data.schedule)
            }).catch(err => {
              console.log(err)
              alert('メール詳細を取得できませんでした。')
            })
          },
          update() {
            //
            if (confirm('編集内容を保存してよろしいですか？予約日時までに削除もしくはキャンセルされた配信先へは、メール配信が行われません。')) {
              if (this.scheduleCheck(this.schedule)) {
                this.setMailData()
                updateEmail({data: this.mailData}).then(res => {
                  alert("メールの編集が完了しました。")
                  this.$emit("close", {})
                }).catch(err => {
                  if (err.response.status === 423) {
                    alert("他のユーザーが更新を行ったか、すでに配信済のメールです。メール詳細を確認してください。")
                  } else {
                    alert("メールの編集処理に失敗しました。");
                  }
                })
              } else {
                alert("予約された時間を過ぎています。メールを編集できません。")
              }
            }
          },
          formatDateTime(datetime) {
            if(datetime == null){
              return "-"
            }
            return datetime ? moment(datetime).format('YYYY-MM-DD HH:mm') : datetime
          },
          formatDateTimeForSend(string){
            let date = moment(string).toDate()
            let yyyy = date.getFullYear();
            let mm = this.toDoubleDigits(date.getMonth() + 1);
            let dd = this.toDoubleDigits(date.getDate());
            let hh = this.toDoubleDigits(date.getHours());
            let mi = this.toDoubleDigits(date.getMinutes());
            return yyyy + '-' + mm + '-' + dd + ' ' + hh + ':' + mi + ':00';
          },
          toDoubleDigits(num) {
            num += "";
            if (num.length === 1) {
              num = "0" + num;
            }
            return num;
          },
          readyMailTemplates() {
            let obj = require('@/assets/mail_template.json')
            this.mailTemplates = JSON.parse(JSON.stringify(obj))
            getAllCustomMailTemplatesList()
            .then(res => {
              let customList = res.data.items
              customList.sort((a, b) => {
                return (a.templateTitle <= b.templateTitle) ? -1 : 1}
                )
              customList.forEach(item => {
                this.mailTemplates.push({
                  template_title: '(カスタム)' + item.templateTitle,
                  contents: this.convertToSimpleText(item.content)
                })
              })
            }).catch(err => {
              console.log(err)
              alert('カスタムメールテンプレートの読み込みに失敗しました')
            })
          }
        },
        mounted(){
            let headers = {accountType: 'organizer'};
            this.readyMailTemplates()
            if(this.isEdit){
              this.loadEmail()
            }
            getMe({headers: headers, accountType:'organizer'})
            .then(res => {
                this.organizer = res.data
                this.loadEvent()
                .then(data => {

                })
                .catch(err => {
                    console.log("loadevent fail", err)
                })
            }).catch(err => {
                console.log("get me failed", err)
                // this.$router.push('/')
            })

        }
    }

</script>
<style scoped>
#inspire{
    color:#666666;
    background-color: white;
}
.tool {
    background-color: #F88366;
    color: white;
    height: 70px;
}
.eventname {
   text-align: left;
   float: left;
   font-size: 24px;
   margin-left: 50px;
   padding: 15px 0px;
}
.iconmenu {
   text-align: right;
   padding: 7px 0px;
   margin-right: 25px;
}
.eventdate{
    text-align:left;
    margin: 10px;
}
.title {
    text-align: left;
    margin-left: 30px;
    margin-right:10px;
}
.titleName{
    display:inline-block;
    vertical-align:top;
    padding-top:9px;
    padding-left:5px;
}
td {
    color:#666666;
}
.inputFieldTitle{
    text-align:left;
    font-weight:bold;
}
.essential{
    background-color: #f44336;
    color: white;
    font-size: 13px;
    font-weight: bold;
    padding: 4px 0.5em;
    vertical-align: top;
    margin-left: 1.1em;
    border-radius: 10px;        /* CSS3草案 */
    -webkit-border-radius: 10px;    /* Safari,Google Chrome用 */
    -moz-border-radius: 10px;   /* Firefox用 */
}
.border{
    border-width:1px;
    border-style:solid;
    border-color:#BDBDBD;
}
.previewContent {
    white-space: pre-wrap;
    text-align: left;
    font-size: 15px;
}
.attention{
    font-weight:normal;
    margin-left:5px;
    font-size:90%;
}
</style>
