<template>
  <v-app id="inspire">
    <template v-if="!displayEmailForm">
      <middle_header :eventData=this.eventData registrationStatus="true"></middle_header>

      <v-container fluid>
        <v-flex>
          <p class="title">
            <v-icon large>how_to_reg</v-icon>
            <span class="titleName">登録状況</span></p>

          <v-container grid-list-md style="margin-left:0px;margin-right:0px;">
            <v-layout row wrap>
              <v-flex ls2 class="text-md-left text-xs-left">
                <p class="graphAttrTitle">来場者数／登録者数</p>
              </v-flex>
              <v-flex ls2>
                <Chart :data="chartData" :options="options" style="width:150px;"></Chart>
              </v-flex>
              <v-flex ls8 class="text-md-left">
                <v-flex md12 style="height:50%;">
                </v-flex>
                <v-flex ls12 style="height:50%;">
                  <span class="salesNum">{{visitedNum}}</span>
                  <span class="salesNumSpacer">／</span>
                  <span class="salesNumMax">{{registeredNum}}</span>
                </v-flex>
              </v-flex>

              <v-flex md8 class="text-md-right">

              </v-flex>
            </v-layout>
          </v-container>
          <v-container grid-list-md style="padding:4px;margin-left:0px;margin-right:0px;max-width:100%;">
            <v-layout align-end justify-end row fill-height>
              <div style="width: 500px">
                <v-text-field
                  v-model="search"
                  append-icon="search"
                  label="Search"
                  hint="選択済み行は検索対象外"
                  single-line
                  hide-details
                ></v-text-field>
              </div>
              <v-tooltip bottom>
                <v-btn slot="activator" @click.stop="checkInVisitor" icon>
                  <v-icon class="iconStyle" lot="activator" large>check_circle_outline</v-icon>
                </v-btn>
                <span>来場者受付</span>
              </v-tooltip>
              <v-tooltip bottom>
                <v-btn slot="activator" @click.stop="openAddNewVisitor" icon>
                  <v-icon class="iconStyle" lot="activator" large>person_add</v-icon>
                </v-btn>
                <span>新規登録</span>
              </v-tooltip>
              <v-tooltip bottom>
                <v-btn slot="activator" @click.stop="openEditVisitor" icon>
                  <v-icon class="iconStyle" lot="activator" large>edit</v-icon>
                </v-btn>
                <span>登録者情報<br>編集</span>
              </v-tooltip>
              <v-tooltip bottom>
                <v-btn slot="activator" @click.stop="checkCancel" icon>
                  <v-icon class="iconStyle" lot="activator" large>cancel</v-icon>
                </v-btn>
                <span>登録者<br>キャンセル</span>
              </v-tooltip>
              <v-tooltip bottom v-if="role!=='Viewer'">
                <v-btn slot="activator" @click.stop="openEmailDestinationSelectDialog" icon>
                  <v-icon class="iconStyle" lot="activator" large>email</v-icon>
                </v-btn>
                <v-dialog v-model="displayEmailDestinationsSelectDialog" max-width="500">
                  <v-card>
                    <v-card-title class="modalHeader">メール送信対象選択</v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-layout>
                          <v-flex>
                            <v-radio-group
                              v-model="emailDestinationsSelect"
                              column
                            >
                              <v-radio label="選択されている登録者" value="selected"></v-radio>
                              <v-radio label="送信時の登録者全員" value="all"></v-radio>
                            </v-radio-group>
                          </v-flex>
                        </v-layout>
                        <v-layout justify-center>
                          <v-btn class="edit-b" @click.native="displayEmailDestinationsSelectDialog=false">閉じる</v-btn>
                          <v-btn color="error" @click="open">開く</v-btn>
                        </v-layout>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </v-dialog>
                <span>メール配信</span>
              </v-tooltip>
              <v-tooltip bottom>
                <v-btn slot="activator" :href="csvTemplateUrl" icon>
                  <v-icon class="iconStyle" lot="activator" large>screen_share</v-icon>
                </v-btn>
                <span>CSVテンプレート</span>
              </v-tooltip>
              <v-tooltip bottom>
                <input type="file" id="csvFile" accept="text/csv" style="display:none" @change="importCsv">
                <v-btn slot="activator" @click="checkCanImportCsv" icon>
                  <v-icon class="iconStyle" lot="activator" large>vertical_align_bottom</v-icon>
                </v-btn>
                <span>CSVインポート</span>
              </v-tooltip>
              <v-tooltip bottom>
                <v-btn slot="activator" :href="csvUrl" icon>
                  <v-icon class="iconStyle" lot="activator" large>vertical_align_top</v-icon>
                </v-btn>
                <span>CSV<br>エクスポート</span>
              </v-tooltip>
              <v-tooltip bottom>
                <v-btn slot="activator" @click.stop="deleteEditor" icon>
                  <v-icon class="iconStyle" lot="activator" large>delete</v-icon>
                </v-btn>
                <span>登録者<br>削除</span>
              </v-tooltip>
              <!--
              <span>
                  <a href="https://itunes.apple.com/jp/app/tkp-event-planner-%E5%8F%97%E4%BB%98%E3%82%A2%E3%83%97%E3%83%AA/id1438093541?mt=8" target="_blank" style="display:inline-block;overflow:hidden;background:url(https://linkmaker.itunes.apple.com/assets/shared/badges/ja-jp/appstore-lrg.svg) no-repeat;width:135px;height:40px;"></a>
              </span>
              -->
            </v-layout>
          </v-container>
          <template>
            <v-layout justify-center wrap>
              <v-dialog v-model="importErrDialog" max-width="500">
                <v-card>
                  <v-card-title class="modalHeader">下記行のデータの取り込みに失敗しました</v-card-title>
                  <div class="text-sm-left" style="margin:20px">
                    <p v-for="(value, key, index) in importErrMsgs" :key="index">
                      {{value}}
                    </p>
                  </div>
                  <v-card-actions>
                    <v-layout justify-center>
                      <v-btn
                        @click="importErrDialog = false"
                      >
                        閉じる
                      </v-btn>
                    </v-layout>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-layout>
          </template>
          <v-dialog v-model="showVisitorDialog" max-width="650px">
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-card>
                <v-card-title class="modalHeader">
                  <span class="headline">{{inputFormTitle}}</span>
                </v-card-title>
                <v-card-text>
                  <v-container grid-list-md>
                    <v-layout wrap>
                      <template v-if="this.newVisitorFlg">
                        <v-flex md12 v-if="ticketsForSelect.length">
                            <v-radio-group v-model="visitorForm.ticketId" :mandatory="false" :rules="ticketSelectRules">
                              <div class="inputFieldTitle"><template>参加料徴収</template><span class="essential">必須</span></div>
                              <template  v-for="ticket in changeDispOrder" >
                                  <v-radio :value="ticket.id" class="ticketRadio" :disabled="ticket.disabled" :key="ticket.id">
                                    <template v-if="ticket.paidTicket" slot="label">
                                                  <span class="ticketLabel">
                                                  {{ticket.name}}　<br>参加費：　{{ticket.price | toPriceLocaleString}}円(税込)<br>販売状況： {{ticket.salesStatus}}
                                                  </span>
                                    </template>
                                    <template v-else slot="label">
                                                  <span class="ticketLabel">
                                                  {{ticket.name}}　<br>参加費：　'無料'<br>販売状況： {{ticket.salesStatus}}
                                                  </span>
                                    </template>
                                  </v-radio>
                              </template>
                            </v-radio-group>
                          <v-switch style="margin-top: 0" v-model="ignoreStock" :label="`販売状況の残席数を無視して登録する`"></v-switch>
                          </v-flex>
                      </template>
                      <template v-if="this.newVisitorFlg">
                        <v-flex md12>
                          <v-text-field
                            v-model="visitorForm.username"
                            :rules="mailAddressRules"
                            label="メールアドレス"
                            required>
                          </v-text-field>
                        </v-flex>
                      </template>
                      <template v-if="this.visitorOptions.LASTNAME">
                        <v-flex md6 xs6>
                          <v-text-field
                            v-model="visitorForm.lastName"
                            :rules="lastNameRules"
                            label="姓"
                            required>
                          </v-text-field>
                        </v-flex>
                        <v-flex md6 xs6>
                          <v-text-field
                            v-model="visitorForm.firstName"
                            :rules="firstNameRules"
                            label="名"
                            required>
                          </v-text-field>
                        </v-flex>
                      </template>
                      <template v-if="this.visitorOptions.COMPANY">
                        <v-flex md12>
                          <v-text-field
                            v-model="visitorForm.companyName"
                            :rules="companyNameRules"
                            label="会社名"
                            required>
                          </v-text-field>
                        </v-flex>
                      </template>
                      <template v-if="this.visitorOptions.DEPT">
                        <v-flex md12>
                          <v-text-field
                            v-model="visitorForm.department"
                            :rules="departmentRules"
                            label="部署"
                            required>
                          </v-text-field>
                        </v-flex>
                      </template>
                      <template v-if="this.visitorOptions.POSITION">
                        <v-flex md12>
                          <v-text-field
                            v-model="visitorForm.position"
                            :rules="positionRules"
                            label="役職"
                            required>
                          </v-text-field>
                        </v-flex>
                      </template>
                      <template v-if="this.visitorOptions.ADDRESS">
                        <v-flex md12>
                          <v-text-field
                            v-model="visitorForm.address"
                            :rules="addressRules"
                            label="住所"
                            required>
                          </v-text-field>
                        </v-flex>
                      </template>
                      <template v-if="this.visitorOptions.TEL">
                        <v-flex md12>
                          <v-text-field
                            v-model="visitorForm.phone"
                            :rules="phoneRules"
                            label="TEL"
                            required>
                          </v-text-field>
                        </v-flex>
                      </template>
                      <template v-for="(item, index) in freeItems">
                        <template v-if="item.active">
                          <v-flex md12>
                            <v-text-field
                              v-model="visitorForm.freeItems[index].value"
                              :label="item.label"
                              :rules="freeItemRules[index]"
                            >
                            </v-text-field>
                          </v-flex>
                        </template>
                      </template>
                      <template v-if="this.visitorOptions.OPT">
                        <v-flex md12>
                          <v-checkbox
                            v-model="visitorForm.opt"
                            label="メールマガジンなどのサービス情報を希望する"
                            required>
                          </v-checkbox>
                        </v-flex>
                      </template>
                      <template v-if="newVisitorFlg">
                        <v-flex md12>
                          <v-text-field
                            v-model="visitorForm.password"
                            :rules="passwordRules"
                            :append-icon="showPassword ? 'visibility_off' : 'visibility'"
                            :type="showPassword ? 'text' : 'password'"
                            @click:append="showPassword = !showPassword"
                            label="パスワード"
                            required>
                          </v-text-field>
                        </v-flex>
                      </template>
                      <template v-else>
                        <v-flex md6>
                          <v-switch
                            :label="`パスワードを変更`"
                            v-model="toggle"
                          >
                          </v-switch>
                        </v-flex>
                        <v-flex md6 v-if="toggle">
                          <v-text-field
                            v-model="visitorForm.password"
                            :rules="passwordRules"
                            :append-icon="showPassword ? 'visibility_off' : 'visibility'"
                            :type="showPassword ? 'text' : 'password'"
                            @click:append="showPassword = !showPassword"
                            label="パスワード"
                            required>
                          </v-text-field>
                        </v-flex>
                      </template>

                      <template>
                        <v-flex md12>
                          <v-textarea
                            v-model="visitorForm.notifications"
                            label="来場通知先SMS"
                            placeholder="電話番号を改行区切りでご記入ください">
                          </v-textarea>
                        </v-flex>
                      </template>
                      <v-spacer></v-spacer>
                      <v-flex md12 class="text-md-center">
                        <v-btn class="edit-b" @click="close">キャンセル</v-btn>
                        <v-btn color="error" :disabled="!valid" @click="saveVisitor">保存</v-btn>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-form>
          </v-dialog>

          <v-dialog v-model="showSelectTicketDialog" max-width="650px">
            <v-form ref="ticketSelectForm" v-model="ticketSelectValid" lazy-validation>
              <v-card>
                <v-card-title class="modalHeader">
                  <span class="headline">インポート対象参加券選択</span>
                </v-card-title>
                <v-card-text>
                  <v-container grid-list-md>
                    <v-layout wrap>
                      <template v-if="this.newVisitorFlg">
                        <v-flex md12 v-if="ticketsForSelect.length">
                          <v-radio-group v-model="ticketIdForImport" :mandatory="false" :rules="ticketSelectForImportRules">
                            <div class="inputFieldTitle"><template>参加料徴収</template><span class="essential">必須</span></div>
                            <template  v-for="ticket in changeDispOrder" >
                              <v-radio :value="ticket.id" class="ticketRadio" :disabled="ticket.disabled" :key="ticket.id">
                                <template v-if="ticket.paidTicket" slot="label">
                                                  <span class="ticketLabel">
                                                  {{ticket.name}}　<br>参加費：　{{ticket.price | toPriceLocaleString}}円(税込)<br>販売状況： {{ticket.salesStatus}}
                                                  </span>
                                </template>
                                <template v-else slot="label">
                                                  <span class="ticketLabel">
                                                  {{ticket.name}}　<br>参加費：　'無料'<br>販売状況： {{ticket.salesStatus}}
                                                  </span>
                                </template>
                              </v-radio>
                            </template>
                          </v-radio-group>
                          <v-switch style="margin-top: 0" v-model="ignoreStock" :label="`販売状況の残席数を無視して登録する`"></v-switch>
                        </v-flex>
                      </template>
                      <v-spacer></v-spacer>
                      <v-flex md12 class="text-md-center">
                        <v-btn class="edit-b" @click="closeSelectTicket">キャンセル</v-btn>
                        <v-btn color="error" :disabled="!ticketSelectValid" @click="importCsvWithTicket">インポートファイル選択</v-btn>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-form>
          </v-dialog>


          <v-data-table
            :headers="headers"
            :items="filteredVisitors"
            :rows-per-page-items="rowsPerPageItems"
            :pagination.sync="pagination"
            :search="search"
            :custom-filter="customFilter"
            :custom-sort="customSort"
            v-model="selected"
            item-key="id"
            select-all
            must-sort
          >
            <template slot="headerCell" slot-scope="props">
              {{ props.header.text }}
              <div v-if="props.header.value=='hasPassword'">
                <v-select text hide-details small  hide-selected
                          prepend-icon="filter_list"
                          :items="filter_options[props.header.value]"
                          v-model="filters[props.header.value]">
                </v-select>
              </div>
            </template>
            <template slot="item" slot-scope="props">
              <tr :active="props.selected" @click="props.selected = !props.selected">
                <td class="text-md-left" width="20">
                  <v-checkbox
                    :input-value="props.selected"
                    primary
                    hide-details
                  >
                  </v-checkbox>
                </td>
                <td v-if="visitorOptions.LASTNAME" class="text-md-left">&nbsp;{{ props.item.lastName }}&nbsp;{{
                  props.item.firstName }}
                </td>
                <td v-if="visitorOptions.MAIL" class="text-md-left">{{ props.item.username }}</td>
                <td v-if="visitorOptions.OPT" class="text-md-left">{{displayOpt(props.item.opt)}}</td>
                <td class="text-md-left">{{ formatDate(props.item.visitedAt) }}
                  <v-btn
                    icon v-if="props.item.receptionDates.length > 1"
                    @click="showReceptionDatesDialog(props.item.receptionDates)">
                    <v-icon class="iconStyle"
                            lot="activator"
                            small>more_horiz</v-icon>
                  </v-btn></td>
                <td v-if="visitorOptions.TICKET" class="text-md-left">{{ props.item.ticketName }}</td>
                <td v-if="visitorOptions.TICKET" class="text-md-left">{{ props.item.ticketPrice | toPriceLocaleString }}</td>
                <td v-if="visitorOptions.COMPANY" class="text-md-left">{{ props.item.companyName }}</td>
                <td v-if="visitorOptions.DEPT" class="text-md-left">{{ props.item.department }}</td>
                <td v-if="visitorOptions.POSITION" class="text-md-left">{{ props.item.position }}</td>
                <td v-if="visitorOptions.ADDRESS" class="text-md-left">{{ props.item.address }}</td>
                <td v-if="visitorOptions.TEL" class="text-md-left">{{ props.item.phone }}</td>
                <td class="text-md-left">{{ props.item.receptionDates.length }}</td>
                <td class="text-md-left">{{ formatDate(props.item.myPageFirstAccess) }}</td>
                <td class="text-md-left">{{ formatDate(props.item.myPageLastAccess) }}</td>
                <td class="text-md-left">{{ formatDate(props.item.createdAt) }}</td>
                <td class="text-md-left">{{ props.item.inflowSource }}</td>
                <td class="text-md-left">{{ props.item.hasPassword ? "設定済み" : "未設定" }}</td>
                <template v-for="(item, index) in freeItems">
                  <td class="text-md-left">{{ getVisitorFreeItemValue(props.item, item.visitorOption) }}</td>
                </template>
              </tr>
            </template>
          </v-data-table>
          <v-dialog v-model="showReceptionDates" max-width="500px">
            <v-card>
              <v-card-title class="modalHeader">来場日時</v-card-title>
              <div class="text-sm-left" style="margin:20px">
                <table style="font-size:120%">
                  <tr v-for="(value, index) in receptionDates" :key="index">
                    <td class="text-md-right">{{index + 1}}</td>
                    <td class="text-md-center" style="width:15px;">:</td>
                    <td class="text-md-left">{{ formatDate(value) }}</td>
                  </tr>
                </table>
              </div>
              <v-card-actions>
                <v-layout justify-center>
                  <v-btn @click="showReceptionDates = false">閉じる</v-btn>
                </v-layout>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-flex>
        <v-flex lg3 offset-lg9 md3 offset-md9 xs10 offset-xs1 class="apparea">
          <p style="font-size:12px; margin-bottom:3px;">受付アプリはApp Storeでインストールしお使いください。<br>※受付アプリはiOS端末のみの対応となります。</p>
          <p style="margin-bottom:0px;"><a
            href="https://itunes.apple.com/jp/app/tkp-event-planner-%E5%8F%97%E4%BB%98%E3%82%A2%E3%83%97%E3%83%AA/id1438093541?mt=8"
            target="_blank"
            style="display:inline-block;overflow:hidden;background:url(https://linkmaker.itunes.apple.com/assets/shared/badges/ja-jp/appstore-lrg.svg) no-repeat;width:135px;height:40px;"></a>
          </p>
        </v-flex>
      </v-container>

    </template>
    <template v-if="displayEmailForm">
      <mailSendForm @close="closeMailSendForm" :mailTo="mailTo" :toAll="emailDestinationsSelect=='all'" :visitorIds="visitorIds"
                    :eventId="eventId" :mailId="null" :isEdit="false"></mailSendForm>
    </template>
  </v-app>
</template>

<script>
  import Chart from '@/components/Chart.vue'
  import header from '@/components/EventHeader.vue'
  import mailSendForm from '@/components/CreateEmail.vue'
  import {
    getEventVisitor,
    getMe,
    getEvent,
    getTicketRemainCounts,
    exportCsvFile,
    importCsvFile,
    attendVisitor,
    deleteVisitor,
    updateVisitor,
    createVisitor,
    cancelVisitor,
    exportCsvTemplateFile,
    importCsvFileIncludeTicket
  } from '../utils/APIUtils'
  import Rules from "../utils/Rules";

  export default {
    name: 'RegistrationStatus',
    components: {
      'middle_header': header,
      Chart,
      'mailSendForm': mailSendForm
    },
    data() {
      return {
        role: '',
        organizerId: '',
        showReceptionDates: false,
        receptionDates: [],
        selected: [],
        visitors: [],
        search: '',
        rowsPerPageItems: [{text: '10', value: 10}, {text: '100', value: 100}, {
          text: '1000',
          value: 1000
        }, {text: '5000', value: 5000}],
        pagination: {
          rowsPerPage: 1000,
          sortBy: 'createdAt',
          descending: true,
        },

        viewMenu: false,
        top: '0px',
        left: '0px',

        mailTo: '',
        visitorIds: '',
        eventId: null,
        eventData: {},
        eventName: '',
        eventDate: '',
        eventFacility: '',
        registeredNum: 0,
        visitedNum: 0,

        headers: [],
        visitorOptions: {
          LASTNAME: false,
          DEPT: false,
          MAIL: false,
          COMPANY: false,
          POSITION: false,
          OPT: false,
          ADDRESS: false,
          TEL: false,
          TICKET: false
        },
        freeItems: [],

        inputFormTitle: '',
        newVisitorFlg: true,
        toggle: false,
        valid: true,
        showVisitorDialog: false,
        showPassword: false,
        visitorForm: {},
        ignoreStock: false,
        ticketRemainCountMap:{},
        ticketsForSelect:[],

        ticketSelectValid: true,
        showSelectTicketDialog: false,
        ticketIdForImport: null,

        displayEmailForm: false,
        displayEmailDestinationsSelectDialog: false,
        emailDestinationsSelect: 'selected',

        // グラフ描画用データ
        chartData: {
          // ラベル
          labels: ["来場者", "未来場者"],
          // データ詳細
          datasets: [{
            data: [0, 0],
            backgroundColor: [
              'rgba(51, 168, 236, 1)',
              'rgba(210, 210, 210, 1)'
            ]
          }]
        },
        // グラフオプション
        options: {
          cutoutPercentage: 75,
          legend: {
            display: false
          }
        },
        csvUrl: '',
        csvTemplateUrl: '',
        importErrMsgs: '',
        importErrDialog: false,
        mailAddressRules: [
          v => !!v || 'メールアドレスは必須です',
          v => /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'メールアドレスの形式で入力を行ってください。'
        ],
        passwordRules: [
          v => !!v || "パスワードは必須です",
          v => (v && v.length <= 55 && v.length >= 8 && /^(?=.*?[a-z])(?=.*?\d)[a-z\d]{8,55}$/i.test(v)) || 'パスワードは半角英字・数字をどちらも使用した文字列で、8-55文字以内で設定してください'
        ],
        lastNameRules: [
          v => !!v || "姓は必須です",
          v => (v && v.length <= 128) || '128文字以下で入力してください'
        ],
        firstNameRules: [
          v => !!v || "名は必須です",
          v => (v && v.length <= 128) || '128文字以下で入力してください'
        ],
        companyNameRules: [
          v => !!v || "会社名は必須です",
          v => (v && v.length <= 128) || '128文字以下で入力してください'
        ],
        departmentRules: [
          v => !!v || "部署名は必須です",
          v => (v && v.length <= 128) || '128文字以下で入力してください'
        ],
        positionRules: [
          v => !!v || "役職は必須です",
          v => (v && v.length <= 128) || '128文字以下で入力してください'
        ],
        addressRules: [
          v => !!v || "住所は必須です",
          v => (v && v.length <= 128) || '128文字以下で入力してください'
        ],
        phoneRules: [
          v => !!v || "TELは必須です。",
          v => (v && /^(?:\+?\d+-)?\d+(?:-\d+){2}$|^\+?\d+$/i.test(v)) || '電話番号のフォーマットで入力してください'
        ],
        freeItemRules: [],
        ticketSelectRules: [
          v => !!v || "選択してください。",
        ],
        ticketSelectForImportRules: [
          v => !!v || "選択してください。",
        ],
        filter_options: {
          hasPassword: [{
            text: "すべて",
            value: null
          }, {
            text: "設定済み",
            value: true
          }, {
            text: "未設定",
            value: false

          }]
        },
        filters:{
          hasPassword: null
        }
      }
    },
    watch:{
      ignoreStock() {
        this.setTicketStatus()
      },
    },
    filters: {
      toPriceLocaleString : function (value){
        if(!value){
          return 0;
        }
        return value.toLocaleString('ja-JP', {"style": "currency", "currency": "JPY"});
      },
      localString: function(value) {
        if (!value) {
          return 0;
        }
        return value.toLocaleString();
      },
      toPriceLocaleString: function(value) {
        if (!value) {
          return 0;
        }
        return value.toLocaleString("ja-JP", {
          style: "currency",
          currency: "JPY"
        });
      }
    },
    methods: {
      getVisitorFreeItemValue(visitor, visitorOption) {
        return visitor.freeItemValuesMap.get(visitorOption)
      },
      checkCanImportCsv() {
        if (this.visitorOptions.TICKET) {
          this.showSelectTicketDialog = true
        } else {
          document.getElementById('csvFile').click()
        }
      },
      importCsvWithTicket(){
        if (this.$refs.ticketSelectForm.validate()) {
          document.getElementById('csvFile').click()
          this.showSelectTicketDialog = false
        }
      },
      closeSelectTicket() {
        this.showSelectTicketDialog = false
        this.ticketIdForImport = null
        this.ignoreStock = false
      },
      importCsv() {
        let input = document.getElementById("csvFile")
        let file = input.files[0]

        if (file === null || file === undefined) {
          return;
        }
        if (!confirm("インポートを実行するにはメールアドレス、姓、名が必須となります。\nデータの取り込みを開始してもよろしいでしょうか？")) {
          this.clearInputFile()
          return
        }
        if (!this.visitorOptions.TICKET) {
          importCsvFile({file: file, eventId: this.eventId}).then(res => {
            if (res.data.errList.length > 0) {
              this.importErrMsgs = res.data.errList
              this.importErrDialog = true
            }
            alert(res.data.savedCount + "件のデータの取り込みが完了しました。")
            this.clearInputFile()
            this.loadEventVisitor()
          }).catch(err => {
            this.clearInputFile()
            alert("データの取り込みに失敗しました。")
          })
        } else {
          importCsvFileIncludeTicket({
            file: file,
            eventId: this.eventId,
            ticketId: this.ticketIdForImport,
            ignoreTicketStock: this.ignoreStock
          }).then(res => {
            if (res.data.errList.length > 0) {
              this.importErrMsgs = res.data.errList
              this.importErrDialog = true
            }
            alert(res.data.savedCount + "件のデータの取り込みが完了しました。")
            this.clearInputFile()
            this.loadEventVisitor()
          }).catch(err => {
            switch (err.response.status) {
              case 410:
                alert("在庫を超過する件数を登録しようとしています。データの取り込みに失敗しました。");
                break;
              default:
                alert("データの取り込みに失敗しました。")
            }
            this.clearInputFile()
          })
        }
      },
      clearInputFile() {
        document.getElementById("csvFile").value = ''
        this.ticketIdForImport = null
        this.ignoreStock = false
      },
      openEmailDestinationSelectDialog() {
        this.emailDestinationsSelect = 'selected'
        this.displayEmailDestinationsSelectDialog = true
      },
      open() {
        if(this.emailDestinationsSelect == "selected") {
          var checkBoxSelected = false
          this.mailTo = ''
          this.visitorIds = ''
          for (var i = 0; i < this.selected.length; ++i) {
            if (this.selected[i]) {
              this.mailTo += this.selected[i].username + ";"
              this.visitorIds += this.selected[i].id + ","
              checkBoxSelected = true
            }
          }
          if (!checkBoxSelected) {
            alert("メール配信対象を１つ以上選択してください。")
            return
          }
        }

        this.displayEmailForm = true
        this.displayEmailDestinationsSelectDialog = false
      },
      displayOpt(data) {
        let opt = ''
        if (data) {
          opt = '○';
        }
        return opt
      },
      completionVisitorFreeItemValues() {
        this.visitors.forEach(v => {
          v.freeItemValuesMap = new Map()
          v.freeItems.forEach(o => {
            v.freeItemValuesMap.set(o.visitorOption, o.value)
          })
        })
      },
      setEventRegistrationInfo(visitors) {
        this.registeredNum = visitors.length
        if (visitors.length !== 0) {
          let visitedVisitors = this.visitors.filter(item => item.visitedAt !== null)
          this.visitedNum = visitedVisitors.length
        } else {
          this.visitedNum = 0
        }
        this.chartData.datasets[0].data[0] = this.visitedNum
        this.chartData.datasets[0].data[1] = this.registeredNum - this.visitedNum
        this.chartData = {...this.chartData}
      },
      loadEventVisitor() {
        this.selected = []
        getEventVisitor({eventId: this.eventId})
          .then(res => {
            this.visitors = res.data
            this.completionVisitorFreeItemValues()
            this.setEventRegistrationInfo(this.visitors)
          }).catch(err => {
          alert('データの取得に失敗しました。')
        })
      },
      loadEvent() {
        getEvent({eventId: this.eventId})
          .then(res => {
            this.eventData = res.data
            this.eventName = this.eventData.eventName
            this.eventDate = this.eventData.eventDate
            this.eventFacility = this.eventData.facility.facilityName
            this.setHeaderData()
            this.setInputRule()
            this.clearVisitorForm()
            this.loadEventVisitor()
            // const user = res.data.eventGroup.members.find(member => {
            //   return member.organizer.id === this.organizerId
            // })
            // this.role = user.role
            if(res.data.tickets.length) {
              this.setTicketStatus();
            }
          }).catch(err => {
          console.log("err", err)
          alert('データの取得に失敗しました。')
          // this.$router.push("/")
        })
      },
      setInputRule() {
        let options = this.eventData.visitorOptions || []
        options.forEach(o => {
          switch (o.visitorOption) {
            case "COMPANY":
              this.companyNameRules = []
              if (o.required) this.companyNameRules.push(v => !!v || "会社名は必須です")
              this.companyNameRules.push(v => {
                if (v != null && v != undefined && v != '') {
                  return (v.length <= 128) || "128文字以下で入力してください"
                } else {
                  return true
                }
              })
              break;
            case "DEPT":
              this.departmentRules = []
              if (o.required) this.departmentRules.push(v => !!v || "部署名は必須です")
              this.departmentRules.push(v => {
                if (v != null && v != undefined && v != '') {
                  return (v.length <= 128) || "128文字以下で入力してください"
                } else {
                  return true
                }
              })
              break;
            case "POSITION":
              this.positionRules = []
              if (o.required) this.positionRules.push(v => !!v || "役職は必須です")
              this.positionRules.push(v => {
                if (v != null && v != undefined && v != '') {
                  return (v.length <= 128) || "128文字以下で入力してください"
                } else {
                  return true
                }
              })
              break;
            case "ADDRESS":
              this.addressRules = []
              if (o.required) this.addressRules.push(v => !!v || "住所は必須です")
              this.addressRules.push(v => {
                if (v != null && v != undefined && v != '') {
                  return (v.length <= 128) || "128文字以下で入力してください"
                } else {
                  return true
                }
              })
              break;
            case "TEL":
              this.phoneRules = []
              if (o.required) this.phoneRules.push(v => !!v || "TELは必須です")
              this.phoneRules.push(v => {
                if (v != null && v != undefined && v != '') {
                  return (/^(?:\+?\d+-)?\d+(?:-\d+){2}$|^\+?\d+$/i.test(v)) || "電話番号のフォーマットで入力してください"
                } else {
                  return true
                }
              })
              break;
            case "TICKET":
              this.ticketSelectRules = []
              if (o.required) this.ticketSelectRules.push(v => !!v || "参加券選択は必須です")
            default:
              break;
          }
        });

        this.freeItemRules = []
        this.freeItems.forEach(o => {
          this.freeItemRules.push(Rules.setFreeItemRule(o.required))
        })
      },
      setHeaderData() {
        let options = this.eventData.visitorOptions || []
        let optionsMap = new Map()
        options.forEach(o => {
          optionsMap.set(o.visitorOption, 0)
        })

        let key = "LASTNAME"
          optionsMap.set(key, 1)
          this.headers.push({text: '氏名', value: 'lastName',})
          this.visitorOptions.LASTNAME = true
        key = "FIRSTNAME"
          optionsMap.set(key, 1)
        key = "MAIL"
        if (optionsMap.has(key)) {
          optionsMap.set(key, 1)
          this.headers.push({text: 'メールアドレス', value: 'username'})
          this.visitorOptions.MAIL = true
        }
        key = "MAIL_CONFIRMATION"
        if (optionsMap.has(key)) {
          optionsMap.set(key, 1)
        }
        key = "OPT"
        if (optionsMap.has(key)) {
          optionsMap.set(key, 1)
          this.headers.push({text: 'オプトイン', value: 'opt'})
          this.visitorOptions.OPT = true
        }
        this.headers.push({text: '来場日時', value: 'visitedAt', width:'115px'})
        if(this.eventData.tickets.length > 0) {
          this.headers.push({text: '参加券名称', value: 'ticketName'})
          this.headers.push({text: '購入価格', value: 'ticketPrice'})
          this.visitorOptions.TICKET = true
        }
        key = "COMPANY"
        if (optionsMap.has(key)) {
          optionsMap.set(key, 1)
          this.headers.push({text: '会社名', value: 'companyName'})
          this.visitorOptions.COMPANY = true
        }
        key = "DEPT"
        if (optionsMap.has(key)) {
          optionsMap.set(key, 1)
          this.headers.push({text: '部署名', value: 'department'})
          this.visitorOptions.DEPT = true
        }
        key = "POSITION"
        if (optionsMap.has(key)) {
          optionsMap.set(key, 1)
          this.headers.push({text: '役職', value: 'position'})
          this.visitorOptions.POSITION = true
        }
        key = "ADDRESS"
        if (optionsMap.has(key)) {
          optionsMap.set(key, 1)
          this.headers.push({text: '住所', value: 'address'})
          this.visitorOptions.ADDRESS = true
        }
        key = "TEL"
        if (optionsMap.has(key)) {
          optionsMap.set(key, 1)
          this.headers.push({text: 'TEL', value: 'phone'})
          this.visitorOptions.TEL = true
        }

        this.headers.push({text: '来場回数', value: 'receptionDates.length',width:'115px'})
        this.headers.push({text: 'MyPage初回閲覧', value: 'myPageFirstAccess', width:'115px'})
        this.headers.push({text: 'MyPage最終閲覧', value: 'myPageLastAccess', width:'115px'})
        this.headers.push({text: '登録日時', value: 'createdAt', width:'115px'})
        this.headers.push({text: '流入媒体', value: 'inflowSource', width:'115px'})
        this.headers.push({text: 'パスワード設定済み', value: 'hasPassword', sortable: false, width:'115px'})

        this.freeItems = []
        options.forEach(o => {
          if(!o.active) return

          if(optionsMap.get(o.visitorOption) == 0) {
            this.headers.push({text: o.manageLabel, value: o.visitorOption})
            this.freeItems.push(o)
          }
        })
      },
      formatDate(date) {
        return date ? new Date(date).toLocaleString() : date;
      },
      checkInVisitor() {
        if (confirm('選択した登録者の来場登録を行ってもよろしいですか？')) {
          var checkBoxSelected = false;
          this.visitorIds = ''
          for (var i = 0; i < this.selected.length; ++i) {
            if (this.selected[i]) {
              this.visitorIds += this.selected[i].id + ","
              checkBoxSelected = true
            }
          }
          if (checkBoxSelected) {
            attendVisitor({data: this.visitorIds.split(",").filter(v => v !== ""), eventId: this.eventId})
              .then(res => {
                this.loadEventVisitor()
                alert('受付処理が完了しました。')
              }).catch(err => {
              console.log("err", err)
              alert('受付処理に失敗しました。')
            })
          } else {
            alert("来場受付者を１つ以上選択してください。")
          }
        }
      },
      clearVisitorForm() {
        //入力情報クリア処理
        this.visitorForm = {
          id: null,
          ticketId: null,
          username: null,
          password: null,
          firstName: null,
          lastName: null,
          companyName: null,
          department: null,
          position: null,
          address: null,
          phone: null,
          opt: false,
          eventId: null,
          notifications: "",
          ignoreTicketStock: false,
          freeItems: []
        }

        this.freeItems.forEach(o => {
          this.visitorForm.freeItems.push({
            visitorOption: o.visitorOption,
            value: null
          })
        })
      },
      openAddNewVisitor() {
        this.clearVisitorForm()

        this.newVisitorFlg = true
        this.inputFormTitle = "来場者新規登録"
        this.showVisitorDialog = true;
        this.visitorForm.eventId = this.eventId
        this.visitorForm.notifications = ""
      },
      openEditVisitor() {
        this.clearVisitorForm()

        this.newVisitorFlg = false
        var checkBoxSelected = false;
        this.visitorIds = ''
        if (this.selected.length === 1) {
          this.inputFormTitle = "来場者情報編集"

          //Set visitor info
          this.visitorForm.id = this.selected[0].id
          this.visitorForm.username = null
          this.visitorForm.password = null
          this.visitorForm.firstName = this.selected[0].firstName
          this.visitorForm.lastName = this.selected[0].lastName
          this.visitorForm.companyName = this.selected[0].companyName
          this.visitorForm.department = this.selected[0].department
          this.visitorForm.position = this.selected[0].position
          this.visitorForm.address = this.selected[0].address
          this.visitorForm.phone = this.selected[0].phone
          this.visitorForm.opt = this.selected[0].opt
          this.visitorForm.eventId = this.eventId
          this.visitorForm.notifications = this.selected[0].notifications

          this.visitorForm.freeItems = []
          this.freeItems.forEach(o => {
            this.visitorForm.freeItems.push({
              visitorOption: o.visitorOption,
              value: this.selected[0].freeItemValuesMap.get(o.visitorOption)
            })
          })

          this.showVisitorDialog = true;
        } else {
          alert("編集対象のみ選択してください。")
        }
      },
      deleteEditor() {
        var checkBoxSelected = false;
        let hasPaidTicket = false;
        this.visitorIds = ''
        for (var i = 0; i < this.selected.length; ++i) {
          if (this.selected[i]) {
            this.visitorIds += this.selected[i].id + ","
            checkBoxSelected = true
            if (this.selected[i].ticketPrice > 0) {
              hasPaidTicket = true
            }
          }
        }
        if(hasPaidTicket) {
          alert('参加料徴収を行っている登録者が含まれるため、削除できません。')
        } else {
          if (confirm('登録者情報の削除をしてもよろしいですか？該当の登録者に関するすべてのデータが削除され、履歴の確認ができなくなります。')) {
            this.executeDelete(checkBoxSelected)
          }
        }
      },
      executeDelete(checkBoxSelected) {
        if (checkBoxSelected) {
          deleteVisitor({data: this.visitorIds.split(",").filter(v => v !== ""), eventId: this.eventId})
            .then(res => {
              this.loadEventVisitor()
              if(this.eventData.tickets.length) {
                this.setTicketStatus();
              }
              alert('削除処理が完了しました。')
            }).catch(err => {
            console.log("err", err)
            alert('削除処理に失敗しました。')
          })
        } else {
          alert("削除対象を１つ以上選択してください。")
        }
      },
      checkCancel() {
        var checkBoxSelected = false;
        let hasPaidTicket = false;
        this.visitorIds = ''
        for (var i = 0; i < this.selected.length; ++i) {
          if (this.selected[i]) {
            this.visitorIds += this.selected[i].id + ","
            checkBoxSelected = true
            if (this.selected[i].ticketPrice > 0) {
              hasPaidTicket = true
            }
          }
        }
        if (hasPaidTicket) {
          if (confirm('参加料徴収を行っている登録者が含まれます。キャンセルしてもよろしいですか？　※別途Square上で決済キャンセル処理が必要です。')) {
            this.cancel(checkBoxSelected)
          }
        } else {
          if (confirm('登録者の申し込みをキャンセルしてもよろしいですか？')) {
            this.cancel(checkBoxSelected)
          }
        }
      },
      cancel(checkBoxSelected) {
        if (checkBoxSelected) {
          cancelVisitor({data: this.visitorIds.split(",").filter(v => v !== ""), eventId: this.eventId})
            .then(res => {
              this.loadEventVisitor()
              alert('申し込みのキャンセルが完了しました。')
              if(this.eventData.tickets.length) {
                this.setTicketStatus();
              }
            }).catch(err => {
            console.log("err", err)
            alert('申し込みのキャンセルに失敗しました。')
          })
        } else {
          alert("申し込みのキャンセル対象を１つ以上選択してください。")
        }
      },
      close() {
        this.showVisitorDialog = false
      },
      closeMailSendForm() {
        this.displayEmailForm = false
      },
      saveVisitor() {
        if (this.$refs.form.validate()) {
          if (this.newVisitorFlg) {
            if (confirm('来場者の登録をしてもよろしいですか？')) {
              this.visitorForm.ignoreTicketStock = this.ignoreStock
              createVisitor({data: this.visitorForm})
                .then(res => {
                  this.loadEventVisitor()
                  alert('登録処理が完了しました。')
                  if (this.eventData.tickets) {
                    this.setTicketStatus()
                  }
                  this.close()
                }).catch(err => {
                console.log("err", err)
                if (err.response.status === 409) {
                  alert("入力されたメールアドレスは既に登録されています。")
                  this.close()
                } else {
                  alert('登録処理に失敗しました。')
                  this.close()
                }
              })
            }
          } else {
            if (confirm('来場者情報の編集をしてもよろしいですか？')) {
              updateVisitor({data: this.visitorForm})
                .then(res => {
                  this.loadEventVisitor()
                  alert('編集処理が完了しました。')
                  this.close()
                }).catch(err => {
                console.log("err", err)
                alert('編集処理に失敗しました。')
                this.close()
              })
            }
          }

        }
      },
      showReceptionDatesDialog(item) {
        this.showReceptionDates = true
        this.receptionDates = item
      },
      setTicketStatus() {
        this.ticketsForSelect = []
        getTicketRemainCounts({eventId: this.eventId}).then(res => {
          let ticketRemainCountsRes = res.data.counts;

          this.ticketRemainCountMap = new Map();
          ticketRemainCountsRes.forEach(record => {
            this.ticketRemainCountMap.set(record.ticketId, record.count);
          }, this);

          this.eventData.tickets.forEach(ticket => {
            ticket.remainCount = this.ticketRemainCountMap.get(ticket.id);
            let res = this.makeTicketStatus(
              ticket.paidTicket,
              ticket.amount,
              ticket.remainCount
            );
            ticket.salesStatus = res.salesStatus;
            ticket.disabled = res.disabled;

            this.ticketsForSelect.push(ticket);
            this.ticketIdForImport = null
          }, this)
        }).catch(err => {
          console.log("err",err)
          //this.$router.push({path: '/Error'})
        })
      },
      makeTicketStatus(paidTicket, limitCount, remainCount){
        let salesStatus = "";
        let disabled = false;

        if (paidTicket){
          disabled = true;
          if (remainCount <= 0){
            salesStatus = "満席　管理者画面から参加料徴収は行えません。"
          } else {
            salesStatus = "残 " + remainCount + " 席　管理者画面から参加料徴収は行えません。"
          }
        } else {
          if (remainCount <= 0) {
            salesStatus = "☓　満席";
            disabled = this.ignoreStock ? false : true;
          } else {
            salesStatus = "残 " + remainCount + " 席";
          }
        }
        if(!this.ignoreStock){
          this.visitorForm.ticketId = null
        }

        return {salesStatus, disabled};
      },
      isIndexFreeItem(index) {
        return (this.freeItems.filter(o => (o.visitorOption == index)).length > 0)
      },
      customSort(items, index, isDesc) {
        let isFreeItem = this.isIndexFreeItem(index)
        items.sort((a, b) => {
          let av,bv

          if(isFreeItem) {
            av = a.freeItemValuesMap.get(index)
            bv = b.freeItemValuesMap.get(index)
          } else if(index == 'lastName') {
            av = a.lastName + " " + a.firstName
            bv = b.lastName + " " + b.firstName
          } else {
            av = a[index]
            bv = b[index]
          }

          av = (av) ? av : ''
          bv = (bv) ? bv : ''

          if (!isDesc) {
            return av < bv ? -1 : 1
          } else {
            return bv < av ? -1 : 1
          }
        })
        return items
      },
      customFilter(items, searchWord, filter, headers) {
        let word = searchWord.trim();
        if(word === "") return items;

        let loweredWord = word.toLowerCase();
        return items.filter(function(item){
          if(this.selected.some(e => e.id == item.id)) return true

          if(this.includesIgnoreCase(item.username, word, loweredWord)) return true

          let fullName = item.lastName + ' ' + item.firstName;
          if(this.includesIgnoreCase(fullName, word, loweredWord)) return true

          if(this.includesIgnoreCase(item.ticketName, word, loweredWord)) return true

          if(this.visitorOptions.COMPANY) {
            if(this.includesIgnoreCase(item.companyName, word, loweredWord)) return true
          }
          if(this.visitorOptions.DEPT) {
            if(this.includesIgnoreCase(item.department, word, loweredWord)) return true
          }
          if(this.visitorOptions.POSITION) {
            if(this.includesIgnoreCase(item.position, word, loweredWord)) return true
          }
          if(this.visitorOptions.ADDRESS) {
            if(this.includesIgnoreCase(item.address, word, loweredWord)) return true
          }
          if(this.visitorOptions.TEL) {
            if(this.includesIgnoreCase(item.phone, word, loweredWord)) return true
          }

          if(this.freeItems.filter(o => {
            if(!o.active) return false

            return (this.includesIgnoreCase(item.freeItemValuesMap.get(o.visitorOption), word, loweredWord))
          }).length > 0) {
            return true
          }

          return false
        }, this)
      },
      includesIgnoreCase(heystack, needle, loweredNeedle) {
        if(!heystack && heystack != 0) return false

        if(heystack.includes(needle)) return true
        if(heystack.toLowerCase().includes(loweredNeedle)) return true

        return false
      }
    },
    mounted() {
      // this.rowsPerPageItems.shift();
      this.eventId = this.$route.query.id || this.$route.params.id
      let headers = {accountType: 'organizer'};
      getMe({headers: headers, accountType: 'organizer'})
        .then(res => {
          this.organizerId = res.data.id
          this.loadEvent();
          this.csvUrl = exportCsvFile({eventId: this.eventId});
          this.csvTemplateUrl = exportCsvTemplateFile({eventId: this.eventId});
        }).catch(err => {
          console.log(err)
        // this.$router.push('/')
      })
    },
    computed:{
      filteredVisitors(){
        return this.visitors.filter((v) => {
          return this.filters["hasPassword"] == null || v.hasPassword == this.filters["hasPassword"];
        })
      },
      changeDispOrder() {
        return this.ticketsForSelect.sort((a, b) => {
          return (a.orderPriority > b.orderPriority) ? 1 : (a.orderPriority < b.orderPriority) ? -1 : 0;
        });
      }
    }
  }

</script>
<style scoped>
  #inspire {
    color: #666666;
    background-color: white;
  }

  .title {
    text-align: left;
    margin-left: 17px;
  }

  .titleName {
    display: inline-block;
    vertical-align: top;
    padding-top: 9px;
    padding-left: 5px;
  }

  td {
    color: #666666;
    white-space: nowrap;
  }

  .graphAttrTitle {
    font-size: 105%;
  }

  .salesNum {
    color: #33a8ec;
    font-size: 200%;
    font-weight: 300;
  }

  .salesNumSpacer {
    color: #cccccc;
    font-size: 200%;
    font-weight: 50;
  }

  .salesNumMax {
    color: grey;
    font-size: 100%;
    font-weight: 50;
    margin-left: -10px;
  }

  .modalHeader {
    background-color: #263238;
    color: white;
    height: 70px;
    font-size: 160%;
    font-weight: bold;
    filter: alpha(opacity=100);
    -moz-opacity: 1;
    opacity: 1;
  }

  .iconStyle {
    color: rgba(0, 0, 0, 0.54) !important;
  }

  .apparea {
    background-color: #FAFAFA;
    padding: 0px;
    border: solid 1px #BDBDBD;
    margin-top: 10px;
  }

  .essential{
    background-color: #f44336;
    color: white;
    font-size: 13px;
    font-weight: bold;
    padding: 4px 0.5em;
    vertical-align: top;
    margin-left: 1.1em;
    border-radius: 10px; /* CSS3草案 */
    -webkit-border-radius: 10px; /* Safari,Google Chrome用 */
    -moz-border-radius: 10px; /* Firefox用 */
  }

  .ticketRadio {
    padding:5px;
    margin-right:0px;
  }
</style>
