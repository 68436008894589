<template>
    <v-app id="inspire" style="background-color: white">
        <v-container fluid>
            <p class="title">{{this.pageTitle}}</p>
            <v-flex md12>
                <v-stepper v-model="e1">
                  <v-stepper-header>
                      <v-stepper-step :complete="e1 > 1" step="1">イベント基本情報</v-stepper-step>
                      <v-divider></v-divider>
                      <v-stepper-step :complete="e1 > 2" step="2">参加料徴収設定</v-stepper-step>
                      <v-divider></v-divider>
                      <v-stepper-step :complete="e1 > 3" step="3">入力項目設定</v-stepper-step>
                      <v-divider></v-divider>
                      <v-stepper-step :complete="e1 > 4" step="4">公開サイト設定</v-stepper-step>
                      <v-divider></v-divider>
                      <v-stepper-step :complete="e1 > 5" step="5">公開サイトプレビュー</v-stepper-step>
                  </v-stepper-header>

                  <v-stepper-items>
                      <!-- イベント基本情報 -->
                      <v-stepper-content step="1" @forward="moveNextStep">
                          <basic-info @forward="moveNextStep" :facilities="facilities" :eventData="eventData" :groups="event_belongingGroups" :optionVcube="optionVcube">
                          </basic-info>
                      </v-stepper-content>

                      <!-- 参加料徴収設定 -->
                      <v-stepper-content step="2">
                         <ticket @forward='moveNextStep' @goBack='goBack' :eventData="eventData" :isCreateNew="isCreateNew">
                         </ticket>
                      </v-stepper-content>

                      <!-- 入力項目設定 -->
                      <v-stepper-content step="3">
                          <user-input @forward='moveNextStep' @goBack='goBack' :eventData="eventData"> </user-input>
                      </v-stepper-content>

                      <!-- 公開サイト設定　-->
                      <v-stepper-content step="4">
                          <site-preview-edit @goBack='goBack' @forward='moveNextStep' :eventData="eventData"></site-preview-edit>
                      </v-stepper-content>

                      <v-stepper-content step="5">
                          <template v-if="ie">
                              <site-preview @goBack='goBack' @publish="publishSite" :facilities="facilities" :eventData="eventData" class="previewHeight"></site-preview>
                          </template>
                          <template v-else>
                              <site-preview @goBack='goBack' @publish="publishSite" :facilities="facilities" :eventData="eventData"></site-preview>
                          </template>
                      </v-stepper-content>

                  </v-stepper-items>
                </v-stepper>
            </v-flex>
        </v-container>
    </v-app>
</template>

<script>
import {
  saveEvent,
  updateEvent,
  getFacilities,
  getMe,
  getEvent,
  getBelongingGroup,
  readyEditEvent
} from '../../utils/APIUtils'
import stepper1 from './EventBasicInfo.vue'
import stepper2 from './TicketInfo.vue'
import stepper3 from './VisitorSignupInputOptions.vue'
import stepper4 from './CoverImageUpload.vue'
import stepper5 from './Preview.vue'
export default {
  components: {
      'basic-info': stepper1,
      'ticket': stepper2,
      'user-input': stepper3,
      'site-preview-edit': stepper4,
      'site-preview': stepper5
  },

  name: 'CreateNewEvent',
  data () {
    return {
      e1: 1,
      ie:false,
      pageTitle:"イベント新規作成",
      eventId:null,
      //イベント基本情報
      eventData: {},
      facilities: [],
      event_belongingGroups:[],
      //公開設定
      uploadedImage: '',
      organizer: {},
      eventCopy:false,
      publishStatus:false,
      isCreateNew:false,
      optionVcube: false
    }
  },
  methods: {
    moveNextStep(data) {
      this.e1++
      this.eventData = {...this.eventData, ...data}
      window.scrollBy(0 - window.scrollX, 0 - window.scrollY)
    },

    goBack(){
      this.e1--
      window.scrollBy(0 - window.scrollX, 0 - window.scrollY)
    },

    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      this.createImage(files[0]);
    },
    // アップロードした画像を表示
    publishSite(data) {
        this.eventData = {...this.eventData, ...data, username: this.organizer.username}
        this.eventData.id = this.$route.query.id
        if(this.eventCopy) this.eventData.id = undefined
        //Edit Event
        if(this.eventData.id !== undefined){
            updateEvent({data: { ...this.eventData, organizerId: this.organizer.id}})
            .then(res => {
                this.$router.push({ path:'RegistrationStatus',query:{id:this.eventData.id}});
            })
            .catch(err => {
              switch(err.response.status) {
                case 412:
                  alert("購入者が存在する参加券は削除できません。再度登録を行ってください。");
                  break;
                default:
                  alert("編集時にエラーが発生しました。再度登録を行ってください。")
                  console.log(err)
              }
            })
        }
        //New Event
        else{
            saveEvent({data: this.eventData})
            .then(res => {
                this.$router.push({ path:'RegistrationStatus',query:{id:res.data.id}});
            })
            .catch(err => {
                if (err.response.status === 400 && err.response.data == 'exceeded plan limit') {
                    alert('プランのイベント作成数上限に達したためイベントの作成に失敗しました。')
                } else {
                    alert("登録時にエラーが発生しました。再度登録を行ってください。")
                    console.log(err)
                }
            })
        }
    },
  },

  mounted() {
    var userAgent = window.navigator.userAgent.toLowerCase();
    if(userAgent.indexOf('trident') != -1 || userAgent.indexOf('msie') != -1) {
        this.ie=true
    }
    this.eventCopy = this.$route.query.cp
    let headers = {accountType: 'organizer'};
    getMe({headers: headers, accountType: 'organizer'})
    .then(res => {
        this.$emit('loggedin', res.data)
        this.organizer = res.data
        this.eventData = {organizerId:this.organizer.id}

        getBelongingGroup()
        .then(res => {
            const groups = res.data.filter(group => {
                return group.role != "Viewer"
            })
            this.event_belongingGroups = groups

            //Edit
            this.eventId = this.$route.query.id
            if(this.eventId !== undefined || this.eventCopy){
                readyEditEvent({ eventId: this.eventId })
                .then(res => {
                    if(this.eventCopy){
                        this.pageTitle = "イベント新規作成"
                    }
                    else{
                        this.pageTitle = "イベント編集"
                    }

                    getEvent({ eventId: this.eventId }).then(res => {
                        this.eventData = {...res.data}

                        this.eventData.startTime = this.eventData.startTime.substring(0, 5).replace(/-/g, ":")
                        this.eventData.endTime = this.eventData.endTime.substring(0, 5).replace(/-/g, ":")
                        let options = this.eventData.visitorOption
                        if(this.eventCopy) this.eventData = {...this.eventData, ...{organizerId:this.organizer.id}}
                    }).catch(err => {
                        console.log(err)
                        this.$router.push("/");
                    })
                }).catch(err => {
                    this.$router.push("/");
                })
            } else {
                this.isCreateNew = true;
            }
        })
        .catch(err => console.log(err))

        getFacilities()
        .then(res => {
            this.facilities = res.data.map(i => ({
                address: i.address,
                venues: i.venues,
                facilityName:i.facilityName,
                id: i.id,
                mapUrl:i.mapUrl,
                googleMapUrl:i.googleMapUrl
            }))
        })
        .catch(err => console.log(err))
    })
    .catch(err => {
        console.log(err)
        this.$router.push('/')
    })
  },
}
</script>

<style scoped>
 p.title {
  font-family: 'Avenir', Helvetica, Arial, YuGothic, sans-serif !important;
 }
 #place {
   text-align: left
 }
 .none{
    box-shadow: none;
 }
 .title{
     text-align: left;
     margin-left: 30px;
     margin-top: 20px;
     font-size: 23px;
 }
 .note{
     text-align: left;
     font-size:12px;
     width:60%;
     color:#666666;
 }
 .input-area{
     margin-bottom: 0px;
 }
 .fee {
    margin: 30px;
}
.free {
    margin: 30px;
}
.previewHeight{
    height:4000px;
}
</style>
