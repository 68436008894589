<template>
  <v-form ref="form" v-if="form">
    <v-container>
      <v-row>
        <v-col>
          <v-select label="受験外国語"
                    v-model="form.language1"
                    :items="languageOptions1"
                    :disabled="submitted"
                    :rules="[rules.required]"></v-select>
        </v-col>
        <v-col cols="2">
          <v-checkbox label="免除申請"
                      v-model="requestFirstLanguageExemption"
                      :disabled="submitted && !requiredResubmit"
                      v-if="form.language1"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-select label="受験第二外国語"
                    v-model="form.language2"
                    :disabled="submitted"
                    :items="languageOptions2"></v-select>
        </v-col>
        <v-col cols="2">
          <v-checkbox label="免除申請"
                      v-model="requestSecondLanguageExemption"
                      readonly
                      :disabled="submitted && !requiredResubmit"
                      v-if="form.language2"></v-checkbox>
        </v-col>
      </v-row>

      <v-radio-group v-if="selectChinese" v-model="form.chineseType"
                     :disabled="submitted" row>
        <template v-slot:label> 中国語を受験する方</template>
        <v-radio label="簡体字" value="簡体字"></v-radio>
        <v-radio label="繁体字" value="繁体字"></v-radio>
      </v-radio-group>

      <v-row v-for="s in nonLanguageSubjects" :key="s.id">
        <v-col>
          <v-text-field :value="s.title" readonly outlined dense></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-checkbox label="免除申請" :value="s.id" v-model="requestOtherSubjectExemption"
                      :disabled="submitted && !requiredResubmit"></v-checkbox>
        </v-col>
      </v-row>


      <h3>希望都市</h3>
      <v-select label="一次試験受験地" v-model="form.writtenExamArea" :items="writtenExamAreasOptions"
                :disabled="submitted && !requiredResubmit"
                :rules="[rules.required]">
      </v-select>
      <v-select label="二次試験受験地" v-model="form.oralExamArea" :items="oralExamAreasOptions"
                :disabled="submitted && !requiredResubmit"
                :rules="[rules.required]"></v-select>

      <h3>免除科目申請</h3>

      <v-data-table class="text-left"
                    :headers="tableHeader"
                    :items="filteredExemptions"
                    :expanded="expandedItems"
                    :items-per-page="-1"
                    item-key="id"
                    hide-default-footer>
        <template v-slot:item.id="{item}">
          <v-checkbox v-model="form.exemptions" :value="item.id"
                      :disabled="submitted && !requiredResubmit"
          ></v-checkbox>
        </template>
        <template v-slot:expanded-item="{headers, item}">
          <td :colspan="headers.length" class="pa-5">

            <p class="text-caption text-left text-pre">{{ item.documents }}</p>

            <template v-if="form.exemptions.includes(item.id) && !!item.schema">
              <v-jsf :schema="item.schema"
                     :options="vjsfOptions"
                     v-model="form.exemptionData[item.id]">
                <template v-slot:観光庁研修終了証明書="{label}">
                    <span v-for="item in getExemptionFiles(item.id)" :key="item.fileId" class="mr-1">
                      <v-chip :href="item.url"
                              :close="!submitted || requiredResubmit"
                              @click:close="removeFromExemptionFiles(item)">
                        {{ item.key|fileName }}
                        </v-chip>
                    </span>
                  <v-file-input show-size
                                :label="label"
                                required
                                multiple
                                :disabled="submitted && !requiredResubmit"
                                :rules="[rules.files(item.id)]"
                                @change="upload($event, item.id)"></v-file-input>
                </template>
              </v-jsf>
            </template>

            <template v-else-if="form.exemptions.includes(item.id)">
                <span v-for="item in getExemptionFiles(item.id)" :key="item.fileId" class="mr-1">
                  <v-chip :href="item.url"
                          :close="!submitted || requiredResubmit"
                          @click:close="removeFromExemptionFiles(item)">
                    {{ item.key|fileName }}
                    </v-chip>
                </span>
              <v-file-input show-size
                            label="ファイルアップロード"
                            required
                            multiple
                            :disabled="submitted && !requiredResubmit"
                            :rules="[rules.files(item.id)]"
                            @change="upload($event, item.id)"
              ></v-file-input>
            </template>
          </td>
        </template>
      </v-data-table>

      <v-row>
        <v-col>
          <v-btn @click.stop="save" v-if="!readonly" color="primary">保存</v-btn>
          <v-btn v-if="!readonly" @click="$router.go(-1)">戻る</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
<script>
import Loading from './Loading'
import axios from 'axios'
import Rules from '../mixins/RulesMixin'
import SubjectSelection from '../mixins/SubjectSelection'
import VJsf from '@koumoul/vjsf/lib/VJsf'

export default {
  components: {Loading, VJsf},
  mixins: [Rules, SubjectSelection],
  props: {
    visitorId: null,
    value: {
      type: Object,
      default: {
        language1: '',
        language2: '',
        chineseType: '',
        exemptions: [],
        exemptionFiles: [],
        writtenExamArea: '',
        oralExamArea: ''
      }
    },
    readonly: {
      type: Boolean,
      default :false
    }
  },
  data () {
    return {
      submitted: false,
      requiredResubmit: false,
      loading: true,
      files: [],
      tableHeader: [
        {text: '申請', value: 'id'},
        {text: '申請の種類', value: 'title'},
        {text: '科目', value: 'subjectName'},
        {text: '免除対象者', value: 'target'},
        // {text: '提出書類', value: 'documents'},
        {text: '免除申請有効期限有無', value: 'hasExpired'},
        {text: '免除申請有効期限', value: 'expiredAt'}
      ],
    }
  },
  async created () {
    this.loading = true
    await this.fetchSubjects()
    this.loading = false
  },
  computed: {
    form: {
      get () {
        if(!this.value) return null;

        this.schemaData = this.value.exemptionData
        if (this.value.language1 && this.value.requestedSubjects.includes(this.value.language1)) {
          this.requestFirstLanguageExemption = true
        }
        this.requestOtherSubjectExemption = this.nonLanguageSubjects
          .filter(v => this.value.requestedSubjects.includes(v.id))
          .map(v => v.id)
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    },
  },
  filters: {
    fileName (v) {
      return v && v.substring(v.lastIndexOf('/') + 1)
    }
  },
  methods: {
    getExemptionFiles (id) {
      return this.form.exemptionFiles.filter(v => v.id == id)
    },
    removeFromExemptionFiles (item) {
      this.form.exemptionFiles = this.form.exemptionFiles.filter(v => v.fileId != item.fileId)
    },
    async upload (file, exemptionId) {
      if (!file) {
        this.form.exemptionFiles = this.form.exemptionFiles.filter(v => v.id != exemptionId)
        return
      }
      if (Array.isArray(file)) {
        this.loading = true
        try {
          for (let i in file) {
            await this.upload(file[i], exemptionId)
          }
        } catch (e) {
          console.error(e)
        }
        this.loading = false
        return
      }
      if (file.size > 20971520) {
        alert('ファイルサイズは20MB以下でアップロードしてください')
        return
      }
      let params = new FormData()
      params.append('file', file)
      let {data} = await axios.post(`/api/visitor/file/upload/${this.visitorId}`, params)
      this.form.exemptionFiles.push({
        id: exemptionId,
        fileId: data.id,
        key: file.name
      })
    },
    getFile (id) {
      console.log(this.files)
      return this.files[id] || null
    },
    save () {
      this.form.requestedSubjects = this.requestedSubjects
      this.$emit('save', this.form)
    },
  }
}

</script>
