<template>
  <v-dialog v-model="showSelf" max-width="700px">
    <v-card>
      <v-card-title class="modalHeader">
        <span class="headline">お知らせ</span>
      </v-card-title>
      <v-card-text>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs12 class="text-md-left text-sm-left text-xs-left">
              <div class="announcement_container" v-if="announcements.length" v-for="item in announcements">
                <div class="announcement_title">
                  <span class="open_date">{{formatDate(item.openDateTime)}}</span>{{item.title}}
                </div>
                <div class="announcement_content">
                  <span>{{item.content}}</span>
                </div>
              </div>
              <div class="announcement_container" v-if="announcements.length == 0">
                <div class="no_announcement">
                  現在、お知らせはありません。
                </div>
              </div>
            </v-flex>

            <v-spacer></v-spacer>
            <v-spacer></v-spacer>

            <v-flex xs12 class="text-md-center">
              <v-btn class="edit-b" @click="showSelf = false">閉じる</v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import moment from 'moment'
  import {getAllOpenAnnouncements} from '@/utils/APIUtils'
  export default {
    name: "AnnouncementDialog",
    data() {
      return {
        showSelf: false,

        announcements: {}
      }
    },
    methods: {
      open() {
        getAllOpenAnnouncements().then(res => {
          this.announcements = res.data
          this.showSelf = true
        })
      },
      formatDate(date) {
        return moment(date).format('YYYY/M/D')
      }
    }
  }
</script>

<style scoped>

  .announcement_container:nth-child(n+2) {
    margin-top: 1.5em;
  }
  .announcement_container:last-child {
    margin-bottom: 2em;
  }
  .announcement_title {
    text-align: left;
    font-weight: bold;
    font-size: 1.3em;
  }
  .announcement_title .open_date {
    margin-right: 1em;
  }
  .announcement_content {
    margin-top: 1em;
  }
  .announcement_content span {
    text-align: left;
    white-space: pre-wrap;
  }
  .no_announcement {
    text-align: center;
  }

</style>
