<template>
  <v-form v-if="form">
    <v-container>
      <v-row>
        <v-col>
          <v-text-field label="姓" v-model="form.lastName" placeholder="姓"
                        :readonly="readonly"
                        :rules="[rules.required]"></v-text-field>
        </v-col>
<!--        <v-col>-->
<!--          <v-text-field label="ミドルネーム" v-model="form.middleName"-->
<!--                        :readonly="readonly"></v-text-field>-->
<!--        </v-col>-->
        <v-col>
          <v-text-field label="名" v-model="form.firstName" placeholder="名"
                        :readonly="readonly"
                        :rules="[rules.required]"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field label="姓(フリガナ)" v-model="form.lastNameKana"
                        :readonly="readonly"
                        :rules="[rules.required, rules.kana]"></v-text-field>
        </v-col>
<!--        <v-col>-->
<!--          <v-text-field label="ミドルネーム(フリガナ)" v-model="form.middleNameKana"-->
<!--                        :readonly="readonly"-->
<!--                        :rules="[rules.kana]"></v-text-field>-->
<!--        </v-col>-->
        <v-col>
          <v-text-field label="名(フリガナ)" v-model="form.firstNameKana"
                        :readonly="readonly"
                        :rules="[rules.required, rules.kana]"></v-text-field>
        </v-col>
      </v-row>

<!--      <v-radio-group-->
<!--        v-model="form.gender"-->
<!--        row-->
<!--        :rules="[rules.required]"-->
<!--        :readonly="readonly"-->
<!--      >-->
<!--        <template v-slot:label>-->
<!--          性別-->
<!--        </template>-->
<!--        <v-radio-->
<!--          label="男"-->
<!--          value="男"-->
<!--        ></v-radio>-->
<!--        <v-radio-->
<!--          label="女"-->
<!--          value="女"-->
<!--        ></v-radio>-->
<!--      </v-radio-group>-->

      <DatePicker v-model="form.birthdate"
                  label="生年月日"
                  baseDate="1995-01-01"
                  :readonly="readonly"
      ></DatePicker>

<!--      <v-select label="本籍(都道府県) ※日本国籍かつ日本居住者の場合"-->
<!--                :readonly="readonly"-->
<!--                v-model="form.permanentAddress" :items="prefectures"></v-select>-->

<!--      <v-row>-->
<!--        <v-col>-->
<!--          <v-select label="国籍 ※外国籍の場合、または日本国籍かつ外国居住者の場合"-->
<!--                    :readonly="readonly"-->
<!--                    v-model="form.nationality" :items="countries"></v-select>-->
<!--        </v-col>-->
<!--        <v-col>-->
<!--          <v-text-field label="国籍（その他・others）" :disabled="!hasSelectNationalityOther"-->
<!--                        v-model="form.nationalityOther"-->
<!--                        :readonly="readonly"></v-text-field>-->
<!--        </v-col>-->
<!--      </v-row>-->
<!--      <v-row>-->
<!--        <v-col>-->
<!--          <v-img :src="form.pictureUrl" width="240" height="320" v-if="form.pictureUrl"></v-img>-->
<!--        </v-col>-->
<!--        &lt;!&ndash;        <v-col>&ndash;&gt;-->
<!--        &lt;!&ndash;&lt;!&ndash;          <v-file-input label="写真" @change="uploadPhoto"></v-file-input>&ndash;&gt;&ndash;&gt;-->
<!--        &lt;!&ndash;        </v-col>&ndash;&gt;-->
<!--        <v-col>-->
<!--          <DatePicker v-model="form.pictureDate" label="写真撮影年月" month-->
<!--                      :readonly="readonly"></DatePicker>-->
<!--        </v-col>-->
<!--      </v-row>-->
      <v-text-field label="郵便番号" v-model="form.zipcode"
                    :readonly="readonly"
                    :rules="[rules.zip]"></v-text-field>
      <v-text-field label="都道府県" v-model="form.prefecture"
                    :readonly="readonly"></v-text-field>
      <v-text-field label="住所" v-model="form.address"
                    :readonly="readonly"></v-text-field>
      <v-text-field label="住所2" v-model="form.address2"
                    :readonly="readonly"></v-text-field>
      <v-text-field label="住所3" v-model="form.address3"
                    :readonly="readonly"></v-text-field>

      <v-text-field label="社名" v-model="form.organization"
                    :readonly="readonly"></v-text-field>
      <v-text-field label="営業所名" v-model="form.salesOffice"
                    :readonly="readonly"></v-text-field>
      <v-text-field label="部署名" v-model="form.department"
                    :readonly="readonly"></v-text-field>

      <v-text-field label="登録行政庁" v-model="form.registrationAdministrativeAgency"
                    :readonly="readonly"></v-text-field>
      <v-text-field label="登録種別" v-model="form.registrationType"
                    :readonly="readonly"></v-text-field>
      <v-text-field label="旅行業登録番号" v-model="form.travelAgencyRegistrationNumber"
                    :readonly="readonly"></v-text-field>
      <v-text-field label="旅行業更新年月日" v-model="form.travelAgencyRegistrationDate"
                    :readonly="readonly"></v-text-field>

      <h3>日中に申込者本人につながる連絡先</h3>

      <v-text-field label="電話番号・携帯番号" v-model="form.tel" :rules="[rules.tel]"
                    :readonly="readonly"></v-text-field>
<!--      <v-text-field label="FAX番号" v-model="form.fax" :rules="[rules.tel]"-->
<!--                    :readonly="readonly"></v-text-field>-->

      <h3>結果通知送付先</h3>
      <v-checkbox v-model="form.notifySameAddress" label="現住所と同様" :readonly="readonly"></v-checkbox>
      <v-text-field label="通知先郵便番号" v-model="form.notifyZipcode" :rules="[rules.zip]"
                    :readonly="readonly"></v-text-field>
      <v-text-field label="通知先住所" v-model="form.notifyAddress"
                    :readonly="readonly"></v-text-field>
      <v-text-field label="通知先住所2" v-model="form.notifyAddress2"
                    :readonly="readonly"></v-text-field>
      <v-text-field label="社名" v-model="form.notifyOrganization"
                    :readonly="readonly"></v-text-field>
      <v-text-field label="勤務先営業所名" v-model="form.notifyOffice"
                    :readonly="readonly"></v-text-field>
      <v-text-field label="勤務先部署" v-model="form.notifyDepartment"
                    :readonly="readonly"></v-text-field>
<!--      <v-text-field label="宛名" v-model="form.notifyAddressLabel"-->
<!--                    :readonly="readonly"></v-text-field>-->
<!--      <v-text-field label="通知先電話番号・携帯番号" v-model="form.notifyTel" :rules="[rules.tel]"-->
<!--                    :readonly="readonly"></v-text-field>-->
<!--      <v-text-field label="通知先FAX番号" v-model="form.notifyFax" :rules="[rules.tel]"-->
<!--                    :readonly="readonly"></v-text-field>-->

<!--      <h3>緊急連絡先</h3>-->
<!--      <v-row>-->
<!--        <v-col>-->
<!--          <v-select label="緊急連絡先種別" v-model="form.urgentType" :items="urgentTypes"-->
<!--                    :readonly="readonly"></v-select>-->
<!--        </v-col>-->
<!--        <v-col>-->
<!--          <v-text-field label="緊急連絡先種別（その他）" v-model="form.urgentTypeOther"-->
<!--                        :readonly="readonly"-->
<!--                        :disabled="form.urgentType != 'その他'"></v-text-field>-->
<!--        </v-col>-->
<!--      </v-row>-->
<!--      <v-text-field label="緊急連絡先電話番号・携帯番号" v-model="form.urgentTel" :rules="[rules.tel]"-->
<!--                    :readonly="readonly"></v-text-field>-->
<!--      <v-text-field label="緊急連絡先FAX番号" v-model="form.urgentFax" :rules="[rules.tel]"-->
<!--                    :readonly="readonly"></v-text-field>-->

<!--      <h3>配慮事項</h3>-->
<!--      <v-textarea label="希望する配慮事項" v-model="form.consideration"-->
<!--                  :readonly="readonly"></v-textarea>-->


<!--      <h3>申込者情報変更に必要な提出書類</h3>-->
<!--      <v-row>-->
<!--        <v-col>-->
<!--          <div v-if="form.documents">-->
<!--        <span v-for="item in form.documents" :key="item.fileId">-->
<!--          <v-chip :href="item.url">-->
<!--          {{ item.key|fileName }}-->
<!--          </v-chip>-->
<!--        </span>-->
<!--            &lt;!&ndash;        <v-file-input show-size&ndash;&gt;-->
<!--            &lt;!&ndash;                      label="ファイルアップロード"&ndash;&gt;-->
<!--            &lt;!&ndash;                      multiple&ndash;&gt;-->
<!--            &lt;!&ndash;                      @change="upload"></v-file-input>&ndash;&gt;-->
<!--          </div>-->
<!--        </v-col>-->
<!--      </v-row>-->

      <v-row>
        <v-col>
          <v-btn @click.stop="save" v-if="!readonly" color="primary">保存</v-btn>
          <v-btn v-if="!readonly" @click="$router.go(-1)">戻る</v-btn>
        </v-col>
      </v-row>

    </v-container>

  </v-form>
</template>
<script>
import {prefectures} from '../../data/prefectures.json'
import countries from '../../data/countries.json'
import DatePicker from './DatePicker'
import Rules from '../mixins/RulesMixin'
import axios from 'axios'

export default {
  components: {DatePicker},
  mixins: [Rules],
  props: {
    value: Object,
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      rules: {},
      // form: {
      //   lastName: '',
      //   firstName: '',
      //   middleName: '',
      //   lastNameKana: '',
      //   firstNameKana: '',
      //   middleNameKana: '',
      //   gender: '',
      //   birthdate: '',
      //   permanentAddress: '',
      //   nationality: '',
      //   picture: '',
      //   pictureDate: '',
      //   pictureUrl: '',
      //   zipcode: '',
      //   address: '',
      //   tel: '',
      //   fax: '',
      //   notifyZipcode: '',
      //   notifyAddress: '',
      //   notifyAddressLabel: '',
      //   notifyTel: '',
      //   notifyFax: '',
      //   urgentType: '',
      //   urgentTypeOther: '',
      //   urgentTel: '',
      //   urgentFax: '',
      //   consideration: '',
      // },
      urgentTypes: [
        '自宅',
        '携帯電話',
        '勤務先',
        'その他'
      ],
    }
  },
  filters: {
    fileName (v) {
      return v && v.substring(v.lastIndexOf('/') + 1)
    }
  },
  computed: {
    form: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    },
    hasSelectNationalityOther () {
      return this.form.nationality === 'その他'
    }
  },
  // watch: {
  //   value (v) {
  //     if (!v) return
  //     this.form = v
  //   }
  // },
  created () {
    this.fetchCountries()
    this.fetchPrefectures()
  },
  methods: {
    async fetchPrefectures () {
      this.prefectures = prefectures.map(v => v.name)
      this.prefectures.unshift('')
    },
    async fetchCountries () {
      this.countries = countries.map(v => v.companyjp)
      this.countries.push('その他')
      this.countries.unshift('')
    },
    save () {
      this.$emit('save', this.form)
    },
    // async save () {
    //   try {
    //     await axios.post(`/api/entry/save/${this.visitorId}`, this.form)
    //     this.$emit('toast', '保存しました')
    //   } catch (e) {
    //     console.error(e)
    //     this.$emit('error', 'エラーが発生しました。しばらくしてから再度お試しください')
    //   }
    // },
  }
}
</script>
