<template>
  <v-app id="inspire">
    <middle_header :eventData=this.eventData mailHistory="true"></middle_header>

    <v-container fluid>
      <v-flex>
        <p class="title">
          <v-icon large>contact_mail</v-icon>
          メール履歴
        </p>

        <v-data-table
          :headers="headers"
          :items="emails"
          item-key="id"
          :rows-per-page-items="rowsPerPageItems"
          :pagination.sync="pagination"
        >
          <template slot="items" slot-scope="props">
            <tr>
              <td class="text-md-left">{{ formatStatus(props.item.status) }}</td>
              <td class="text-md-left">{{ props.item.title }}</td>
              <td class="text-md-left">{{ formatDateTime(props.item.sendDate) }}</td>
              <td class="text-md-left">{{ formatDateTime(props.item.schedule) }}</td>
              <td class="justify-center layout px-0">
                <v-icon
                  small
                  class="mr-2"
                  @click="openMailDetail(props.item.id)"
                >
                  description
                </v-icon>
              </td>　
            </tr>
          </template>
        </v-data-table>

      </v-flex>
    </v-container>
  </v-app>
</template>

<script>
  import moment from 'moment'
  import header from '@/components/EventHeader.vue'
  import {getMe, getEvent, getEmailList, readyEditEvent} from '../utils/APIUtils'
  export default {
    name: 'MailHistory',
    components: {
      'middle_header': header
    },
    data() {
      return {
        role: '',
        organizerId: '',
        eventId: null,
        eventData: {},
        eventName: '',
        eventDate: '',
        eventFacility: '',

        rowsPerPageItems: [25, 50, 100, 1000],
        pagination: {
          rowsPerPage: 100,
          sortBy: 'id',
        },

        headers: [
          {
            text: '配信済',
            value: 'status',
            width: 50
          },
          {
            text: '件名',
            value: 'title',
            width: 500
          },
          {
            text: '配信日時',
            value: 'sendDate'
          },
          {
            text: '予約日時',
            value: 'schedule'
          },
          {
            text: '',
            value: '',
            width: 80
          },
        ],
        emails: [],
      }
    },
    methods: {
      formatDateTime(datetime) {
        if(datetime == null){
          return '-'
        }
        return datetime ? moment(datetime).format('YYYY/M/D HH:mm') : datetime
      },
      formatStatus(status) {
        if(status == 0){
          return '-'
        }
        return status == "202" ? '○' : '☓'
      },
      getMailList() {
        getEmailList({eventId: this.eventId}).then(res =>{
          this.emails = res.data
          }).catch(err => {
          return console.log(err)
        })
      },
      loadEvent() {
        return getEvent({eventId:this.eventId})
          .then(res => {
            this.eventData = res.data
            this.eventName = this.eventData.eventName
            this.eventDate = this.eventData.eventDate
            this.eventFacility = this.eventData.facility.facilityName
            const user = res.data.eventGroup.members.find(member => {
              return member.organizer.id === this.organizerId
            })
            this.role = user.role
          }).catch(err => {
            alert('データの取得に失敗しました。')
          })
      },
      openMailDetail(mailId) {
        this.$router.push({path: 'MailDetail', query: {id: this.eventId, mailId: mailId}});
      },
    },
    mounted(){
      this.eventId = this.$route.query.id
      let headers = {accountType: 'organizer'};
      getMe({headers: headers, accountType:'organizer'})
        .then(res => {
          this.organizerId = res.data.id

          readyEditEvent({eventId: this.eventId})
            .then(res => {
              this.loadEvent()
              this.getMailList()
            })
            .catch(err => {
              this.$router.push({path:'/'})
            })
        }).catch(err => {
        console.log("get me failed", err)
      })
    }
  }

</script>
<style>
  #inspire {
    color: #666666;
    background-color: white;
  }

  .title {
    text-align: left;
    margin-left: 30px;
  }

  td {
    color: #666666;
  }
</style>
