<template>
  <v-app mode="out-in">
    <v-app-bar dense clipped-left app permanent>
<!--      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>-->
      <v-btn to="/" text>旅行業務取扱管理者定期研修 管理画面</v-btn>
      <v-spacer></v-spacer>
      <!--      <v-toolbar-items>-->
      <!--        <v-btn text>{{ name }}</v-btn>-->
      <!--        <v-btn v-if="loggedin" @click='logOut' text color="blue-grey">ログアウト</v-btn>-->
      <!--      </v-toolbar-items>-->

      <v-toolbar-items class="hidden-sm-and-down">
        <v-menu offset-y v-if="loggedin">
          <template v-slot:activator="{on, attrs}">
            <v-btn
              text
              v-bind="attrs"
              v-on="on"
            >
              {{ organization }} {{ name }}
            </v-btn>
          </template>
          <v-list style="text-align: left">
            <v-list-item link>
              <v-list-item-title @click="openOrganizerUpdateDialog">
                ユーザ情報編集
              </v-list-item-title>
            </v-list-item>

<!--            <v-list-item link>-->
<!--              <v-list-item-title-->
<!--                @click="moveToCustomMailTemplates"-->
<!--                v-if="isRoleEditable(role)"-->
<!--              >-->
<!--                カスタムメールテンプレート-->
<!--              </v-list-item-title>-->
<!--            </v-list-item>-->
<!--            <v-list-item link>-->
<!--              <v-list-item-title-->
<!--                @click="moveToOperationLog">-->
<!--                操作履歴-->
<!--              </v-list-item-title>-->
<!--            </v-list-item>-->
<!--            <v-list-item link>-->
<!--              <v-list-item-title-->
<!--                @click="moveToTermsOfServie">-->
<!--                利用規約-->
<!--              </v-list-item-title>-->
<!--            </v-list-item>-->
            <v-list-item link>
              <v-list-item-title
                @click="logOut">
                ログアウト
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
<!--        <v-btn text @click="openAnnouncementDialog">-->
<!--          お知らせ<span v-if="existOpenAnnouncement">({{ lastOpenAnnouncementDate }} 更新)</span>-->
<!--        </v-btn>-->
        <announcement_dialog ref="announcementDialog"></announcement_dialog>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon dark v-on="on" @click="openHelpPage" style="color:#aba9a9;">
              <v-icon>help</v-icon>
            </v-btn>
          </template>
          <span>ヘルプ</span>
        </v-tooltip>
      </v-toolbar-items>

      <v-dialog v-model="dialog" max-width="500px">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card>
            <v-card-title class="modalHeader">
              <span class="headline">オーガナイザ情報編集</span>
            </v-card-title>
            <v-card-text>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex md12>
                    <v-text-field v-model="editedItem.affiliation" :rules="affiliationRules" label="会社名"
                                  required></v-text-field>
                  </v-flex>
                  <v-flex md6>
                    <v-text-field v-model="editedItem.lastName" :rules="lastNameRules" label="姓"
                                  required></v-text-field>
                  </v-flex>
                  <v-flex md6>
                    <v-text-field v-model="editedItem.firstName" :rules="firstNameRules" label="名"
                                  required></v-text-field>
                  </v-flex>
                  <v-flex md6 v-if="isEditWindow">
                    <v-switch
                      :label="`パスワードを変更`"
                      v-model="toggle"
                    >
                    </v-switch>
                  </v-flex>
                  <v-flex md12 v-if="!isEditWindow || isEditWindow&&toggle">
                    <v-text-field v-model="editedItem.password" :rules="passwordRules" label="パスワード" required
                                  type="password"></v-text-field>
                  </v-flex>
                  <v-flex md12 v-if="!isEditWindow || isEditWindow&&toggle">
                    <v-text-field v-model="editedItem.passwordConfirm" :rules="passwordConfirmRules" label="パスワード（確認）"
                                  required type="password"></v-text-field>
                  </v-flex>
                  <v-spacer></v-spacer>
                  <v-flex md12 class="text-md-center">
                    <v-btn class="edit-b" @click="this.closeModal">閉じる</v-btn>
                    <v-btn color="error" :disabled="!valid" @click="submit">保存</v-btn>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
          </v-card>
        </v-form>
      </v-dialog>

      <v-toolbar-items class="hidden-md-and-up">
        <v-menu bottom>
          <template v-slot:activator="{on}">
            <v-btn
              v-on="on"
              dark
              icon
            >
              <v-icon color="black">more_vert</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-tile-content>
              <v-list-tile v-if="loggedin">
                <v-list-tile-title>{{ organization }}</v-list-tile-title>
              </v-list-tile>
              <v-list-tile v-if="loggedin">
                <v-list-tile-title>{{ name }}</v-list-tile-title>
              </v-list-tile>
              <v-list-tile @click='openOrganizerUpdateDialog' v-if="loggedin">
                <v-list-tile-title>ユーザ情報編集</v-list-tile-title>
              </v-list-tile>
              <v-list-tile
                @click='moveToCustomMailTemplates'
                v-if="loggedin && isRoleEditable(role)"
              >
                <v-list-tile-title>カスタムメールテンプレート</v-list-tile-title>
              </v-list-tile>
              <v-list-tile @click='moveToOperationLog' v-if="loggedin">
                <v-list-tile-title>操作履歴</v-list-tile-title>
              </v-list-tile>
              <v-list-tile @click='moveToTermsOfServie' v-if="loggedin">
                <v-list-tile-title>利用規約</v-list-tile-title>
              </v-list-tile>
              <v-list-tile @click='openAnnouncementDialog'>
                <v-list-tile-title>
                  お知らせ<span v-if="existOpenAnnouncement">({{ lastOpenAnnouncementDate }} 更新)</span>
                </v-list-tile-title>
              </v-list-tile>
              <v-list-tile @click='openHelpPage'>
                <v-list-tile-title>ヘルプ</v-list-tile-title>
              </v-list-tile>
              <v-list-tile @click='logOut' v-if="loggedin">
                <v-list-tile-title>ログアウト</v-list-tile-title>
              </v-list-tile>
            </v-list-tile-content>
          </v-list>
        </v-menu>
      </v-toolbar-items>
    </v-app-bar>

<!--    <v-navigation-drawer-->
<!--      v-model="drawer"-->
<!--      clipped permanent app-->
<!--    >-->
<!--      <v-list-->
<!--        dense-->
<!--        nav-->
<!--      >-->
<!--        <v-list-item-->
<!--          v-for="item in menus"-->
<!--          :key="item.title"-->
<!--          link-->
<!--        >-->
<!--          <v-list-item-icon>-->
<!--            <v-icon>{{ item.icon }}</v-icon>-->
<!--          </v-list-item-icon>-->
<!--          <v-list-item-content>-->
<!--            <v-list-item-title>{{ item.title }}</v-list-item-title>-->
<!--          </v-list-item-content>-->
<!--        </v-list-item>-->
<!--      </v-list>-->
<!--    </v-navigation-drawer>-->

    <v-main>
      <router-view @loggedin="loadUser"
                   @login="clean"

                   @toast="toast"
                   @error="error"
      ></router-view>
    </v-main>
    <v-snackbar v-model="showToast" top :color="toastColor">
      {{ toastText }}
    </v-snackbar>
  </v-app>
</template>

<script>
import moment from 'moment'
import announcementDialog from '@/components/announcement/AnnouncementDialog.vue'
import {getLastOpenAnnouncement, signout, updateOrganizer} from '../utils/APIUtils.js'
import {mapGetters} from 'vuex'

export default {
  name: 'App',
  components: {
    'announcement_dialog': announcementDialog
  },
  data () {
    return {
      menus: [
        { title: '登録者管理', icon: 'mdi-view-dashboard' },
        { title: 'お知らせ管理', icon: 'mdi-view-dashboard' },
        { title: 'マスタ管理', icon: 'mdi-view-dashboard' },
        { title: '権限管理', icon: 'mdi-view-dashboard' },
      ],
      organization: '',
      role: '',
      name: '',
      firstName: '',
      lastName: '',
      organizerId: '',
      userName: '',
      // loggedin: false,
      dialog: false,
      toggle: false,
      valid: false,
      isEditWindow: true,
      editedItem: {
        id: '',
        affiliation: '',
        firstName: '',
        lastName: '',
        username: '',
        password: '',
        passwordConfirm: '',
      },
      affiliationRules: [
        v => !!v || '会社名は必須です',
        v => (v && v.length <= 128) || '128文字以下にしてください'
      ],
      firstNameRules: [
        v => !!v || '名は必須です',
        v => (v && v.length <= 128) || '128文字以下にしてください'
      ],
      lastNameRules: [
        v => !!v || '姓は必須です',
        v => (v && v.length <= 128) || '128文字以下にしてください'
      ],
      usernameRules: [
        v => !!v || 'メールアドレスは必須です',
        v => (v && v.length <= 128) || '128文字以下にしてください',
        v => /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'メールアドレスの形式で入力を行ってください。'
      ],
      passwordRules: [
        v => !!v || 'パスワードは必須です',
        v => (v && v.length <= 55 && v.length >= 8 && /^(?=.*?[a-z])(?=.*?\d)[a-z\d]{8,55}$/i.test(v)) || 'パスワードは半角英字・数字をどちらも使用した文字列で、8-55文字以内で設定してください'
      ],
      passwordConfirmRules: [
        v => !!v || 'パスワードは必須です',
        v => (v && v.length <= 55 && v.length >= 8 && /^(?=.*?[a-z])(?=.*?\d)[a-z\d]{8,55}$/i.test(v)) || 'パスワードは半角英字・数字をどちらも使用した文字列で、8-55文字以内で設定してください',
        v => v == this.editedItem.password || '入力したパスワードが一致しません。'
      ],

      lastOpenAnnouncementDate: null,
      existOpenAnnouncement: false,
      squareAccountType: '',
      hasOptionZoom: false,
      showToast: false,
      toastText: null,
      toastColor: 'success',
    }
  },
  methods: {
    toast (v) {
      this.toastText = v
      this.toastColor = 'success'
      this.showToast = true
    },
    error (v) {
      this.toastText = v
      this.toastColor = 'error'
      this.showToast = true
    },
    isRoleEditable (role) {
      return (role == 'Owner' || role == 'Member')
    },
    openOrganizerUpdateDialog () {
      this.dialog = true
    },
    openAnnouncementDialog () {
      this.$refs.announcementDialog.open()
    },
    closeNotificationDialog () {
      this.notificationDialog = false
    },
    loadUser (data) {
      this.organization = data.affiliation
      this.firstName = data.firstName
      this.lastName = data.lastName
      this.name = data.lastName + ' ' + data.firstName
      this.userId = data.id
      this.organizerId = data.id
      this.userName = data.username
      // this.loggedin = true
      this.role = data.role

      this.editedItem.id = this.organizerId
      this.editedItem.affiliation = this.organization
      this.editedItem.firstName = this.firstName
      this.editedItem.lastName = this.lastName
      this.editedItem.username = this.userName
    },
    clean () {
      this.organization = '',
        this.name = ''
    },
    toPreview (data) {
      this.eventData = data
    },
    logOut () {
      signout()
        .then(res => {
          this.loggedin = false
          this.$router.push({path: '/Signout'})
        })
        .catch(err => {
          console.log('signout catch ', err)
        })
    },
    submit () {
      if (this.$refs.form.validate() && confirm('データを登録してもよろしいですか？')) {
        //Organizerの更新
        if (!this.toggle) this.editedItem.password = null
        updateOrganizer({
          data: this.editedItem
        }).then(res => {
          alert('アカウント情報を更新しました。')
          this.organization = this.editedItem.affiliation
          this.firstName = this.editedItem.firstName
          this.lastName = this.editedItem.lastName
          this.name = this.lastName + ' ' + this.firstName
          this.closeModal()
        }).catch(err => {
          console.log('err', err)
          alert('アカウント情報の更新に失敗しました。')
        })
      }
    },
    closeModal () {
      this.dialog = false
      this.toggle = false

      this.editedItem.affiliation = this.organization
      this.editedItem.firstName = this.firstName
      this.editedItem.lastName = this.lastName
      this.editedItem.username = this.userName
      this.editedItem.password = ''
      this.editedItem.passwordConfirm = ''
    },
    moveToCustomMailTemplates () {
      this.$router.push({path: '/CustomMailTemplates'})
    },
    moveToOperationLog () {
      this.$router.push({path: '/OperationLog'})
    },
    moveToTermsOfServie () {
      this.$router.push({path: '/TermsOfService'})
    },
    openHelpPage () {
      window.open('https://teachme.jp/44555/f/shikenjnto', '_blank')
    },
    setLastOpenAnnounceDate () {
      getLastOpenAnnouncement().then(res => {
        switch (res.status) {
          case 200:
            this.lastOpenAnnouncementDate = moment(res.data.openDateTime).format('YYYY/M/D')
            this.existOpenAnnouncement = true
            break
          case 204:
            this.lastOpenAnnouncementDate = null
            this.existOpenAnnouncement = false
            break
          default:
            console.log('status on announcement/lastOpen:', res.status)
            alert('エラーが発生しました')
            break
        }
      }).catch(err => {
        console.log('err', err)
      })
    },
    // getSquareAccountType() {
    //   getSquareAccountType().then(res => {
    //     this.squareAccountType = res.data;
    //   }).catch(err => {
    //     console.log("err", err)
    //     alert('決済情報の確認に失敗しました。')
    //   })
    // },
    checkHasOptionZoom () {
      hasOptionZoom().then(res => {
        this.hasOptionZoom = res.data
      })
    }
  },
  computed: {
  ...mapGetters([
      'loggedin', 'data'
    ]),
  },
  async mounted () {
    await this.$store.dispatch("getMe")
  },
  watch:{
    data(value){
      this.loadUser(value)
    }
  },
  updated () {
    this.setLastOpenAnnounceDate()
  }
}
</script>

<style>
* {
  /* macOs catalina + Chromeでsans-serifが明朝体になってしまう事象の対応 */
  font-family: 'Avenir', Helvetica, Arial, YuGothic, sans-serif;
}

.headline {
  /* macOs catalina + Chromeでsans-serifが明朝体になってしまう事象の対応 */
  /* vuetify側でimportant指定されていたためimportant指定して上書く */
  font-family: 'Avenir', Helvetica, Arial, YuGothic, sans-serif !important;
}

#app {
  font-family: 'Avenir', Helvetica, Arial, YuGothic, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #666666;
}

.headerlogo {
  width: 200px;
  margin-top: 5px;
}

.modalHeader {
  background-color: #263238;
  color: white;
  height: 70px;
  font-size: 160%;
  font-weight: bold;
  filter: alpha(opacity=100);
  -moz-opacity: 1;
  opacity: 1;
}

.info_title {
  font-weight: bold;
  font-size: 1.3em;
}

@media screen and (max-width: 960px) {
  .headerlogo {
    width: 150px;
    margin-top: 5px;
  }
}
</style>

<style>
.info-text{
  text-align: left;
}
.info-text p{
  margin: 0 !important;
}
</style>
