<template>
  <AdminSidebar>
    <v-container fluid v-show="!displayEmailForm">
      <v-flex>
        <p class="title">
          <v-icon large>mdi-how-to-reg</v-icon>
          <span class="titleName">登録状況</span></p>

        <!--          <v-container grid-list-md style="margin-left:0px;margin-right:0px;">-->
        <!--            <v-layout row wrap>-->
        <!--              <v-flex ls2 class="text-md-left text-xs-left">-->
        <!--                <p class="graphAttrTitle">来場者数／登録者数</p>-->
        <!--              </v-flex>-->
        <!--              <v-flex ls2>-->
        <!--                <Chart :data="chartData" :options="options" style="width:150px;"></Chart>-->
        <!--              </v-flex>-->
        <!--              <v-flex ls8 class="text-md-left">-->
        <!--                <v-flex md12 style="height:50%;">-->
        <!--                </v-flex>-->
        <!--                <v-flex ls12 style="height:50%;">-->
        <!--                  <span class="salesNum">{{visitedNum}}</span>-->
        <!--                  <span class="salesNumSpacer">／</span>-->
        <!--                  <span class="salesNumMax">{{registeredNum}}</span>-->
        <!--                </v-flex>-->
        <!--              </v-flex>-->

        <!--              <v-flex md8 class="text-md-right">-->

        <!--              </v-flex>-->
        <!--            </v-layout>-->
        <!--          </v-container>-->


        <div style="padding: 2rem;margin: 1rem; border:solid 1px;border-radius: 15px">
          <v-form ref="form">
            <h3>検索</h3>
            <v-row style="padding: 1rem 0">
              <v-col cols="12" sm="6">
                <v-text-field dense label="受験番号" v-model="form.examineesNumber"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field dense label="メールアドレス" v-model="form.email"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="3">
                <v-text-field dense label="姓" v-model="form.lastName"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-text-field dense label="名" v-model="form.firstName"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-text-field dense label="姓（カナ）" v-model="form.lastNameKana"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-text-field dense label="名（カナ）" v-model="form.firstNameKana"></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-select dense :items="languageOptions" label="第一科目" v-model="form.language1"></v-select>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-select
                  dense
                  :items="languageOptions"
                  label="第二科目"
                  v-model="form.language2"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
            <v-col cols="12" sm="6">
              <v-select dense label="一次会場" v-model="form.facilityId" :items="facilityOptions"></v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select dense label="一次部屋" v-model="form.venueId" :items="venueOptions"></v-select>
            </v-col>
              <v-col cols="12" sm="6">
                <v-select dense label="二次会場" v-model="form.oralFacilityId" :items="facilityOptions"></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
              >
                <v-select
                  dense
                  :items="statuses"
                  label="提出ステータス"
                  v-model="form.entryStatus"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-select
                  dense
                  :items="ticketOptions"
                  label="チケット"
                  v-model="form.ticketId"
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-select
                  dense
                  :items="paymentStatuses"
                  label="決済ステータス"
                  v-model="form.paymentStatus"
                ></v-select>
              </v-col>

            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-input v-model="submittedFrom"
                         @click.stop="$refs.from.focus()"
                         label="提出日時from"
                         readonly>
<!--                  <template v-slot:append>-->
<!--                    <input type="datetime-local" ref="from" v-model="form.submittedFrom">-->
<!--                  </template>-->
                  <template v-slot:append>
                    <input type="date" ref="from" v-model="form.from.date">
                    <input type="time" v-model="form.from.time">
                  </template>
                  <template v-slot:append-after>
                  </template>
                </v-input>
              </v-col>
              <!--              {{ form.from }}-->
              <!--              <input type="date" v-model="form.from.date"> <input type="time" v-model="form.from.time">-->
              <v-col cols="12" sm="6" md="6">
                <v-input v-model="submittedTo"
                         @click.stop="$refs.to.focus()"
                         label="提出日時to"
                         readonly>
<!--                  <template v-slot:append>-->
<!--                    <input type="datetime-local" ref="to" v-model="form.submittedTo">-->
<!--                  </template>-->
                  <template v-slot:append>
                    <input type="date" ref="to" v-model="form.to.date">
                    <input type="time" v-model="form.to.time">
                  </template>
                </v-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="3"
              >
                <v-text-field
                  dense
                  label="メモ"
                  v-model="form.memo"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-btn @click.stop="searchVisitor">検索</v-btn>
              <v-btn @click="$refs.form.reset()">クリア</v-btn>
            </v-row>
          </v-form>
        </div>

        <v-container grid-list-md style="padding:4px;margin-left:0px;margin-right:0px;max-width:100%;" >
          <v-layout align-end justify-end row fill-height>

<!--            <v-tooltip bottom>-->
<!--              <template v-slot:activator="{ on, attrs }">-->
<!--                <v-btn v-on="on" @click.stop="openAddNewVisitor" icon>-->
<!--                  <v-icon class="iconStyle" large>person_add</v-icon>-->
<!--                </v-btn>-->
<!--              </template>-->
<!--              <span>新規登録</span>-->
<!--            </v-tooltip>-->

<!--            <v-tooltip bottom>-->
<!--              <template v-slot:activator="{ on, attrs }">-->
<!--                <v-btn v-on="on" @click.stop="openEditVisitor" icon>-->
<!--                  <v-icon class="iconStyle" large>edit</v-icon>-->
<!--                </v-btn>-->
<!--              </template>-->
<!--              <span>登録者情報<br>編集</span>-->
<!--            </v-tooltip>-->
            <v-tooltip bottom v-if="isAdmin">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-on="on" @click.stop="checkCancel" icon>
                  <v-icon class="iconStyle" large>cancel</v-icon>
                </v-btn>
              </template>
              <span>登録者<br>キャンセル</span>
            </v-tooltip>
            <v-tooltip bottom v-if="isEditor">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-on="on" @click.stop="displayEmailDestinationsSelectDialog = true" icon>
                  <v-icon class="iconStyle" large>email</v-icon>
                </v-btn>
              </template>
              <v-dialog v-model="displayEmailDestinationsSelectDialog" max-width="500">
                <v-card>
                  <v-card-title class="modalHeader">メール送信対象選択</v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-layout>
                        <v-flex>
                          <v-radio-group
                            v-model="emailDestinationsSelect"
                            column
                          >
                            <v-radio label="選択されている登録者" value="selected"></v-radio>
                            <v-radio label="送信時の登録者全員" value="all"></v-radio>
                          </v-radio-group>
                        </v-flex>
                      </v-layout>
                      <v-layout justify-center>
                        <v-btn class="edit-b" @click.native="displayEmailDestinationsSelectDialog=false">閉じる</v-btn>
                        <v-btn color="error" @click="open">開く</v-btn>
                      </v-layout>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-dialog>
              <span>メール配信</span>
            </v-tooltip>
            <!--            <v-tooltip bottom>-->
            <!--              <template v-slot:activator="{ on, attrs }">-->
            <!--                <v-btn v-on="on" :href="csvTemplateUrl" icon>-->
            <!--                  <v-icon class="iconStyle" large>screen_share</v-icon>-->
            <!--                </v-btn>-->
            <!--              </template>-->
            <!--              <span>CSVテンプレート</span>-->
            <!--            </v-tooltip>-->
            <!--            <v-tooltip bottom>-->
            <!--              <template v-slot:activator="{ on, attrs }">-->
            <!--                <input type="file" id="csvFile" accept="text/csv" style="display:none" @change="importCsv">-->
            <!--                <v-btn v-on="on" @click="checkCanImportCsv" icon>-->
            <!--                  <v-icon class="iconStyle"  large>vertical_align_bottom</v-icon>-->
            <!--                </v-btn>-->
            <!--              </template>-->
            <!--              <span>CSVインポート</span>-->
            <!--            </v-tooltip>-->
            <v-tooltip bottom v-if="isViewer">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-on="on" :href="exportCsvUrl" icon>
                  <v-icon class="iconStyle" large>vertical_align_top</v-icon>
                </v-btn>
              </template>
              <span>CSV<br>エクスポート</span>
            </v-tooltip>
            <v-tooltip bottom v-if="isViewer">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-on="on" :href="allocateCsvUrl" icon>
                  <v-icon class="iconStyle" large>location_city</v-icon>
                </v-btn>
              </template>
              <span>一次試験受験地<br>割当候補出力</span>
            </v-tooltip>
            <v-tooltip bottom v-if="isViewer">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-on="on" :href="exportLabelUrl" icon target="_blank">
                  <v-icon class="iconStyle" large>label</v-icon>
                </v-btn>
              </template>
              <span>ラベル出力</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-on="on" @click.stop="displayCheckinDialog = true" icon>
                  <v-icon class="iconStyle" large>check_circle_outline</v-icon>
                </v-btn>
              </template>
              <v-dialog v-model="displayCheckinDialog" max-width="500">
                <v-card>
                  <v-card-title class="modalHeader">出欠登録</v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-layout>
                        <v-flex>
                          <v-select label="科目" v-model="subjectName" :items="subjectNames"></v-select>
                          <v-select label="出欠" v-model="attendanceType" :items="attendanceTypes"></v-select>
                        </v-flex>
                      </v-layout>
                      <v-layout justify-center>
                        <v-btn class="edit-b" @click.native="displayCheckinDialog=false">閉じる</v-btn>
                        <v-btn color="error" @click="checkInVisitor">登録</v-btn>
                      </v-layout>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-dialog>
              <span>出欠登録</span>
            </v-tooltip>
            <v-tooltip bottom v-if="isEditor">
              <input type="file" id="csvFile" accept="text/csv" style="display:none" @change="importCsv">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-on="on" @click="checkCanImportCsv" icon>
                  <v-icon class="iconStyle" large>vertical_align_bottom</v-icon>
                </v-btn>
              </template>
              <span>CSV<br>インポート</span>
            </v-tooltip>
<!--            <v-tooltip bottom v-if="isAdmin">-->
<!--              <template v-slot:activator="{ on, attrs }">-->
<!--                <v-btn v-on="on" @click.stop="deleteEditor" icon>-->
<!--                  <v-icon class="iconStyle" large>delete</v-icon>-->
<!--                </v-btn>-->
<!--              </template>-->
<!--              <span>登録者<br>削除</span>-->
<!--            </v-tooltip>-->
            <!--            <v-btn slot="activator">-->
            <!--              承認判定-->
            <!--            </v-btn>-->
            <!--            <v-btn slot="activator">-->
            <!--              一括更新-->
            <!--            </v-btn>-->
            <!--            <v-tooltip bottom>-->
            <!--              <v-btn slot="activator">-->
            <!--                来場者受付-->
            <!--              </v-btn>-->
            <!--              <span>来場者受付</span>-->
            <!--            </v-tooltip>-->

          </v-layout>
        </v-container>
        <v-data-table
          show-select
          :headers="headers"
          :items="visitors"
          :options.sync="options"
          :server-items-length="totalVisitors"
          :loading="loading"
          class="elevation-1"
          item-key="id"
          v-model="selected"
          :footer-props="{
            'items-per-page-options': [100,250,500,-1]
          }">
          <template v-slot:item.id="{item}">
            <td>
              {{ item.examineesNumber }}
            </td>
          </template>
          <template v-slot:item.name="{item}">
            <td>
              <router-link :to="`/event/${eventId}/visitor/${item.id}`">{{ item.lastName }}{{
                  item.firstName
                }}
              </router-link>
            </td>
          </template>
          <template v-slot:item.entryStatus="{value}">
            <td>
              {{ statusLabel(value) }}
            </td>
          </template>
          <template v-slot:item.ticketOrder.result="{value}">
            <td>
              {{ resultLabel(value) }}
            </td>
          </template>
          <template v-slot:item.submittedAt="{value}">
            <td>
              {{ value | formatDate }}
            </td>
          </template>
        </v-data-table>
      </v-flex>
    </v-container>
    <template v-if="displayEmailForm">
      <mailSendForm
        @close="displayEmailForm = false"
        :mailTo="mailTo"
        :toAll="emailDestinationsSelect=='all'"
        :visitorIds="visitorIds"
        :eventId="eventId" :mailId="null" :isEdit="false"></mailSendForm>
    </template>

    <template>
      <v-layout row justify-center wrap>
        <v-dialog v-model="importErrDialog" max-width="500">
          <v-card>
            <v-card-title class="modalHeader">下記行のデータの取り込みに失敗しました</v-card-title>
            <div class="text-sm-left" style="margin:20px">
              <p v-for="(value, key, index) in importErrMsgs" :key="index">
                {{value}}
              </p>
            </div>
            <v-card-actions>
              <v-layout justify-center>
                <v-btn
                  @click="importErrDialog = false"
                >
                  閉じる
                </v-btn>
              </v-layout>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </template>
  </AdminSidebar>
</template>
<script>
import EventLayout from './EventLayout'
import AdminSidebar from './AdminSidebar'
import VisitorService from '../mixins/VisitorService'
import mailSendForm from '@/components/CreateEmail.vue'
import {mapGetters} from 'vuex'
import axios from 'axios'
import dayjs from 'dayjs'

export default {
  components: {AdminSidebar, EventLayout, mailSendForm},
  mixins: [VisitorService],
  data () {
    return {
      options: {
        itemsPerPage: 100,
        page: 1
      },
      totalVisitors: 0,
      loading: true,
      form: {
        id: null,
        examineesNumber: null,
        lastName: null,
        firstName: null,
        lastNameKana: null,
        firstNameKana: null,
        language1: null,
        language2: null,
        entryStatus: null,
        paymentStatus: null,
        ticketId: null,
        memo: null,
        submittedFrom: null,
        submittedTo: null,
        from: {
          date: null, time: null
        },
        to: {
          date: null, time: null
        }
      },
      visitors2: [],
      subjects: [],
      selected: [],
      headers: [
        {
          text: '受験番号',
          value: 'id'
        },
        {
          text: 'メールアドレス',
          value: 'username'
        },
        {
          text: '氏名',
          value: 'name',
          sortable: false
        },
        {
          text: 'ステータス',
          value: 'entryStatus'
        },
        {
          text: 'チケット',
          value: 'ticketOrder.name'
        },
        {
          text: '決済',
          value: 'ticketOrder.result'
        },
        // {
        //   text: '第一言語',
        //   value: 'subjectEntry.language1.title'
        // },
        // {
        //   text: '第二言語',
        //   value: 'subjectEntry.language2.title'
        // },
        {
          text: '提出日時',
          value: 'submittedAt'
        },
      ],
      statuses: [{
        value: '',
        text: '-'
      }, {
        value: 'NOT_SUBMIT',
        text: '未提出'
      }, {
        value: 'PENDING',
        text: '承認待ち'
      }, {
        value: 'WAIT_FOR_RESUBMIT',
        text: '再提出依頼'
      }, {
        value: 'ACCEPTED',
        text: '承認'
      }, {
        value: 'HOLD',
        text: '保留'
      }, {
        value: 'REJECTED',
        text: '却下'
      },
      ],
      paymentStatuses: [
        {
          value: '',
          text: '-'
        },
        {
          value: 'Success',
          text: '成功'
        }, {
          value: 'WaitForPayment',
          text: '決済待ち'
        }],
      empty: [{value: '', text: '-'}],
      facilities: [],
      tickets: [],
      displayCheckinDialog: false,
      subjectName: null,
      attendanceType: null,
      attendanceTypes: [{
        value: 'PRESENT',
        text: '出席'
      }, {
        value: 'ABSENT',
        text: '欠席'
      }, {
        value: 'FORGOT',
        text: '身分証忘れ'
      }, {
        value: 'EXEMPT',
        text: '免除'
      }],
      subjectNames: ['外国語', '日本地理', '日本歴史', '一般常識', '通訳案内の実務', '二次1', '二次2'],
    }
  },
  computed: {
    ...mapGetters([
      'eventId', 'isAdmin', 'isEditor', 'isViewer'
    ]),
    // eventId: this && this.$route.params.id,
    languageOptions () {
      let ret = this.empty
      return ret.concat(this.subjects.filter(v => v.isLanguage)
        .map(v => {
          return {
            text: v.title,
            value: v.id
          }
        }))
    },
    sortOption () {
      let ret = []
      for (const k in this.options.sortBy) {
        ret.push(this.options.sortBy[k])
        if (this.options.sortDesc[k]) {
          ret.push('desc')
        }
      }
      return ret.join(',')
    },
    submittedFrom:{
      get () {
        if(!this.form.from.date) return
        let time = this.form.from.time || "00:00:00"
        return this.form.from.date +"T"+ time
      },
      set(v) {
        this.form.from.date = null
        this.form.from.time = null
      }
    },
    submittedTo:{
      get () {
        if(!this.form.to.date) return
        let time = this.form.to.time || "00:00:00"
        return this.form.to.date +"T"+ time
      },
      set(v) {
        this.form.to.date = null
        this.form.to.time = null
      }
    },
    getSearchParams () {
      return {
        eventId: this.eventId,
        id: this.form.id,
        examineesNumber: this.form.examineesNumber,
        firstName: this.form.firstName,
        lastName: this.form.lastName,
        firstNameKana: this.form.firstNameKana,
        lastNameKana: this.form.lastNameKana,
        email: this.form.email,
        language1: this.form.language1,
        language2: this.form.language2,
        entryStatus: this.form.entryStatus,
        paymentStatus: this.form.paymentStatus,
        ticketId: this.form.ticketId,
        // submittedFrom: this.form.submittedFrom,
        // submittedTo: this.form.submittedTo,
        submittedFrom: this.submittedFrom,
        submittedTo: this.submittedTo,
        memo: this.form.memo,
        size: this.options.itemsPerPage,
        page: this.options.page - 1,
        sort: this.sortOption,
        facilityId: this.form.facilityId,
        venueId: this.form.venueId,
        oralFacilityId: this.form.oralFacilityId
      }
    },
    exportCsvUrl () {
      let params = {}
      Object.assign(params, this.getSearchParams);
      params.ids = this.selected.map(v => v.id)
      const buildURLQuery = obj =>
        Object.entries(obj)
          .filter(([k, v]) => v != null)
          .map(pair => pair.map(encodeURIComponent).join('='))
          .join('&')
      return '/api/visitor_search/export?' + buildURLQuery(params)
    },
    allocateCsvUrl(){
      return `/api/subject_venue/allocate/${this.eventId}/`
    },
    exportLabelUrl(){
      let params = {}
      Object.assign(params, this.getSearchParams);
      params.ids = this.selected.map(v => v.id)
      const buildURLQuery = obj =>
        Object.entries(obj)
          .filter(([k, v]) => v != null)
          .map(pair => pair.map(encodeURIComponent).join('='))
          .join('&')
      return '/api/visitor_search/label?' + buildURLQuery(params)
    },

    facilityOptions () {
      return this.facilities.map(v => {
        return {
          value: v.id,
          text: v.facilityName
        }
      })
    },
    venueOptions () {
      let ret = this.facilities
      if (!!this.form.facilityId) {
        ret = ret.filter(v => v.id == this.form.facilityId)
      }
      return ret.flatMap(v => v.venues)
        .map(v => {
          return {
            value: v.id,
            text: v.venueName + (v.subject ? `(${v.subject.title})` : "")
          }
        })
    },
    ticketOptions(){
      return this.tickets.map(v => {
        return {
          value: v.id,
          text: v.name
        }
      })
    }
  },
  watch: {
    options: {
      handler () {
        this.searchVisitor()
      },
      deep: true,
    },
  },
  filters: {
    formatDate (v) {
      return v && dayjs(v).format('YYYY-MM-DD HH:mm:ss')
    },
  },
  created () {
    this.$store.commit('setEventId', this.$route.params.eventId)
    // this.loadEventVisitor()
    this.fetchSubjects()
    // this.searchVisitor()
    this.fetchFacilities()

    // this.fetchTickets()
    this.fetchEvent()
  },
  methods: {
    statusLabel (v) {
      return v && (this.statuses.find(s => s.value == v) || {}).text
    },
    resultLabel (v) {
      return v && (this.paymentStatuses.find(s => s.value == v) || {}).text
    },
    async searchVisitor () {
      try {
        this.selected = []
        this.loading = true
        let {data} = await axios.get('/api/visitor_search', {
          params: this.getSearchParams
        })
        this.visitors = data.content
        this.totalVisitors = data.totalElements
      } catch (e) {
        this.$emit('error', '読み込みに失敗しました')
      } finally {
        this.loading = false
      }
    },
    async fetchSubjects () {
      let {data} = await axios.get('/api/subject')
      this.subjects = data.subjects
      this.exemptions = data.exemptions
      this.writtenExamAreas = data.writtenExamAreas
      this.oralExamAreas = data.oralExamAreas
    },
    openAddNewVisitor () {

    },
    openEditVisitor () {

    },
    async fetchFacilities () {
      let {data} = await axios.get('/api/facility/all')
      this.facilities = data
    },
    async fetchTickets (){
      let {data} = await axios.get(`/api/eventsite/tickets/${this.eventId}`)
      this.tickets = data
    },
    async fetchEvent (){
      let {data} = await axios.get(`/api/event/${this.eventId}`)
      this.event = data
      this.tickets = data.tickets
    }
  }
}
</script>
