<template>
  <AdminSidebar>
    <v-container fluid>

      <p class="title">
        <v-icon large>mdi-how-to-reg</v-icon>
        <span class="titleName">出席状況</span>
      </p>


      <div style="padding: 2rem;margin: 1rem; border:solid 1px;border-radius: 15px">
        <v-form ref="form">
          <h3>検索</h3>
          <v-row style="padding: 1rem 0">
            <v-col cols="12" sm="6">
              <v-text-field dense label="受験番号" v-model="form.examineesNumber"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select dense label="一次エリア" v-model="form.areaId" :items="areaOptions"></v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select dense label="一次会場" v-model="form.facilityId" :items="facilityOptions"></v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select dense label="一次部屋" v-model="form.venueId" :items="venueOptions"></v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select dense label="二次エリア" v-model="form.oralAreaId" :items="areaOptions"></v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select dense label="二次会場" v-model="form.oralFacilityId" :items="oralFacilityOptions"></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-select dense :items="languageOptions" label="第一科目" v-model="form.language1"></v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select dense label="科目" v-model="form.subjectName" :items="subjectNames"></v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select dense label="出欠" v-model="form.attendanceType" :items="attendanceTypes"></v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-checkbox v-model="form.showAll" label="すべてのチェックインを表示"></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-btn @click.stop="fetchAttendances">検索</v-btn>
            <v-btn @click="$refs.form.reset()">クリア</v-btn>
          </v-row>
        </v-form>
      </div>

      <v-container grid-list-md style="padding:4px;margin-left:0px;margin-right:0px;max-width:100%;">
        <v-layout align-end justify-end row fill-height>
          <v-tooltip bottom v-if="isViewer">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-on="on" :href="exportCsvUrl" icon>
                <v-icon class="iconStyle" large>vertical_align_top</v-icon>
              </v-btn>
            </template>
            <span>CSV<br>エクスポート</span>
          </v-tooltip>
        </v-layout>
      </v-container>
      <v-data-table
        :headers="headers"
        :items="attendances"
        :loading="loading"
        :search="search"
        :options.sync="options"
        :server-items-length="totalVisitors"
        item-key="id"
        :footer-props="{
            'items-per-page-options': [100,250,500,-1]
          }">
        <template v-slot:top >
          <div>
<!--          検索結果：{{ totalVisitors }}件-->
          <table class="ma-auto">
            <tr>
            <template v-for="s in summary">
              <th class="pl-4">{{s.label}}</th>
              <td class="ps-1">{{s.score}}件</td>
            </template>
            </tr>
          </table>
          </div>
        </template>
        <template v-slot:item.visitedAt="{value}">
          <td>{{ new Date(value).toLocaleString() }}</td>
        </template>
        <template v-slot:item.attendanceType="{item}">
          <td>{{ item.attendanceLabel }}</td>
        </template>
      </v-data-table>
    </v-container>
  </AdminSidebar>
</template>
<script>
import AdminSidebar from './AdminSidebar'
import axios from 'axios'
import {mapGetters} from 'vuex'

export default {
  components: {AdminSidebar},
  data () {
    return {
      search: null,
      headers: [
        {
          text: '受験番号',
          value: 'visitorId'
        },
        {
          text: '氏名',
          value: 'name',
          sortable: false
        },
        {
          text: '第一科目',
          value: 'language1',
          sortable: false
        },
        // {
        //   text: '一次エリア',
        //   value: 'areaTitle',
        //   sortable: false
        // },
        {
          text: '一次会場',
          value: 'facilityName',
          sortable: false
        },
        {
          text: '一次部屋',
          value: 'venueName',
          sortable: false
        },
        // {
        //   text: '二次エリア',
        //   value: 'oralAreaTitle',
        //   sortable: false
        // },
        {
          text: '二次会場',
          value: 'oralFacilityName',
          sortable: false
        },
        {
          text: '科目',
          value: 'subjectName'
        },
        {
          text: '出欠',
          value: 'attendanceType'
        },
        {
          text: '日時',
          value: 'visitedAt'
        },
      ],
      options: {
        itemsPerPage: 100,
        page: 1
      },
      totalVisitors: null,
      loading: false,
      attendances: [],
      areas: [],
      subjects: [],
      subjectNames: ['外国語', '日本地理', '日本歴史', '一般常識', '通訳案内の実務', '二次1', '二次2'],
      attendanceTypes: [{
        value: 'PRESENT',
        text: '出席'
      }, {
        value: 'ABSENT',
        text: '欠席'
      }, {
        value: 'FORGOT',
        text: '身分証忘れ'
      }, {
        value: 'EXEMPT',
        text: '免除'
      }],
      facilities: [],
      form: {}
    }
  },
  computed: {
    ...mapGetters([
      'eventId', 'isAdmin', 'isEditor', 'isViewer'
    ]),
    languageOptions () {
      return this.subjects.filter(v => v.isLanguage)
        .map(v => {
          return {
            text: v.title,
            value: v.id
          }
        })
    },
    subjectOptions () {
      return this.subjects.map(v => {
        return {
          value: v.id,
          text: v.title
        }
      })
    },
    areaOptions () {
      return this.areas.map(v => {
        return {
          value: v.id,
          text: v.title
        }
      })
    },
    facilityOptions () {
      let ret = this.facilities
      if (!!this.form.areaId) {
        ret = ret.filter(v => v.area && v.area.id == this.form.areaId)
      }
      return ret.map(v => {
        return {
          value: v.id,
          text: v.facilityName
        }
      })
    },
    oralFacilityOptions () {
      let ret = this.facilities
      if (!!this.form.oralAreaId) {
        ret = ret.filter(v => v.area && v.area.id == this.form.oralAreaId)
      }
      console.log(ret)
      return ret.map(v => {
        return {
          value: v.id,
          text: v.facilityName
        }
      })
    },
    venueOptions () {
      let ret = this.facilities
      if (!!this.form.facilityId) {
        ret = ret.filter(v => v.id == this.form.facilityId)
      }
      return ret.flatMap(v => v.venues)
        .map(v => {
          return {
            value: v.id,
            text: v.venueName + (v.subject ? `(${v.subject.title})` : "")
          }
        })
    },
    sortOption () {
      let ret = []
      for (const k in this.options.sortBy) {
        ret.push(this.options.sortBy[k])
        if (this.options.sortDesc[k]) {
          ret.push('desc')
        }
      }
      return ret.join(',')
    },
    exportCsvUrl(){
      let params = {
        eventId: this.eventId
      }
      Object.assign(params, this.form)
      const buildURLQuery = obj =>
        Object.entries(obj)
          .filter(([k, v]) => v != null)
          .map(pair => pair.map(encodeURIComponent).join('='))
          .join('&')
      return '/api/attendance/export?' + buildURLQuery(params)
    },
    summary(){
      // let types = this.attendances.map(v => v.attendanceLabel)
      //   .filter((elem, index, self) => self.indexOf(elem) === index);
      let types = this.attendanceTypes.map(v => v.text)
      let ret = types.map(v =>{
        return {
          label: v,
          score: this.attendances.filter(a=>a.attendanceLabel == v).length
        }
      });
      ret.unshift({
        label: "合計",
        score: this.attendances.length
      })
      return ret
    }
  },
  async created () {
    this.loading = true
    this.$store.commit('setEventId', this.$route.params.eventId)
    await Promise.all([
      this.fetchAttendances(),
      this.fetchSubjects(),
      this.fetchAreas(),
      this.fetchFacilities()])
    this.loading = false
  },
  watch: {
    options: {
      handler () {
        this.fetchAttendances()
      },
      deep: true,
    },
  },
  methods: {
    async fetchSubjects () {
      let {data} = await axios.get('/api/subject')
      this.subjects = data.subjects.filter(v => v.isLanguage)
    },
    async fetchAreas () {
      let {data} = await axios.get('/api/area')
      this.areas = data
    },
    async fetchFacilities () {
      let {data} = await axios.get('/api/facility/all')
      this.facilities = data
    },
    async fetchAttendances () {
      try {
        this.loading = true
        let params = {
          eventId: this.eventId,
          size: this.options.itemsPerPage,
          page: this.options.page - 1,
          sort: this.sortOption
        }
        Object.assign(params, this.form)
        let {data} = await axios.get(`/api/attendance/search`, {
          params: params
        })
        this.attendances = data.content
        this.totalVisitors = data.totalElements
      } catch (e) {
        this.$emit('error', '読み込みに失敗しました')
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
