// import 'babel-polyfill'
import 'formdata-polyfill'
import Vue from 'vue'
import App from './admin/App'
import router from './admin/router'
import store from './admin/store'
// import 'vuetify/dist/vuetify.css'
// import VueCookie from 'vue-cookies'
import vuetify from './plugins/vuetify'
import {Datetime} from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'

Vue.config.productionTip = false
// Vue.use(VueCookie)

Vue.component('datetime', Datetime);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
