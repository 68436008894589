<template>
    <v-app id="inspire" style="background-color: white;min-width:320px;">
        <template v-if="displayAuth">
            <div id="fix-header">
                <v-container style="padding-top: 10px; padding-bottom: 10px;">
                    <v-layout align-center>
                        <v-flex hidden-sm-and-down md8 class="text-md-left">
                            <div id="fix-header-event-title">
                                <span class="d-inline-block text-truncate" style="max-width: 100%">
                                    {{this.event_data.eventName}}
                                </span>
                            </div>
                        </v-flex>
                        <v-flex xs12 md4 class="text-md-right" style="max-height:100px;">
                            <v-dialog persistent v-model="dialog" max-width="500px">
                                <v-btn
                                    slot="activator"
                                    color="primary"
                                    outline
                                    dark
                                    style="text-transform:none"
                                >
                                    <v-icon dark small></v-icon>
                                    MyPageにログイン
                                </v-btn>
                                <v-form v-model="validLogin" lazy-validation @submit.prevent="singIn()">
                                    <v-card>
                                        <v-card-title class="modalHeader">
                                            <span class="headline">MyPageにログイン</span>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-container grid-list-md>
                                                <v-layout wrap>
                                                    <v-flex xs12>
                                                        <v-text-field
                                                          v-model="loginInfo.email"
                                                          :rules="loginInfoRules.emailRules"
                                                          label="Email Address"
                                                        >
                                                        </v-text-field>
                                                    </v-flex>
                                                    <v-flex xs12>
                                                        <v-text-field
                                                            v-model="loginInfo.password"
                                                            :append-icon="loginInfo.showPassword ? 'visibility_off' : 'visibility'"
                                                            :rules="loginInfoRules.passwordRules"
                                                            label="Password"
                                                            :type="loginInfo.showPassword ? 'text' : 'password'"
                                                            @click:append="loginInfo.showPassword = !loginInfo.showPassword"
                                                        >
                                                        </v-text-field>
                                                    </v-flex>
                                                    <v-spacer></v-spacer>
                                                    <v-flex xs12 class="text-md-center">
                                                        <v-btn class="edit-b" @click.native="close">キャンセル</v-btn>
                                                        <v-btn type="submit" color="error" :disabled="!validLogin">ログイン</v-btn>
                                                    </v-flex>
                                                    <v-flex xs12 class="text-md-center">
                                                        <v-icon style="position: relative; top:5px;">help</v-icon>
                                                        <span><router-link :to="{path: publishId + '/passwordReset'}">パスワードを忘れた方はこちら</router-link></span>
                                                    </v-flex>
                                                </v-layout>
                                            </v-container>
                                        </v-card-text>
                                    </v-card>
                                </v-form>
                            </v-dialog>
                        </v-flex>
                    </v-layout>
                </v-container>
            </div>

            <div style="display:block;height:80px;"></div>

            <parts-event-title
                ref="partsEventTitle"
                v-bind:viewEventName="event_data.viewEventName"
                v-bind:eventName="event_data.eventName"
                v-bind:coverImageUrl="event_data.coverImageUrl"
                v-bind:eventTitleColorHex8="eventTitleColorHex8"
                v-bind:eventTitleFont="eventTitleFont"
                v-bind:eventTitleSize="eventTitleSize"
            ></parts-event-title>

            <div id="spacer-title-bottom" style="display:block;height:40px;"></div>

            <v-container grid-list-md text-md-center offset-xl2>
                <v-layout row wrap aling-center>
                    <v-flex xs12>
                        <div class="textarea" style="text-align:left;margin-left:6px;" v-html="this.event_data.information"></div>
                    </v-flex>
                    <v-flex xs12>
                        <div class="subTitle subContentsTitle"><span>主催者情報</span></div>
                    </v-flex>
                    <v-flex xs12>
                        <div class="organizer" style="text-align:left;margin-left:6px;" v-html="this.event_data.organizerInfo"></div>
                    </v-flex>
                    <v-flex xs12>
                        <div class="subTitle subContentsTitle"><span>会期日</span></div>
                    </v-flex>
                    <v-flex xs12>
                        <p class="text-xs-left contents">
                            {{formatDateWithDayOfWeek(this.event_data.eventDate)}}
                            <template v-if="event_data.eventEndDate != null">
                                〜 {{formatDateWithDayOfWeek(this.event_data.eventEndDate)}}
                            </template>
                        </p>
                    </v-flex>
                    <v-flex xs12>
                        <div class="subTitle subContentsTitle"><span>会期時間</span></div>
                    </v-flex>
                    <v-flex xs12>
                        <p class="text-xs-left contents">{{this.event_data.startTime}}〜{{this.event_data.endTime}}</p>
                    </v-flex>

                    <template v-if="facility.id > 0">
                        <v-flex xs12>
                            <div class="subTitle subContentsTitle"><span>会場アクセス</span></div>
                        </v-flex>
                        <v-flex xs12>
                            <p class="text-xs-left contents">
                                {{this.facility.facilityName}}
                            </p>
                            <p class="text-xs-left contents" style="font-size:120%;font-weight:normal;">
                                {{this.facility.address}}
                            </p>
                            <p class="text-xs-left contents" style="font-size:120%;font-weight:normal;">
                                {{this.event_data.roomDescription}}
                            </p>
                        </v-flex>

                        <!-- PC時地図 -->
                        <v-flex lg6 md12 class="hidden-sm-and-down" v-if="mapUrlValid && facility.mapUrl != null">
                            <p class="text-md-left">オリジナルマップ</p>
                            <p class="text-md-left"><img v-bind:src="facility.mapUrl" width="560" height="315" @error="imgError()"></p>
                        </v-flex>
                        <v-flex lg6 md12 class="hidden-sm-and-down" v-if="facility.googleMapUrl !== null">
                            <p class="text-md-left">Google Map</p>
                            <div class="mapresize">
                                <p class="text-md-left" v-html="facility.googleMapUrl"></p>
                            </div>
                        </v-flex>

                        <!-- スマホ時地図 -->
                        <v-flex xs12 class="hidden-md-and-up">
                            <div class="maparea text-md-center">
                                <v-tabs
                                centered
                                color="grey"
                                dark
                                icons-and-text
                                >
                                    <v-tabs-slider color="white"></v-tabs-slider>
                                    <template v-if="facility.mapUrl != null">
                                        <v-tab href="#tab-1" v-if="mapUrlValid">
                                            オリジナルマップ
                                        </v-tab>
                                    </template>
                                    <v-tab href="#tab-2" v-if="facility.googleMapUrl !== null">
                                        Google Map
                                    </v-tab>
                                    <template v-if="facility.mapUrl != null">
                                        <v-tab-item
                                        :id="'tab-1'"
                                        :key="1"
                                        v-if="mapUrlValid"
                                        >
                                            <p class="text-md-center">
                                                <img v-bind:src="facility.mapUrl" width="100%" @error="imgError()">
                                            </p>
                                        </v-tab-item>
                                    </template>
                                    <v-tab-item
                                    :id="'tab-2'"
                                    :key="2"
                                    >
                                        <p class="text-md-center" v-html="facility.googleMapUrl"></p>
                                    </v-tab-item>
                                </v-tabs>
                            </div>
                        </v-flex>
                    </template>

                        <v-flex xs12>
                            <template v-if="event_data.closed">
                                    <v-alert
                                    :value="true"
                                    type="warning"
                                    style="font-size:120%;"
                                    class="pa-4"
                                    >
                                    本イベントの受付は終了いたしました。
                                    </v-alert>
                            </template>
                            <template v-else-if="this.hasPaidTickets() && ! paymentEnable">
                                    <v-alert
                                    :value="true"
                                    type="warning"
                                    style="font-size:120%;"
                                    class="pa-4"
                                    >
                                    現在申込を受け付けておりません。<br />
                                    主催者にお問い合わせください。
                                    </v-alert>
                            </template>
                            <template v-else-if="this.hasTickets() && !this.hasSelectableTickets()">
                                    <v-alert
                                    :value="true"
                                    type="warning"
                                    style="font-size:120%;"
                                    class="pa-4"
                                    >
                                    本イベントの受付は終了いたしました。
                                    </v-alert>
                            </template>

                            <template v-else>
                                <v-form v-model="valid">
                                    <div class="input-area">
                                        <p class="inputAreaTitle">お申込み情報入力</p>
                                      <v-flex xs10 offset-xs1 v-if="this.hasTickets()" style="margin-bottom: 20px;">
                                        <v-flex style="background-color: #cccccc;">
                                          <v-radio-group v-model="ticketId" :mandatory="false" :rules="ticketSelectRules">
                                            <div class="inputFieldTitle" style="color: #666666">
                                              <template>　選択してください</template>
                                              <span class="essential">必須</span>
                                            </div>
                                            <template v-for="ticket in changeDispOrder">
                                              <v-radio :value="ticket.id" class="ticketRadio"
                                                       :disabled="ticket.disabled" style="margin:1em 1em auto 1em;">
                                                <div slot="label">
                                                  <div v-if="ticket.paidTicket">
                                                    <span class="ticketLabel">
                                                    {{ticket.name}}　参加費　{{ticket.price | toPriceLocaleString}}円(税込)　　{{ticket.salesStatus}}
                                                    </span>
                                                  </div>
                                                  <div v-else>
                                                    <span class="ticketLabel">
                                                      {{ticket.name}}<span v-if="event_data.displayPriceOfFreeTickets">　参加費　'無料'</span>　　{{ticket.salesStatus}}
                                                    </span>
                                                  </div>
                                                  <div v-if="ticket.description != undefined && ticket.description.length > 0" class="ticketDescription">
                                                    <span>{{ticket.description}}</span>
                                                  </div>
                                                </div>
                                              </v-radio>
                                            </template>
                                          </v-radio-group>
                                        </v-flex>
                                      </v-flex>
                                        <v-flex xs10 offset-xs1>
                                            <div class="inputFieldTitle"><template v-if="visitorInputOptions.MAIL.label==null">メールアドレス</template><template v-else>{{visitorInputOptions.MAIL.label}}</template><span class="essential">必須</span></div>
                                            <v-text-field
                                                v-model="email"
                                                :rules="mailAddressRules"
                                                solo
                                                text
                                                class="inputField"
                                            >
                                            </v-text-field>
                                            <template v-if="this.visitorInputOptions !== undefined && this.visitorInputOptions.MAIL_CONFIRMATION.display">
                                                <div class="inputFieldTitle"><template v-if="visitorInputOptions.MAIL_CONFIRMATION.label==null">メールアドレス（確認）</template><template v-else>{{visitorInputOptions.MAIL_CONFIRMATION.label}}</template><span class="essential">必須</span></div>
                                                <v-text-field
                                                  id="emailConfirmation"
                                                  v-model="emailConfirmation"
                                                  :rules="emailConfirmationRules"
                                                  solo
                                                  text
                                                  class="inputField"
                                                >
                                                </v-text-field>
                                            </template>
                                            <div class="inputFieldTitle">Password<span class="essential">必須</span></div>
                                            <v-text-field
                                                v-model="password"
                                                :rules="passwordRules"
                                                solo
                                                text
                                                :append-icon="showPassword ? 'visibility_off' : 'visibility'"
                                                :type="showPassword ? 'text' : 'password'"
                                                @click:append="showPassword = !showPassword"
                                                class="inputField"
                                            >
                                            </v-text-field>
                                            <template v-if="this.visitorInputOptions !== undefined && this.visitorInputOptions.LASTNAME">
                                                <v-layout row wrap>
                                                    <v-flex xs6>
                                                    <div class="inputFieldTitle"><template v-if="visitorInputOptions.LASTNAME.label==null">姓</template><template v-else>{{visitorInputOptions.LASTNAME.label}}</template><span class="essential">必須</span></div>
                                                    <v-text-field
                                                        v-model="lastName"
                                                        :rules="lastNameRules"
                                                        solo
                                                        text
                                                        class="inputField"
                                                        required
                                                    >
                                                    </v-text-field>
                                                    </v-flex>
                                                    <v-flex xs6>
                                                    <div class="inputFieldTitle"><template v-if="visitorInputOptions.FIRSTNAME.label==null">名</template><template v-else>{{visitorInputOptions.FIRSTNAME.label}}</template><span class="essential">必須</span></div>
                                                    <v-text-field
                                                        v-model="firstName"
                                                        :rules="firstNameRules"
                                                        solo
                                                        text
                                                        class="inputField"
                                                    >
                                                    </v-text-field>
                                                </v-flex>
                                                </v-layout>
                                            </template>
                                            <template v-for="(item, index) in sortableItems">
                                              <template v-if="item.active">
                                                <div class="inputFieldTitle">{{item.label}}<span class="essential" v-if="item.required">必須</span><span v-if="item.visitorOption == 'TEL'" class="attention">(ハイフンなしで入力してください)</span></div>
                                                <v-text-field
                                                  v-model="sortableItemValues[index]"
                                                  :rules="sortableItemRules[index]"
                                                  solo
                                                  text
                                                  class="inputField"
                                                >
                                                </v-text-field>
                                              </template>
                                            </template>
                                            <template v-if="this.visitorInputOptions !== undefined && this.visitorInputOptions.OPT.display">
                                            <v-layout row wrap>
                                                <v-flex xs12>
                                                    今後、弊社よりお送りさせていただくメールマガジンなどのサービス情報を
                                                </v-flex>
                                                <v-flex md5 xs4>
                                                    &nbsp;
                                                </v-flex>
                                                <v-flex md4 xs6 justify-center>
                                                    <v-checkbox
                                                        v-model="opt"
                                                        label="希望する"
                                                        color="info"
                                                    ></v-checkbox>
                                                </v-flex>
                                                <v-flex md3 xs2>
                                                    &nbsp;
                                                </v-flex>
                                            </v-layout>
                                            </template>
                                            <v-layout row wrap>
                                                <v-flex xs12>
                                                    <div class="subTitle subContentsTitle" style="text-align:center;"><span style="background-color:#F0F0F0;">個人情報の取り扱いについて</span></div>
                                                </v-flex>
                                                <v-spacer style="margin-top:40px;"></v-spacer>
                                                <v-flex xs12>
                                                    <a :href="event_data.privacyPolicyUrl" class="linkDecoration" target="_blank">こちら</a> をご確認いただき、同意される場合はチェックを入れてください。
                                                </v-flex>

                                                <v-flex md5 xs4>
                                                    &nbsp;
                                                </v-flex>
                                                <v-flex md4 xs6 justify-center>
                                                    <v-checkbox
                                                        v-model="personalInfoCheck"
                                                        :rules="personalInfoCheckRules"
                                                        label="同意する"
                                                        color="info"
                                                    ></v-checkbox>
                                                </v-flex>
                                                <v-flex md3 xs2>
                                                    &nbsp;
                                                </v-flex>

                                                <v-flex xs12 class="text-xs-center">
                                                    <v-btn id="button-apply-event" rounded block color="error" :disabled="!valid" @click.native="signUp()">申し込む</v-btn>
                                                </v-flex>
                                            </v-layout>
                                        </v-flex>
                                    </div>
                                    <v-flex xs12>
                                        <div style="text-align:right;">
                                            <img src="https://cdn.event-planner.net/publish_footer.png" width="200px">
                                        </div>
                                    </v-flex>
                                </v-form>
                            </template>
                        </v-flex>
                </v-layout>
            </v-container>
        </template>
        <template v-else>
            <v-container fluid>
                <v-form v-model="validSiteAccess" @submit.prevent="verify()">
                    <v-flex md6 offset-md3 xs12>
                        <div class="siteAccessTitle">
                            <v-icon large color="white">lock</v-icon>
                            <span style="vertical-align:7px;">限定公開サイトへアクセス</span>
                        </div>
                        <div class="siteAccessContent">
                            イベント主催者より伝えられたパスワードを入力してください
                        </div>
                        <v-text-field
                            v-model="siteAccessPassword"
                            :rules="siteAccessPasswordRules"
                            required
                            label="パスワード"
                            class="inputField"
                            :append-icon="showSiteAccessPassword ? 'visibility_off' : 'visibility'"
                            :type="showSiteAccessPassword ? 'text' : 'password'"
                            @click:append="showSiteAccessPassword = !showSiteAccessPassword"
                        >
                        </v-text-field>
                        <br/>
                        <v-btn depressed large color="error" type="submit" :disabled="!validSiteAccess">アクセス</v-btn>
                    </v-flex>
                </v-form>
            </v-container>
        </template>
    </v-app>
</template>
<script>
import {
  getEvent,
  getEventId,
  getTickets,
  applyEvent,
  getToken,
  verifyPrivateSiteAccess,
  completePreOrder,
  getTicketRemainCounts,
  isPaymentEnable
} from "../utils/VisitorAPIUtils";
import {
  writeEventSiteInflowSource,
  readEventSiteInflowSource
} from "../utils/CookieUtils"
import moment from 'moment'
import Rules from "../utils/Rules"
import PartsEventTitle from "./Publish/PartsEventTitle";
export default {
    name: 'Publish',
    components: {
      'parts-event-title': PartsEventTitle
    },
    data () {
        return {
            eventId:'',
            publishId:'',
            active: null,
            valid: false,
            showPassword: false,
            mailAddressRules: [
                v => !!v || '必須事項です',
                v => /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'メールアドレスの形式で入力を行ってください。'
            ],
            emailConfirmationRules: [
                v => !!v || '必須事項です',
                v => this.checkMatchEmailInputs() || 'メールアドレスが一致していません'
            ],
            passwordRules: [
                v => !!v || "パスワードは必須事項です",
                v => (v && v.length <= 55 && v.length >= 8 && /^(?=.*?[a-z])(?=.*?\d)[a-z\d]{8,55}$/i.test(v))|| 'パスワードは半角英字・数字をどちらも使用した文字列で、8-55文字以内で設定してください'
            ],
            lastNameRules: [
                v => !!v || "必須事項です",
                v => (v && v.length <= 128) || '128文字以下で入力してください'
            ],
            firstNameRules: [
                v => !!v || "必須事項です",
                v => (v && v.length <= 128) || '128文字以下で入力してください'
            ],
            companyNameRules: [],
            departmentRules: [],
            positionRules: [],
            addressRules: [],
            phoneRules: [],
            personalInfoCheck: false,
            personalInfoCheckRules: [
                v => !!v || "個人情報の取り扱いについての同意をお願いします"
            ],
            ticketId: null,
            email: null,
            emailConfirmation: null,
            password: null,
            firstName: null,
            lastName: null,
            opt: false,
            event_data: {
              tickets: {}
            },
            eventTitleColorHex8:{},
            eventTitleFont:'',
            eventTitleSize:'',
            facility: {},
            mapUrl:null,
            visitorData: {},
            visitorInputOptions: {
                LASTNAME: {
                    display: false,
                    required: false,
                    label:null,
                },
                FIRSTNAME: {
                    display: false,
                    required: false,
                    label:null,
                  },
                MAIL: {
                    display: false,
                    required: false,
                    label:null,
                },
                MAIL_CONFIRMATION: {
                    display: false,
                    required: false,
                    label:null,
                },
                OPT: {
                    display: false
                },
            },

            sortableItems: [],
            sortableItemRules: [],
            sortableItemValues: [],
            sortableItemDefaultLabelsMap: {
              'COMPANY': '会社名',
              'DEPT': '部署',
              'POSITION': '役職',
              'ADDRESS': '住所',
              'TEL': 'TEL'
            },

            validLogin: false,
            dialog: false,
            loginInfo: {
                email: null,
                password: null,
                showPassword: false
            },
            loginInfoRules: {
                emailRules: [
                    v => !!v || 'メールアドレスを入力してください'
                ],
                passwordRules: [
                    v => !!v || 'パスワードを入力してください'
                ]
            },
            displayAuth:true,
            siteAccessPassword:'',
            siteAccessPasswordRules: [
                v => !!v || "パスワードは必須です。",
            ],
            validSiteAccess:false,
            showSiteAccessPassword:false,
            mapUrlValid:true,
            ticketSelectRules: [
                v => !!v || "選択してください。",
            ],
            paymentEnable:false,
            ticketRemainCountMap:{},
            ticketsForSelect:[],
        }
    },
    metaInfo() {
        return{
            title: this.event_data.eventName,
            meta: [
                {
                    'property' : 'description',
                    'content':this.event_data.information,
                },
                {
                    'property' : 'og:title',
                    'content':this.event_data.eventName,
                },
                {
                    'property' : 'og:type',
                    'content':'website',
                },
                {
                    'property' : 'og:description',
                    'content':this.event_data.information,
                },
                {
                    'property' : 'og:site_name',
                    'content':this.event_data.eventName,
                },
                {
                    'property' : 'twitter:card',
                    'content':'Sumarry',
                },
            ]
        }
    },
    filters: {
        toPriceLocaleString : function (value){
            if(!value){
                return 0;
            }
            return value.toLocaleString('ja-JP', {"style": "currency", "currency": "JPY"});
        },
    },
    computed: {
      changeDispOrder() {
        return this.ticketsForSelect.sort((a, b) => {
          if(a.entryIndex != b.entryIndex) {
            if(!a.entryIndex) return 1
            if(!b.entryIndex) return -1

            return a.entryIndex - b.entryIndex
          } else {
            return a.orderPriority - b.orderPriority
          }
        });
      }
    },
    watch: {
      email(data) {
        if(this.visitorInputOptions.MAIL_CONFIRMATION.display) {
          let originalValue = this.emailConfirmation
          if(originalValue) {
            this.emailConfirmation = ""
            this.$nextTick().then(() => {
              this.emailConfirmation = originalValue
            })
          }
        }
      }
    },
    methods: {
        formatDateWithDayOfWeek(date) {
          return moment(date).format('YYYY-M-D(ddd)')
        },
        moveToPasswordReset() {
          this.$router.push("/" + this.publishId + "/passwordReset")
        },
        checkMatchEmailInputs() {
            if(!this.email) return true
            if(!this.emailConfirmation) return true

            return (this.email == this.emailConfirmation)
        },
        hasTickets() {
            return this.event_data.hasTickets;
        },
        hasPaidTickets() {
            return this.event_data.hasPaidTickets;
        },
        hasSelectableTickets() {
            if(this.ticketsForSelect.length > 0) {
                return (this.ticketsForSelect.filter(e => (! e.disabled)).length > 0);
            } else {
                return false;
            }
        },
        setInputRules() {
            this.sortableItemRules = []
            this.sortableItems.forEach(item => {
              let rule
              switch(item.visitorOption) {
                case 'COMPANY':
                  rule = Rules.setCompanyRule(item.required)
                  break
                case 'DEPT' :
                  rule = Rules.setDepartmentRule(item.required)
                  break
                case 'POSITION' :
                  rule = Rules.setPositionRule(item.required)
                  break
                case 'ADDRESS' :
                  rule = Rules.setAddressRule(item.required)
                  break
                case 'TEL' :
                  rule = Rules.setPhoneRule(item.required)
                  break
                default :
                  rule = Rules.setFreeItemRule(item.required)
              }
              this.sortableItemRules.push(rule)
            }, this)
        },
        getValueOrNullWhenEmpty(value) {
          return (value) ? value : null
        },
        signUp() {
            if(confirm("このイベントへの参加登録を行ってもよろしいですか？")) {
              this.visitorData.eventId = this.event_data.id;
              this.visitorData.username = this.email;
              this.visitorData.password = this.password;
              this.visitorData.firstName = this.firstName;
              this.visitorData.lastName = this.lastName;
              this.visitorData.opt = this.opt;
              this.visitorData.freeItems = []
              for(let i=0;i<this.sortableItems.length;i++) {
                let item = this.sortableItems[i]
                let value = this.sortableItemValues[i]
                switch(item.visitorOption) {
                  case 'COMPANY' :
                    this.visitorData.companyName = value
                    break
                  case 'DEPT' :
                    this.visitorData.department = value
                    break
                  case 'POSITION' :
                    this.visitorData.position = value
                    break
                  case 'ADDRESS' :
                    this.visitorData.address = value
                    break
                  case 'TEL' :
                    this.visitorData.phone = value
                    break
                  default :
                    this.visitorData.freeItems.push({
                      visitorOption: item.visitorOption,
                      value: value
                    })
                    break
                }
              }

              this.visitorData.notifications = "";
              if (this.hasTickets()) {
                  this.visitorData.ticketId = this.ticketId;
              }
              this.visitorData.ignoreTicketStock = false;
              this.visitorData.inflowSource = readEventSiteInflowSource(this.publishId)
              applyEvent({data: this.visitorData, headers: {isFromVisitor: "true"}}).then(res => {
                  switch(res.status) {
                      case 201:
                          this.loginInfo.email = this.visitorData.username
                          this.loginInfo.password = this.visitorData.password
                          this.loginInfo.eventId = this.visitorData.eventId
                          alert("参加登録が完了しました。")
                          this.singIn()
                          break;
                      case 202:
                          window.location.href = res.data;
                          break;
                      default:
                          alert("エラーが発生しました。");
                  }
              }).catch(err => {
                  console.log(err);
                  switch(err.response.status) {
                      case 409:
                          alert("入力されたメールアドレスは既に登録されています。ログインボタンからログインしてください。");
                          break;
                      case 410:
                          alert("申し込みできませんでした。最新の情報をご確認ください。");
                          this.refreshDisplayAndQueryParameters();
                          break;
                      case 412:
                          alert("発行予定件数に達し、登録できませんでした。最新の情報をご確認ください。");
                          this.refreshDisplayAndQueryParameters();
                          break;
                      default:
                          alert("登録に失敗しました");
                          this.refreshDisplayAndQueryParameters();
                  }

              });
            }
        },
        singIn() {
            getToken({
                username: this.loginInfo.email,
                password: this.loginInfo.password,
                accountType: 'visitor',
                eventId: this.eventId
            }).then(res => {
                let account = JSON.parse(atob(res.data.split('.')[1]));
                this.$router.push({ path: this.publishId + '/MyPage', query: {eventId: this.eventId, userId: account.id} })
            }).catch(err => {
                console.log(err)
                alert("メールアドレスまたはパスワードが間違っています。")
            })
        },
        close() {
            this.dialog = false
            this.loginInfo.email = ''
            this.loginInfo.password = ''
        },
        imgError(){
            this.mapUrlValid = false
        },
        verify(){
            verifyPrivateSiteAccess({
                data: {
                    publishId: this.publishId,
                    password: this.siteAccessPassword
                }
            }).then(res => {
                this.displayAuth = true
                this.loadEvent()
            })
            .catch(err => {
                console.log("err",err)
                alert("パスワードが間違っています。")
            })
        },
        setTicketStatus() {
            getTicketRemainCounts({eventId: this.eventId}).then(res => {
                let ticketRemainCountsRes = res.data.counts;

                this.ticketRemainCountMap = new Map();
                ticketRemainCountsRes.forEach(record => {
                    this.ticketRemainCountMap.set(record.ticketId, record);
                }, this);

                this.event_data.tickets.forEach(ticket => {
                    let remainCount = this.ticketRemainCountMap.get(ticket.id);
                    let res = this.makeTicketStatus(
                        ticket.paidTicket,
                        remainCount.status,
                        remainCount.count
                    );
                    ticket.salesStatus = res.salesStatus;
                    ticket.disabled = res.disabled;

                    this.ticketsForSelect.push(ticket);
                }, this)
            }).catch(err => {
                console.log("err",err)
                this.$router.push({path: '/Error'})
            })
        },
        makeTicketStatus(paidTicket, remainStatus, remainCount){
            let salesStatus = "";
            let disabled = false;

            switch(remainStatus.toUpperCase()) {
              case "FULL" :
                salesStatus = "☓　満席";
                disabled = true;
                break;
              case "NUMBER" :
                salesStatus = "残　" + remainCount + "　席";
                break;
              case "FEW" :
                salesStatus = "△　残席が少なくなりました。お急ぎください。";
                break;
              default :
                salesStatus = "◯　お席には余裕があります。";
            }

            return {salesStatus, disabled};
        },
        refreshDisplayAndQueryParameters() {
            //再ロード。もともとクエリパラメタがなければリロードされないが、許容
            this.$router.push({path: this.publishId});
        },
        loadEvent(){
            getEvent({ publishId: this.publishId }).then(res => {
                if (!!res.data) {
                    this.event_data = {...res.data};
                    this.eventId = this.event_data.id
                    this.facility = this.event_data.facility
                    this.event_data.startTime = this.event_data.startTime.substring(0, 5).replace(/-/g, ":");
                    this.event_data.endTime = this.event_data.endTime.substring(0, 5).replace(/-/g, ":");

                    //Title の設定
                    let titleFontInfo = this.event_data.titleFontInfo;
                    if(!titleFontInfo) titleFontInfo = {}

                    this.eventTitleColorHex8 = this.$refs.partsEventTitle.decideEventTitleColorHex8(titleFontInfo.color, this.event_data.coverImageUrl)
                    this.eventTitleFont = this.$refs.partsEventTitle.decideEventTitleFont(titleFontInfo.font)
                    this.eventTitleSize = this.$refs.partsEventTitle.decideEventTitleSize(titleFontInfo.size)

                    let options = this.event_data.visitorOptions
                    this.sortableItems = []
                    options.forEach(o => {
                        switch (o.visitorOption) {
                            case "MAIL":
                                this.visitorInputOptions.MAIL.display = true
                                this.visitorInputOptions.MAIL.required = true
                                if(o.label != null){
                                    this.visitorInputOptions.MAIL.label = o.label
                                }
                                break;
                            case "MAIL_CONFIRMATION":
                                this.visitorInputOptions.MAIL_CONFIRMATION.display = true
                                this.visitorInputOptions.MAIL_CONFIRMATION.required = true
                                if(o.label != null){
                                    this.visitorInputOptions.MAIL_CONFIRMATION.label = o.label
                                }
                                break;
                            case "LASTNAME":
                                this.visitorInputOptions.LASTNAME.display = true
                                this.visitorInputOptions.LASTNAME.required = true
                                if(o.label != null){
                                    this.visitorInputOptions.LASTNAME.label = o.label
                                }
                                break;
                            case "FIRSTNAME":
                                this.visitorInputOptions.FIRSTNAME.display = true
                                this.visitorInputOptions.FIRSTNAME.required = true
                                if(o.label != null){
                                    this.visitorInputOptions.FIRSTNAME.label = o.label
                                }
                                break;
                            case "OPT":
                                this.visitorInputOptions.OPT.display = true;
                                break;
                            case "COMPANY":
                            case "DEPT":
                            case "POSITION":
                            case "ADDRESS":
                            case "TEL":
                                this.sortableItems.push({
                                  ...o,
                                  label: (o.label) ? o.label : this.sortableItemDefaultLabelsMap[o.visitorOption]
                                })
                                break;
                            default:
                                if(o.active) {
                                  this.sortableItems.push(o)
                                }
                                break;
                        }
                    });

                    this.sortableItems.sort((a,b) => {
                      return a.entryIndex - b.entryIndex
                    })
                    this.sortableItemValues = []
                    this.sortableItems.forEach(item => {
                      this.sortableItemValues.push(null)
                    })

                    this.setInputRules()
                    if(this.hasTickets()) {
                        if(!this.event_data.closed) {
                            isPaymentEnable({ eventId: this.eventId}).then(res => {
                                this.paymentEnable = res.data;
                                if(this.hasPaidTickets() && !this.paymentEnable) {
                                } else {
                                    getTickets({ eventId: this.eventId}).then(res => {
                                        this.event_data.tickets = res.data;
                                        this.setTicketStatus();
                                    }).catch(err => {
                                        console.log("get tickets catch error. ",err)
                                        this.$router.push({path: '/Error'})
                                    })
                                }
                            }).catch(err => {
                                console.log("is payment enable catch error. ",err)
                                this.$router.push({path: '/Error'})
                            })
                        }
                    }
                }
                else{
                    console.log("empty data.")
                    this.$router.push({path: '/Error'})
                }
            }).catch(err => {
                console.log("err",err)
                //ここで公開サイトチェック
                if (err.response.status === 404 || err.response.status === 410) {
                    this.$router.push({ path: '/Error', query: {status: err.response.status} })
                } else if(err.response.status === 401 || err.response.status === 400) {
                    this.displayAuth = false
                }
            });
        },
        setStyleFixHeader() {
            let fixHeader = document.getElementById("fix-header")
            if(window.scrollY > 0) {
                fixHeader.className = "visible scroll"
            } else {
                fixHeader.className = "visible"
            }

            let fixHeaderEventTitle = document.getElementById("fix-header-event-title")
            if(document.getElementById("spacer-title-bottom").getBoundingClientRect().top <= 80) {
                fixHeaderEventTitle.className = "visible"
            } else {
                fixHeaderEventTitle.className = ""
            }
        },
        getCookiesMap() {
          let map = new Map()
          let values = document.cookie
          values.split(/;[ ]*/).forEach(keyValue => {
            let elems = keyValue.split('=')
            map.set(elems[0], elems[1])
          })
          return map
        },
    },
    mounted() {
      switch(this.$route.query.status) {
        case undefined :
          //初期表示
          writeEventSiteInflowSource(this.publishId, this.$route.query.is)

          setTimeout(() => {
            this.setStyleFixHeader()
          }, 1000)
          window.addEventListener("scroll", this.setStyleFixHeader)
          break
        case "200" :
          getEventId({
            publishId: this.publishId
          }).then(resEventId => {
            this.eventId = resEventId.data

            //決済成功
            getToken({
              username: this.$route.query.username,
              password: this.$route.query.onetime,
              accountType: 'visitor',
              eventId: this.eventId
            }).then(res => {
              let account = JSON.parse(atob(res.data.split('.')[1]));
              completePreOrder({
                data: account.id, uuid: this.$route.query.uuid
              }).then(res => {
                this.$router.push({
                  path: this.publishId + '/MyPage',
                  query: {userId: account.id}
                })
              }).catch(err => {
                // unknown errors
                console.log(err)
                alert("決済後の処理でエラーが発生しました。申し訳ございませんが、主催者までお問い合わせください。");
                this.refreshDisplayAndQueryParameters();
              })
            }).catch(err => {
              // unknown errors
              console.log(err)
              alert("決済後の処理でエラーが発生しました。申し訳ございませんが、主催者までお問い合わせください。");
              this.refreshDisplayAndQueryParameters();
            })
          }).catch(err => {
            //unknown errors
            console.log(err)
            alert("決済後の処理でエラーが発生しました。申し訳ございませんが、主催者までお問い合わせください。");
          })
          break
        case "400" :
          switch(this.$route.query.errorcode) {
            case "INVALID_VALUES" :
              alert("決済に失敗しました。入力内容をお確かめのうえ、再度お試しください。");
              break
            case "CARD_NOT_SUPPORTED" :
              alert("ご入力いただいたカードは使用いただけません。");
              break
            case "CARD_CAN_NOT_USABLE" :
              alert("ご入力いただいたカードは使用いただけません。");
              break
            case "CARD_EXPIRED" :
              alert("カードの有効期限が切れており、決済できませんでした。");
              break
            default :
              break
          }
          this.refreshDisplayAndQueryParameters();
          break
        case "404" :
          alert("セッションが切れました。最新の状態を確認し、再度お試しください。");
          this.refreshDisplayAndQueryParameters();
          break
        case "409" :
          alert("入力されたメールアドレスは既に登録されています。ログインボタンからログインしてください。");
          this.refreshDisplayAndQueryParameters();
          break
        case "410" :
          alert("問題が発生し決済することができませんでした。最新の状態を確認し、再度お試しください。");
          this.refreshDisplayAndQueryParameters();
          break
        case "503" :
          alert("遅延が発生し、決済することができませんでした。恐れ入りますが、最新の状態を確認し、再度お試しください。");
          this.refreshDisplayAndQueryParameters();
          break
        case "500" :
        default :
          alert("問題が発生し決済することができませんでした。申し訳ございませんが、主催者までお問い合わせください。。");
          this.refreshDisplayAndQueryParameters();
          break
      }
    },
    created() {
        this.publishId = this.$route.params.publishId
        this.loadEvent()
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.setStyleFixHeader)
    }
};
</script>

<style scoped>
    #fix-header {
      display: block;
      width: 100%;
      height: 70px;
      border-bottom: none;
      position: fixed;
      top: -70px;
      left: 0px;
      z-index: 100;
      background-color: white;
    }
    #fix-header.visible {
      top: 0px;
      border-bottom: none;

      transition-property: top;
      transition-duration: 0.5s;
    }
    #fix-header.scroll {
      border-bottom: solid 1px #cccccc;
    }
    #fix-header-event-title {
      color: #FFFFFF;
      font-size: 150%;
      font-weight: bold;
      visibility: visible;
      transition-property: color;
      transition-duration: 1s;
    }
    #fix-header-event-title.visible {
      color: #959595;
      visibility: visible;
      transition-duration: 1s;
    }
    .textarea{
        color:#969696;
        margin-top: 60px;
        margin-bottom: 30px;
        font-size:110%;
    }
    .organizer{
      color:#969696;
      margin-top: 0px;
      margin-bottom: 30px;
      font-size:110%;
    }
    .subTitle{
        position: relative;
        text-align: left;
        font-size:130%;
        font-weight:bold;
        color:#959595;
    }
    .subTitle:before {
        border-top: 1px solid;
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
    }
    .subTitle span {
        background-color: white;
        display: inline-block;
        padding: 0 0.5em;
        position: relative;
    }
    .subContentsTitle{
        font-size:110%;
    }
    .contents{
        font-size:150%;
        color:#959595;
        font-weight:bold;
        text-indent:5px;
    }
    .maparea{
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .mapresize{
        width: 560px;
        height: 315px;
        display: block;
        overflow: hidden;
        position: relative;
    }

    .input-area{
        background-color: #F0F0F0;
        padding-top:20px;
        padding-bottom: 50px;
        color:#959595;
    }
    .inputFieldTitle{
        text-align:left;
        font-weight:bold;
    }
    .inputAreaTitle{
        font-size:150%;
        font-weight:bold;
    }
    .linkDecoration{
        text-decoration:none;
        color:#5f9ddd;
        font-weight:bold;
    }
    .modalHeader{
        background-color: #263238;
        color: white;
        height: 70px;
        font-size:160%;
        font-weight:bold;
        filter:alpha(opacity=100);
        -moz-opacity: 1;
        opacity: 1;
    }
    .essential{
        background-color: #f44336;
        color: white;
        font-size: 13px;
        font-weight: bold;
        padding: 4px 0.5em;
        vertical-align: top;
        margin-left: 1.1em;
        border-radius: 10px; /* CSS3草案 */
        -webkit-border-radius: 10px; /* Safari,Google Chrome用 */
        -moz-border-radius: 10px; /* Firefox用 */
    }
    .attention {
        margin-left: 10px;
        font-weight:normal;
    }
    .siteAccessTitle{
        background-color:#263238;
        padding:15px;
        color:white;
        font-size:20px;
    }
    .siteAccessContent{
        margin-bottom:40px;
        margin-top:40px;
        font-size:17px;
    }

    .ticketRadio {
      background-color:#ffffff;
      padding:20px;
      margin-right:0px;
    }
    .ticketRadio span.ticketLabel {
      color: rgba(0,0,0,0.77);
    }
    .ticketDescription {
      color: rgba(0,0,0,0.77);
      padding-left: 30px;
      padding-top: 10px;
      font-size: 90%;
      white-space: pre-wrap;
      word-wrap: break-word;
    }
</style>
