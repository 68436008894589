<template>
  <v-dialog v-model="showSelf" max-width="600px">
    <v-form ref="mainForm" v-model="valid">
      <v-card>
        <v-card-title class="modalHeader">
          <span class="headline">{{variableName}}</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 sm6>
                <v-menu
                  ref="dateMenu"
                  lazy
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  full-width
                  :nudge-right="40"
                  min-width="290px"
                  :return-value.sync="date"
                >
                  <v-text-field
                    slot="activator"
                    v-model="date"
                    :rules="dateRules"
                    readonly
                    clearable
                    label="日付"
                  ></v-text-field>
                  <v-date-picker v-model="date" no-title scrollable @input="$refs.dateMenu.save(date)">
                  </v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs3>
                <v-select
                  :items="hourData"
                  :rules="hourRules"
                  v-model="hour"
                  auto
                  label="時"
                  @change="setTime"
                ></v-select>
              </v-flex>
              <v-flex xs3>
                <v-select
                  :items="minData"
                  :rules="minRules"
                  v-model="min"
                  label="分"
                  @change="setTime"
                ></v-select>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn  @click="close">キャンセル</v-btn>
          <v-btn color="error" @click="save">{{okButtonTitle}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
  import moment from 'moment'
  export default {
    name: "DateTimeDialog",
    props: [
      'variableName',
      'okButtonTitle',
      'isRequired'
    ],
    data() {
      return {
        showSelf: false,
        valid: false,

        hourData:['','00','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23'],
        minData:['','00','05','10','15','20','25','30','35','40','45','50','55'],

        date: null,
        hour: '',
        min: '',

        dateRules: [],
        hourRules: [],
        minRules: [],

      }
    },
    methods: {
      extractDateTime(data) {
        let res = {
          date: null,
          hour: '',
          min: '',
          dateTime: ''
        }

        if(data != null && data != ''){
          let tmpDateTime

          tmpDateTime = moment(data)
          res.date = tmpDateTime.format('YYYY-MM-DD')
          res.hour = tmpDateTime.format('HH')
          res.min = tmpDateTime.format('mm')
          res.dateTime = res.date + ' ' + res.hour + ':' + res.min
        }

        return res
      },
      setRules() {
        this.dateRules = [v => this.isDateInput() || '日付が入力されていません']
        this.hourRules = [v => this.isHourInput() || '時間が入力されていません']
        this.minRules = [v => this.isMinInput() || '分が入力されていません']

        if(this.isRequired) {
          this.dateRules.push(
            v => this.isInput() || this.variableName + 'は必須です'
          )
        }

      },
      open(data) {
        let extractedDateTime = this.extractDateTime(data);
        this.date = extractedDateTime.date;
        this.hour = extractedDateTime.hour;
        this.min = extractedDateTime.min;
        this.dateTime = extractedDateTime.dateTime;

        this.setRules()

        this.showSelf = true;
      },
      close() {
        this.showSelf = false;
      },
      save() {
        if(this.$refs.mainForm.validate()) {
          this.dateTime = ''
          if(this.date != null){
            this.dateTime = this.date + ' ' + this.hour + ':' + this.min
          }

          this.$emit('save', this.dateTime);
          this.close();
        }
      },
      setTime(){
      },
      isInput(){
        if(this.min === '' && this.hour === '' && this.date === null) {
          return false
        }
        return true
      },
      isDateInput(){
        if ((this.min !== '' || this.hour !== '') && this.date === null) {
          return false
        }
        return true
      },
      isHourInput(){
        if ((this.date !== null || this.min !== '') && this.hour === '') {
          return false
        }
        return true
      },
      isMinInput(){
        if ((this.date !== null || this.hour !== '') && this.min === '') {
          return false
        }
        return true
      },
    }
  }
</script>

<style scoped>

</style>
