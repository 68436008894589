<template>
    <v-app id="inspire">
        <div class="tool">
            <div class="toolTitle">{{groupData.groupName}}</div>
            <v-layout justify-end v-if="groupData.role === 'Owner'" class="pr-4">
                <v-tooltip bottom>
                    <v-btn class="mt-3" slot="activator" @click.stop="openEditGroupDialog" icon>
                        <v-icon color="white" lot="activator" medium>edit</v-icon>
                    </v-btn>
                    <span>グループ<br>編集</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <v-btn class="mt-3" slot="activator"  @click="deleteGroup" icon>
                        <v-icon color="white" lot="activator" medium>delete_forever</v-icon>
                    </v-btn>
                    <span>グループ<br>削除</span>
                </v-tooltip>
            </v-layout>
        </div>

        <v-container fluid>
            <v-flex>
                <p class="title"><v-icon large>group</v-icon><span class="titleName">グループ管理</span></p>

                <v-container grid-list-md style="padding:4px;margin-left:0px;margin-right:0px;max-width:100%;">
                <v-layout align-end justify-end row fill-height v-if="groupData.role === 'Owner'">
                    <v-tooltip bottom>
                        <v-btn slot="activator"  @click.stop="showAddMemberDialog = true" icon>
                            <v-icon color="grey darken-1" lot="activator" large>person_add</v-icon>
                        </v-btn>
                        <span>グループメンバー<br>招待</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <v-btn slot="activator"  @click="deleteMember" icon>
                            <v-icon color="grey darken-1" lot="activator" large>person_add_disabled</v-icon>
                        </v-btn>
                        <span>グループメンバー<br>削除</span>
                    </v-tooltip>
                </v-layout>
                </v-container>

                <v-dialog v-model="showAddMemberDialog" max-width="500px">
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <v-card>
                            <v-card-title class="modalHeader">
                                <span class="headline">グループメンバー招待</span>
                            </v-card-title>
                        <v-card-text>
                        <v-container grid-list-md>
                            <v-layout wrap>
                                <v-flex md12>
                                    <v-text-field
                                        v-model="inviteForm.username"
                                        :rules="mailAddressRules"
                                        label="メールアドレス"
                                        required>
                                    </v-text-field>
                                </v-flex>
                                <v-spacer></v-spacer>
                                <v-flex md12>
                                  <v-select
                                    v-model="inviteForm.role"
                                    :items="roles"
                                    item-value="id"
                                    item-text="id"
                                    label="権限"
                                    required>
                                  </v-select>
                                </v-flex>
                                <v-spacer></v-spacer>
                                <v-flex md12 class="text-md-center">
                                    <v-btn class="edit-b" @click="close">キャンセル</v-btn>
                                    <v-btn color="error" :disabled="!valid" @click="invite">招待</v-btn>
                                </v-flex>
                            </v-layout>
                        </v-container>
                        </v-card-text>
                        </v-card>
                    </v-form>
                </v-dialog>

                <v-dialog
                        v-model="showEditGroupDialog"
                        :close-on-content-click="false"
                        :nudge-width="200"
                        offset-x
                        max-width="400"
                        style="margin-top:-5px;margin-bottom:-5px;"
                    >
                    <v-card>
                        <v-card-title class="modalHeader">
                            グループ情報編集
                        </v-card-title>
                        <v-card-text>
                            <v-container grid-list-md>
                                <v-form ref="groupEditForm" v-model="validGroupEdit">
                                <div>
                                    <v-text-field
                                        v-model="groupEditForm.groupName"
                                        :rules="groupNameRules"
                                        label="グループ名"
                                        required
                                    ></v-text-field>
                                </div>
                                <div>
                                    <v-layout align-start justify-start>イベント色</v-layout>
                                    <v-layout align-center justify-center class="mt-2 mb-4">
                                        <compact-picker style="width:250px" v-model="editGroupColor" />
                                    </v-layout>
                                </div>
                                <v-spacer></v-spacer>
                                <div>
                                    <v-btn class="edit-b" @click="closeEditGroup">閉じる</v-btn>
                                    <v-btn color="error" :disabled="!validGroupEdit" @click="editGroup">保存</v-btn>
                                </div>
                                </v-form>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-dialog>

                <v-data-table
                    :headers="headers"
                    :items="groupData.members"
                    :rows-per-page-items="rowsPerPageItems"
                    :pagination.sync="pagination"
                    v-model="selected"
                    item-key="organizer.username"
                    must-sort
                >
                    <template slot="items" slot-scope="props">
                        <tr :active="props.item.role == 'Owner' ? null : props.selected" @click="props.item.role == 'Owner' ? null : props.selected = !props.selected">
                            <td class="text-md-left" width="20">
                                <v-checkbox
                                    :input-value="props.item.role == 'Owner' ? null : props.selected"
                                    primary
                                    hide-details
                                    :disabled="props.item.role == 'Owner'"
                                >
                                </v-checkbox>
                            </td>
                            <td class="text-md-left">&nbsp;{{ props.item.organizer.lastName }}&nbsp;{{ props.item.organizer.firstName }}</td>
                            <td class="text-md-left">{{ props.item.organizer.username }}</td>
                            <td class="text-md-left">{{ props.item.organizer.affiliation }}</td>
                            <td class="text-md-left">{{ props.item.role }}</td>
                            <td class="text-md-left">{{ formatDate(props.item.createdAt) }}</td>
                        </tr>
                    </template>
                </v-data-table>
            </v-flex>
        </v-container>
</v-app>
</template>

<script>
    import {Compact} from 'vue-color'
    import {getMe, getGroup, updateGroup, deleteGroupMember, inviteGroupMember, deleteGroup, getAffiliationRoles} from '../utils/APIUtils'

    export default {
        name: 'Group',
        components: {
            'compact-picker': Compact
        },
        data() {
            return {
                headers: [
                    {
                        text: '',
                        value: '',
                        sortable: false,
                    },
                    {
                        text: '氏名',
                        value: 'organizer.lastName'
                    },
                    {
                        text: 'メールアドレス',
                        value: 'organizer.username'
                    },
                    {
                        text: '会社名',
                        value: 'affiliation'
                    },
                    {
                        text: '権限',
                        value: 'role'
                    },
                    {
                        text: '登録日時',
                        value: 'createdAt'
                    },
                ],
                roles: [],
                selected: [],
                rowsPerPageItems: [10, 25, 50,100,1000,5000],
                pagination:{
                    rowsPerPage: 100,
                    sortBy:'createdAt',
                    descending: true,
                },
                editGroupColor:'',
                groupData:{},

                showAddMemberDialog:false,
                valid:false,
                inviteForm: {
                    username: null,
                    groupId: null,
                    role: null,
                },

                showEditGroupDialog:false,
                validGroupEdit:false,
                groupEditForm:{},

                groupNameRules: [
                    v => !!v || 'グループ名は必須です',
                    v => (v && v.length <= 128)|| '128文字以下で入力してください'
                ],
                mailAddressRules: [
                    v => !!v || 'メールアドレスは必須です',
                    v => /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'メールアドレスの形式で入力を行ってください。'
                ],
                passwordRules: [
                    v => !!v || "パスワードは必須です",
                    v => (v && v.length <= 55 && v.length >= 8 && /^(?=.*?[a-z])(?=.*?\d)[a-z\d]{8,55}$/i.test(v))|| 'パスワードは半角英字・数字をどちらも使用した文字列で、8-55文字以内で設定してください'
                ],
                lastNameRules: [
                    v => !!v || "姓は必須です",
                    v => (v && v.length <= 128)|| '128文字以下で入力してください'
                ],
                firstNameRules: [
                    v => !!v || "名は必須です",
                    v => (v && v.length <= 128)|| '128文字以下で入力してください'
                ],
                affliationRules: [
                    v => !!v || "会社名は必須です",
                    v => (v && v.length <= 128)|| '128文字以下で入力してください'
                ],
            }
        },
        methods: {
          loadGroup(groupId) {
              this.selected = []
              getGroup({id: groupId})
              .then(res => {
                  this.groupData = res.data
                  this.groupEditForm = Object.assign({}, this.groupData)
                  this.editGroupColor = this.groupData.groupColor
                  getAffiliationRoles().then(res => {this.roles=res.data})
              }).catch(err => {
                  console.log("err",err)
                  alert('データの取得に失敗しました。')
                  this.$router.push("/")
              })
          },
          formatDate(date) {
              return date ? new Date(date).toLocaleString() : date;
          },
          invite() {
              if(!this.$refs.form.validate()) return

              inviteGroupMember({data:this.inviteForm})
              .then(res => {
                this.loadGroup(this.groupData.id)
                alert('ユーザをグループに招待しました。')
                this.close()
              }).catch(err => {
                if (err.response.status === 409) {
                  alert("入力されたメールアドレスは既に登録されています。")
                  this.close()
                } else if (err.response.status === 400 && err.response.data == 'exceeded plan limit') {
                  alert("プランのメンバー数上限に達したため招待に失敗しました。")
                  this.close()
                } else {
                  alert('招待処理に失敗しました。')
                  this.close()
                }
              })
          },
          deleteMember() {
              if (this.selected.length < 1) {
                alert("削除対象を１つ以上選択してください。")
                return
              }
              if (!confirm('選択したユーザをグループから削除してもよろしいですか？')) return

              let tmp = this.selected.filter(m => { return (m.role === 'Owner') })
              if (tmp.length > 0) {
                alert("削除対象にOwnerが含まれています。")
                return
              }

              let organizerIds = []
              this.selected.forEach(m => {organizerIds.push(m.organizer.id)})

              deleteGroupMember({data: {organizerIds: organizerIds, groupId: this.groupData.id}}).then(() => {
                alert('グループメンバーを削除しました。')
                this.loadGroup(this.groupData.id)
              }).catch(err => {
                alert('グループメンバーの削除に失敗しました。')
              })
          },
          openEditGroupDialog() {
              this.showEditGroupDialog = true

              this.groupEditForm = Object.assign({}, this.groupData)
              this.editGroupColor = this.groupData.groupColor
          },
          editGroup() {
              if (!this.$refs.groupEditForm.validate()) return
							if (!confirm('グループ情報の更新を行なってもよろしいでしょうか？')) return

              if (this.editGroupColor.hex) {
                this.groupEditForm.groupColor = this.editGroupColor.hex
              } else {
                this.groupEditForm.groupColor = this.editGroupColor
              }
              this.groupEditForm["groupId"] = this.groupData.id
              updateGroup({data:this.groupEditForm})
              .then(res => {
                  alert('保存しました。')
                  this.showEditGroupDialog = false
                  this.groupData.groupName = this.groupEditForm.groupName
                  this.groupData.groupColor = this.groupEditForm.groupColor
              }).catch(err => {
                  alert('保存に失敗しました。')
              })
          },
          deleteGroup() {
              if (!confirm('グループに関連した全てのデータが削除されますがよろしいでしょうか？')) return

              deleteGroup({groupId: this.groupData.id}).then(() => {
                  this.$router.push('/')
              }).catch(err => {
                  alert("グループの削除に失敗しました")
              })
          },
          close() {
              this.showAddMemberDialog = false
              //入力情報クリア処理
              this.inviteForm.username = ''
              this.valid=true
          },
          closeEditGroup() {
              this.showEditGroupDialog = false
              //入力情報クリア処理
              this.showEditGroupDialog = false
              this.groupEditForm = Object.assign({}, this.defaultItem)
          }
        },
        mounted() {
            let groupId = this.$route.query.id
            let headers = {accountType: 'organizer'};
            getMe({headers: headers, accountType:'organizer'})
            .then(res => {
                this.inviteForm.groupId = groupId
                this.loadGroup(groupId)
            }).catch(err => {
                this.$router.push('/')
            })
        }
}

</script>
<style scoped>
#inspire{
    color:#666666;
    background-color: white;
}
.tool {
    background-color: #aba9a9;
    color: white;
}
.toolTitle {
   text-align: left;
   float: left;
   font-size: 24px;
   margin-left: 50px;
   padding: 15px 0px;
}
.eventdate{
    text-align:left;
    margin: 10px;
}
.title {
    text-align: left;
    margin-left: 17px;
}
.titleName{
    display:inline-block;
    vertical-align:top;
    padding-top:9px;
    padding-left:5px;
}
td {
    color:#666666;
    white-space: nowrap;
}
.modalHeader{
    background-color: #263238;
    color: white;
    height: 70px;
    font-size:160%;
    font-weight:bold;
    filter:alpha(opacity=100);
    -moz-opacity: 1;
    opacity: 1;
}
</style>
