<template>
  <v-dialog persistent scrollable v-model="showSelf" max-width="700px">
    <v-card>
      <v-card-text>
        <div style="margin:0 50px 50px 50px; padding-top:50px">
          <p class="previewContent" v-html="previewMailContents"></p>
        </div>
        <img src="https://cdn.event-planner.net/haikei_white.png" width="100%">
      </v-card-text>
      <v-card-actions>
        <v-container>
          <v-layout>
            <v-flex xs12>
              <div class="text-xs-center">
                <v-btn @click="showSelf = false">閉じる</v-btn>
              </div>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: "CustomMailTemplatePreviewDialog",
    data() {
      return {
        showSelf: false,
        previewMailContents: '',
      }
    },
    methods: {
      openPreview(content) {
        this.previewMailContents = this.convertToRichText(content)
        this.previewMailContents = this.replaceAll(this.previewMailContents, "{{lastName}}", "(姓)")
        this.previewMailContents = this.replaceAll(this.previewMailContents, "{{firstName}}", "(名)")
        this.previewMailContents = this.replaceAll(this.previewMailContents, "{{organizationFullName}}", "(会社名)")
        this.previewMailContents = this.replaceAll(this.previewMailContents, "{{departmentName}}", "(部署名)")
        this.previewMailContents = this.replaceAll(this.previewMailContents, "{{jobTitle}}", "(役職名)")
        this.previewMailContents = this.replaceAll(this.previewMailContents, "{{eventTitle}}", "(イベント名)")
        this.previewMailContents = this.replaceAll(this.previewMailContents, "{{eventDate}}", "(イベント開始日)")
        this.previewMailContents = this.replaceAll(this.previewMailContents, "{{eventEndDate}}", "(イベント終了日)")
        this.previewMailContents = this.replaceAll(this.previewMailContents, "{{startTime}}", "(イベント開始時刻)")
        this.previewMailContents = this.replaceAll(this.previewMailContents, "{{endTime}}", "(イベント終了時刻)")
        this.previewMailContents = this.replaceAll(this.previewMailContents, "{{eventFacility}}", "(開催場所)")
        this.previewMailContents = this.replaceAll(this.previewMailContents, "{{eventMyPageUrl}}", "(マイページへのリンク)")
        this.previewMailContents = this.replaceAll(this.previewMailContents, "{{myQrcodeUrl}}", '<img src="https://s3-ap-northeast-1.amazonaws.com/mixlite-dev-mock-map/qrcode.jpg">')
        this.previewMailContents = this.replaceAll(this.previewMailContents, "{{invitationPageUrl}}", '(招待ページリンク)')

        this.showSelf = true
      },
      replaceAll(content, target, value) {
        let reg = new RegExp(target, "g");
        return content.replace(reg, value);
      },
      convertToRichText(textareaData){
        if(!textareaData) return ''

        //ダブルクォートをシングルクォートに置換
        textareaData = textareaData.replace(/"/g, '\'')
        //改行コードをタグに置換
        textareaData = textareaData.replace(/\r?\n/g, '<br/>')
        return textareaData
      },
    }
  }
</script>

<style scoped>
  .previewContent {
    white-space: pre-wrap;
    text-align: left;
    font-size: 15px;
  }
</style>
