export default {
    setCompanyRule(required) {
        let companyNameRules = []
        if (required) {
            companyNameRules.push(v => !!v || "必須事項です")
        }
        companyNameRules.push(v => {
            if (v != null && v != undefined && v != '') {
                return (v.length <= 128)|| "128文字以下で入力してください"
            } else {
                return true
            }
        })

        return companyNameRules
    },
    setDepartmentRule(required) {
        let departmentRules = []
        if (required) {
            departmentRules.push(v => !!v || "必須事項です")
        }
        departmentRules.push(v => {
            if (v != null && v != undefined && v != '') {
                return (v.length <= 128)|| "128文字以下で入力してください"
            } else {
                return true
            }
        })

        return departmentRules
    },
    setPositionRule(required) {
        let positionRules = []
        if (required) {
            positionRules.push(v => !!v || "必須事項です")
        }
        positionRules.push(v => {
            if (v != null && v != undefined && v != '') {
                return (v.length <= 128)|| "128文字以下で入力してください"
            } else {
                return true
            }
        })

        return positionRules
    },
    setAddressRule(required) {
        let addressRules = []
        if (required) {
            addressRules.push(v => !!v || "必須事項です")
        }
        addressRules.push(v => {
            if (v != null && v != undefined && v != '') {
                return (v.length <= 128)|| "128文字以下で入力してください"
            } else {
                return true
            }
        })

        return addressRules
    },
    setPhoneRule(required) {
        let phoneRules = []
        if (required) {
            phoneRules.push(v => !!v || "TELは必須事項です")
        }
        phoneRules.push(v => {
            if (v != null && v != undefined && v != '') {
                return (/^(?:\+?\d+-)?\d+(?:-\d+){2}$|^\+?\d+$/i.test(v)) || "電話番号のフォーマットで入力してください"
            } else {
                return true
            }
        })

        return phoneRules
    },
    setFreeItemRule(required) {
        let rules = []
        if (required) {
            rules.push(v => !!v || "必須事項です")
        }

        return rules
    }
}
