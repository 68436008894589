<template>
  <div>
    <v-toolbar text class="tool" id="app-header">

      <v-toolbar-title class="eventTitle" :title="eventData.eventName">{{ eventData.eventName }}</v-toolbar-title>

      <v-spacer></v-spacer>

           <div class="hidden-md-and-down">
                <v-tooltip bottom>
                    <v-btn icon dark slot="activator" @click.native="moveToEventSite">
                        <v-icon lot="activator">laptop</v-icon>
                    </v-btn>
                    <span>公開サイト表示</span>
                </v-tooltip>

                <v-tooltip bottom>
                    <v-btn icon dark slot="activator" @click.native="moveToRegistrationStatus" v-bind:class='{iconColor:registrationStatus}' >
                        <v-icon lot="activator">how_to_reg</v-icon>
                    </v-btn>
                    <span>登録状況</span>
                </v-tooltip>

                <v-tooltip bottom v-if="hasTicket">
                    <v-btn icon dark slot="activator" @click.native="moveToTicketSales" v-bind:class='{iconColor:ticketSales}'>
                        <v-icon lot="activator">local_atm</v-icon>
                    </v-btn>
                    <span>参加料徴収状況</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <v-btn icon dark slot="activator" @click.native="moveToCancelInformation" v-bind:class='{iconColor:cancelInformation}'>
                    <v-icon lot="activator">cancel</v-icon>
                  </v-btn>
                    <span>キャンセル状況</span>
                </v-tooltip>

                <v-tooltip bottom v-if="isEditable">
                    <v-btn icon dark slot="activator" @click.native="moveToFileShare" v-bind:class='{iconColor:fileShare}'>
                        <v-icon lot="activator">backup</v-icon>
                    </v-btn>
                    <span>ファイル共有</span>
                </v-tooltip>

                <v-tooltip bottom v-if="isEditable && allowSharedVideo">
                    <v-btn icon dark slot="activator" @click.native="moveToSharedVideo" v-bind:class='{iconColor:sharedVideo}'>
                        <v-icon lot="activator">subscriptions</v-icon>
                    </v-btn>
                    <span>動画共有</span>
                </v-tooltip>

                <v-tooltip bottom v-if="isEditable">
                    <v-btn icon dark slot="activator" @click.native="moveToMailHistory" v-bind:class='{iconColor:mailHistory}'>
                        <v-icon>contact_mail</v-icon>
                    </v-btn>
                    <span>メール配信状況</span>
                </v-tooltip>

                <v-tooltip bottom v-if="isEditable">
                    <v-btn icon dark slot="activator" @click.native="eventEdit">
                        <v-icon>edit</v-icon>
                    </v-btn>
                     <span>イベントサイト編集</span>
                </v-tooltip>
                <v-tooltip bottom v-if="isEditable">
                    <v-btn icon dark slot="activator" @click.native="eventCopy">
                        <v-icon>file_copy</v-icon>
                    </v-btn>
                    <span>イベントサイトコピー</span>
                </v-tooltip>
                <v-tooltip bottom v-if="isEditable">
                    <v-btn icon dark slot="activator" @click.native="eventDelete">
                        <v-icon>delete_forever</v-icon>
                    </v-btn>
                    <span>イベントサイト削除</span>
                </v-tooltip>
            </div>

            <div class="hidden-lg-and-up">
              <v-menu bottom>
                <v-btn
                slot="activator"
                dark
                icon
                >
                  <v-icon>more_vert</v-icon>
                </v-btn>

                <v-list>
                    <v-list-tile @click="moveToEventSite">
                        <v-list-tile-title class="dropdown-menu-item-title">
                          <v-icon small>laptop</v-icon>
                          <span>公開サイト表示</span>
                        </v-list-tile-title>
                    </v-list-tile>
                    <v-list-tile @click="moveToRegistrationStatus">
                        <v-list-tile-title class="dropdown-menu-item-title">
                          <v-icon small v-bind:class='{iconColor:registrationStatus}'>how_to_reg</v-icon>
                          <span>登録状況</span>
                        </v-list-tile-title>
                    </v-list-tile>
                    <v-list-tile @click="moveToTicketSales" v-if="hasTicket">
                      <v-list-tile-title class="dropdown-menu-item-title">
                          <v-icon small v-bind:class='{iconColor:ticketSales}'>local_atm</v-icon>
                          <span>参加料徴収状況</span>
                        </v-list-tile-title>
                    </v-list-tile>
                    <v-list-tile @click="moveToCancelInformation">
                      <v-list-tile-title class="dropdown-menu-item-title">
                        <v-icon small v-bind:class='{iconColor:cancelInformation}'>cancel</v-icon>
                        <span>キャンセル状況</span>
                      </v-list-tile-title>
                    </v-list-tile>
                    <v-list-tile @click="moveToFileShare" v-if="isEditable">
                      <v-list-tile-title class="dropdown-menu-item-title">
                          <v-icon small v-bind:class='{iconColor:fileShare}'>backup</v-icon>
                        <span>ファイル共有</span>
                        </v-list-tile-title>
                    </v-list-tile>
                    <v-list-tile @click="moveToSharedVideo" v-if="isEditable && allowSharedVideo">
                      <v-list-tile-title class="dropdown-menu-item-title">
                        <v-icon small v-bind:class='{iconColor:sharedVideo}'>subscriptions</v-icon>
                        <span>動画共有</span>
                      </v-list-tile-title>
                    </v-list-tile>
                    <v-list-tile @click="moveToMailHistory" v-if="isEditable">
                      <v-list-tile-title class="dropdown-menu-item-title">
                        <v-icon small v-bind:class='{iconColor:mailHistory}'>contact_mail</v-icon>
                        <span>メール配信状況</span>
                      </v-list-tile-title>
                    </v-list-tile>
                    <v-list-tile @click="eventEdit" v-if="isEditable">
                      <v-list-tile-title class="dropdown-menu-item-title">
                          <v-icon small>edit</v-icon>
                        <span>イベントサイト編集</span>
                        </v-list-tile-title>
                    </v-list-tile>
                    <v-list-tile @click="eventCopy" v-if="isEditable">
                      <v-list-tile-title class="dropdown-menu-item-title">
                          <v-icon small>file_copy</v-icon>
                        <span>イベントコピー</span>
                        </v-list-tile-title>
                    </v-list-tile>
                    <v-list-tile @click="eventDelete" v-if="isEditable">
                      <v-list-tile-title class="dropdown-menu-item-title">
                          <v-icon small>delete_forever</v-icon>
                        <span>イベントサイト削除</span>
                        </v-list-tile-title>
                    </v-list-tile>
               </v-list>
            </v-menu>
        </div>

    </v-toolbar>

    <div class="eventdate">
        <span style="margin-left: 37px;">開催日</span>
        <span>｜</span>
        <span>
                {{eventData.eventDate}}
                <template v-if="this.eventData.eventEndDate != null">
                    〜 {{eventData.eventEndDate}}
                </template>
        </span>

        <span style="margin-left: 50px;">開催場所</span>
        <span>｜</span>
        <span>{{formatFacilityName(eventData.facility)}}</span>

        <template v-if="onlineEventPlatform == 'VCUBE'">
            <span style="margin-left: 50px;">認証用リファラURL</span>
            <span>｜</span>
            <span>{{vcubeEvent.referrerUrl}}</span>
        </template>

        <template v-if="onlineEventPlatform == 'ZOOM'">
            <span style="margin-left: 50px;">ZOOMウェビナーID</span>
            <span>｜</span>
            <template v-if="eventData.zoomEvent && eventData.zoomEvent.zoomWebinarId">
                <span>{{eventData.zoomEvent.zoomWebinarId}}</span>
            </template>
            <template v-else>
                <span>( 未設定 )</span>
            </template>

            <template v-if="zoomReflectionStatus">
                <span style="margin-left: 50px;">ZOOMアカウント</span>
                <span>｜</span>
                <template v-if="eventData.zoomEvent && eventData.zoomEvent.zoomAccount">
                    <span>{{eventData.zoomEvent.zoomAccount.email}}</span>
                </template>
                <template v-else>
                    <span>( 未設定 )</span>
                </template>
            </template>
            <template v-else>
                <span style="margin-left: 50px;">
                    <v-btn class="ma-0" color="blue lighten-1" dark rounded slot="activator" @click.native="moveToZoomReflectionStatus">
                        <v-icon small>videocam</v-icon>
                        ZOOM連携設定と反映状況
                    </v-btn>
                </span>
            </template>
        </template>
    </div>
    <v-divider class="border"></v-divider>
  </div>
</template>

<script>
import {
  deleteEvent,
  getEventHeader,
  getReferrerUrlVcube,
  isThisEventPaymentEnable
} from '../utils/APIUtils'
export default {
    props: ['eventData',
            'registrationStatus',
            'ticketSales',
            'cancelInformation',
            'mailHistory',
            'fileShare',
            'sharedVideo',
            'zoomReflectionStatus'
            ],
    name: 'EventHeader',
    data() {
        return {
            eventId:null,
            eventSiteUrl: '',
            hasTicket: false,
            hasPaidTicket: false,
            isEditable: false,
            role: '',
            onlineEventPlatform: '',
            allowSharedVideo: false,

            vcubeEvent: {
              referrerUrl: ""
            }
        }
    },
    methods: {
        formatFacilityName(facility) {
            if(facility == undefined) return ""

            return facility.facilityName ? facility.facilityName : '来場なし'
        },
        isRoleEditable(role) {
            return (role == 'Owner' || role == 'Member')
        },
        moveToEventSite(){
            window.open(this.eventSiteUrl, '_blank');
        },
        moveToRegistrationStatus(){
            this.$router.push({ path:'RegistrationStatus',query:{id:this.eventId}});
        },
        moveToTicketSales(){
            this.$router.push({ path:'TicketSales',query:{id:this.eventId}});
        },
        moveToCancelInformation(){
          this.$router.push({ path:'CancelInformation',query:{id:this.eventId}});
        },
        moveToFileShare(){
            this.$router.push({ path:'FileShare',query:{id:this.eventId}});
        },
        moveToSharedVideo(){
          this.$router.push({ path:'SharedVideo',query:{id:this.eventId}});
        },
        moveToMailHistory(){
            this.$router.push({ path:'MailHistory',query:{id:this.eventId}});
        },
        moveToZoomReflectionStatus() {
          this.$router.push({ path:'ZoomReflectionStatus',query:{id:this.eventId}});
        },
        eventEdit(){
            this.$router.push({ path:'CreateNewEvent',query:{id:this.eventId}});
        },
        eventCopy(){
          if(this.hasTicket && this.hasPaidTicket) {
            isThisEventPaymentEnable({
              eventId: this.eventId
            }).then(res => {
              if(!res.data) {
                alert('決済サービスが利用できない状態です。参加料徴収が設定されているイベントはコピーできません。')
                return
              }

              this.eventCopyMain()
            }).catch(err => {
              console.log("err", err)
              alert('状態の確認に失敗しました。')
              return
            })
          } else {
            this.eventCopyMain()
          }

        },
        eventCopyMain() {
          this.$router.push({path: 'CreateNewEvent', query: {id: this.eventId, cp: true}})
        },
        eventDelete(){
            if(confirm('イベントの削除を行ってもよろしいですか？')){
                deleteEvent({eventId: this.eventId})
                .then(res => {
                    this.$router.replace({path: '/EventSchedule'})
                })
                .catch(err => {
                  switch(err.response.status) {
                    case 412:
                      alert("購入者が存在する参加券があるため、イベントの削除ができません。");
                      break;
                    default:
                      console.log(err)
                  }
                })
            }
        }
    },
    mounted(){
        this.eventId = this.$route.query.id
        getEventHeader({eventId:this.eventId}).then(res => {
            this.eventSiteUrl = res.data.eventSiteUrl
            this.hasTicket = res.data.hasTicket
            this.hasPaidTicket = res.data.hasPaidTicket
            this.role = res.data.role
            this.isEditable = this.isRoleEditable(this.role)
            this.onlineEventPlatform = res.data.onlineEventPlatform
            this.allowSharedVideo = res.data.allowSharedVideo

            if(this.onlineEventPlatform == "VCUBE") {
                getReferrerUrlVcube({eventId:this.eventId}).then(res => {
                    this.vcubeEvent.referrerUrl = res.data;
                }).catch(err => {
                    console.log(err)
                    alert('データの取得に失敗しました、')
                })
            }

        }).catch(err => {
            console.log(err)
            alert('データの取得に失敗しました。')
        })
    },
}
</script>

<style scoped>
#app-header.tool {
    background-color: #aba9a9;
    color: white;
}
.iconmenu {
  text-align: right;
  padding: 7px 0px;
  margin-right: 25px;
}
.eventdate {
  text-align: left;
  margin: 10px;
}
.iconColor {
  border-width: 1px;
  border-style: solid;
  border-color: white;
}
.border {
  border-width: 1px;
  border-style: solid;
  border-color: #BDBDBD;
}

@media screen and (min-width: 1264px) {
   .eventTitle{
        width:50%;
        text-align:left;
    }
}

@media screen and (max-width: 1263px) {
    .eventTitle{
        width:100%;
        text-align:left;
    }
}

.dropdown-menu-item-title {
  height: 30px;
}
.dropdown-menu-item-title >>> .v-icon {
  border-radius: 50%;
  border-style:none;
  padding: 5px;
}
.dropdown-menu-item-title >>> .v-icon.iconColor {
  border-style:solid;
  padding: 3px;
  border-width:2px;
  border-color:grey;
}
.dropdown-menu-item-title >>> span {
  margin-left: 5px;
}

</style>
