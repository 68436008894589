import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import {getEvents, getMe} from '../../utils/APIUtils'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    eventId: null,
    data: null
  },
  getters: {
    eventId (state) {
      return state.eventId
    },
    data (state) {
      return state.data
    },
    loggedin (state) {
      return !!state.data
    },
    isAdmin(state){
      if(!state.data || !state.data.authorities) return false
      return state.data.authorities.includes("ROLE_ORGANIZER_ADMIN")
    },
    isEditor(state){
      if(!state.data || !state.data.authorities) return false
      return state.data.authorities.includes("ROLE_ORGANIZER_ADMIN")
        || state.data.authorities.includes("ROLE_ORGANIZER_EDITOR")
    },
    isViewer(state){
      if(!state.data || !state.data.authorities) return false
      return state.data.authorities.includes("ROLE_ORGANIZER_ADMIN")
        || state.data.authorities.includes("ROLE_ORGANIZER_EDITOR")
        || state.data.authorities.includes("ROLE_ORGANIZER_READONLY")
    }
  },
  mutations: {
    setEventId (state, eventId) {
      state.eventId = eventId
    },
    loggedin(state, data){
      state.data = data
    }
  },
  actions: {
    async getMe ({commit, dispatch}) {
      // try {
      //   let {data} = await axios.get('/api/visitor/me')
      //   commit('login', data)
      // } catch (e) {
      //   commit('logout')
      // }
      console.log("store getMe")

      let headers = {accountType: 'organizer'}
      getMe({headers: headers, accountType: 'organizer'})
        .then(res => {
          // this.role = res.data.role
          // this.$emit('loggedin', res.data)
            commit('loggedin', res.data)
          // this.$router.replace({path: '/EventList'})
          // this.organizer = res
          // this.authorities = this.organizer.data.authorities
        })
        .catch(err => {
          console.log('get organizer catch ', err)
          // this.$router.push('/')
        })
    },
  }
})
