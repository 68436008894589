<template>

  <AdminSidebar>

    <v-container fluid>
      <v-flex>
        <p class="title">
          <v-icon large>mdi-how-to-reg</v-icon>
          <span class="titleName">登録状況</span>
        </p>


        <h2>ステータス</h2>
        <v-container>
          <v-row>
            <v-col>
              <v-select label="提出ステータス" :items="statuses" v-model="status"></v-select>
            </v-col>
            <v-col cols="3" v-if="isEditor">
              <v-btn color="primary" @click.stop="changeStatus">ステータスを更新する</v-btn>
            </v-col>
          </v-row>
          <v-row v-if="detail.ticketOrder">
            <v-col>
              <v-text-field label="決済ステータス" v-model="detail.ticketOrder.result" readonly>
                <template v-slot:append v-if="isEditor && detail.ticketOrder.result == 'WaitForPayment'">
                  <v-btn text @click="refPayment">入金を確認する</v-btn>
                </template>
                <template v-slot:append v-if="detail.ticketOrder.result == 'Success'">
                  <v-btn text @click="downloadReceipt">領収証</v-btn>
                </template>


              </v-text-field>
              <v-text-field label="支払い方法" v-model="detail.ticketOrder.paymentMethod" readonly></v-text-field>
              <v-text-field label="オーダーID" v-model="detail.ticketOrder.uuid" readonly></v-text-field>
              <v-text-field label="ソニーペイメントプロセスID" v-model="detail.ticketOrder.transactionId" readonly></v-text-field>
              <v-text-field label="品名" v-model="detail.ticketOrder.name" readonly></v-text-field>
              <v-text-field label="価格" v-model="detail.ticketOrder.price" readonly></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea label="メモ" v-model="memo"></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span v-for="item in documents" :key="item.fileId" class="mr-1">
              <v-chip :href="item.url" close
                      @click:close="removeDocument(item)">
                {{ item.key|fileName }}
              </v-chip>
              </span>
              <v-file-input show-size
                            label="添付ファイルアップロード"
                            @change="upload"></v-file-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn color="primary" @click.stop="updateMemo">メモを更新する</v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field label="メールアドレス" :value="detail.username" readonly></v-text-field>
            </v-col>
          </v-row>
        </v-container>

        <h2>受講票</h2>
        <v-container>
          <v-flex xl8 offset-xl2 xs12>
            <h3 class="mt-5">受験番号 {{ detail.examineesNumber }}</h3>

            <v-img :src="`/api/visitor/${eventId}/qr/${visitorId}`" width="150" height="150" class="ma-auto"></v-img>

            <v-btn rounded
                   color="blue-grey"
                   tag="a"
                   :href="`/api/visitor/${eventId}/ticket/${visitorId}`"
                   target="_blank"
                   class="white--text buttonStyle">一次試験受験票ダウンロード
            </v-btn>
          </v-flex>
          <v-flex xl8 offset-xl2 xs12 class="mt-3"
                  v-if="detail.examResult && detail.examResult.canTakeOralExam">
            <v-btn rounded
                   color="blue-grey"
                   tag="a"
                   :href="`/api/visitor/${eventId}/oral_ticket/${visitorId}`"
                   target="_blank"
                   class="white--text buttonStyle">二次試験受験票ダウンロード
            </v-btn>
          </v-flex>
        </v-container>

        <v-container v-if="detail.examResult">
          <h2>試験結果</h2>
          <template v-if="detail.examResult.resultDetail">
            <v-row>
              <v-col md="4" sm="12">
                <v-text-field label="受験外国語一次試験結果" v-model="detail.examResult.resultDetail.writtenLanguageResult"
                              readonly></v-text-field>
              </v-col>
              <v-col md="4" sm="12">
                <v-text-field label="日本地理一次試験結果" v-model="detail.examResult.resultDetail.writtenGeographyResult"
                              readonly></v-text-field>
              </v-col>
              <v-col md="4" sm="12">
                <v-text-field label="日本歴史一次試験結果" v-model="detail.examResult.resultDetail.writtenHistoryResult"
                              readonly></v-text-field>
              </v-col>
              <v-col md="4" sm="12">
                <v-text-field label="一般常識一次試験結果" v-model="detail.examResult.resultDetail.writtenCommonSenseResult"
                              readonly></v-text-field>
              </v-col>
              <v-col md="4" sm="12">
                <v-text-field label="通訳案内の実務一次試験結果" v-model="detail.examResult.resultDetail.writtenPracticeResult"
                              readonly></v-text-field>
              </v-col>
            </v-row>
          </template>
          <v-row>
            <v-col>
              <v-text-field label="一次試験結果" v-model="detail.examResult.writtenResult" readonly>
                <template v-slot:append>
                  <v-btn v-if="detail.examResult.writtenResult == '合格' && detail.examResult.oralResult == '不合格'"
                         text
                         tag="a"
                         :href="`/api/visitor/${eventId}/certificate/${visitorId}`"
                         target="_blank"
                  >
                    一次合格証書
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field label="二次試験結果" v-model="detail.examResult.oralResult" readonly></v-text-field>
            </v-col>
          </v-row>
          <template v-if="detail.examResult.writtenExamRoom">
            <v-row>
              <v-col>
                <v-text-field label="一次試験会場" v-model="detail.examResult.writtenExamRoom.facilityName" readonly
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field label="一次試験部屋" v-model="detail.examResult.writtenExamRoom.venueName"
                              readonly></v-text-field>
              </v-col>
            </v-row>
          </template>
          <template v-if="detail.examResult.oralExamRoom">
            <v-row>
              <v-col>
                <v-text-field label="二次試験1会場" v-model="detail.examResult.oralExamRoom.facilityName"
                              readonly></v-text-field>
              </v-col>
              <v-col>
                <v-text-field label="二次試験1受付" v-model="detail.examResult.oralExamRoom.venueName"
                              readonly></v-text-field>
              </v-col>
            </v-row>
          </template>
          <template v-if="detail.examResult.oralExamRoom2">
            <v-row>
              <v-col>
                <v-text-field label="二次試験2会場" v-model="detail.examResult.oralExamRoom2.facilityName"
                              readonly></v-text-field>
              </v-col>
              <v-col>
                <v-text-field label="二次試験2受付" v-model="detail.examResult.oralExamRoom2.venueName"
                              readonly></v-text-field>
              </v-col>
            </v-row>
          </template>
          <template v-if="detail.examResult.timeDetail">
            <v-row>
              <v-col md="4" sm="12">
                <v-text-field label="二次試験受付時間" v-model="detail.examResult.timeDetail.oralExam1ReceptionStart"
                              readonly></v-text-field>
              </v-col>
              <v-col md="4" sm="12">
                <v-text-field label="二次試験受付時間 2" v-model="detail.examResult.timeDetail.oralExam2ReceptionStart"
                              readonly></v-text-field>
              </v-col>
              <v-col md="4" sm="12">
                <v-text-field label="二次試験試験時間" v-model="detail.examResult.timeDetail.oralExam1Start"
                              readonly></v-text-field>
              </v-col>
              <v-col md="4" sm="12">
                <v-text-field label="二次試験試験時間 2" v-model="detail.examResult.timeDetail.oralExam2Start"
                              readonly></v-text-field>
              </v-col>
              <v-col md="4" sm="12">
                <v-text-field label="二次試験解散時間" v-model="detail.examResult.timeDetail.oralExamEnd"
                              readonly></v-text-field>
              </v-col>
            </v-row>
          </template>
        </v-container>


        <h2>登録情報
          <!--          <v-dialog-->
          <!--            v-model="editEntryDialog"-->
          <!--            width="700"-->
          <!--            class="white"-->
          <!--          >-->
          <!--            <template v-slot:activator="{ on, attrs }">-->
          <!--              <v-btn icon-->
          <!--                     v-bind="attrs"-->
          <!--                     v-on="on">-->
          <!--                <v-icon>edit</v-icon>-->
          <!--              </v-btn>-->
          <!--            </template>-->
          <!--            <v-card>-->
          <!--              <v-card-text>-->
          <!--              <EntryForm v-model="entry" @toast="$emit('toast')" @error="$emit"></EntryForm>-->
          <!--              </v-card-text>-->
          <!--            </v-card>-->
          <!--          </v-dialog>-->
          <v-btn icon :to="{name:'editEntry', eventId:eventId, visitorId: visitorId}" v-if="isEditor">
            <v-icon>edit</v-icon>
          </v-btn>
        </h2>
        <EntryForm v-model="entry" readonly></EntryForm>


        <template v-if="subjectEntry">
          <h2>科目選択
            <v-btn icon :to="{name:'editSubjectEntry', eventId:eventId, visitorId: visitorId}" v-if="isEditor">
              <v-icon>edit</v-icon>
            </v-btn>
          </h2>

          <v-container>
            <v-row>
              <v-col>
                <v-text-field label="受験外国語" v-model="subjectEntry.language1 && subjectEntry.language1.title"
                              readonly></v-text-field>
              </v-col>
              <v-col>
                <v-text-field label="受験第二外国語" v-model="subjectEntry.language2 && subjectEntry.language2.title"
                              readonly></v-text-field>
              </v-col>
              <v-col>
                <v-text-field label="中国語を受験する方" v-model="subjectEntry.chineseType" readonly></v-text-field>
              </v-col>
            </v-row>
          </v-container>

          <h3>希望都市</h3>
          <v-container>
            <v-row>
              <v-col>
                <v-text-field label="一次試験受験地"
                              v-model="subjectEntry.writtenExamArea.title" readonly
                              v-if="subjectEntry.writtenExamArea"></v-text-field>
                <v-text-field label="一次試験受験地"
                              value="免除" readonly
                              v-else></v-text-field>
              </v-col>
              <v-col>
                <v-text-field label="二次試験受験地"
                              v-model="subjectEntry.oralExamArea.title" readonly
                              v-if="subjectEntry.oralExamArea"></v-text-field>
              </v-col>
            </v-row>
          </v-container>

          <h3>免除科目</h3>
          <v-container>
            <v-row>
              <v-col v-for="s in subjectEntry.requestedSubjects">
                <v-checkbox disabled
                            v-model="subjectEntry.requestedSubjects"
                            :value="s" :label="s.title"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
          <!--        <SubjectForm v-model="subjectEntry"></SubjectForm>-->
          <v-form>
            <v-simple-table class="text-left">
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">申請の種類</th>
                  <th class="text-left">科目</th>
                  <th class="text-left">提出書類</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in subjectEntry.exemptions" :key="item.id">
                  <td>{{ item.exemption.title }}</td>
                  <td>{{ item.exemption.subject }}</td>
                  <td>
                    <div v-if="item.exemption.schema">
                      <v-jsf :schema="item.exemption.schema"
                             :options="{disableAll:true}"
                             readonly
                             v-model="item.data">
                      </v-jsf>
                    </div>
                    <div v-if="item.files">
                    <span v-for="item in item.files" :key="item.fileId">
                      <v-chip :href="item.url">
                      {{ item.key|fileName }}
                      </v-chip>
                    </span>
                    </div>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-form>
        </template>

          <h3>更新履歴</h3>
          <v-simple-table class="text-left">
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left">
                  項目
                </th>
                <th class="text-left">
                  変更前
                </th>
                <th class="text-left">
                  変更後
                </th>
                <th class="text-left">
                  更新者
                </th>
                <th class="text-left">
                  時刻
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                v-for="item in entryChangelogs"
                :key="item.id"
              >
                <td>{{ item.field }}</td>
                <td>{{ item.beforeValue }}</td>
                <td>{{ item.afterValue }}</td>
                <td><span v-if="item.organizer">{{ item.organizer.lastName + item.organizer.firstName }}</span></td>
                <td>{{ new Date(item.createdAt).toLocaleString() }}</td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>

      </v-flex>
    </v-container>
  </AdminSidebar>
</template>
<script>
import EventLayout from './EventLayout'
import AdminSidebar from './AdminSidebar'
import {getVisitor} from '../../utils/APIUtils'
import EntryForm from '../../event/components/EntryForm'
import SubjectForm from '../../event/components/SubjectForm'
import VJsf from '@koumoul/vjsf/lib/VJsf'
import axios from 'axios'
import {mapGetters} from 'vuex'

export default {
  components: {SubjectForm, EntryForm, AdminSidebar, EventLayout, VJsf},
  computed: {
    ...mapGetters([
      'isAdmin', 'isEditor', 'isViewer'
    ]),
  },
  data () {
    return {
      eventId: this.$route.params.eventId,
      visitorId: this.$route.params.visitorId,
      memo: null,
      detail: {},
      entry: {},
      subjectEntry: null,
      entryChangelogs: [],
      rules: {},
      status: null,
      statuses: [{
        value: 'NOT_SUBMIT',
        text: '未提出'
      }, {
        value: 'PENDING',
        text: '承認待ち'
      }, {
        value: 'WAIT_FOR_RESUBMIT',
        text: '再提出依頼'
      }, {
        value: 'ACCEPTED',
        text: '承認'
      }, {
        value: 'HOLD',
        text: '保留'
      }, {
        value: 'REJECTED',
        text: '却下'
      },
      ],
      editEntryDialog: false,
      documents: []
    }
  },
  filters: {
    fileName (v) {
      return v && v.substring(v.lastIndexOf('/') + 1)
    }
  },
  async created () {
    await this.fetchVisitor(this.visitorId)
    await this.fetchMemo()
  },
  methods: {
    async fetchVisitor () {
      let {data} = await getVisitor({
        visitorId: this.visitorId
      })
      this.detail = data
      this.entry = data.entry
      this.subjectEntry = data.subjectEntry
      this.status = data.entryStatus
      this.entryChangelogs = data.entryChangelogs
    },
    async fetchMemo () {
      let {data} = await axios.get('/api/memo/' + this.visitorId)
      this.memo = data && data.data
      this.documents = data && data.documents || []
    },
    async updateMemo () {
      await axios.post('/api/memo/' + this.visitorId, {
        memo: this.memo,
        documents: this.documents
      })
      this.$emit('toast', 'メモを更新しました')
    },
    async changeStatus () {
      if (this.status == 'ACCEPTED') {
        if (!confirm('承認待ちから承認への変更はメールが送信されますがよろしいですか？')) {
          return
        }
      }
      try {
        await axios.post('/api/status/change/' + this.visitorId, {
          status: this.status
        })
        this.$emit('toast', '変更しました')
      } catch (e) {
        this.$emit('error', '更新に失敗しました')
      }
    },
    async refPayment () {
      try {
        let {data} = await axios.post('/api/status/payment/' + this.visitorId)
        if (data.result == 'Success') {
          this.$emit('toast', '入金を確認しました')
          this.detail.ticketOrder = data
        } else {
          this.$emit('toast', '入金は確認できませんでした')
        }
      } catch (e) {
        this.$emit('error', '読み込みに失敗しました')
      }
    },

    async upload (file) {
      if (file.size > 20971520) {
        alert('ファイルサイズは20MB以下でアップロードしてください')
        return
      }
      let params = new FormData()
      params.append('file', file)
      let {data} = await axios.post(`/api/visitor/file/upload/${this.visitorId}`, params)
      this.documents.push({
        fileId: data.id,
        key: file.name
      })
    },
    removeDocument (item) {
      this.documents = this.documents.filter(v => v.fileId != item.fileId)
    },
    downloadReceipt(){
      window.open(`/api/visitor/receipt/${this.visitorId}`, "_blank")
    }
  }
}
</script>
