<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      clipped permanent app
    >
      <v-list
        dense
        nav
      >
        <v-list-item
          v-for="item in items"
          :key="item.title"
          :to="item.to"
          link
          exact-path
          v-show="!item.auth || item.auth(authorities)"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <slot></slot>
  </div>
</template>
<script>

import {mapGetters} from 'vuex'

const hasRole = (arr) => {
  arr = arr || []
  return (roles) => (roles || []).some(r => arr.includes(r))
}

export default {
  data () {
    return {
      eventId: this.$route.params.eventId,
      drawer: false
    }
  },
  computed: {
    ...mapGetters([
      'data'
    ]),
    authorities () {
      return this.data && this.data.authorities
    },
    items () {
      return [
        {
          title: '登録者管理', icon: 'mdi-view-dashboard',
          to: {name: 'event', params: {eventId: this.eventId}}
        },
        {
          title: '出席管理', icon: 'mdi-view-dashboard',
          to: {name: 'attendance', params: {eventId: this.eventId}}
        },
        {
          title: 'お知らせ管理', icon: 'mdi-view-dashboard',
          to: {name: 'info', params: {eventId: this.eventId}},
          auth: hasRole(['ROLE_ORGANIZER_ADMIN', 'ROLE_ORGANIZER_EDITOR'])
        },
        {
          title: '会場管理', icon: 'mdi-view-dashboard',
          to: {name: 'venue', params: {eventId: this.eventId}},
          auth: hasRole(['ROLE_ORGANIZER_ADMIN', 'ROLE_ORGANIZER_EDITOR'])
        },
        {
          title: '日程管理', icon: 'mdi-view-dashboard',
          to: {name: 'timeline'},
          auth: hasRole(['ROLE_ORGANIZER_ADMIN', 'ROLE_ORGANIZER_EDITOR'])
        },
        {
          title: '権限管理', icon: 'mdi-view-dashboard',
          to: {name: 'account'},
          auth: hasRole(['ROLE_ORGANIZER_ADMIN'])
        },
      ]
    }
  },
}
</script>
