<template>
  <v-container>
    <Loading></Loading>
    <h2>受験科目の編集</h2>
    <SubjectForm v-model="subjectEntry" :visitor-id="visitorId" @save="save"></SubjectForm>
  </v-container>
</template>
<script>
import {getVisitor} from '../../utils/APIUtils'
import axios from 'axios'
import Loading from '../../event/components/Loading'
import SubjectForm from '../../event/components/SubjectForm'

export default {
  components: {SubjectForm, Loading},
  data () {
    return {
      loading: false,
      eventId: this.$route.params.eventId,
      visitorId: this.$route.params.visitorId,
      subjectEntry: null
    }
  },
  async created () {
    this.loading = true
    await this.fetchVisitor(this.visitorId)
    this.loading = false
  },
  methods: {
    async fetchVisitor () {
      let {data} = await axios.get(`/api/subject/draft/${this.visitorId}`)
      this.subjectEntry = data
    },
    async save (data) {
      this.loading = true
      try {
        await axios.post(`/api/subject/save/${this.visitorId}`, data)
        this.$emit('toast', '保存しました')
      } catch (e) {
        console.error(e)
        this.$emit('error', 'エラーが発生しました。しばらくしてから再度お試しください')
      } finally {
        this.loading = false
      }
    },
  }
}
</script>
