<template>
  <div>
    EventList

<!--    <v-btn to="/CreateNewEvent">+</v-btn>-->

    <v-card
      class="mx-auto"
      max-width="400"
      tile
    >
      <template v-for="event in events">
        <v-list-item :to="`/event/${event.id}`">
          <v-list-item-content >
            <v-list-item-title>{{event.eventName}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-card>


  </div>
</template>
<script>
import EventLayout from './EventLayout'
import {getEvents, getMe} from '../../utils/APIUtils'
import {Compact} from 'vue-color'

export default {
  components: {EventLayout,
    'compact-picker': Compact},
  data(){
    return {
      groupAddMenu: false,
      events: []
    }
  },
  mounted () {
    let headers = {accountType: 'organizer'}
    getMe({headers: headers, accountType: 'organizer'})
      .then(res => {
        this.role = res.data.role
        this.$emit('loggedin', res.data)
        // this.$router.replace({path: '/EventList'})
        this.organizer = res
        this.authorities = this.organizer.data.authorities
        // this.isAcceptTermOfService()
        //イベント情報を取得
        // this.getBelongingGroupEventData()
        getEvents()
          .then(res => {
            console.log(res.data)
            this.events = res.data;
          })
      })
      .catch(err => {
        console.log('get organizer catch ', err)
        // this.$router.push('/')
      })

  }
}
</script>
