<template>
    <v-app id="inspire">
    <v-card text>
        <div class="tool">
           <div class="toolTitle">操作履歴</div>
        </div>
    </v-card>

    <v-container fluid>
        <v-flex md12>
          <v-data-table
          :headers="headers"
          :items="operationLogs"
          :rows-per-page-items="rowsPerPageItems"
          :pagination.sync="pagination"
          :total-items="totalItems"
          :loading="loading"
          item-key="id"
          >
          <template slot="items" slot-scope="props">
            <tr>
              <td class="text-md-left">{{ formatDate(props.item.createdAt) }}</td>
              <td class="text-md-left">{{ props.item.groupName }}</td>
              <td class="text-md-left">{{ props.item.eventName }}</td>
              <td class="text-md-left">{{ props.item.message }}</td>
              <td class="text-md-left">{{ props.item.userId }}</td>
              <td class="text-md-left">{{ props.item.organizerName }}</td>
            </tr>
          </template>
          </v-data-table>
        </v-flex>
    </v-container>
</v-app>
</template>

<script>
import {getMe,getOperationLogs} from '../utils/APIUtils'
export default {
    name: 'OperationLog',
    data () {
      return {
        operationLogs: [],
        loading:true,
        rowsPerPageItems: [10, 25, 50, 100],
        pagination:{
          rowsPerPage: 100,
        },
        totalItems:0,
        headers: [
            {
                text: '操作日時',
                value: 'createdAt',
                width: 195,
                sortable:false,
            },
            {
                text: 'グループ名',
                value: 'groupName',
                sortable:false,
            },
            {
                text: 'イベント名',
                value: 'eventName',
                sortable:false,
            },
            {
                text: '操作',
                value: 'message',
                sortable:false,
            },
            {
                text: 'ユーザID',
                value: 'userId',
                width: 40,
                sortable:false,
            },
            {
                text: 'ユーザ名',
                value: 'organizerName',
                width: 180,
                sortable:false,
            }
        ],
      }
  },
  watch: {
    pagination: {
      handler () {
        this.getDataFromApi()
      },
      deep: true
    }
  },
  mounted() {
    let headers = {accountType: 'organizer'};
    getMe({headers: headers, accountType: 'organizer'})
    .then(res => {
        this.$emit('loggedin', res.data)
        this.getDataFromApi()
    }).catch(err => {
        this.$router.push('/')
    })
  },
  methods: {
    getLogs(){
      let pagingData = {"offset":this.pagination.page-1, "limit":this.pagination.rowsPerPage};
      getOperationLogs({params: pagingData})
      .then(res => {
          this.operationLogs = res.data.data.map(i => ({
            id: i.id,
            createdAt:i.createdAt,
            groupName: i.groupName,
            eventName: i.eventName,
            message:i.auditLogMessage.message,
            userId: i.organizerId,
            organizerName:i.organizerName,
          }),
          this.totalItems = res.data.count
          )
      })
      .catch(err => {
          console.log(err)
      })
    },
    formatDate(date){
        return date ? new Date(date).toLocaleString() : date;
    },
    getDataFromApi () {
      this.loading = true
      return new Promise((resolve, reject) => {
        const { sortBy, descending, page, rowsPerPage } = this.pagination
        this.getLogs()
        let items = this.operationLogs
        const total = items.length
        if (rowsPerPage > 0) {
          items = items.slice((page - 1) * rowsPerPage, page * rowsPerPage)
        }
        setTimeout(() => {
          this.loading = false
          resolve({
            items,
            total
          })
        }, 1000)
      })
    },
  }
}

</script>

<style scoped>
#inspire{
    color:#666666;
    background-color: white;
}
.tool {
    color: white;
    height: 70px;
    background-color: #aba9a9;
}
.toolTitle {
   text-align: left;
   float: left;
   font-size: 24px;
   margin-left: 50px;
   padding: 15px 0px;
}
.iconmenu {
   text-align: right;
   padding: 7px 0px;
   margin-right: 25px;
}
.icon_design{
  vertical-align:middle;
  line-height:1;
}
.eventdate{
    text-align:left;
    margin: 10px;
}
</style>

