<template>
    <v-app id="inspire">
    <middle_header :eventData=this.eventData fileShare="true"> </middle_header>

    <v-container fluid>
        <v-flex>
        <p class="title"><v-icon large>cloud_upload</v-icon><span class="titleName">ファイル共有</span></p>
        <v-container grid-list-md style="padding:4px;margin-left:0px;margin-right:0px;max-width:100%;">
          <v-layout align-end justify-end row fill-height>
            <v-dialog v-model="dialog" max-width="500px">
              <v-tooltip bottom slot="activator">
                <v-btn slot="activator" @click="openUploadWindow" icon title="アップロード">
                  <v-icon class="iconStyle" large>cloud_upload</v-icon>
                </v-btn>
                <span>アップロード</span>
              </v-tooltip>

              <v-form ref="form" v-model="valid" lazy-validation>
                <v-card>
                  <v-card-title class="modalHeader">
                    <span class="headline">ファイルアップロード</span>
                  </v-card-title>
                    <v-card-text>
                      <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex md12>
                              <input type="file" value="" id="fileElem" @change="onDrop">
                              <div style="color:red;margin-top:15px;" id="errorMessageFile"></div>
                            </v-flex>
                            <v-flex md12>
                            </v-flex>
                            <v-flex md12 class="text-md-center">
                                <v-btn class="edit-b" @click.native="close">閉じる</v-btn>
                                <v-btn :disabled="!buttonStatus" color="error" @click="fileUpload">アップロード</v-btn>
                            </v-flex>
                        </v-layout>
                      </v-container>
                    </v-card-text>
                </v-card>
              </v-form>
            </v-dialog>
            <v-tooltip bottom>
              <v-btn slot="activator" @click.stop="fileDelete" icon light>
                <v-icon class="iconStyle" lot="activator" large>delete</v-icon>
              </v-btn>
              <span>削除</span>
            </v-tooltip>
            <v-tooltip bottom>
              <v-btn slot="activator" @click.stop="sortSharedFiles" icon light>
                <v-icon class="iconStyle" lot="activator" large>format_list_numbered</v-icon>
              </v-btn>
              <span>表示順変更</span>
            </v-tooltip>
            <v-tooltip bottom>
              <v-btn slot="activator" @click.stop="fileShare" icon light>
                <v-icon class="iconStyle" lot="activator" large>folder_shared</v-icon>
              </v-btn>
              <span>共有</span>
            </v-tooltip>
            <v-tooltip bottom>
              <v-btn slot="activator" @click.stop="fileUnShare" icon light>
                <v-icon class="iconStyle" lot="activator" large>folder_open</v-icon>
              </v-btn>
              <span>共有解除</span>
            </v-tooltip>
          </v-layout>
        </v-container>
        <v-data-table
          :headers="headers"
          :items="eventFiles"
          v-model="selected"
          :rows-per-page-items="rowsPerPageItems"
          :pagination.sync="pagination"
          item-key="id"
          :must-sort="true"
          :custom-sort="customSort"
          select-all
        >
        <template slot="items" slot-scope="props">
        <tr :active="props.selected" @click="props.selected = !props.selected">
          <td class="text-md-left" width="20">
            <v-checkbox
            :input-value="props.selected"
            hide-details
            ></v-checkbox>
          </td>
          <td class="text-md-left"><a :href="props.item.fileUrl" target="_blank">{{ props.item.fileName }}</a></td>
          <td class="text-md-left">{{ formatSharedOutput(props.item.shared) }}</td>
          <td class="text-md-left">{{ props.item.sortIndex }}</td>
          <td class="text-md-left">{{ formatDate(props.item.createdAt) }}</td>
          <td class="text-md-left">{{ formatDate(props.item.updatedAt) }}</td>
        </tr>
        </template>
        </v-data-table>

        </v-flex>
    </v-container>

      <save_and_sort_dialog ref="sortDialog"
                        @succeed="onSucceedSortDialog"
                        @failed="onFailedSortDialog"
      ></save_and_sort_dialog>

</v-app>
</template>

<script>
import header from '@/components/EventHeader.vue'
import saveAndSortDialog from '@/components/FileShareSortDialog'
import {getMe,getEvent,uploadFile,getFileList,deleteFile,readyEditEvent} from '../utils/APIUtils'
export default {
  name: 'MailHistory',
  components: {
        'middle_header': header,
        'save_and_sort_dialog': saveAndSortDialog
  },
  data () {
      return {
        search: '',
        selected: [],
        rowsPerPageItems: [10, 25, 50,100],
        pagination:{
          rowsPerPage: 100,
        },
        dialog:false,
        valid:false,
        buttonStatus:true,

        eventId:null,
        eventData:{},
        eventName:'',
        eventDate:'',
        eventFacility:'',

        fileList:'',

        headers: [
          {
             text: 'ファイル名',
             value: 'fileName',
          },
          {
             text: '共有状態',
             value: 'shared'
          },
          {
            text: '表示順',
            value: 'sortIndex'
          },
          {
             text: '登録日時',
             value: 'createdAt'
          },
          {
             text: '更新日時',
             value: 'updatedAt'
          }
        ],
        eventFiles:[],
        sharedLists:[],
      }
  },
  watch: {
      dialog (val) {
        val || this.close()
      }
  },
  methods: {
      openUploadWindow () {
          this.fileList=''
          let input = document.getElementById("fileElem")
          input.value =""
      },
      fileUpload(){
        if(this.fileList!=''){
            let input = document.getElementById("fileElem")
            let file = input.files[0]

            if(file.name.length > 100) {
                alert("ファイル名が長過ぎます。ファイル名は100文字以内で指定してください。")
                return;
            }

            let eventId = this.eventId
            uploadFile({file, eventId})
            .then(res => {
                this.loadFiles()
                alert('ファイルをアップロードしました。')
            })
            .catch(err => {
              　alert("ファイルのサイズが上限を超えたため、アップロードに失敗しました。1GB以下で再度アップロードしてください。")
                console.log("failed", err)
            })
            this.dialog = false
        }
        else{
          alert("アップロードをするファイルを指定してください。")
        }
      },
      fileDelete () {
        if(this.selected.length>0){
          if(confirm('選択しているファイルを削除してもよろしいですか？')){
            let deletePromise = [];
            for (var i = 0; i < this.selected.length; ++i) {
                deletePromise.push(deleteFile({eventId:this.eventId,fileName:this.selected[i].fileName}))
            }
            Promise.all(deletePromise)
            .then(res => {
              this.loadFiles()
            })
            .catch(err => {
              alert('ファイルの削除に失敗しました。管理者にお問い合わせください。')
              console.log("delete failed ", err)
            })
            alert('ファイルを削除しました。')
          }
        }
        else{
          alert("ファイルを１つ以上選択してください。")
        }
      },
      sortSharedFiles() {
        this.saveAndSort(true)
      },
      fileShare () {
        let targetIds = []
        if(this.selected.length>0){
          for (let i = 0; i < this.selected.length; ++i) {
            targetIds.push(this.selected[i].id)
          }
          this.saveAndSort(true, targetIds)
        }
        else{
          alert("ファイルを１つ以上選択してください。")
        }
      },
      fileUnShare () {
        let targetIds = []
        if(this.selected.length>0){
          for (let i = 0; i < this.selected.length; ++i) {
            targetIds.push(this.selected[i].id)
          }
          this.saveAndSort(false, targetIds)
        }
        else{
          alert("ファイルを１つ以上選択してください。")
        }
      },
      saveAndSort(isShared, argTargetIds) {
        let targetIds = argTargetIds ? argTargetIds : []
        let targetIdsSet = new Set()
        targetIds.forEach(id => {
          targetIdsSet.add(id)
        })

        let sharedFilesList = []
        this.eventFiles.forEach(file => {
          if(isShared) {
            if(!file.shared && !targetIdsSet.has(file.id)) return
          } else {
            if(targetIdsSet.has(file.id)) return
            if(!file.shared) return
          }

          sharedFilesList.push({
            id: file.id,
            fileName: file.fileName,
            sortIndex: file.sortIndex
          })
        })

        this.$refs.sortDialog.open(
          this.eventId,
          sharedFilesList,
          'この内容で共有設定を更新します。よろしいですか？'
        )
      },
      onSucceedSortDialog() {
        alert('データの共有設定を変更いたしました')
        this.loadFiles()
      },
      onFailedSortDialog() {
        alert('データの更新に失敗しました')
      },
      close () {
        this.dialog = false
      },
      customSort(items, index, isDesc) {
        items.sort((a, b) => {
          let av,bv
          if (index === "sortIndex") {
            let as = a["shared"]
            let bs = b["shared"]

            if(as != bs) {
              return as ? -1 : 1
            }
          }

          av = a[index]
          bv = b[index]

          av = (av) ? av : ''
          bv = (bv) ? bv : ''

          if (!isDesc) {
            return av < bv ? -1 : 1
          } else {
            return bv < av ? -1 : 1
          }
        })
        return items
      },
      formatDate(date){
        return date ? new Date(date).toLocaleString() : date;
      },
      formatSharedOutput(sharedstatus){
        if(sharedstatus){
          return '共有中'
        }
        else{
          return ''
        }
      },
      //ファイルを選択したときの処理
      onDrop:function(event){
        this.fileList = event.target.files
        var errorMessageArea = document.getElementById("errorMessageFile");
        if(this.duplicateFileNameCheck(this.fileList[0].name)){
          errorMessageArea.innerHTML="同じファイル名のファイルが既にアップロードされています。"
          this.buttonStatus=false
        }
        else{
          errorMessageArea.innerHTML=""
          this.buttonStatus=true
        }
      },
      duplicateFileNameCheck(inputFileName){
        let duplicateFlg = false
        for (var i = 0; i < this.eventFiles.length; ++i) {
            if(this.eventFiles[i].fileName === inputFileName){
              duplicateFlg = true
              return duplicateFlg
            }
        }
        return duplicateFlg
      },
      loadEvent() {
        getEvent({eventId:this.eventId})
        .then(res => {
            this.eventData = res.data
            this.eventName = this.eventData.eventName
            this.eventDate = this.eventData.eventDate
            this.eventFacility = this.eventData.facility.facilityName
        }).catch(err => {
            alert('データの取得に失敗しました。')
        })
      },
      loadFiles(){
        this.eventFiles = []
        this.selected = []
        getFileList({eventId:this.eventId})
        .then(res => {
            this.eventFiles = res.data
        }).catch(err => {
            alert('データの取得に失敗しました。')
        })
      }
  },
  mounted(){
        this.eventId = this.$route.query.id
        let headers = {accountType: 'organizer'};
        getMe({headers: headers, accountType:'organizer'})
        .then(res => {
            readyEditEvent({ eventId: this.eventId })
            .then(res => {
                this.loadEvent()
                this.loadFiles()
            })
            .catch(err => {
                this.$router.push('/')
            })
        }).catch(err => {
            this.$router.push('/')
        })
  },
}

</script>
<style scoped>
#inspire{
    color:#666666;
    background-color: white;
}
.tool {
    background-color: #F88366;
    color: white;
    height: 70px;
}
.eventname {
   text-align: left;
   float: left;
   font-size: 24px;
   margin-left: 50px;
   padding: 15px 0px;
}
.iconmenu {
   text-align: right;
   padding: 7px 0px;
   margin-right: 25px;
}
.title {
    text-align: left;
    margin-left: 17px;
}
.titleName{
    display:inline-block;
    vertical-align:top;
    padding-top:9px;
    padding-left:5px;
}
td {
    color:#666666;
}
.title_name{
    margin-left:25px;
    font-size:120%;
}
.modalHeader{
    background-color: #263238;
    color: white;
    height: 70px;
    font-size:160%;
    font-weight:bold;
    filter:alpha(opacity=100);
    -moz-opacity: 1;
    opacity: 1;
}
.iconStyle{
  color:rgba(0,0,0,0.54) !important;
}
</style>
