<template>
  <div>
    <v-form ref="form" v-model="valid">
      <v-container class="input-area">

        <v-flex md12>
          <h3 class="subtitle">参加料徴収設定</h3>
        </v-flex>
        <v-flex md12 v-if="isCreateNew">
          <v-switch v-model="createTicket" :label="`参加料徴収利用`"></v-switch>
        </v-flex>
        <v-layout row wrap justify-center v-if="createTicket">
          <v-flex xs12 lg10>

            <v-layout justify-center class="mb-4">
              <v-flex xs12 sm10 md7>
                <div class="note-common-use-square-account">
                  参加料徴収を行う場合、使用するSquareアカウントを指定する必要があります。
                </div>
                <div class="pa-2" style="border:solid 1px;">
                  <v-layout justify-center row wrap>
                    <v-flex xs12>
                      <h4 class="subtitle">Square連携設定</h4>
                    </v-flex>
                    <v-flex xs10 class="ma-1">
                      <v-layout row wrap>
                        <v-flex xs4 class="text-xs-left">Squareアカウント</v-flex>
                        <v-flex xs8 class="text-xs-left">
                          <template v-if="squareAccountEmail">
                            {{squareAccountEmail}}
                          </template>
                          <template v-else>
                            ( 未設定 )
                          </template>
                        </v-flex>
                        <v-flex xs4 class="text-xs-left">オンライン店舗情報</v-flex>
                        <v-flex xs8 class="text-xs-left">
                          <template v-if="squareLocationName">
                            {{squareLocationName}}
                          </template>
                          <template v-else>
                            ( 未設定 )
                          </template>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 class="mt-2">
                      <v-layout justify-center row>
                        <v-btn color="error" dark rounded slot="activator" @click.native="openSquareEventSettings">
                          Square連携設定の変更
                        </v-btn>
                        <square_event_settings_dialog ref="squareEventSettingsDialog" @save="saveSquareEventSettings"></square_event_settings_dialog>
                        <v-btn color="error" dark rounded @click.native="deleteSquareEventSettings">
                          削除
                        </v-btn>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </div>
              </v-flex>
            </v-layout>

            <div v-for="(ticket, index) in sortTicketsByPriority">
              <v-container grid-list-md class="ma-0 pa-0">
              <v-layout  wrap v-if="ticket.delFlg!==true">
                <v-flex xs1 class="text-xs-right">
                  <span v-if="ticket.id" class="ticket-id mr-4">{{ticket.id}}</span>
                </v-flex>
                <v-flex xs5>
                  <v-text-field label="参加券名称" v-model="ticket.name" placeholder="ex 入場券 VIP席"
                                :rules="ticket_nameRules"></v-text-field>
                </v-flex>
                <v-spacer></v-spacer>
                <v-flex xs2 v-if="ticket.paidTicket">
                  <v-text-field label="税込価格" prefix="￥" v-model="ticket.price" type="Integer" :rules="ticket_priceRules"
                                @keypress="num_validate" placeholder="ex 1000"></v-text-field>
                </v-flex>
                <v-flex xs2 v-else>&nbsp;</v-flex>
                <v-spacer></v-spacer>
                <v-flex xs2>
                  <v-text-field label="販売予定数" v-model="ticket.amount" type="Integer" :rules="ticket_amountRules"
                                @keypress="num_validate" placeholder="ex 50"></v-text-field>
                </v-flex>
                <v-flex xs1>
                  <v-card-actions>
                    <v-tooltip bottom>
                      <v-btn @click.stop="change_disp_ticket(ticket, index)" slot="activator" icon>
                        <v-icon color="error" v-if="ticket.noDisp">visibility_off</v-icon>
                        <v-icon color="blue" v-else>visibility</v-icon>
                      </v-btn>
                      <span>表示／非表示</span>
                    </v-tooltip>
                  </v-card-actions>
                </v-flex>
                <v-flex xs1>
                  <v-card-actions>
                    <v-tooltip bottom>
                      <v-btn @click.stop="delete_ticket(ticket, index)" slot="activator" icon>
                        <v-icon color="grey darken-1">delete_forever</v-icon>
                      </v-btn>
                      <span>削除</span>
                    </v-tooltip>
                  </v-card-actions>
                </v-flex>
                <template v-if="ticket.existDescription">
                  <v-flex xs1 offset-xs1>
                    <v-layout align-start>
                      <v-btn small @click.prevent="delete_ticket_description(index)">説明の削除</v-btn>
                    </v-layout>
                  </v-flex>
                  <v-flex xs9>&nbsp;</v-flex>
                  <v-flex xs1>&nbsp;</v-flex>
                  <v-flex xs1 offset-xs1>&nbsp;</v-flex>
                  <v-flex xs9>
                    <template  v-if="eventData.facilityId > 0">
                      <v-layout row>
                        <v-switch v-model="ticket.printIncludeDescription" @change="change_print_include_description(index)" :label="`説明を参加証に記載`"></v-switch>
                        <template v-if="ticket.printIncludeDescription">
                          <v-btn @click.native="download_sample_ticket(index)" round>参加証のサンプルをダウンロード</v-btn>
                        </template>
                      </v-layout>
                      <template  v-if="ticket.printIncludeDescription">
                        <p class="note-print-include-description">必ず参加証をダウンロードし、レイアウト崩れが発生していないかご確認ください。</p>
                      </template>
                    </template>
                    <v-textarea solo label="説明" v-model="ticket.description"
                                  :rules="[ validate_ticket_description(index) ]"></v-textarea>
                  </v-flex>
                  <v-flex xs1>&nbsp;</v-flex>
                </template>
                <template v-else>
                  <v-flex xs1 offset-xs1>
                    <v-layout align-start>
                      <v-btn small @click.prevent="add_ticket_description(index)">説明の追加</v-btn>
                    </v-layout>
                  </v-flex>
                  <v-flex xs9>&nbsp;</v-flex>
                  <v-flex xs1>&nbsp;</v-flex>
                </template>
              </v-layout>
              </v-container>
            </div>
            <div class="btn-area">
              <v-btn large @click.prevent="add_paid_ticket" class="fee" :disabled="!isAllowPaidTicket">➕　参加料徴収</v-btn>
              <v-btn large @click.prevent="add_free_ticket" class="free">➕　無料参加券</v-btn>
            </div>
            <p class="note">※お支払い方法はSquare決済のみとなります。</p>
          </v-flex>
        </v-layout>
        <v-layout row wrap v-else>
          <p class="note">
            参加券なしのイベントを作成する。
          </p>
        </v-layout>

        <hr class="submenu">

        <h3 class="subtitle">登録者によるキャンセル制御設定</h3>
          <v-flex md12>
            <v-switch v-model="controlCancel" :label="`登録者によるキャンセルを制御する`"></v-switch>
          </v-flex>
        <v-layout row wrap v-if="controlCancel">
            <v-flex md4 offset-md2　xs12>
              <div class="inputFieldTitle" style="margin-top:20px;">登録者キャンセル期限日
                <v-tooltip top>
                  <v-btn icon dark slot="activator" style="color:#999999;">
                    <v-icon lot="activator" small>info</v-icon>
                  </v-btn>
                  <span>登録者は申し込みからキャンセル期限日までの間、マイページ上からキャンセルが可能です。</span>
                </v-tooltip>
              </div>
            </v-flex>
            <v-flex md4 xs12>
              <v-text-field
                slot="activator"
                v-model="cancelDeadlineDateTime"
                @click="dialogCancelDeadline = true"
                readonly
              ></v-text-field>
              <v-dialog v-model="dialogCancelDeadline" persistent max-width="600px">
                <v-form ref="cancelDeadlineDateForm">
                  <v-card>
                    <v-card-title class="modalHeader">
                      <span class="headline">キャンセル期限日
                      </span>
                    </v-card-title>
                    <v-card-text>
                      <v-container grid-list-md>
                        <v-layout wrap>
                          <v-flex xs12 sm6>
                            <v-menu
                              ref="menu3"
                              lazy
                              v-model="cancelDeadlineDateMenu"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              full-width
                              :nudge-right="40"
                              min-width="290px"
                              :return-value.sync="cancelDeadlineDate"
                            >
                              <v-text-field
                                slot="activator"
                                v-model="cancelDeadlineDate"
                                :rules="cancelDeadlineDateRules"
                                readonly
                                clearable
                                label="期限日"
                              ></v-text-field>
                              <v-date-picker v-model="cancelDeadlineDate" no-title scrollable
                                             @input="$refs.menu3.save(cancelDeadlineDate)">
                              </v-date-picker>
                            </v-menu>
                          </v-flex>
                          <v-flex xs3>
                            <v-select
                              :items="hourData"
                              :rules="cancelDeadlineHourRules"
                              v-model="cancelDeadlineHour"
                              auto
                              label="時"
                              @change="setDeadlineTime"
                            ></v-select>
                          </v-flex>
                          <v-flex xs3>
                            <v-select
                              :items="minData"
                              :rules="cancelDeadlineMinRules"
                              v-model="cancelDeadlineMin"
                              label="分"
                              @change="setDeadlineTime"
                            ></v-select>
                          </v-flex>
                        </v-layout>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn @click="dialogCancelDeadline = false">キャンセル</v-btn>
                      <v-btn color="error" @click="setCancelDeadlineDateTime">保存</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-form>
              </v-dialog>
          </v-flex>
        </v-layout>
        <v-layout row wrap v-else>
          <v-flex md12>
            <p class="note">登録者はマイページ上からいつでも申し込みキャンセルが可能です。</p>
          </v-flex>
        </v-layout>
      </v-container>

      <v-btn class="edit-b" @click.native="goBack()">戻る</v-btn>
      <v-btn color="error" @click.native="moveNextStep()" :disabled="!valid">次へ</v-btn>
    </v-form>
  </div>
</template>

<script>
  import { saveAs } from 'file-saver'
  import moment from 'moment'
  import squareEventSettingsDialog from '@/components/square/SquareEventSettingsDialog.vue'
  import {
    checkCanBeDeleted,
    downloadSampleTicket,
    getSquareAccountSettingNames,
    getPaymentServiceStatusThisOwner,
    checkPaymentInfoCanBeDeleted
  } from '../../utils/APIUtils'
  export default {
    components: {
      'square_event_settings_dialog': squareEventSettingsDialog
    },
    props: ['eventData', 'isCreateNew'],
    watch: {
      eventData(data) {
        if (data !== undefined) {
          this.eventData = data;
          if (data.tickets !== undefined && data.tickets.length !== 0) {
            this.tickets = data.tickets;
            this.tickets.forEach(ticket => ticket.existDescription = (ticket.description != undefined && ticket.description.length > 0))
            if(this.$route.query.cp){
              this.tickets.forEach(ticket =>
                ticket["id"] = null
              )
            }
            this.createTicket = true;
          } else if(this.createTicket && data.tickets !== undefined && data.tickets.length === 0) {
            this.tickets.push(this.create_new_ticket(false, this.tickets.length + 1))
          }

          let deadlineDateTime
          if(data.cancellationDeadline === undefined || data.cancellationDeadline === null){
            this.controlCancel = false;
            this.cancelDeadlineDate = ''
            this.cancelDeadlineHour = ''
            this.cancelDeadlineMin = ''
          }
          else{
            this.controlCancel = true;
            deadlineDateTime = moment(data.cancellationDeadline).toDate()
            this.cancelDeadlineDate = deadlineDateTime.getFullYear() + "-" + ("0"+(deadlineDateTime.getMonth()+1)).slice(-2) + "-" + ("0"+deadlineDateTime.getDate()).slice(-2)
            this.cancelDeadlineHour = ("0"+deadlineDateTime.getHours()).slice(-2)
            this.cancelDeadlineMin = ("0"+deadlineDateTime.getMinutes()).slice(-2)
            this.cancelDeadlineTime = this.cancelDeadlineHour + ':' + this.cancelDeadlineMin
            this.cancelDeadlineDateTime = this.cancelDeadlineDate + ' ' + this.cancelDeadlineHour + ':' + this.cancelDeadlineMin
          }

          if(data.squareEvent) {
            let squareEvent = data.squareEvent
            this.squareEventSettings.squareAccount = {
              id : squareEvent.squareAccount.id
            }
            this.squareEventSettings.squareLocationId = squareEvent.squareLocationId
            this.squareEventSettings.companyName = squareEvent.companyName
            this.squareEventSettings.email = squareEvent.email
            this.squareEventSettings.zipCode = squareEvent.zipCode
            this.squareEventSettings.address = squareEvent.address
            this.squareEventSettings.telNumber = squareEvent.telNumber

            this.setSquareEventSettingNames()
          }
        }
      },
      controlCancel(data) {
        if(!data) {
          this.cancelDeadlineDate = ''
          this.cancelDeadlineHour = ''
          this.cancelDeadlineMin = ''
          this.cancelDeadlineDateTime = ''
        }
      }
    },
    data() {
      return {
        isDeleted: false,
        valid: true,

        squareAccountEmail: '',
        squareLocationName: '',
        squareEventSettings :{
          squareAccount: {
            id: 0
          },
          squareLocationId: '',
          companyName: '',
          email: '',
          zipCode: '',
          address: '',
          telNumber: ''
        },

        squareAccountType: '',

        tickets: [],
        ticket_nameRules: [
          v => !!v || '参加券名称を入力してください',
          v => (v && v.length <= 128) || '128文字以下で入力してください'
        ],
        ticket_amountRules: [
          v => (v != null) || '参加券販売予定数は必須です',
          v => this.is_number(v) || '半角数字で入力してください。',
        ],
        ticket_priceRules: [
          v => this.is_number(v) || '半角数字で入力してください。',
          v => (100 <= v) || '100円以上の金額を入力してください',
        ],
        createTicket: false,

        controlCancel: false,
        dialogCancelDeadline: false,
        cancelDeadlineDateTime:'',
        cancelDeadlineDate:null,
        cancelDeadlineDateRules: [
          v => this.isCancelDeadlineDateInput() || '日付が入力されていません'
        ],
        cancelDeadlineDateMenu:false,
        cancelDeadlineHour:'',
        cancelDeadlineHourRules: [v => this.isCancelDeadlineHourInput() || '時間が入力されていません'],
        cancelDeadlineMin:'',
        cancelDeadlineMinRules: [v => this.isCancelDeadlineMinInput() || '分が入力されていません'],
        cancelDeadlineTime:'',

        hourData:['','00','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23'],
        minData:['','00','05','10','15','20','25','30','35','40','45','50','55'],
      }
    },
    methods: {
      num_validate(e) {
        const charCode = (e.which) ? e.which : e.keyCode
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          e.preventDefault()
        } else {
          return true
        }
      },
      is_number(val) {
        const pattern = /^([1-9]\d*|0)$/;
        return pattern.test(val);
      },
      validate_ticket_description(index) {
        let validate = (index) => {
          let ticket = this.tickets[index]
          if(!ticket.printIncludeDescription) return true

          let lines = ticket.description.split(/\n|\r\n/)
          let lineCount = lines.length

          lines.forEach(line => {
            if(line.length > 0) {
              let chars = Array.from(line)
              let ew = 0
              chars.forEach(c => {
                ew += c.match(/[\x20-\x7e]/) ? 1 : 2
              })
              lineCount += Math.floor((ew - 1) / 50)
            }
          })

          if(lineCount > 10) return false

          return true
        }

        let result = validate(index)
        return (result) ? true : '説明文が長過ぎます。横は全角25文字（半角50文字）で10行以内（文字数超過時の折返し含む）に収めてください'

      },
      change_print_include_description(index) {
        this.$nextTick().then(() => {
          this.$refs.form.validate()
        })
      },
      openSquareEventSettings() {
        this.$refs.squareEventSettingsDialog.open(
          this.squareEventSettings,
          this.squareAccountEmail
        )
      },
      saveSquareEventSettings(settings) {
        this.squareEventSettings.squareAccount = {
          id : settings.squareAccountId
        }
        this.squareEventSettings.squareLocationId = settings.squareLocationId
        this.squareEventSettings.companyName = settings.companyName
        this.squareEventSettings.email = settings.email
        this.squareEventSettings.zipCode = settings.zipCode
        this.squareEventSettings.address = settings.address
        this.squareEventSettings.telNumber = settings.telNumber

        this.setSquareEventSettingNames()
      },
      deleteSquareEventSettings() {
        checkPaymentInfoCanBeDeleted({
          eventId: this.eventData.id
        }).then(res => {
          if(!res.data) {
            alert('有料での販売実績があるため、Square連携設定は削除できません。')
            return
          }

          if(confirm('現在のSquare連携設定を削除して良いですか？')) {
            this.squareEventSettings = {
              squareAccount: {
                id: 0
              },
              squareLocationId: '',
              companyName: '',
              email: '',
              zipCode: '',
              address: '',
              telNumber: ''
            }

            this.squareAccountEmail = ''
            this.squareLocationName = ''
          }
        }).catch(err => {
          console.log(err)
          alert('Square連携設定の削除可否判定に失敗しました。')
        })
      },
      setSquareEventSettingNames() {
        this.squareAccountEmail = ''
        this.squareLocationName = ''

        let squareAccountId = this.squareEventSettings.squareAccount.id
        let squareLocationId = this.squareEventSettings.squareLocationId

        if(squareAccountId) {
          getSquareAccountSettingNames({
            squareAccountId: squareAccountId,
            squareLocationId: squareLocationId
          }).then(res => {
            this.squareAccountEmail = res.data.squareAccount.email
            this.squareLocationName = res.data.squareLocation.name
          }).catch(err => {
            console.log('err', err)
            alert('Square決済情報の取得に失敗しました')
          })
        }
      },
      create_new_ticket(paidTicket, orderPriority) {
        return {
          id: null,
          name: '',
          paidTicket: paidTicket,
          price: '',
          amount: '',
          description: '',
          printIncludeDescription: false,
          delFlg: false,
          noDisp: false,
          orderPriority: orderPriority
        }
      },
      add_free_ticket() {
        this.tickets.push(this.create_new_ticket(false, this.tickets.length + 1))
        this.isDeleted = false;
      },
      add_paid_ticket() {
        this.tickets.push(this.create_new_ticket(true, this.tickets.length + 1))
      },
      add_ticket_description(index) {
        let ticket = this.tickets[index]
        ticket.existDescription = true
        this.tickets.splice(index, 1, ticket)
      },
      delete_ticket_description(index) {
        let ticket = this.tickets[index]
        ticket.description = ''
        ticket.existDescription = false
        this.tickets.splice(index, 1, ticket)
      },
      download_sample_ticket(index) {
        let ticket = this.tickets[index]
        let event = this.eventData
        let facility = event.facility
        let options = {
          ticketName: ticket.name,
          price: ticket.paidTicket ? ticket.price : 0,
          ticketDescription: ticket.description,
          printIncludeDescription: ticket.printIncludeDescription,

          eventName: event.eventName,
          eventDate: event.eventDate,
          startTime: event.startTime,
          endTime: event.endTime,
          eventEndDate: event.eventEndDate,
          eventDisplayPriceOfFreeTickets: event.displayPriceOfFreeTickets,

          facilityName: facility.facilityName,
          facilityAddress: facility.address,
          facilityMapUrl: facility.mapUrl,
        }

        let saveByteArray = function (data, name) {
          let blob = new Blob(data, {type: "application/pdf"})
          saveAs(blob, name)
        };

        downloadSampleTicket(options).then(res => {
          saveByteArray([res.data], 'sample-ticket.pdf')
        })
      },
      change_disp_ticket(row, i) {
        if(this.tickets[i].noDisp) {
          this.tickets[i].noDisp = false
        } else {
          if (this.tickets.filter(e => {return !e['delFlg'] && !e['noDisp']}).length !== 1) {
            this.tickets[i].noDisp = true
          } else {
            alert('参加券をすべて消すことはできません。')
          }
        }
      },
      delete_ticket(row, i) {
        if (this.tickets.filter(e => {return !e['delFlg'] && !e['noDisp']}).length !== 1) {
          if (row.id === null) {
            this.tickets.forEach((ticket, index) => {
              if (i === index) {
                this.tickets.splice(index, 1)
              }
            });
          } else {
            checkCanBeDeleted({ticketId: row.id}).then(res => {
              this.tickets[i]['delFlg'] = res.data
              if(!res.data) {
                alert('購入者が存在する参加券は削除できません。')
              }
            }).catch(err => {
              alert('編集時にエラーが発生しました。再度登録を行ってください。。')
              console.log(err)
            })
          }
        } else {
          alert('参加券をすべて消すことはできません。')
        }
      },
      changeOrder() {

      },
      setDeadlineTime(){
        if(this.cancelDeadlineHour !== '' || this.cancelDeadlineMin !== ''){
          this.cancelDeadlineTime = this.cancelDeadlineHour + ":" +  this.cancelDeadlineMin
        }
      },
      setCancelDeadlineDateTime(){
        if(this.$refs.cancelDeadlineDateForm.validate()){
          this.cancelDeadlineDateTime = ''
          if(this.cancelDeadlineDate === undefined || this.cancelDeadlineDate !== ''){
            this.cancelDeadlineDateTime = this.cancelDeadlineDate + ' ' + this.cancelDeadlineHour + ':' + this.cancelDeadlineMin
          }
          this.dialogCancelDeadline = false
        }
      },
      isCancelDeadlineDateInput(){
        if ((this.cancelDeadlineMin !== '' || this.cancelDeadlineHour !== '') && this.cancelDeadlineDate === null) {
          return false
        }
        return true
      },
      isCancelDeadlineInput(){
        if(this.cancelDeadlineMin === '' && this.cancelDeadlineHour === '' && this.cancelDeadlineDate === null) {
          return false
        }
        return true
      },
      isCancelDeadlineHourInput(){
        if ((this.cancelDeadlineDate !== '' || this.cancelDeadlineMin !== '') && this.cancelDeadlineHour === '') {
          return false
        }
        return true
      },
      isCancelDeadlineMinInput(){
        if ((this.cancelDeadlineDate !== '' || this.cancelDeadlineHour !== '') && this.cancelDeadlineMin === '') {
          return false
        }
        return true
      },
      moveNextStep() {
        if(this.$refs.form.validate()) {
          if (this.createTicket) {
            if (this.tickets.length === 0) {
              alert('参加券を一つ以上設定してください。')
              return false;
            }
            let uniqueName = [...new Map(this.tickets.map((t) => [t.name, t])).values()];
            if (uniqueName.length !== this.tickets.length) {
              alert('参加券名称は一意で設定してください。')
              return false;
            }
          } else {
            this.tickets = [];
          }
          let cancelDeadLine
          if (this.cancelDeadlineDate !== '') {
            cancelDeadLine = this.cancelDeadlineDate + " " + this.cancelDeadlineTime + ":00"
          } else {
            cancelDeadLine = null
          }
          this.$emit('forward', {
            tickets: this.tickets,
            createTicket: this.createTicket,
            cancellationDeadline: cancelDeadLine,
            squareEvent: (this.squareLocationName != null && this.squareLocationName.length > 0) ? this.squareEventSettings : null
          });
        }
      },
      goBack() {
        this.$emit('goBack')
      },
      setPaymentStatusThisOwner() {
        this.squareAccountType = ''

        getPaymentServiceStatusThisOwner().then(res => {
          let squareStatus = res.data.square
          if(squareStatus) {
            this.squareAccountType = squareStatus.squareAccountType
          } else {
            console.log("error : Illegal payment method.")
            // alert("決済情報の取得に失敗しました。")
          }
        }).catch(err => {
          console.log(err)
          // alert("決済情報の取得に失敗しました。")
        })
      }
    },
    computed: {
      isAllowPaidTicket() {
        return (this.squareLocationName != null && this.squareLocationName.length > 0)
      },
      sortTicketsByPriority() {
        return this.tickets.sort((a, b) => {
          return  (a.orderPriority > b.orderPriority) ? 1 : (a.orderPriority < b.orderPriority) ? -1 : 0;
        });
      }
    },
    mounted() {
      // this.setPaymentStatusThisOwner()
    },
  }
</script>

<style scoped>
  .subtitle{
    text-align: left;
    color: #666666;
  }

  .note {
    text-align: center;
    font-size: 18px;
    width: 100%;
    color: #666666;
  }
  .note-print-include-description {
    width: 100%;
    text-align: center;
    font-size: 12px;
    color: #f8504a;
  }
  .note-common-use-square-account {
    text-align: left;
    color: #666666;
  }

  .ticket-id {
    color: #666666;
  }

  .input-area {
    margin-bottom: 0px;
  }

  .fee {
    margin: 30px;
  }

  .free {
    margin: 30px;
  }

  .submenu{
    margin: 30px 0;
  }
</style>

