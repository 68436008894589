<template>
  <AdminSidebar>
    <Loading></Loading>
    <v-container fluid>
      <v-flex>
        <p class="title">
          <v-icon large>mdi-how-to-reg</v-icon>
          <span class="titleName">イベントタイムライン</span></p>

        <v-form ref="form">
          <v-simple-table>
            <template v-slot:default>
              <thead class="text-left">
              <tr>
                <th>
                  項目
                </th>
                <th>
                  日時
                </th>
              </tr>
              </thead>
              <tbody class="text-left">
              <tr>
                <td>申請開始</td>
                <td>
                  <datetime type="datetime" v-model="form.registrationStartAt"></datetime>
                </td>
              </tr>
              <tr>
                <td>申請締め切り</td>
                <td>
                  <datetime type="datetime" v-model="form.registrationEndAt"></datetime>
                </td>
              </tr>
              <tr>
                <td>一次受験票公開</td>
                <td>
                  <datetime type="datetime" v-model="form.writtenExamTicketOpenAt"></datetime>
                </td>
              </tr>
              <tr>
                <td>一次結果発表</td>
                <td>
                  <datetime type="datetime" v-model="form.writtenExamResultOpenAt"></datetime>
                </td>
              </tr>
              <tr>
                <td>二次受験票公開</td>
                <td>
                  <datetime type="datetime" v-model="form.oralExamTicketOpenAt"></datetime>
                </td>
              </tr>
              <tr>
                <td>二次結果発表</td>
                <td>
                  <datetime type="datetime" v-model="form.oralExamResultOpenAt"></datetime>
                </td>
              </tr>
              <tr>
                <td>変更締め切り</td>
                <td>
                  <datetime type="datetime" v-model="form.updateDeadline"></datetime>
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>

          <v-btn @click.stop="update">更新する</v-btn>

        </v-form>


      </v-flex>
    </v-container>
  </AdminSidebar>
</template>
<script>
import AdminSidebar from './AdminSidebar'
import {mapGetters} from 'vuex'
import axios from 'axios'
import Loading from '../../event/components/Loading'

export default {
  components: {AdminSidebar, Loading},
  data () {
    return {
      loading: false,
      form: {
        registrationStartAt: null,
        registrationEndAt: null,
        writtenExamTicketOpenAt: null,
        writtenExamResultOpenAt: null,
        oralExamTicketOpenAt: null,
        oralExamResultOpenAt: null,
        updateDeadline: null,
      }
    }
  },
  computed: {
    ...mapGetters([
      'eventId'
    ]),
  },
  async created () {
    this.loading = true
    this.$store.commit('setEventId', this.$route.params.eventId)
    await this.getEventTimeline()
    this.loading = false
  },
  methods: {
    async getEventTimeline () {
      let {data} = await axios.get('/api/timeline/' + this.eventId)
      Object.assign(this.form, data)
    },
    async update () {
      if (!this.$refs.form.validate()) {
        return
      }
      try {
        this.loading = true
        let {data} = await axios.post('/api/timeline/' + this.eventId, this.form)
        Object.assign(this.form, data)
        this.$emit('toast', '変更しました')
      } catch (e) {
        this.$emit('error', '更新に失敗しました')
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
