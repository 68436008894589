// For Organizer
import Vue from 'vue'
import VueRouter from 'vue-router'
import Meta from 'vue-meta'

import CreateNewEvent from '@/components/CreateEvent/CreateNewEvent'
import MailHistory from '@/components/MailHistory'
import MailDetail from '@/components/MailDetail'
import FileShare from '@/components/FileShare'
import RegistrationStatus from '@/components/RegistrationStatus'
import TicketSales from '@/components/TicketSales'
import CancelInformation from '@/components/CancelInformation'
import Signout from '@/components/Signout'
import CreateEmail from '@/components/CreateEmail'
import PasswordReset from '@/components/PasswordReset'
import PasswordSetting from '@/components/PasswordSetting'
import Publish from '@/components/Publish'
import OperationLog from '@/components/OperationLog'
import Group from '@/components/Group'
import Invitation from '@/components/Invitation'
import CustomMailTemplates from '../components/custommailtemplate/CustomMailTemplates'
import EventList from './views/EventList'
import Signin from '../components/Signin'
import EventTop from './views/EventTop'
import VisitorDetail from './views/VisitorDetail'
import EventInfo from './views/EventInfo'
import VenueList from '../components/VenueList'
import AccountList from '../components/AccountList'
import EditEntry from './views/EditEntry'
import EditSubject from './views/EditSubject'
import Timeline from './views/Timeline'
import Attendance from './views/Attendance'

Vue.use(VueRouter)
Vue.use(Meta)


const router = new VueRouter({
  base: '/admin',
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Signin',
      component: Signin
    },
    {
      path: '/Signout',
      name: 'Signout',
      component: Signout
    },
    {
      path: '/EventList',
      name: 'EventList',
      component: EventList
    },
    {
      name: 'event',
      path: '/event/:eventId',
      component: EventTop,
      props: true
    },
    {
      path: '/event/:eventId/visitor/:visitorId',
      component: VisitorDetail,
      props: true
    },
    {
      name: "editEntry",
      path: '/event/:eventId/visitor/:visitorId/entry',
      component: EditEntry,
      props: true
    },
    {
      name: "editSubjectEntry",
      path: '/event/:eventId/visitor/:visitorId/subject',
      component: EditSubject,
      props: true
    },
    {
      name: 'info',
      path: '/event/:eventId/info',
      component: EventInfo,
      props: true
    },
    {
      path: '/event/:eventId/master',
      component: EventTop,
      props: true
    },
    {
      name: 'venue',
      path: '/event/:eventId/venue',
      component: VenueList,
      props: true
    },
    {
      path: '/event/:eventId/account',
      name: 'account',
      component: AccountList,
      props: true
    },
    {
      path: '/event/:eventId/timeline',
      name: 'timeline',
      component: Timeline,
      props: true
    },
    {
      path: '/event/:eventId/attendance',
      name: 'attendance',
      component: Attendance,
      props: true
    },
    // {
    //   path: '/event/:eventId/venue',
    //   component: VenueList,
    //   props: true
    // },
    {
      path: '/CreateNewEvent',
      name: 'CreateNewEvent',
      component: CreateNewEvent
    },
    {
      path: '/MailHistory',
      name: 'MailHistory',
      component: MailHistory
    },
    {
      path: '/MailDetail',
      name: 'MailDetail',
      component: MailDetail
    },
    {
      path: '/FileShare',
      name: 'FileShare',
      component: FileShare
    },
    {
      path: '/RegistrationStatus',
      name: 'RegistrationStatus',
      component: RegistrationStatus
    },
    {
      path: '/TicketSales',
      name: 'TicketSales',
      component: TicketSales
    },
    {
      path: '/CancelInformation',
      name: 'CancelInformation',
      component: CancelInformation
    },
    // {
    //   path: '/EventSchedule',
    //   name: 'EventSchedule',
    //   component: EventSchedule
    // },
    // {
    //   path: '/EventScheduleList',
    //   name: 'EventScheduleList',
    //   component: EventScheduleList
    // },
    {
      path: '/CreateEmail',
      name: 'CreateEmail',
      component: CreateEmail
    },
    {
      path: '/PasswordReset',
      name: 'PasswordReset',
      component: PasswordReset
    },
    {
      path: '/PasswordSetting',
      name: 'PasswordSetting',
      component: PasswordSetting
    },
    {
      path: '/Publish',
      name: 'Publish',
      component: Publish
    },
    {
      path: '/OperationLog',
      name: 'OperationLog',
      component: OperationLog
    },
    // {
    //   path: '/TermsOfService',
    //   name: 'TermsOfServicePage',
    //   component: TermsOfServicePage
    // },
    // {
    //   path: '/SquareAccounts',
    //   name: 'SquareAccounts',
    //   component: SquareAccounts
    // },
    // {
    //   path: '/SquareAccountGrantStatus',
    //   name: 'SquareAccountGrantStatus',
    //   component: SquareAccountGrantStatus
    // },
    // {
    //   path: '/SquareOAuthBridge',
    //   name: 'SquareOAuthBridge',
    //   component: SquareOAuthBridge
    // },
    // {
    //   path: '/SquareOAuthReceiver',
    //   name: 'SquareOAuthReceiver',
    //   component: SquareOAuthReceiver
    // },
    {
      path: '/Group',
      name: 'Group',
      component: Group
    },
    {
      path: '/Invitation',
      name: 'Invitation',
      component: Invitation
    },
    {
      path: '/CustomMailTemplates',
      name: 'CustomMailTemplates',
      component: CustomMailTemplates
    },
  ],
  scrollBehavior (to, from, savedPosition) {
    return {x: 0, y: 0} // トップに移動
  }
})

// router.beforeEach((to, from, next) => {
//   console.log(to, from, store.state)
//   if (to.matched.some(record => record.meta.requiresAuth) && !store.state.loggedIn) {
//     next('/')
//   } else {
//     next()
//   }
// })
export default router
//
// Vue.use(VueAnalytics, {
//   id: process.env.ANALYTICS_ID_MEMBER,
//   debug: {
//     enabled: true
//   },
//   router
// })
