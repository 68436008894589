<template>
  <AdminSidebar>
    <v-container fluid>
      <v-flex md12>
        <p class="title">
          <v-icon large class="icon_design">location_city</v-icon>
          <span class="title_name">会場一覧</span></p>
        <v-spacer></v-spacer>
      </v-flex>
      <v-layout row wrap>
        <v-flex md9>
        </v-flex>
        <v-flex md3 style="text-align:right;">
          <v-dialog v-model="dialog" max-width="670px">
            <template v-slot:activator="{ on }">
              <v-btn
                color="primary"
                dark
                v-on="on"
              >
                <v-icon dark small>add_circle</v-icon>
                会場追加
              </v-btn>
            </template>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-card>
                <v-card-title class="modalHeader">
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container grid-list-md>
                    <v-layout wrap>

                      <v-flex md12>
                        <v-select v-model="editedItem.areaId" :items="areaOptions" label="エリア"
                                  :rules="required"
                                  required></v-select>
                      </v-flex>
                      <v-flex md12>
                        <v-text-field v-model="editedItem.facilityName" :rules="facilityNameRules" label="会場名"
                                      required></v-text-field>
                      </v-flex>
<!--                      <v-flex md12>-->
<!--                        <v-text-field v-model="editedItem.receptionCounter" label="受付場所"-->
<!--                                      required></v-text-field>-->
<!--                      </v-flex>-->
                      <v-flex md12>
                        <v-text-field v-model="editedItem.facilityAddress" :rules="facilityAddressRules" label="住所"
                                      required></v-text-field>
                      </v-flex>
                      <v-flex md12 class="guide">
                        <v-textarea
                          label="交通案内"
                          v-model="editedItem.guide"
                          required
                        ></v-textarea>
                      </v-flex>
                      <v-flex md12>
                        <v-text-field v-model="editedItem.mapUrl" label="MAPURL"
                                      required></v-text-field>
                      </v-flex>
                      <v-flex md12>
                        <template v-for="i in editedItem.venues">

                          <v-row>
                            <v-col>
                              <span v-if="i.id" class="text-caption">部屋ID:{{ i.id }}</span>
                            </v-col>
                            <v-col>
                              <v-text-field v-model="i.venueName" label="部屋"
                                            append-icon="close"
                                            :rules="[v => !!v || '必須です',]"
                                            @click:append="editedItem.venues.splice(editedItem.venues.indexOf(i),1)"
                              ></v-text-field>
                            </v-col>
                            <v-col>
                              <v-select v-model="i.subjectId" label="科目" :items="subjectOptions"></v-select>
                            </v-col>
                            <v-col>
                              <v-text-field type="number" v-model="i.capacity" label="収容人数"
                                            min="0"></v-text-field>
                            </v-col>
                            <v-col>
                              <v-text-field type="number" v-model="i.priority" label="優先順位"
                                            min="0"></v-text-field>
                            </v-col>
                          </v-row>
                          <!--                          <v-text-field v-model="i.venueName" label="部屋"-->
                          <!--                                        append-icon="close"-->
                          <!--                                        :rules="[v => !!v || '必須です',]"-->
                          <!--                                        @click:append="editedItem.venues.splice(editedItem.venues.indexOf(i),1)"-->
                          <!--                          >-->
                          <!--                            <template v-slot:prepend-inner>-->
                          <!--                              <span v-if="i.id" class="text-caption">部屋ID:{{i.id}}</span>-->
                          <!--                            </template>-->
                          <!--                            <template v-slot:append>-->
                          <!--                              <v-text-field v-model="i.subjectName" label="科目"></v-text-field>-->
                          <!--                              <v-text-field type="number" v-model="i.capacity" label="収容人数"></v-text-field>-->
                          <!--                              <v-text-field type="number" v-model="i.priority" label="優先度"></v-text-field>-->
                          <!--                            </template>-->

                          <!--                          </v-text-field>-->

                        </template>
                        <v-btn @click="editedItem.venues.push({})" text color="info">部屋の追加</v-btn>
                      </v-flex>


                      <v-spacer></v-spacer>
                      <v-flex md12 class="text-md-center">
                        <v-btn class="edit-b" @click.native="close">閉じる</v-btn>
                        <v-btn color="error" :disabled="!valid" @click="submit">保存</v-btn>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-form>
          </v-dialog>
        </v-flex>
      </v-layout>
      <v-flex md12>
        <v-data-table
          :headers="headers"
          :items="facilities"
          :search="search"
          v-model="selected"
          item-key="id"
          show-expand
          sort-by="id"
        >
          <template v-slot:top>
            <v-text-field
              v-model="search"
              label="Search"
              class="mx-4"
            ></v-text-field>
          </template>
          <template slot="item.facilityName" slot-scope="props">
            <td class="text-md-left">{{ props.item.facilityName }}</td>
          </template>
          <template slot="item.lastUpdateDate" slot-scope="props">
            <td class="text-md-left">{{ formatDate(props.item.lastUpdateDate) }}</td>
          </template>

          <template slot="item.action" slot-scope="props">
            <td class="justify-center layout px-0">
              <v-icon small class="mr-2" @click="editItem(props.item)">mdi-pencil</v-icon>
              <v-icon small class="mr-2" @click="deleteItem(props.item)">mdi-delete</v-icon>
            </td>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="pa-5">
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th class="text-left"> 部屋ID</th>
                    <th class="text-left"> 部屋名</th>
                    <th class="text-left"> 科目</th>
                    <th class="text-left"> 収容人数</th>
                    <th class="text-left"> 優先順位</th>
                  </tr>
                  </thead>
                  <tbody class="text-left">
                  <tr v-for="item in item.venues" :key="item.id">
                    <td>{{ item.id }}</td>
                    <td>{{ item.venueName }}</td>
                    <td><span v-if="item.subject">{{ item.subject.title }}</span></td>
                    <td>{{ item.capacity }}</td>
                    <td>{{ item.priority }}</td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </td>
          </template>
        </v-data-table>
      </v-flex>
    </v-container>
  </AdminSidebar>
</template>

<script>
import header from '@/components/AdminHeader.vue'
import {createFacility, deleteFacility, getAllVenues, updateFacility} from '../utils/APIUtils'
import AdminSidebar from '../admin/views/AdminSidebar'
import axios from 'axios'

export default {
  name: 'venueList',
  components: {
    'middle_header': header,
    AdminSidebar
  },
  data () {
    return {
      search: '',
      selected: [],
      dialog: false,
      valid: true,
      facilities: [],
      googleMapCode: '',

      rowsPerPageItems: [10, 25, 50, 100],
      pagination: {
        rowsPerPage: 100,
      },

      headers: [
        {
          text: '会場ID',
          value: 'id'
        },
        {
          text: 'エリア',
          value: 'area.title'
        },
        {
          text: '会場名',
          value: 'facilityName'
        },
        {
          text: '住所',
          value: 'facilityAddress'
        },
        {
          text: '更新日時',
          value: 'lastUpdateDate',
          width: 180
        },
        {
          text: 'action',
          value: 'action',
          width: 30
        }
      ],
      facilityTypes: [
        {text: 'TKP', value: 'TKP'},
        {text: '提携先', value: 'PARTNER'},
        {text: '固有', value: 'CLIENT'},
      ],
      selectableFacilityTypes: [
        {text: '提携先', value: 'PARTNER'},
        {text: '固有', value: 'CLIENT'},
      ],

      editedIndex: -1,
      editedItem: {
        id: '',
        facilityName: '',
        receptionCounter: '',
        facilityAddress: '',
        facilityType: '',
        mapUrl: '',
        googleMapUrl: '',
        clientId: '',
        guide: '',
        venues: [],
        areaId: null
      },
      facilityNameRules: [
        v => !!v || '会場名は必須です',
        v => (v && v.length <= 128) || '128文字以下にしてください'
      ],
      facilityAddressRules: [
        v => !!v || '住所は必須です',
        v => (v && v.length <= 128) || '128文字以下にしてください'
      ],
      facilityTypeRules: [
        v => !!v || 'タイプは必須です'
      ],
      clientRules: [
        v => !!v || 'クライアントは必須です'
      ],
      required: [
        v => v == 0 || !!v || '必須です'
      ],
      defaultItem: {
        id: '',
        facilityName: '',
        receptionCounter: '',
        facilityAddress: '',
        facilityType: '',
        mapUrl: '',
        googleMapUrl: '',
        guide: '',
        venues: [],
        areaId: null
      },
      mapName: 'googleMapArea',
      clients: [],
      areas: [],
      subjects: []
    }
  },
  computed: {
    formTitle () {
      return this.editedIndex === -1 ? '会場追加' : '会場編集'
    },
    subjectOptions () {
      return this.subjects.map(v => {
        return {
          text: v.title,
          value: v.id
        }
      })
    },
    areaOptions () {
      return this.areas.map(v => {
        return {
          text: v.title,
          value: v.id
        }
      })
    }
  },
  mounted () {
    let headers = {accountType: 'organizer'}
    // getMe({headers: headers, accountType: 'organizer'})
    // .then(res => {
    //     this.$emit('loggedin', res.data)
    //     this.$router.replace({path: '/VenueList'})
    //     this.load()
    //     })
    // .catch(err => {
    //     this.$router.push('/')
    // })
    this.load()
  },
  watch: {
    dialog (val) {
      val || this.close()
    }
  },
  methods: {
    join: item => item.organizer.affiliation + '（' + item.customerId + '）',
    load () {
      getAllVenues().then(res => {
        this.facilities = res.data.map(i => ({
          id: i.id,
          value: false,
          facilityName: i.facilityName,
          receptionCounter: i.receptionCounter,
          facilityAddress: i.address,
          facilityType: i.type,
          googleMap: i.googleMapUrl,
          mapUrl: i.mapUrl,
          clientId: i.client != null ? i.client.id : null,
          lastUpdateDate: i.updatedAt,
          guide: i.guide,
          venues: i.venues.map(v => {
            v.subjectId = v.subject && v.subject.id
            v.capacity = v.capacity || 0
            v.priority = v.priority
            return v
          }),
          area: i.area,
          areaId: i.area && i.area.id
        }))
      }).catch(err => {
        console.log(err)
      })
      // getClients().then(res => {
      //   this.clients = res.data
      // })
      this.fetchSubjects()
      this.fetchAreas()
    },
    async fetchSubjects () {
      let {data} = await axios.get('/api/subject')
      this.subjects = data.subjects.filter(v => v.isLanguage)
      // this.exemptions = data.exemptions
      // this.writtenExamAreas = data.writtenExamAreas
      // this.oralExamAreas = data.oralExamAreas
    },
    async fetchAreas () {
      let {data} = await axios.get('/api/area')
      this.areas = data
    },
    formatDate (updateAtDate) {
      return updateAtDate ? new Date(updateAtDate).toLocaleString() : updateAtDate
    },
    editItem (item) {
      this.editedIndex = this.facilities.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.editedItem.venues = [...item.venues]
      this.dialog = true
    },

    deleteItem (item) {
      const index = this.facilities.indexOf(item)
      if (!confirm('データを削除してもよろしいですか？')) {
        return
      }

      deleteFacility({
        id: item.id
      }).then(res => {
        this.facilities.splice(index, 1)
        alert('削除が完了しました。')
      }).catch(err => {
        alert('削除に失敗しました。')
      })
    },
    close () {
      this.dialog = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
      this.googleMapCode = ''
    },

    //一覧にデータを差し込む
    submit () {
      if (!this.$refs.form.validate() || !confirm('データを登録してもよろしいですか？')) {
        return
      }
      if (this.editedItem.id === '') {
        //会場データの新規登録
        createFacility({
          data: {
            facilityName: this.editedItem.facilityName,
            receptionCounter: this.editedItem.receptionCounter,
            address: this.editedItem.facilityAddress,
            type: this.editedItem.facilityType,
            mapUrl: this.editedItem.mapUrl,
            googleMapUrl: this.editedItem.googleMap,
            clientId: this.editedItem.clientId,
            guide: this.editedItem.guide,
            venues: this.editedItem.venues,
            areaId: this.editedItem.areaId
          }
        }).then(res => {
          this.load()
          this.close()
          alert('データを登録しました。')

        }).catch(err => {
          console.log(err)
          alert('データの登録に失敗しました。')
        })
      } else {
        //会場データの更新
        updateFacility({
          id: this.editedItem.id,
          data: {
            facilityName: this.editedItem.facilityName,
            receptionCounter: this.editedItem.receptionCounter,
            address: this.editedItem.facilityAddress,
            type: this.editedItem.facilityType,
            mapUrl: this.editedItem.mapUrl,
            googleMapUrl: this.editedItem.googleMap,
            clientId: this.editedItem.clientId,
            guide: this.editedItem.guide,
            venues: this.editedItem.venues,
            areaId: this.editedItem.areaId
          },
        }).then(res => {
          this.load()
          this.close()
          alert('データを更新しました。')
        }).catch(err => {
          console.log(err)
          alert('データの更新に失敗しました。')
        })
      }
    },
    openNewVenue () {
      this.editedItem.facilityName = ''
      this.editedItem.facilityAddress = ''
    },
  }
}

</script>

<style scoped>
#inspire {
  color: #666666;
  background-color: white;
}

.icon_design {
  vertical-align: middle;
  line-height: 1;
}

.title {
  text-align: left;
  margin-left: 30px;
}

.title_name {
  margin-left: 25px;
  font-size: 120%;
}

td {
  color: #666666;
}

.modalHeader {
  background-color: #263238;
  color: white;
  height: 70px;
  font-size: 160%;
  font-weight: bold;
  filter: alpha(opacity=100);
  -moz-opacity: 1;
  opacity: 1;
}

.google-map {
  width: 100%;
}
</style>

