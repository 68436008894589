<template>
  <v-dialog persistent v-model="showSelf" max-width="700px">
    <v-form ref="editForm" lazy-validation>
      <v-card>
        <v-card-title class="modalHeader">
          <span class="headline">{{selfTitle}}</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-container grid-list-md style="margin-left:0px;margin-right:0px;max-width:100%;">
              <v-layout row wrap>
                <v-flex xs12>
                  <v-text-field label="名称" v-model="templateTitle" :rules="titleRules" required></v-text-field>
                </v-flex>
                <v-flex xs6 class="text-xs-left">
                  <v-btn @click="showPreview()">プレビュー</v-btn>
                </v-flex>
                <v-flex xs6 class="text-xs-right">
                  <v-btn @click="selectTemplate">テンプレートをコピー</v-btn>
                  <v-dialog v-model="selectTemplateDialog" max-width="624">
                    <v-card>
                      <v-card-text>
                        <v-select
                          label="コピー元メールテンプレート"
                          v-model="selectedTemplate"
                          :items="mailTemplates"
                          item-text="template_title"
                          item-value="template_title"
                          return-object
                        ></v-select>
                        <v-btn @click="selectTemplateDialog = false">キャンセル</v-btn>
                        <v-btn color="error" @click="setMailTemplate()">選択</v-btn>
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                </v-flex>
                <v-flex xs12>
                  <v-textarea
                    label="本文"
                    v-model="content"
                    rows="8"
                    :rules="contentRules"
                    required
                    @contextmenu="show"
                  ></v-textarea>
                  <v-menu
                    v-model="showMenu"
                    :position-x="x"
                    :position-y="y"
                    absolute
                    offset-y
                  >
                    <v-list>
                      <v-list-tile
                        v-for="(item, index) in rightClickMenuItems"
                        :key="index"
                        @click="insertCode(item.value)"
                      >
                        <v-list-tile-title>{{item.title}}</v-list-tile-title>
                      </v-list-tile>
                    </v-list>
                  </v-menu>
                </v-flex>
                <v-flex xs12>
                  <v-textarea label="メモ" v-model="memo" required></v-textarea>
                </v-flex>
                <v-flex xs12>
                  <v-spacer></v-spacer>

                  <v-btn class="edit-b" @click.native="closeSelf">閉じる</v-btn>
                  <v-btn color="error" @click="save">保存</v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-container>
        </v-card-text>
      </v-card>
    </v-form>

    <preview-dialog ref="previewDialog"></preview-dialog>
  </v-dialog>
</template>

<script>
  import moment from 'moment'
  import previewDialog from '@/components/custommailtemplate/CustomMailTemplatePreviewDialog.vue'
  import {
    createCustomMailTemplate,
    getAllCustomMailTemplatesList,
    updateCustomMailTemplate
  } from '../../utils/APIUtils'
  export default {
    name: "mailTemplateEditDialog",
    components : {
      'preview-dialog' : previewDialog
    },
    data() {
      return {
        titleRules: [
          v => !!v || '名称は必須です',
          v => (v && v.length <= 64)|| '64文字以下にしてください'
        ],
        contentRules: [
          v => !!v || '本文は必須です'
        ],

        showSelf: false,
        isEditedWindow: false,
        selfTitle: "",
        disabledPreview: true,

        entityId: 0,
        templateTitle: null,
        content: null,
        memo: null,

        showMenu: false,
        x: 0,
        y: 0,
        rightClickMenuItems:[],

        selectTemplateDialog: false,
        selectedTemplate: {},
        mailTemplates:[],
      }
    },
    methods: {
      showPreview() {
        this.$refs.previewDialog.openPreview(this.content)
      },
      selectTemplate() {
        this.selectTemplateDialog = true
      },
      setMailTemplate() {
        if(!confirm("編集中の内容が、テンプレートの内容で上書きされ消去されますがよろしいですか？")) {
          this.selectedTemplate = null
          return
        }
        this.content = this.selectedTemplate.contents
        this.selectTemplateDialog = false
      },
      clearEditData() {
        this.entityId = 0
        this.templateTitle = null
        this.content = null
        this.memo = null
      },
      assignEditData(item) {
        this.entityId = item.id
        this.templateTitle = item.templateTitle
        this.content = this.convertToSimpleText(item.content)
        this.memo = item.memo
      },
      openForCreate() {
        this.clearEditData()
        this.selfTitle = "新規追加"
        this.isEditedWindow = false
        this.readyMailTemplates()
        this.showSelf = true
      },
      openForEdit(item) {
        this.clearEditData()
        this.assignEditData(item)
        this.selfTitle = "登録内容編集"
        this.isEditedWindow = true
        this.readyMailTemplates(item.id)
        this.showSelf = true
      },
      closeSelf() {
        this.showSelf = false
        this.clearEditData()
      },
      save() {
        if(!this.$refs.editForm.validate()) return

        if(this.isEditedWindow) {
          if (!confirm("この内容で更新してもよろしいでしょうか？")) return

          updateCustomMailTemplate({
            data: {
              id : this.entityId,
              templateTitle : this.templateTitle,
              content : this.convertToRichText(this.content),
              memo: this.memo,
            }
          }).then(() => {
            alert("テンプレートを更新しました")
            this.$emit('completeEdit')
            this.closeSelf()
          }).catch(err => {
            alert("テンプレートの更新に失敗しました")
          })
        } else {
          createCustomMailTemplate({
            data: {
              templateTitle : this.templateTitle,
              content : this.convertToRichText(this.content),
              memo: this.memo,
            }
          }).then(() => {
            alert("テンプレートを追加しました")
            this.$emit('completeEdit')
            this.closeSelf()
          }).catch(err => {
            alert("テンプレートの追加に失敗しました")
          })
        }
      },
      show (e) {
        e.preventDefault()
        this.showMenu = false
        this.x = e.clientX
        this.y = e.clientY
        this.showMenu = true
      },
      insertCode(value){
        let textarea = document.querySelector('textarea')
        let sentence = textarea.value
        let len      = sentence.length
        let pos      = textarea.selectionStart
        let before   = sentence.substr(0, pos)
        let after    = sentence.substr(pos, len)
        this.content = before + value + after
      },
      convertToRichText(textareaData){
        if(!textareaData) return ''

        //ダブルクォートをシングルクォートに置換
        textareaData = textareaData.replace(/"/g, '\'')
        //改行コードをタグに置換
        textareaData = textareaData.replace(/\r?\n/g, '<br/>')
        return textareaData
      },
      convertToSimpleText(textareaData) {
        if(!textareaData) return ''

        // シングルクォートをダブルクォートに置換
        textareaData = textareaData.replace('\'', /"/g)
        // タグを改行コードへ置換
        textareaData = textareaData.replace(/<br\/>/g,  "\r\n")
        return textareaData
      },
      setRightClickMenu() {
        this.rightClickMenuItems.push({ title: '姓' ,value:'{{lastName}}'})
        this.rightClickMenuItems.push({ title: '名' ,value:'{{firstName}}'})
        this.rightClickMenuItems.push({ title: '部署名' ,value:'{{departmentName}}'})
        this.rightClickMenuItems.push({ title: '会社名' ,value:'{{organizationFullName}}'})
        this.rightClickMenuItems.push({ title: '役職名' ,value:'{{jobTitle}}'})
        this.rightClickMenuItems.push({ title: 'イベント名' ,value:'{{eventTitle}}'})
        this.rightClickMenuItems.push({ title: 'イベント開始日（YYYY-MM-DD）' ,value:'{{eventDate}}'})
        this.rightClickMenuItems.push({ title: 'イベント終了日（YYYY-MM-DD）' ,value:'{{eventEndDate}}'})
        this.rightClickMenuItems.push({ title: 'イベント開始時刻（hh:mm）' ,value:'{{startTime}}'})
        this.rightClickMenuItems.push({ title: 'イベント終了時刻（hh:mm）' ,value:'{{endTime}}'})
        this.rightClickMenuItems.push({ title: '開催場所' ,value:'{{eventFacility}}'})
        this.rightClickMenuItems.push({ title: 'QRコード' ,value:'{{myQrcodeUrl}}'})
        this.rightClickMenuItems.push({ title: 'マイページリンク' ,value:'{{eventMyPageUrl}}'})
        this.rightClickMenuItems.push({ title: '招待ページリンク' ,value:'{{invitationPageUrl}}'})
      },
      readyMailTemplates(currentId) {
        let obj = require('@/assets/mail_template.json')
        this.mailTemplates = JSON.parse(JSON.stringify(obj))
        getAllCustomMailTemplatesList()
          .then(res => {
            let customList = res.data.items
            customList.sort((a, b) => {
              return (a.templateTitle <= b.templateTitle) ? -1 : 1}
            )
            customList.forEach(item => {
              if(currentId != undefined && currentId == item.id) return
              this.mailTemplates.push({
                template_title: '(カスタム)' + item.templateTitle,
                contents: this.convertToSimpleText(item.content)
              })
            })
          }).catch(err => {
          console.log(err)
          alert('カスタムメールテンプレートの読み込みに失敗しました')
        })
      }
    },
    mounted() {
      this.setRightClickMenu()
    }
  }
</script>

<style scoped>

</style>
