<template>
    <div>
        <v-container class="input-area">
            <v-form ref="form" v-model="valid">
                <v-layout row wrap>
                    <v-flex xs12 md10 offset-md1 lg8 offset-lg2>
                        <v-container fluid>
                            <v-layout row wrap>
                            <v-flex xs12>
                                <div class="inputFieldTitle" style="text-align: left;">トップカバー画像
                                  <span style="font-size:12px;">推奨画像サイズ（縦:300px 横:600px）</span>
                                </div>

                                <div id="image-area"   v-bind:style="{ backgroundImage: 'url(' + imageUrl + ')' }" style="background-size:contain;border: 1px solid #DCDCDC; border-radius: 10px;">
                                        <div>
                                            <div id="drag-drop-note">

                                            </div>
                                        </div>
                                </div>
                              <div>
                                <input type="file" id="fileElem" accept="image/*" @change="upload">
                                <span class="tooltip1 button-area" v-on:click="deleteImage" v-if="this.coverImageUrl !== ''">
                                    <v-icon small class="inner">close</v-icon>
                                    <span class="description1" >ファイル削除</span>
                                </span>
                              </div>
                            </v-flex>

                              <v-flex xs12 style="margin-top:30px;">
                                <v-radio-group v-model="information.editorType" @change="onChangeEditorTypeForInformation()" row hide-details style="margin-top:5px;">
                                  <v-radio label="通常エディタ" value="normal"></v-radio>
                                  <v-radio label="リッチテキストエディタ" value="rich"></v-radio>
                                </v-radio-group>
                              </v-flex>
                              <v-flex xs12>
                                <div class="inputFieldTitle" style="text-align: left;">概要文など
                                  <span style="font-size:12px;">上部に表示される文字などご入力ください。</span><span
                                  v-if="information.editorType == 'rich'" style="font-size:12px; white-space: nowrap;" :class="classInformationLength">（ソース文字数：{{informationLength}}/2000）</span>
                                </div>
                                <template v-if="information.editorType == 'rich'">
                                  <ckeditor
                                    v-model="information.valueForRichEditor"
                                    :editor-url="editorUrl"
                                    :config="editorConfig"
                                  ></ckeditor>
                                </template>
                                <template v-else>
                                  <v-textarea v-model='information.valueForNormalEditor' solo counter="2000" rows="7"
                                              style="width:100%;"></v-textarea>
                                </template>
                              </v-flex>

                              <v-flex xs12 style="margin-top:30px;">
                                <v-radio-group v-model="organizerInfo.editorType" @change="onChangeEditorTypeForOrganizerInfo()" row hide-details style="margin-top:5px;">
                                  <v-radio label="通常エディタ" value="normal"></v-radio>
                                  <v-radio label="リッチテキストエディタ" value="rich"></v-radio>
                                </v-radio-group>
                              </v-flex>
                              <v-flex xs12>
                                <div class="inputFieldTitle" style="text-align: left;">主催者情報
                                  <span style="font-size:12px;">会社名、所在地、問い合わせ先などをご入力ください。</span><span
                                    class="essential">必須</span>
                                  <template v-if="organizerInfo.editorType == 'rich'">
                                    <br />
                                    <span
                                      v-if="organizerInfoLength > 0"
                                      style="font-size:12px; white-space: nowrap;"
                                      :class="classOrganizerInfoLength"
                                    >（ソース文字数：{{organizerInfoLength}}/2000）</span>
                                    <span
                                      v-else
                                      style="font-size:12px; white-space: nowrap;"
                                      class="ckeditor-error-message"
                                    >主催者情報は必須です。</span>
                                  </template>
                                </div>
                                <template v-if="organizerInfo.editorType == 'rich'">
                                  <ckeditor
                                    v-model="organizerInfo.valueForRichEditor"
                                    :editor-url="editorUrl"
                                    :config="editorConfig"
                                  ></ckeditor>
                                </template>
                                <template v-else>
                                  <v-textarea
                                    v-model='organizerInfo.valueForNormalEditor'
                                    :rules="organizerInfoRules"
                                    solo
                                    counter="2000"
                                    rows="7"
                                    style="width:100%;"></v-textarea>
                                </template>
                              </v-flex>

                              <v-flex xs12 style="margin-top:45px;">
                                <div class="inputFieldTitle" style="margin-bottom:-14px;">プライバシーポリシーURL<span
                                  class="essential">必須</span></div>
                                <v-text-field
                                  v-model="privacyPolicyUrl"
                                  :rules="privacyPolicyUrlRules"
                                  style="width:100%; border: 1px solid #DCDCDC; height:35px; border-radius: 1px; padding:1px; padding-left:5px;"
                                  autocomplete="nope"
                                ></v-text-field>
                              </v-flex>

                            <v-flex xs12 style="margin-top:30px;" >
                                <div class="inputFieldTitle" style="text-align: left;">公開設定</div>
                                <v-radio-group v-model="isPrivate" row style="margin-top:5px;">
                                    <v-radio label="公開" value="false"></v-radio>
                                    <v-radio label="限定公開" value="true"></v-radio>
                                </v-radio-group>
                            </v-flex>
                            <v-flex md6 sm6 xs12 v-if="isPrivate === 'true'" style="margin-top:-30px;">
                                <v-text-field
                                    v-model="password"
                                    :rules="passwordRules"
                                    label="パスワード"
                                    required
                                    autocomplete="nope"
                                    >
                                </v-text-field>
                            </v-flex>
                            </v-layout>
                        </v-container>
                    </v-flex>
                </v-layout>
                <v-btn class="edit-b" @click.native="goBack()">戻る</v-btn>
                <v-btn color="error" :disabled="!imageUploadCheck" @click.prevent="moveNextStep()">プレビュー</v-btn>
            </v-form>
        </v-container>
    </div>
</template>

<script>
import { uploadPhoto } from '../../utils/APIUtils'
export default {
    props: ['eventData'],
    computed: {
        classInformationLength() {
            return (this.information.valueForRichEditor.length > 2000) ? "ckeditor-error-message" : ""
        },
        classOrganizerInfoLength() {
            let length = this.organizerInfo.valueForRichEditor.length
            return (length < 1 || 2000 < length) ? "ckeditor-error-message" : ""
        },
        informationLength: function() {
            return this.information.valueForRichEditor.length
        },
        organizerInfoLength: function() {
            return this.organizerInfo.valueForRichEditor.length
        }
    },
    watch: {
        eventData(data){
            this.eventData = data
            if(this.eventData.id !== undefined){
                //Edit
                this.imageUrl = this.eventData.coverImageUrl
                this.coverImageUrl = this.eventData.coverImageUrl
                this.information = this.genHtmlTextObject(
                    this,
                    this.eventData.editorTypeForInformation,
                    this.eventData.information
                )
                this.organizerInfo = this.genHtmlTextObject(
                    this,
                    this.eventData.editorTypeForOrganizerInfo,
                    this.eventData.organizerInfo
                )
                this.privacyPolicyUrl = this.eventData.privacyPolicyUrl
                this.password = this.eventData.password
                if(this.eventData.private || this.eventData.isPrivate==='true') this.isPrivate = 'true'
                else this.isPrivate = 'false'
            }
        }
    },
    data() {
        return {
            valid:true,
            imageUploadCheck:true,
            coverImageUrl: '',
            imageUrl:'',
            FileInputRules: [v => !v || v.size < 10000000 || 'ファイルサイズが大きすぎます。10MB以下のファイルを選択してください。'],
            information: {
                editorType: 'normal',
                valueForNormalEditor: '',
                valueForRichEditor: '',
            },
            organizerInfo: {
                editorType: 'normal',
                valueForNormalEditor: '',
                valueForRichEditor: '',
            },
            organizerInfoRules: [v => !!v || '主催者情報は必須です'],
            privacyPolicyUrl:'',
            privacyPolicyUrlRules: [
                v => !!v || 'プライバシーポリシーURLは必須です',
                v => (v && v.length <= 255)|| '255文字以下で入力してください',
                v => /https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]\.[^\s]{2,}/.test(v) || 'URLの形式で入力してください',
            ],
            isPrivate:'false',
            password:null,
            passwordRules: [
                v => !!v || "パスワードは必須です",
                v => (v && v.length <= 55 && v.length >= 4 && /^[0-9a-zA-Z]+$/i.test(v))|| 'パスワードは4-55文字以内の半角英数字で設定してください'
            ],

            editorUrl: '/static/ckeditor/ckeditor.js',
            editorConfig: {},
            editorCustomConfig: {
                // The configuration of the editor.
                enterMode: 2,   //br
                shiftEnterMode: 1,    //p
                contentsCss: '/static/ckeditor_eventsite.css'
            },
        }
    },
    methods: {
        createImage(file) {
            let reader = new FileReader();
            reader.onload = (e) => {
                this.imageUrl = e.target.result;
            };
            reader.readAsDataURL(file);
        },
        moveNextStep() {
            let informationLength = this.information.getValueLengthForCheck()
            if (informationLength > 2000) {
                alert("概要文などはソース文字数が2000文字以内になるよう設定してください。")
                return
            }
            let organizerInfoLength = this.organizerInfo.getValueLengthForCheck()
            if (organizerInfoLength < 1) {
                alert("主催者情報は必須です。")
                return
            }
            if (organizerInfoLength > 2000) {
                alert("主催者情報はソース文字数が2000文字以内になるよう設定してください。")
                return
            }
            if (this.$refs.form.validate()) {
                this.$emit("forward", {
                    coverImageUrl: this.coverImageUrl,
                    imageUrl:this.imageUrl,
                    information: this.information.getValueForSave(),
                    editorTypeForInformation: this.information.editorType,
                    organizerInfo: this.organizerInfo.getValueForSave(),
                    editorTypeForOrganizerInfo: this.organizerInfo.editorType,
                    privacyPolicyUrl:this.privacyPolicyUrl,
                    isPrivate:this.isPrivate,
                    password:this.password,
                })
            }
        },
        goBack() {
            this.$emit("goBack")
        },
        upload(){
            this.imageUploadCheck = false
            let input = document.getElementById("fileElem")
            let file = input.files[0]

            if(file.name.length > 100) {
                alert("ファイル名が長過ぎます。ファイル名は100文字以内で指定してください。")
                this.imageUploadCheck = true;
                return;
            }

            let imageUrls = []
            uploadPhoto(file,this.eventData.organizerId)
            .then(res => {
                this.imageUrl = res.data
                imageUrls = this.imageUrl.split('?')
                this.coverImageUrl = imageUrls[0]
                this.imageUploadCheck = true
            })
            .catch(err => {
                if (err.response.status === 500 || err.response.status === 413) {
                    alert("カバー写真のサイズが上限を超えたため、アップロードに失敗しました。10MB以下で再度アップロードしてください。")
                } else {
                    alert("アップロードに失敗しました。")
                }
                this.imageUploadCheck = true
                console.log("failed", err)
            })
        },
        deleteImage() {
            if(this.coverImageUrl !== null) {
                if(confirm('トップカバー画像を削除してよろしいですか？')) {
                    this.imageUrl = ''
                    this.coverImageUrl = ''
                    this.imageUploadCheck = true
                    alert('トップカバー画像を削除しました。')
                    console.log("this.coverImageUrl", this.coverImageUrl)
                }
            }
        },
        onChangeEditorTypeForInformation() {
            if(this.information.isSwitchToRich()) {
                if(!confirm('リッチテキスト編集に切り替えると、現在編集中の内容が変更される可能性があります。よろしいですか？')) {
                    this.$nextTick().then(() => {
                        this.information.setToPrevEditorType()
                    })
                    return
                }
            }
            this.information.switchEditor()
        },
        onChangeEditorTypeForOrganizerInfo() {
            if(this.organizerInfo.isSwitchToRich()) {
                if(!confirm('リッチテキスト編集に切り替えると、現在編集中の内容が変更される可能性があります。よろしいですか？')) {
                    this.$nextTick().then(() => {
                        this.organizerInfo.setToPrevEditorType()
                    })
                    return
                }
            }
            this.organizerInfo.switchEditor()
        },
        genHtmlTextObject(owner, editorType, htmlText) {
            let regex = /<br\s*[\/]?>\r?\n?/gi
            let valueForNormalEditor = htmlText.replace(regex,'\n')

            return {
                owner: owner,
                editorType: editorType,
                valueForNormalEditor: valueForNormalEditor,
                valueForRichEditor: htmlText,
                prevEditorType: editorType,
                getValueForSave: function() {
                    if(this.isEditorTypeNormal()) {
                        let value = this.valueForNormalEditor
                        value = value.replace(/\r?\n/g, '<br />')
                        return value
                    }
                    if(this.isEditorTypeRich()) {
                        return this.valueForRichEditor
                    }
                    return ""
                },
                getValueLengthForCheck() {
                    if(this.isEditorTypeNormal()) {
                        return this.valueForNormalEditor.length
                    } else {
                        return this.valueForRichEditor.length
                    }
                },
                isSwitchToRich: function() {
                    if (this.editorType == this.prevEditorType) {
                        return false
                    }

                    return this.isEditorTypeRich()
                },
                switchEditor: function() {
                    if (this.editorType == this.prevEditorType) {
                        return
                    }

                    if(this.isEditorTypeNormal()) {
                        this.prevEditorType = this.editorType
                        let regex = /<br\s*[\/]?>\r?\n?/gi
                        this.valueForNormalEditor = this.valueForRichEditor.replace(regex,'\n')
                    }
                    if(this.isEditorTypeRich()) {
                        this.prevEditorType = this.editorType
                        this.valueForRichEditor = this.valueForNormalEditor.replace(/\r?\n/g, '<br />')
                    }
                },
                setToPrevEditorType: function() {
                    this.editorType = this.prevEditorType
                },
                isEditorTypeNormal: function() {
                    return (this.editorType == 'normal')
                },
                isEditorTypeRich: function() {
                    return (this.editorType == 'rich')
                }
            }
        }
    },
    mounted() {
        const drop_area = document.getElementById('image-area')
        function highlight(e) {
            drop_area.classList.add('highlight')
        }
        function unhighlight(e) {
            drop_area.classList.remove('highlight')
        }
        function preventDefaults (e) {
            e.preventDefault()
            e.stopPropagation()
        }
        function handleDrop(e) {
            let dt = e.dataTransfer
            let files = dt.files

            handleFiles(files)
        }
        function uploadFile(file) {
            let url = 'YOUR URL HERE'
            let formData = new FormData()
            formData.append('file', file)
        }

        this.information = this.genHtmlTextObject(
            this,
            'normal',
            ''
        )
        this.organizerInfo = this.genHtmlTextObject(
            this,
            'normal',
            ''
        )
    }
}
</script>

<style scoped>
.input-area{
     margin-bottom: 0px;
}

#image-area {
    padding: 10%;
    text-align: center;
    height: 40vh;
    max-height: 400px;
}

#drag-drop-note {
    font-size: large;
    margin: 30px;
}

.highlight {
    background: #e1e5ed
}
.inputFieldTitle{
        color:#666666;
        text-align:left;
        font-weight:bold;
}
.essential{
    background-color: #f44336;
    color: white;
    font-size: 13px;
    font-weight: bold;
    padding: 4px 0.5em;
    vertical-align: top;
    margin-left: 1.1em;
    border-radius: 10px;        /* CSS3草案 */
    -webkit-border-radius: 10px;    /* Safari,Google Chrome用 */
    -moz-border-radius: 10px;   /* Firefox用 */
}
.button-area{
  text-align: center;
}
.inner{
  display: inline-block; /* インライン要素にすることで横に回り込む */
  vertical-align: middle; /* 上下中央に寄せる */
}
.tooltip1{
  position: relative;
  cursor: pointer;
  display: inline-block;
}
.description1 {
  display: none;
  position: absolute;
  padding: 5px;
  font-size: 12px;
  /*line-height: 1.6em;*/
  color: #fff;
  border-radius: 5px;
  background: rgba(0,0,0,0.7);
  width: 100px;
}
.description1:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  /*border: 15px solid transparent;*/
  /*border-top: 15px solid #000;*/
  margin-left: -15px;
}
.tooltip1:hover .description1{
  display: inline-block;
  top: 30px;
  left: -30px;
}
.ckeditor-error-message {
  color: #f8504a;
}
</style>


